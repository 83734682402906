import { createChatBotMessage } from 'react-chatbot-kit';
import Avatar from './components/Avatar';
import UserOption from './components/UserOption'; 
import data from './data'; 


const config = {
    botName: "Syncuppro",
    initialMessages: [createChatBotMessage(`Welcome to Syncuppro! Select your preference.`, 
    {
        widget: "userOption"
        })],
    
    customComponents: {
        botAvatar: (props) => <Avatar {...props} />,

    },
    state: {
        checker: null,
        data,
        userData: {            
            category: {},
            
        }
    },
    widgets: [
        {
            widgetName: "userOption",
            widgetFunc: (props) => <UserOption {...props} />,
        },
        {
            widgetName: "faq",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.faq.link} target="_blank" >{props.state.data.faq.name}</a>,
        },
        {
            widgetName: "contract",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.contract.link} target="_blank" >{props.state.data.contract.name}</a>,
        },
        {
            widgetName: "privacy",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.privacy.link} target="_blank" >{props.state.data.privacy.name}</a>,
        },
        {
            widgetName: "terms",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.terms.link} target="_blank" >{props.state.data.terms.name}</a>,
        },
        {
            widgetName: "cookie",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.cookie.link} target="_blank" >{props.state.data.cookie.name}</a>,
        },
        {
            widgetName: "conduct",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.conduct.link} target="_blank" >{props.state.data.conduct.name}</a>,
        } 
        ,
        {
            widgetName: "payment",
            widgetFunc: (props) => <a className="m-l-40" href={props.state.data.payment.link} target="_blank" >{props.state.data.payment.name}</a>,
        } 
    ]
};

export default config;