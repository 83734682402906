import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { ProposalStatusUpdate, CreateAlert } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays"; 
import ModalJobProposal from "../../components/JobProposal/ClientJobProposal";
import JobProposal from "../../components/JobProposal/JobProposal";
import Message from "../../components/Message";
import AlertUtils from "../../utils/AlertUtils";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
 

export default function Proposals(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [proposalList, setProposalList] = useState([]);
    const [currentValue, setCurrentValue] = useState({ id: null });
    const [id, setId] = useState(null);
    const [message, setMessage] = React.useState(false);
    const [proposalData, setproposalData] = useState({});
    const [proposalModalVisible, setProposalModalVisible] = useState(false);
    const [editproposalModalVisible, setEditProposalModalVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(proposalList, page, gContext.paginationSize);

    const toggleProposalModal = () => {
        setProposalModalVisible(!proposalModalVisible);
    };
    const toggleEditProposalModal = () => {
        setEditProposalModalVisible(!editproposalModalVisible);
    };
    function ViewProposal(data) {
        setproposalData(data);
        toggleProposalModal();
    }
    function EditProposal(data) {
        setproposalData(data);
        toggleEditProposalModal();
    }
    const callConfirm = (mode, id, data) => {

        if (id) {
            setproposalData(data);
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + mode + " this proposal?", ["confirmed"]: false, id: id, showButton: true, mode: mode ,page:"proposal"});
            gContext.toggleConfirmModal();
        }
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed && gContext.confirmModal.page=="proposal" ) {
            updateStatus(gContext.confirmModal.id, gContext.confirmModal.mode);
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["page"]:""
            });
        }
        else { setCurrentValue({ id: null,page:"" }); }
    }, [gContext.confirmModal]);

    async function SendNotification() {

        const profileInfo = gContext.userInfo.profileInfo;


        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = AlertUtils.ProposalWithdrawn.
                replace("[EXPERTSUB]", gContext.userInfo.sub).
                replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                replace("[JOBTITLE]", proposalData.title).
                replace("[JOBGUID]", proposalData.guid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: proposalData.clientid });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    const updateStatus = async (id, mode) => {
        gContext.setLoaderModalVisible(true);
        try {
            // 500 to withdraw
            const result = await ProposalStatusUpdate(id, 500);

            if (result.data.proposalStatusUpdate.responseData.code == "200") {
                SendNotification();
                props.callbackUpdateProposal();
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }

    function LoadProposal() {
        if (props.proposalData) {
            setPage(1);
            let data;
            if (props.mode === "open") {
                data = props.proposalData.submitted
            }
            else if (props.mode === "active") {
                data = props.proposalData.active;
            }
            else if (props.mode === "withdrawn") {
                data = props.proposalData.withdrawn;
            }
            else if (props.mode === "archived") { data = props.proposalData.archived }

            setProposalList(data);
            setLoaded(true);
        }
    }
    useEffect(() => {

        LoadProposal();
    }, [props.proposalData]);

    useEffect(() => {

        LoadProposal();
    }, [props.mode]);

    useEffect(() => {
        document.title = "Proposals | Syncuppro";
    }, []);
    return (
        <>


            {message && <div className="col-lg-12 col-xl-12 text-left ml-6">
                <Message message="Job deleted successfully" />
            </div>}

            {(loaded && proposalList && proposalList.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No proposal found</span>
            </div>}
            {(loaded && proposalList && proposalList.length > 0) && <>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                <th
                                    className="w-200 min-lg-cell"
                                >
                                    Job Title
                        </th>
                                <th
                                    className="  min-lg-cell"
                                >
                                    Company
                        </th> <th
                                    className="  min-lg-cell"
                                >
                                    Rate
                        </th>
                                <th
                                    className="  min-lg-cell"
                                >
                                    Bid Rate
                        </th>
                                <th                                   
                                    className="  min-lg-cell"
                                >Submitted on</th>
                                {(props.mode !== "open") && <> <th className="  min-lg-cell">
                                    {(props.mode === "withdrawn") ? "Withdrawn on" : (props.mode === "archived") ? "Archived on" : " Shortlisted on"}
                                </th></>  }

                               

                             


                            </tr>
                        </thead>
                        <tbody>
                            {slice && slice.map((data, index) => {

                                return (
                                    <tr key={index}>
                                        
                                        <td>
                                            <span className="d-flex justify-content-left align-items-left">
                                                <span className="title d-flex pointer" data-toggle="tooltip" title={data.title} onClick={() => { window.location.href = "job/" + data.guid; }}><span className="one-line">{data.title}</span> </span>
                                                    
                                                </span>
                                            </td>
                                        
                                        
                                        <td className="d-none">
                                            <div className="d-flex justify-content-left align-items-left">
                                                <div className="info-meta company pointer" data-toggle="tooltip" title={data.title} >  <span className=" table-title one-line text-black pointer" onClick={() => { window.location.href = "job/" + data.guid; }}>
                                                    {data.title} </span> 
                                                </div></div>
                                           
                                        </td> 
                                        <td>

                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="info-meta company pointer" onClick={(e) => { window.open("/client/" + data.sub, "_blank") }}   >
                                                    <img className="m-r-10 "  src={s3URL + data.logo} alt={data.company} />
                                                    <span className="one-line text-black ">{data.company} </span> 
                                                    
                                                </div>

                                               
                                            </div>
                                        </td>
                                        <td>
                                            ${(data.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.dailyrate)) : ((data.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.fixedrate)) : (data.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.hourlyrate)) : (data.ratefrom && data.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(data.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(data.rateto)) : '-')}
                                        </td>
                                        <td>${(data.bid) ? new Intl.NumberFormat('en-US').format(parseInt(data.bid)) : ''}</td>
                                        <td>
                                            {  (data.datecreated ? getMonthDateYear(data.datecreated) : "")}</td>
                                       
                                        {(props.mode !== "open") && <td> {(data.datemodified) ? getMonthDateYear(data.datemodified) : ""}</td>}
                                        <td>
                                            <div className="btn-table">
                                                <button className="btn btn-sm btn-gray"  onClick={() => (ViewProposal(data))}>
                                                    View</button>

                                                {(props.mode === "open" || props.mode === "job") && data.statuscode == 100 &&

                                                    <button className="btn btn-sm btn-gray"  onClick={() => { setId(data.guid); EditProposal(data); }}>
                                                        Edit</button>
                                                }{(data.statuscode == 100) &&
                                                    <button className="btn btn-sm btn-gray"  onClick={() => { callConfirm('withdraw', data.id, data) }}>
                                                        Withdraw</button>

                                                }



                                            </div>
                                        </td>
                                    </tr>)
                            })}</tbody>
                    </table>
                </div>
                {(loaded && proposalList && proposalList.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                <div className="bg-white  pt-5   pb-9 px-2 d-none">
                    <div className="table-responsive ">
                        <table className="table table-striped">
                            <thead>
                                <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                    <th
                                        scope="col"
                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                       Job Title
                        </th>
                                    <th
                                        scope="col"
                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Company
                        </th> <th
                                        scope="col"
                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Rate
                        </th>
                                    <th
                                        scope="col"
                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Bid Rate
                        </th>
                                    <th
                                        scope="col"
                                        className="pl-5 table-y-middle text-center  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Submitted Date 
                                    </th>
                                    <th
                                        scope="col"
                                        className="pl-5 table-y-middle text-center  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        {(props.mode === "withdrawn") ? "Withdrawn Date" : (props.mode === "archived") ? "Archived Date" : " Proposed Date"}
                                    </th>
                                    <th
                                        scope="col"
                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    ></th>


                                </tr>
                            </thead>
                            <tbody>
                                {proposalList && proposalList.map((data, index) => {

                                    return (
                                        <tr className="border border-color-2" key={index}>


                                            <th
                                                scope="row"
                                                className="pl-6 border-0 py-5  max-width-px-235 "
                                            >

                                                <div className="max-width-px-235 media" >
                                                    <a data-toggle="tooltip" title={data.title} style={{ wordWrap: "break-word" }} className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer max-width-px-235 ellipsis" target="_blank" rel="noopener noreferrer" href={"job/" + data.guid} >
                                                        {data.title}
                                                    </a> <i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-3 text-right justify-content-md-end"></i>
                                                </div>

                                            </th>

                                            <td className="table-y-middle py-5 min-width-px-235">
                                                <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={"/client/" + data.sub}  >
                                                    <div className="circle-30 mr-6">
                                                        <img src={s3URL + data.logo} alt="" className=" circle-30 w-100" />
                                                    </div>
                                                    <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                        {data.company} <i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-1"></i>
                                                    </h4>
                                                </a>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 text-right">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    <i className="fas fa-dollar   text-green-2"></i>
                                                    {(data.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.dailyrate)) : ((data.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.fixedrate)) : (data.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.hourlyrate)) : (data.ratefrom && data.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(data.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(data.rateto)) : '-')}
                                                </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 text-right">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    <i className="fas fa-dollar   text-green-2"></i>{(data.bid) ? new Intl.NumberFormat('en-US').format(parseInt(data.bid)) : ''}
                                                </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-135 text-center">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">

                                                    {(data.statuscode === 500 || data.statuscode === 400) ? ((data.datemodified) ? getMonthDateYear(data.datemodified) : "") : (data.datecreated ? getMonthDateYear(data.datecreated) : "")}
                                                </h3>
                                            </td>
                                            <td className="table-y-middle  w-100 pr-0 media text-right">
                                                <a className="border font-size-3 font-weight-bold  px-5 rounded-5 mr-1 text-green-2  border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer"  onClick={() => (ViewProposal(data))}>
                                                    View
                              </a>
                                                {(props.mode === "open" || props.mode === "job") && data.statuscode == 100 &&
                                                    <div data-toggle="tooltip"  >

                                                        <a className="border font-size-3 font-weight-bold  px-5 rounded-5 mr-1 text-red  border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer"  onClick={() => { setId(data.guid); EditProposal(data); }}>
                                                            Edit
                              </a>

                                                    </div>}{(data.statuscode == 100) && <div data-toggle="tooltip"  >

                                                        <a className="border font-size-3 font-weight-bold  px-5 rounded-5 mr-1 text-red  border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer"  onClick={() => { callConfirm('withdraw', data.id, data) }}>
                                                            Withdraw
                              </a>

                                                    </div>}
                                            </td>


                                        </tr>)
                                })}


                            </tbody>
                        </table>
                    </div>
                </div></>}

            {proposalModalVisible && <ModalJobProposal proposaldata={proposalData} id={proposalData.guid} proposalmodalvisible={proposalModalVisible} toggleproposalmodal={toggleProposalModal} />
            }  {editproposalModalVisible && <JobProposal mode="edit" id={id} proposaldata={proposalData} proposalModalVisible={editproposalModalVisible} toggleProposalModal={toggleEditProposalModal} />

            }</>
    );
};