import React, { useContext, useEffect, useState } from "react"; 
import { ExpertFileUpload, getExpertFile, createDirName } from "../../utils/fileUpload";
import { Select } from "../../components/Core";
import GlobalContext from "../../context/GlobalContext"; 
import { sinceDate, getMonthDateYear } from "../../utils/addDays"; 
import DOMPurify from 'isomorphic-dompurify';
import { CreateMilestone, UpdateMilestone, DeleteMilestone, CreateAlert } from "../../utils/apiCalls";
import { LoadAllMilestoneStatus } from "../../utils/GetAPIUtils";
import { isInvalid } from "../../utils/helperFn";
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import validator from 'validator';
import ReactQuill from 'react-quill';
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import AlertUtils from "../../utils/AlertUtils";
import CardForm from "../payment/CardPayment";


const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;
export default function Milestones(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [attachments, setAttachments] = useState([]);
    const [statusList, setstatusList] = useState([]); 
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [statusFullList, setstatusFullList] = useState([]);
    const taskQuillRef = React.useRef();
    const crNote1QuillRef = React.useRef();
    const crNote2QuillRef = React.useRef();
    const rejectedReasonQuillRef = React.useRef();
    const addtaskQuillRef = React.useRef();
    const [mode, setMode] = useState(""); 
    const [togSetting, setTogSetting] = useState(false);
    const gContext = useContext(GlobalContext);
    const [mileStones, setMileStones] = useState({});
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = useState(false);
    const [milestoneDetail, setMilestoneDetail] = useState({ editvalue: "", editTest: "", editLength: 0 });
    const [milestoneEditDetail, setMilestoneEditDetail] = useState({ editvalue: "", editTest: "", editLength: 0 });
    const [rejectedReason, setRejectReason] = useState({ editvalue: "", editTest: "", editLength: 0 });
    const [crNote1, setcrNote1] = useState({ editvalue: "", editTest: "", editLength: 0 });
    const [crNote2, setcrNote2] = useState({ editvalue: "", editTest: "", editLength: 0 });
    const [values, setValues] = useState({ id: null, rejectedReason: "", statusCode: null, hoursSpent: null, status: null, statusId: null, task: "", amount: null, resetAttachment: '', attachmentName: '', attachment: null, taskDetailText: "", taskDetail: "", taskDetailEditText: "", taskEditDetail: "", dueDate: "", crdueDate: "", crNote1: "", crNote2: "", attachment: "", error: { attachment: "", task: "", hoursSpent: "", amount: "", taskDetail: "", dueDate: "", crdueDate: "", crNote1: "", crNote2: "", status: "", taskEditDetail: "", rejectedReason: "", taskDetailText: "" } });
    const [showPayment, setShowPayment] = useState(false);
    const [page, setPage] = useState(1);
    const [milestonePage, setMilestonePage] = useState(1);
    const { slice, range } = tableHelper(attachments, page, gContext.paginationSize);
    const milestoneValues = tableHelper(props.milestones ? props.milestones : [], milestonePage, gContext.paginationSize);
    const [successModVisible, setSuccessModVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const popoverAttachment = (
        <Popover className="   " style={{ zIndex: "99999999999" }}>

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>Allowed format pdf,jpeg,png,jpg</p>

            </div>
        </Popover>
    );
    function getStatusValue(data) {
        if (data.mspaymentstatuscode == 100) {

            var paymentDate = new Date(data.completeddate);
            paymentDate.setDate(paymentDate.getDate() + 14);
            return "Payment will be made by " + getMonthDateYear(paymentDate);
        }
        else if (data.mspaymentstatuscode == 200) {
            if (data.approveddate != null)
                return "Payment will be made by " + getMonthDateYear(data.approveddate);
            else if (data.rejecteddate != null)
                return "Payment will be made by " + getMonthDateYear(data.rejecteddate);
            else
                return ""
        }
        else
            return "";
    }
    async function SendNotificationtoExpert(msSeqNo) {

        const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
 
        setErrorMessage("");
        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            let alert = AlertUtils.MilestonetoExpert.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company : "").
                replace("[TITLE]", props.contract.title).
                replaceAll("[GUID]", props.contract.contractguid).
                replace("[MILESTONE]", ((values.id) ? "MS" + mileStones.seq : "MS" + msSeqNo) + " - " + values.task).
                replace("[STATUS]", ((values.id && values.status && values.status.label) ? values.status.label : "New"));

            if (values.id) {
                
                if (values.status && values.status.code == 200) {
                    alert = alert + " Let's get to work!";
                }
                else if (values.status && values.status.code == 100) {
                    alert = alert + " Client will change status to Inprogress to begin working.";
                }
                else if (values.status && values.status.code == 450) {
                    alert = alert + " Review, make changes to Milestone, and update client.";
                }
                else if (values.status && values.status.code == 350) {
                    alert = alert + " Congratulations!!! Client approved milestone. Payment pending.";
                }
                else if (values.status && values.status.code == 500) {
                    alert = alert + " 50% payment will be processed.";
                }
            }
            else {
                alert = alert + " Client will change status to Inprogress to begin working.";
            }


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: (props.contract) ? props.contract.expertid : null });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);

            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }

    async function SendNotificationtoClient() {

        const profileInfo = gContext.userInfo.profileInfo;

        setErrorMessage("");
        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]

            let alert = AlertUtils.MilestonetoClient.
                replace("[TITLE]", props.contract.title).
                replace("[MILESTONE]", ((values.id) ? "MS" + mileStones.seq : "") + " - " + values.task).
                replaceAll("[GUID]", props.guid);

            if (gContext.userInfo.userType == "1") {
                alert = alert.replace("[EXPERTSUB]", gContext.userInfo.sub).
                    replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "");
            }
            else {
                alert = alert.replace("/expert/[EXPERTSUB]", "/client/" + props.contract.sub).
                    replace("[EXPERTNAME]", props.contract.hiringperson);
            } 
            if (values.status.code == 300) {
                alert = alert + "Ready for review.";
            }
            else if (values.status.code == 350) {
                alert = alert.replace("Completed", "Approved") + " Initiate payment processing.";
            }
            else if (values.status.code == 500) {
                alert = alert.replace("Completed", "Not Approved") + " Initiate 50% payment for the expert.";
            }

            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: props.contract.clientid });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    useEffect(() => {
        document.title = "Milestone | Syncuppro";
        if (gContext.userInfo.userType == 2)
            setData(JSON.parse(gContext.userInfo.profileInfo))
    }, []);
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    async function getUtils() {

        const result = await LoadAllMilestoneStatus();
        if (result.data && result.data.loadAllMilestoneStatus && result.data.loadAllMilestoneStatus.responseData.code == "200") {

            let data = JSON.parse(result.data.loadAllMilestoneStatus.responseData.message);

            let status = [];
            data.map((value) => (
                status.push({ value: value.id, code: value.code, label: value.status })));

            setstatusFullList(status);
        }

    }
    const onFileChange = (event, index) => {
        setMessage(false);
        if (event.target.files && event.target.files.length > 0) {

            TestFileType(event.target.files[0], ['gif', 'jpg', 'png', 'jpeg', 'pdf'], index);

        }
    }

    function TestFileType(fileName, fileTypes, index) {


        var type = fileName.type.substring(fileName.type.indexOf("/") + 1, fileName.type.length);

        if ((fileTypes.indexOf(type) != -1) == true) {

            const rowsInput = [...attachments];

            rowsInput[((page - 1) * gContext.paginationSize) + index]["file"] = fileName;
            rowsInput[((page - 1) * gContext.paginationSize) + index]["fileName"] = fileName["name"];
            setAttachments(rowsInput); 

        }


    }
    useEffect(() => {
        getUtils();
    }, []);
    function clearData() {
        setValues({ id: null, rejectedReason: "", statusCode: null, hoursSpent: null, status: null, statusId: null, task: "", amount: null, resetAttachment: '', attachmentName: '', attachment: null, taskDetailText: "", taskDetail: "", taskDetailEditText: "", taskEditDetail: "", dueDate: "", crdueDate: "", crNote1: "", crNote2: "", attachment: "", error: { attachment: "", task: "", hoursSpent: "", amount: "", taskDetail: "", dueDate: "", crdueDate: "", crNote1: "", crNote2: "", status: "", taskEditDetail: "", rejectedReason: "" } });
        setMilestoneDetail({ editvalue: "", editTest: "", editLength: 0 });
        setMilestoneEditDetail({ editvalue: "", editTest: "", editLength: 0 });
        setcrNote1({ editvalue: "", editTest: "", editLength: 0 });
        setcrNote2({ editvalue: "", editTest: "", editLength: 0 });

        setRejectReason({ editvalue: "", editTest: "", editLength: 0 });


    }
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    function ToggleEditMode(data) {

        setMileStones(data);
       
        if (gContext.userInfo.userType == 1 && (data.mscode == 200 || data.mscode == 450))
            setstatusList(statusFullList.filter(item => item["code"] == 300));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 100) && props.contract.statuscode == 200)
            setstatusList(statusFullList.filter(item => item["code"] == 200));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 100) && props.contract.statuscode == 100)
            setstatusList(statusFullList.filter(item => item["code"] == 100));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 200))
            setstatusList(statusFullList.filter(item => item["code"] == 200));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 450))
            setstatusList(statusFullList.filter(item => item["code"] == 450));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 300 && (!data.changereq1 || !data.changereq2)))
            setstatusList(statusFullList.filter(item => item["code"] > 300 && item["code"] != 500));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 300 && (data.changereq1 && data.changereq2)))
            setstatusList(statusFullList.filter(item => (item["code"] > 300 && item["code"] != 450)));
        else if (gContext.userInfo.userType == 2 && (data.mscode == 500 && data.mspaymentstatuscode != 400))
            setstatusList(statusFullList.filter(item => (item["code"] == 350)));
        if (data.attachments) {
            let attachlist = data.attachments.split(",");

            let list = [];
            attachlist.map((data) => (list.push({ file: null, fileName: data })));
            setAttachments(list);
        }
        else
            setAttachments([{ file: null, fileName: "" }]);


        setMilestoneDetail({ editvalue: data.taskdetails, editTest: "", editLength: data.taskdetails ? data.taskdetails.replace(/(<([^>]+)>)/ig, '').length : 0 });

        setValues({
            ...values, ["id"]: data.id, ["task"]: data.task, ["taskEditDetail"]: "", ["taskDetail"]: data.taskdetails, ["amount"]: (data.amount) ? parseInt(data.amount) : null, ["hoursSpent"]: ((data.mscode == 450 || data.mscode == 200) ? null : data.hoursspent), ["statusCode"]: data.mscode, ["status"]: { value: data.status, code: data.mscode, label: data.msstatus }, resetAttachment: data.attachments ? data.attachments : "", attachmentName: data.attachments ? data.attachments : "", ["dueDate"]: (data.duedate) ? new Date(data.duedate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "", ["crdueDate"]: (data.crduedate) ? new Date(data.crduedate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "", ["crNote1"]: data.changereq1 ? data.changereq1 : "", ["crNote2"]: data.changereq2 ? data.changereq2 : ""
        });
        setMode("edit");
        setTogSetting(!togSetting);
    }
    function AddFile() {
        setAttachments([...attachments, { file: null, fileName: "" }]);

    }
    function removeFile(index) {
        const rows = [...attachments];
        rows.splice(((page - 1) * gContext.paginationSize) + index, 1);
        if (slice.length == 1)
            setPage(page != 1 ? page - 1 : 1);
        setAttachments(rows);
    }
    function ToggleViewMode(data) {
        setMileStones(data);

        setMode("view");
        setTogSetting(!togSetting);


    }
    const handleClose = () => {
        setSuccessMessage('');
        toggleSuccessModal();
    };
    const toggleSuccessModal = () => {

        setSuccessModVisible(!successModVisible);
    };
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            setTogSetting(false);
            
            let msMessage = "Milestone updated successfully.";
            if ((status && status.code == 100) || mode == "add") {
                msMessage = "Milestone updated successfully, Change milestone status to Inprogress to begin work.";
            }
            else if (status && (status.code == 200 || status.code == 450)) {
                msMessage = "Milestone updated successfully, The expert will mark the milestone as completed when finished, ready for your review. ";
            }
            else if (status && status.code == 300) {
                msMessage = "Milestone updated successfully, Client will review the work.";
            }
            else if (status && status.code == 350) {
                msMessage = "Milestone updated successfully, Initiate Payment for the expert.";
            }
            else if (status && status.code == 500) {
                msMessage = "Milestone updated successfully, Milestone not approved. Initiate 50% payment for the expert.";
            } 

            setSuccessMessage(msMessage);
            setStatus(null);
            toggleSuccessModal();

        }
    }, [message, gContext.loaderModalVisible]);
    async function AddMilestone() {
        setErrorMessage("");
        setMessage(false);

        try {

            var data = { ...values };
            if (checkValidity()) {
                gContext.setLoaderModalVisible(true);
                if (values.id) {
                    let files = "";

                    attachments.map((value) => {

                        if (value.file) {
                            const updatedFile = getExpertFile(value.file);

                            ExpertFileUpload(updatedFile, "milestones/" + props.guid + "/" + values.id);
                            if (files == "")
                                files += createDirName() + "/milestones/" + props.guid + "/" + values.id + "/" + updatedFile.name;
                            else
                                files += "," + createDirName() + "/milestones/" + props.guid + "/" + values.id + "/" + updatedFile.name;
                        }
                        else {
                            if (value.fileName != "") {

                                if (files == "") {

                                    files += value.fileName;
                                }
                                else
                                    files += "," + value.fileName;
                            }
                        }

                    });
                    data.attachmentName = files;


                }
                let msSeqNo = null;
                const result = (values.id) ? await UpdateMilestone(data, props.contractId, gContext.userInfo.userID) : await CreateMilestone(values, props.contractId, gContext.userInfo.userID);

                if ((!values.id && result.data.createMilestone.responseData.code == "200") || (values.id && result.data.updateMilestone.responseData.code == "200")) {
                    if (!values.id) {

                        if (result.data.createMilestone.responseData.message) {
                            const data = JSON.parse(result.data.createMilestone.responseData.message);

                            if (data && data.length > 0) { msSeqNo = data[0].seq; }

                        }

                    }
                    setMessage(true);
                    props.BindContract();
                    if (gContext.userInfo.userType == 1) {
                        if (values.status.code != 200)
                            SendNotificationtoClient();
                    }
                    else {
                        SendNotificationtoExpert(msSeqNo);
                        if (values.id && values.status.code && (values.status.code == 350 || values.status.code == 500))
                            SendNotificationtoClient();
                    } 

                    gContext.setLoaderModalVisible(false);
                }
                else if (result.data.updateMilestone && result.data.updateMilestone.responseData.code == "400") {
                    setErrorMessage(result.data.updateMilestone.responseData.status);
                }
                else {
                    setErrorMessage("Server Error");
                }


            } else {
                return;

            }
            clearData();
            if (props.isPopup) { props.hideContract(); }
        }
        catch (e) {
            console.log(e);
            setMessage(false);
            clearData();
            if (props.isPopup) { props.hideContract(); }
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);

    }
    
    const handleSelectChange = (e, name) => {

        const value = e;

        let error = { ...values.error };
        switch (name) {
            case "status":
                error.status = "";
                break;


            default:
                break;
        }
        setStatus(value);
        setValues({ ...values, error, [name]: value, [name + 'Id']: value.value });

    }
    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    const handleLeave = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {


            case "amount":

                error.amount = (value != '') ? (validator.isDecimal(value) ? ((value < 1) ? "Amount is invalid" : "") : "Amount is invalid") : '';

                break;
            case "hoursSpent":

                error.hoursSpent = (value != '') ? (validator.isNumeric(value) ? ((value < 1) ? "Hours Spent is invalid" : "") : "Hours Spent is invalid") : '';


                break;

        }

        setValues({ ...values, error, [name]: value });
    }


    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error }; 

        switch (name) {
            case "title":
                error.title = validator.isEmpty(value) ? "Title is required" : "";
                break;

            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
            case "amount":

                error.amount = (value != '') ? (validator.isDecimal(value) ? ((value < 1) ? "Amount is invalid" : "") : "Amount is invalid") : '';

                break;
            case "hoursSpent":

                error.hoursSpent = (value != '') ? (validator.isNumeric(value) ? ((value < 1) ? "Hours Spent is invalid" : "") : "Hours Spent is invalid") : '';


                break;
        }

        setValues({ ...values, error, [name]: value });

    }


    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];



    const deleteTableRows = (id) => {

        if (id) {
            gContext.setconfirmModal({ header: "Milestone delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, id: id, showButton: true });
            gContext.toggleConfirmModal(); 
        }

    }


    async function deletecMilestone(id) {


        try {
            gContext.setLoaderModalVisible(true);
            setErrorMessage(""); 
            setMessage(false);
            const result = await DeleteMilestone(id);

            if (result.data.deleteMilestone.responseData.code == "200") {
                props.BindContract();
                setSuccessMessage("Milestone deleted successfully");
                toggleSuccessModal();

            }

        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }

    const addtaskeditorChange = (content, delta, source, addeditor) => {

        let { error, ...rest } = values;
        if (addeditor) {
            if ((milestoneDetail.editLength + addeditor.getText().trim().length) <= 2000) {

                setMilestoneEditDetail({ ...milestoneEditDetail, editvalue: addeditor.getHTML(), editTest: addeditor.getText().trim(), editLength: addeditor.getText().trim().length });
                setValues({
                    ...values, error, ["taskEditDetail"]: addeditor.getHTML()
                });
                if (isInvalid(addeditor.getText())) {
                    error.taskDetailText = "Invalid format.";

                }
                else {
                    error.taskDetailText = "";

                }
            }
            else {
                setMilestoneEditDetail({ ...milestoneEditDetail, editvalue: milestoneEditDetail.editvalue + " ", editTest: milestoneEditDetail.editTest, editLength: milestoneEditDetail.editLength });
            }
        }

    };
    const addtaskeditorKeyDown = (event) => {

        const unprivilegedEditor = addtaskQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor && ((milestoneDetail.editLength + unprivilegedEditor.getText().trim().length) > 2000 && event.key != 'Backspace')) {
            event.preventDefault();
        }
    };



    const rejectedReasoneditorChange = (content, delta, source, editor) => {

        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 2000) {

            setRejectReason({ ...rejectedReason, editvalue: editor.getHTML(), editTest: editor.getText().trim(), editLength: editor.getText().trim().length });
            setValues({
                ...values, error, ["rejectedReason"]: editor.getHTML()
            });
            if (isInvalid(editor.getText())) {
                error.rejectedReason = "Invalid format.";

            }
            else {
                error.rejectedReason = "";

            }
        }
        else {
            setRejectReason({ ...rejectedReason, editvalue: rejectedReason.editvalue + " ", editTest: rejectedReason.editTest, editLength: rejectedReason.editLength });
        }

    };
    const rejectedReasoneditorKeyDown = (event) => {

        const unprivilegedEditor = rejectedReasonQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 500 && event.key != 'Backspace') {
            event.preventDefault();
        }
    };

    const taskeditorChange = (content, delta, source, editor) => {

        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 2000) {

            setMilestoneDetail({ ...milestoneDetail, editvalue: editor.getHTML(), editTest: editor.getText().trim(), editLength: editor.getText().trim().length });
            setValues({
                ...values, error, ["taskDetail"]: editor.getHTML()
            });
            if (isInvalid(editor.getText())) {
                error.taskDetailText = "Invalid format.";

            }
            else {
                error.taskDetailText = "";

            }
        }
        else {
            setMilestoneDetail({ ...milestoneDetail, editvalue: milestoneDetail.editvalue + " ", editTest: milestoneDetail.editTest, editLength: milestoneDetail.editLength });
        }

    };

    const taskeditorKeyDown = (event) => {

        const unprivilegedEditor = taskQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 2000 && event.key != 'Backspace') {
            event.preventDefault();
        }
    };


    const crNote2editorChange = (content, delta, source, crNote2editor) => {

        let { error, ...rest } = values;
        if (crNote2editor.getText().trim().length <= 500) {

            setcrNote2({ ...crNote2, editvalue: crNote2editor.getHTML(), editTest: crNote2editor.getText().trim(), editLength: crNote2editor.getText().trim().length });
            setValues({
                ...values, error, ["crNote1"]: "", ["crNote2"]: crNote2editor.getHTML()
            });
            if (isInvalid(crNote2editor.getText())) {
                error.crNote2 = "Invalid format.";

            }
            else {
                error.crNote2 = "";

            }
        }
        else {
            setcrNote2({ ...crNote2, editvalue: crNote2.editvalue + " " });
        }

    };


    const crNote2editorKeyDown = (event) => {

        const unprivilegedEditor = crNote2QuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 500 && event.key != 'Backspace') {
            event.preventDefault();
        }
    };

    const crNote1editorChange = (content, delta, source, crNote1editor) => {

        let { error, ...rest } = values;

        if (crNote1editor.getText().length <= 500) {

            setcrNote1({ ...crNote1, editvalue: crNote1editor.getHTML(), editTest: crNote1editor.getText(), editLength: crNote1editor.getText().length });
            setValues({
                ...values, error, ["crNote1"]: crNote1editor.getHTML()
            });
            if (isInvalid(crNote1editor.getText())) {
                error.crNote1 = "Invalid format.";

            }
            else {
                error.crNote1 = "";

            }
        }
        else {
            setcrNote1({ ...crNote1, editvalue: crNote1.editvalue + " ", editTest: crNote1.editTest, editLength: crNote1.editLength });
        }

    };

    const crNote1editorKeyDown = (event) => {

        const unprivilegedEditor = crNote1QuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 500 && event.key != 'Backspace') {
            event.preventDefault();
        }
    };

    const handleDateChange = (name, value) => {

        setMessage(false);
        let { error, ...rest } = values;
        error[name] = "";
        setValues({
            ...values, error, [name]: (value) ? value : ""
        });
    }
    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = values;

        if (rest.dueDate == "") { error.dueDate = "Due date is required"; }
        error.task = (validator.isEmpty(rest.task.trim()) ? "Task Title is required" : (isInvalid(rest.task) ? "Invalid input." : ''));

        error.amount = ((rest.amount) ? (validator.isDecimal(rest.amount.toString()) ? ((rest.amount < 1) ? "Amount is invalid" : "") : "Amount is invalid ") : "Amount is required");


        if (rest.status && gContext.userInfo.userType == 1)
            error.hoursSpent = ((rest.hoursSpent) ? (validator.isNumeric(rest.hoursSpent.toString()) ? ((rest.hoursSpent < 1) ? "Hours Spent is invalid" : "") : "Hours Spent is invalid ") : "Hours Spent is required");

        if (rest.status && rest.status.code == 450 && rest.statusCode != 450) {

            error.crdueDate = rest.crdueDate == "" ? "Due date is required" : "";
            if (mileStones.changereq1 && mileStones.changereq1 != "")
                error.crNote2 = (validator.isEmpty(crNote2.editTest.trim()) ? "Request note is required" : (isInvalid(crNote2.editTest.trim()) ? "Invalid input." : ''));
            else
                error.crNote1 = (validator.isEmpty(crNote1.editTest.trim()) ? "Request note is required" : (isInvalid(crNote1.editTest.trim()) ? "Invalid input." : ''));



        }

        if (rest.status && rest.status.code == 500 && rest.statusCode != 500) {

            error.rejectedReason = (validator.isEmpty(rejectedReason.editTest.trim()) ? "Rejected Reason is required" : "");


        }

        if (gContext.userInfo.userType == 2 && rest.status && rest.status.code == 500 && rest.statusCode == 500) {

            error.status = "Select status";

        }
        if (gContext.userInfo.userType == 2 && rest.status && rest.status.code == 300) {
            error.status = "Select status";
        }
        if (gContext.userInfo.userType == 1 && rest.status && rest.status.code != 200) {
            if (gContext.userInfo.userType == 1 && rest.status && (rest.status.code != 300)) {
                error.status = "Select status";
            }
        }
        if (gContext.userInfo.userType == 1 && rest.status && rest.status.code != 200) {
            if (gContext.userInfo.userType == 1 && rest.status && (rest.status.code != 300)) {
                error.status = "Select status";
            }
        }

        setValues({ ...values, error });

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    useEffect(() => {
        if (milestoneValues.slice.length == 0 && milestonePage !== 1)
            setMilestonePage(milestonePage - 1);
    }, [milestoneValues.slice.length]);
    useEffect(() => {
        gContext.setLoaderModalVisible(true);


        if (gContext.confirmModal.confirmed) {
            deletecMilestone(gContext.confirmModal.id);
        }

        gContext.setLoaderModalVisible(false);
    }, [gContext.confirmModal]);
    return (
        <>
            <div className="card panel  p-0">

                <div className="card-header">

                    <div className="client-title d-flex">
                        <h6>Milestone Details</h6> <OverlayTrigger trigger="hover" placement="auto"
                            overlay={<Popover className="   " >

                                <div className="tooltip-header bg-gray">
                                    <h5>Information</h5>
                                </div>
                                <div className="tooltip-meta">
                                    <p>
                                        <div className={`card-body w-full`}>
                                            <table className="table table-striped  ">
                                                <thead>
                                                    <tr ><th className="min-lg-cell ">Status</th><th>Detail</th></tr></thead>
                                                {
                                                    gContext.userInfo.userType == 2 && <tbody><tr> <td><strong>New</strong></td><td className="d-flex justify-content-left align-items-left text-left"> Expert cannot begin work until milestone status is "In Progress". Edit<span className="text-theme m-icon material-icons">edit</span> milestone to change status.

                                                    </td></tr>
                                                        <tr> <td><strong>In Progress</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left">Expert actively working on the Miletsone.</span></td> </tr>
                                                        <tr> <td><strong>Completed</strong> </td><td ><span className="d-flex justify-content-left align-items-left text-left">Milestone is ready for review, change or approval.
                                                        </span> </td></tr>
                                                        <tr> <td><strong>Approved</strong> </td><td ><span className="d-flex justify-content-left align-items-left text-left">Milestone approved, Initiate payment.
                                                        </span></td> </tr>
                                                        <tr> <td><strong>Change Requested</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left">Two change requests are allowed.
                                                        </span></td> </tr>
                                                        <tr> <td><strong>Not Approved</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left">Expert to get paid 50% of the Milestone.

                                                        </span></td> </tr>

                                                    </tbody>}
                                                {
                                                    gContext.userInfo.userType == 1 && <tbody><tr> <td><strong>New</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left"> Client must update milestone status to "In Progress" before starting work. Prompt client to update status.


                                                    </span>  </td></tr>
                                                        <tr> <td><strong>In Progress</strong> </td><td ><span className="d-flex justify-content-left align-items-left text-left"> Upon Job completion update the status to completed, and submit for Client approval</span></td> </tr>
                                                        <tr> <td><strong>Completed</strong> </td><td ><span className="d-flex justify-content-left align-items-left text-left">Milestone will be ready for review upon job completion.

                                                        </span> </td></tr>
                                                        <tr> <td><strong>  Approved</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left"> Milestone approved by client; Payment initiation underway.

                                                        </span> </td> </tr>
                                                        <tr> <td><strong>Change Requested</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left">Change requested by Client. Milestone will be ready for review upon job completion.

                                                        </span> </td> </tr>
                                                        <tr> <td><strong>Not Approved</strong></td><td ><span className="d-flex justify-content-left align-items-left text-left"> Milestone not  approved, you get paid 50% of the cost.

                                                        </span></td> </tr>

                                                    </tbody>}
                                            </table>
                                        </div>
                                    </p>
                                </div>
                            </Popover>} >
                            <span className="text-theme m-icon material-icons m-l-5 f-24 pointer  hide-md  " >info</span>
                        </OverlayTrigger>


                        <OverlayTrigger trigger="hover" placement="auto"
                            overlay={<Popover className="   " >

                                <div className="tooltip-header bg-gray">
                                    <h5>Information</h5>
                                </div>
                                <div className="tooltip-meta">
                                    {
                                        gContext.userInfo.userType == 2 && <p>
                                            <strong>New</strong>
                                            <br />Expert cannot begin work until milestone status is "In Progress". Edit<span className="text-theme m-icon material-icons">edit</span> milestone to change status.
                                            <br />
                                            <strong>In Progress</strong>
                                            <br />Expert actively working on the Miletsone.
                                            <br />
                                            <strong>Completed</strong>
                                            <br />Milestone is ready for review, change or approval.

                                            <br />
                                            <strong>Approved</strong>
                                            <br />Milestone approved, Initiate payment.
                                            <br />
                                            <strong>Change Requested</strong>
                                            <br />Two change requests can be initiated.
                                            <br />
                                            <strong>Not Approved</strong>
                                            <br />Expert to get paid 50% of the Milestone.
                                        </p>

                                    }
                                    {
                                        gContext.userInfo.userType == 1 && <p>
                                            <strong>New</strong>
                                            <br />Client must update milestone status to "In Progress" before starting work. Prompt client to update status.
                                            <br />
                                            <strong>In Progress</strong>
                                            <br />Upon Job completion update the status to completed, and submit for Client approval.
                                            <br />
                                            <strong>Completed</strong>
                                            <br />Milestone will be ready for review upon job completion.

                                            <br />
                                            <strong>Approved</strong>
                                            <br />Milestone approved by client; Payment initiation underway.
                                            <br />
                                            <strong>Change Requested</strong>
                                            <br />Change requested by Client. Milestone will be ready for review upon job completion.
                                            <br />
                                            <strong>Not Approved</strong>
                                            <br />Milestone not  approved, you get paid 50% of the cost.
                                        </p>

                                    }


                                </div>
                            </Popover>} >
                            <span className="text-theme m-icon material-icons m-l-5 f-24 pointer d-none hide-lg show-md " >info</span>
                        </OverlayTrigger>


                    </div>
                    <div className="add-more">
                        {
                            (gContext.userInfo.userType == 2 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.contractMode != "terminate" && props.contractMode != "complete") && <button type="button" onClick={() => { if (props.isPopup) { props.hideContract(); } setMode("add"); setTogSetting(!togSetting); }} data-toggle="tooltip" title="Add Milestone" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add </button>}
                    </div>
                </div>



                <div className={`card-body `}>
                    <div className="table-responsive">
                        <table className="table table-striped w-full">
                            <thead>
                                <tr>
                                    <th className="min-sm-cell w-20">MS#</th>
                                    <th className="min-lg-cell w-150">Task</th>
                                    {props.isPopup == false && <th className="min-lg-cell w-150">Assignee</th>}
                                    <th className="min-lg-cell">Due Date</th>
                                    <th className="min-md-cell">Amount </th>


                                    {props.isPopup == false && <th className="min-lg-cell" >Hours Spent</th>}

                                    <th className="min-lg-cell" >Status</th>
                                    {props.isPopup == false && <th className="min-lg-cell" >Payment Status   </th>}
                                    <th className=" min-sm-cell w-100" ></th>

                                </tr>
                            </thead>
                            <tbody>
                                {milestoneValues.slice.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>MS{data.seq}</td>

                                            <td className="fw-600 pointer"  ><span className="d-flex justify-content-center align-items-center">
                                                <span className="table-title one-line pointer" data-toggle="tooltip" title={data.task} onClick={() => { if (props.isPopup) { props.hideContract(); } ToggleViewMode(data) }}>{data.task.replaceAll("''", "'")} </span>
                                            </span></td>
                                            {props.isPopup == false && <td className="text-center">
                                                <div className="info-meta company pointer w-full text-center  justify-content-center align-items-center " onClick={(e) => { window.location.href= "/expert/" + data.sub; }}   >
                                                    <img className="m-r-0  " src={s3URL + data.avatar} alt={data.firstname + ' ' + data.lastname} />
                                                    <span className="one-line text-black m-l-5">{data.firstname + ' ' + data.lastname} </span>  
                                                </div>

                                            </td>}
                                            <td>{data.duedate ? getMonthDateYear(data.duedate) : ""}</td>
                                            <td>  ${(data.amount) ? new Intl.NumberFormat('en-US').format(parseInt(data.amount)) : '-'}</td>
                                            {props.isPopup == false &&
                                                <td>{data.hoursspent}</td>}
                                            <td> <span className="d-flex align-item-center justify-content-center">{data.msstatus}
                                                {<OverlayTrigger trigger="hover" placement="auto"
                                                    overlay={<Popover className="   " >

                                                        <div className="tooltip-header bg-gray">
                                                            <h5>Information</h5>
                                                        </div>
                                                        <div className="tooltip-meta">

                                                            {gContext.userInfo.userType == 1 && data.mscode == 100 && <p>Client must update milestone status to "In Progress" before starting work. <br />Prompt client to update status.
                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && data.mscode == 200 && <p> Upon Job completion update the status to completed, and submit for Client approval.
                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && data.mscode == 300 && <p>Milestone will be ready for review upon job completion.
                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && data.mscode == 350 && data.mspaymentstatuscode != 400 && <p>Milestone approved by client; Payment initiation underway.

                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && data.mscode == 350 && data.mspaymentstatuscode == 400 && <p>Milestone approved by client; Payment Completed.

                                                            </p>}

                                                            {gContext.userInfo.userType == 1 && data.mscode == 450 && <p>Change requested by Client.<br /> Milestone will be ready for review upon job completion.

                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && data.mscode == 500 && <p>Milestone not  approved, you get paid 50% of the cost.

                                                            </p>}


                                                            {gContext.userInfo.userType == 2 && data.mscode == 100 && <p> Expert cannot begin work until milestone status is "In Progress". <br />Edit<span className="text-theme m-icon material-icons">edit</span> milestone to change status.
                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && data.mscode == 200 && <p> Expert actively working on the Miletsone. </p>}

                                                            {gContext.userInfo.userType == 2 && data.mscode == 300 && (!data.changereq1 || !data.changereq2) && <p> Milestone is ready for review, change or approve.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && data.mscode == 300 && data.changereq1 && data.changereq2 && <p> Milestone is ready for review, approve or not approve.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && data.mscode == 350 && data.mspaymentstatuscode != 400 && <p> Milestone approved, Initiate payment.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && data.mscode == 350 && data.mspaymentstatuscode == 400 && <p>Milestone approved, Payment Completed.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && data.mscode == 450 && <p>Two change requests can be initiated.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && data.mscode == 500 && <p>Expert to get paid 50% of the Milestone.

                                                            </p>}
                                                        </div>



                                                    </Popover>} >
                                                    <span className="text-theme m-icon material-icons m-l-5 m-b-5  f-24 pointer" >info</span>
                                                </OverlayTrigger>}</span></td>
                                            {props.isPopup == false && <td className="pointer"   >
                                                {(data.mspaymentstatuscode && data.mspaymentstatuscode <= 200) ?
                                                    <OverlayTrigger trigger="hover" placement="auto"
                                                        overlay={<Popover className="   " >

                                                            <div className="tooltip-header bg-gray">
                                                                <h5>Information</h5>
                                                            </div>
                                                            <div className="tooltip-meta">
                                                                <p   >
                                                                    {(data.mspaymentstatuscode && data.mspaymentstatuscode <= 200) ? getStatusValue(data) : data.mspaymentstatus}
                                                                </p>

                                                            </div>



                                                        </Popover>} >
                                                        <span>{data.mspaymentstatus}</span>
                                                    </OverlayTrigger> : (data.mspaymentstatus ? data.mspaymentstatus : "")}</td>}

                                            <td>
                                                <div className="d-flex">
                                                    <a className="btn btn-circle light" onClick={() => { if (data.attachments && data.attachments != "") ToggleViewMode(data); }} title="View Attachments" ><span className={` m-icon material-icons ${(data.attachments && data.attachments != "") ? "text-theme" : "text-gray"}`}>attach_file</span></a>

                                                    {!props.isPopup && <> {
                                                        gContext.userInfo.userType == 1 && (props.contract.statuscode != 100 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.contractMode != "terminate" && props.contractMode != "complete") &&
                                                        <>{(!(props.contract.statuscode != 100 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.mode != "view" && (data.mscode == 200 || data.mscode == 450))) &&

                                                            <OverlayTrigger trigger="hover" placement="auto"
                                                                overlay={<Popover className="   " >

                                                                    <div className="tooltip-header bg-gray">
                                                                        <h5>Information</h5>
                                                                    </div>
                                                                    <div className="tooltip-meta">
                                                                        <p>
                                                                            {data.msstatus} Milestone cannot be edited
                                                                        </p>
                                                                    </div>
                                                                </Popover>} >
                                                                <a className="btn btn-circle light pointer"  ><span className="text-theme m-icon material-icons">edit</span></a>
                                                            </OverlayTrigger>
                                                        }

                                                            {((props.contract.statuscode != 100 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.mode != "view" && (data.mscode == 200 || data.mscode == 450))) && <a className="btn btn-circle light"  ><span className="text-theme m-icon material-icons" onClick={() => { if (props.contract.statuscode != 100 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.mode != "view" && (data.mscode == 200 || data.mscode == 450)) { if (props.isPopup) { props.hideContract(); } ToggleEditMode(data); } }}>  edit</span></a>}
                                                        </>}
                                                        {
                                                            data.mspaymentstatuscode == 400 || (gContext.userInfo.userType == 2 && (props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.contractMode != "terminate" && props.contractMode != "complete")) && <>

                                                                {(!(props.contract.statuscode != 300 && props.contract.statuscode != 400 && data.mscode != 350)) &&
                                                                    <OverlayTrigger trigger="hover" placement="auto"
                                                                        overlay={<Popover className="   " >

                                                                            <div className="tooltip-header bg-gray">
                                                                                <h5>Information</h5>
                                                                            </div>
                                                                            <div className="tooltip-meta">
                                                                                <p>
                                                                                    {data.msstatus} Milestone cannot be edited
                                                                                </p>
                                                                            </div>
                                                                        </Popover>} >
                                                                        <a className="btn btn-circle light pointer"  ><span className="text-theme m-icon material-icons">edit</span></a>
                                                                    </OverlayTrigger>}

                                                                {((props.contract.statuscode != 300 && props.contract.statuscode != 400 && data.mscode != 350)) && <a className="btn btn-circle light"    ><span className="text-theme m-icon material-icons" onClick={() => { if (props.contract.statuscode != 300 && props.contract.statuscode != 400 && data.mscode != 350) { if (props.isPopup) { props.hideContract(); } ToggleEditMode(data); } }}>  edit</span></a>}
                                                            </>}

                                                        {gContext.userInfo.userType == 2 && data.mscode == 100 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && props.contractMode != "terminate" && props.contractMode != "complete" && <a className="btn btn-circle light pointer" onClick={() => { if (data.mscode == 100 && gContext.userInfo.userType == 2 && props.contract.statuscode != 300 && props.contract.statuscode != 400 && data.mscode != 350) { deleteTableRows(data.id); } }}  ><span className="text-theme m-icon material-icons">delete</span></a>}
                                                    </>}
                                                    {
                                                        props.contractMode != "terminate" && gContext.userInfo.userType == 2 && (data.mscode == 350 || data.mscode == 500) && data.mspaymentstatuscode != 400 && data.mspaymentstatuscode != 200   && <a className="btn btn-sm  bg-green text-white m-l-5 w-80 p-x-0" title="Pay" onClick={() => {  setMessage(false); setMileStones(data); setShowPayment(true); }}  > Pay Now</a>}


                                                </div>
                                            </td>



                                        </tr>
                                    )


                                })}



                            </tbody>
                        </table>
                        {(props.milestones && props.milestones.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                            <span className="text-light-gray m-icon material-icons m-b-10">menu</span>
                            <span>No Milestone found</span>
                        </div>}
                        {(gContext.userInfo.userType == "2") &&
                          
                                <CardForm   paymentInfo={mileStones} contract={props.contract} showPayment={showPayment} bindData={() => { props.BindContract(); }} setShowPayment={(flag) => { setMessage(false); setShowPayment(!showPayment); setMileStones({}); clearData(); setTogSetting(false); if (flag) { props.BindContract(); } }} />
                        } </div>


                    {(props.milestones && props.milestones.length > 5) && <TablePagination range={milestoneValues.range} slice={milestoneValues.slice} setPage={setMilestonePage} page={milestonePage} />}


                </div>

            </div>

            {togSetting &&
                <ModalStyled size="lg"
                    centered
                    show={togSetting}
                    backdrop="static"
                    onHide={() => { if (props.isPopup) { props.hideContract(); setMessage(false); } clearData(); setTogSetting(false) }}
                >

                    <div className="modal-header">
                        <h4 className="modal-title"> MileStone</h4>
                        <button type="button" className="btn-close" onClick={() => {
                            clearData();
                            if (props.isPopup) { props.hideContract(); }
                            setTogSetting(!togSetting);

                        }}></button>
                    </div>

                    <div className="modal-body text-left">
                        {mode != "add" && <a className="   pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + mileStones.sub}  >
                            <div className="company-post">
                                <div className="medie-info-img">
                                    <img src={s3URL + mileStones.avatar} alt="" />
                                </div>

                                <div className="d-flex flex-column">
                                    <a className="m-b-10 m-t-10" href="#"><b>{mileStones.firstname + ' ' + mileStones.lastname}</b></a>

                                </div>
                            </div>

                        </a>}
                        {mode != "view" && mode != "" &&
                            <>  <div className="row m-b-10 p-b-10">

                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="forn-group-input">
                                        <label for="job title" className="form-label m-b-10">    Task Title  {(mode == "add" || !(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 100))) && <span className="text-red"> * </span>}</label>
                                        {(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 100)) &&
                                            <span className="form-label m-b-5">  <b>{values.task.replaceAll("''", "'")}  </b> </span>
                                        }
                                        {((gContext.userInfo.userType == 2 && (values.statusCode == 100)) || mode == "add") &&
                                            <> <input
                                                type="text" defaultValue={values.task.replaceAll("''", "'")} Value={values.task.replaceAll("''", "'")}
                                                className={`form-control   ${(values.error.task.length > 0) ? ' error' : ''}`}
                                                id="task"
                                                name="task"
                                                onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                            />
                                                {values.error.task.length > 1 && (
                                                    <span className="text-red">{values.error.task}</span>
                                                )}</>}
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">
                                        <label for="amount" className="form-label m-b-10">Amount {(mode == "add" || !(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 100))) && <span className="text-red"> * </span>}</label>
                                        {(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 100)) &&
                                            <span className="form-label m-b-10">  <b>${values.amount}  </b> </span>
                                        }
                                        {((gContext.userInfo.userType == 2 && (values.statusCode == 100)) || mode == "add") &&
                                            <> <input name="amount"
                                                type="text" Value={values.amount} className={`form-control   ${(values.error.amount.length > 0) ? ' error' : ''}`}

                                                id="amount" onKeyUp={handleFieldsChange}
                                                placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                            />
                                                {values.error.amount.length > 1 && (
                                                    <span className="text-red "> {values.error.amount}</span>
                                                )}</>}


                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">
                                        <label className="form-label m-b-10">Due Date{(mode == "add" || !(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 100))) && <span className="text-red"> * </span>}</label>
                                        {(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 100)) &&
                                            <span className="form-label m-b-10">  <b>{values.dueDate ? getMonthDateYear(values.dueDate) : ""}  </b> </span>
                                        }
                                        {((gContext.userInfo.userType == 2 && (values.statusCode == 100)) || mode == "add") &&
                                            <>
                                                <CustomDatePicker className="form-control "
                                                    placeholderText="Due Date"
                                                    selected={values.dueDate}

                                                    onChange={(date) => (handleDateChange('dueDate', date))}
                                                    minDate={new Date()}
                                                />
                                                {values.error.dueDate.length > 1 && (
                                                    <span className="text-red ">{values.error.dueDate}</span>
                                                )}</>}


                                    </div>
                                </div>
                                {(gContext.userInfo.userType == 2 && (values.statusCode == 100 || mode == "add")) && <div className="company-desc m-b-50">
                                    <div className="desc-info flex-column justify-content-start">
                                        <label for="first name" className="form-label m-b-10"><b>Task Details</b><span className="text-red">  (Max 2000 character )</span></label>
                                        <span className="text-gray">Remaining Character length:  <span className="text-green">{2000 - ((milestoneDetail.editLength) ? milestoneDetail.editLength : 0)}</span></span>
                                    </div>

                                    <ReactQuill modules={modules} style={{ height: '100px' }}
                                        formats={formats} theme="snow" id="editor"
                                        onKeyDown={taskeditorKeyDown}
                                        ref={taskQuillRef}
                                        value={milestoneDetail.editvalue ? milestoneDetail.editvalue.replaceAll("''", "'") : ""} onChange={taskeditorChange}
                                    ></ReactQuill>
                                    {values.error.taskDetailText && values.error.taskDetailText.length > 1 && (
                                        <span className="text-red m-t-30 p-t-10">{values.error.taskDetailText}</span>
                                    )}

                                </div>}


                                {gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && (values.statusCode >= 200)) && <> <div className="forn-group-input">
                                    <label className="form-label m-b-5">Task Details </label>

                                    <p className="m-b-0  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(values.taskDetail?.replaceAll("''", "'") + "<br/>" + milestoneEditDetail.editvalue.replaceAll("''", "'")) }}></p>
                                </div></>}

                                {mileStones.crduedate && mode == "edit" && <>      <div className="divider-line"></div> <div className="forn-group-input">
                                    <label className="form-label m-b-10">Change Request Note</label>

                                    {mileStones.changereq1 && <><b>{"Note 1:"}</b> <p className="m-b-0  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.changereq1?.replaceAll("''", "'")).trim() }}></p>
                                    </>}
                                    {mileStones.changereq2 && <><b>{"Note 2:"}</b><p className="m-b-0  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.changereq2?.replaceAll("''", "'")).trim() }}></p><br /></>}
                                </div>  <div className="divider-line"></div></>}
                                {mileStones.rejectedreason && mode == "edit" && <>      <div className="col-lg-12 col-md-12 col-sm-12  " > <div className="icon-wrapper m-0">

                                    <div className="d-flex flex-column">
                                        <span className="m-b-5">Rejected reason </span>
                                        <b><p className="m-b-5  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.rejectedreason?.replaceAll("''", "'")).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).trim() }}></p></b>
                                    </div>
                                </div></div></>}
                                {mileStones.mspaymentstatuscode && mode == "edit" && <><div className="col-lg-12 col-md-12 col-sm-12  m-t-5" > <div className="icon-wrapper m-0">

                                    <div className="d-flex flex-column">
                                        <span className="m-b-5">Payment Status </span>
                                        <b><p className="m-b-20  " >{(mileStones.mspaymentstatuscode && mileStones.mspaymentstatuscode <= 200) ? mileStones.mspaymentstatus + " - " + getStatusValue(mileStones) : mileStones.mspaymentstatus}</p></b>
                                    </div>
                                </div></div><div className="divider-line"></div></>}


                                {values.statusCode > 200 && mode == "edit" &&
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="forn-group-input">
                                            <label className="form-label m-b-10">Total Hours Spent </label>
                                            {(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2)) &&
                                                <span className="form-label m-b-10">  <b>{mileStones.hoursspent ? mileStones.hoursspent : ""}  </b> </span>
                                            }



                                        </div>
                                    </div>}

                                {mode == "edit" && <div className="col-lg-6 col-md-6 col-sm-12">
                                    {(gContext.userInfo.userType == 1 && !(values.statusCode == 200 || values.statusCode == 450)) ?
                                        <>  <label className="form-label m-b-10">Status</label>

                                            <span className="form-label m-b-10" style={{}}>  <b>{(values.status) ? values.status.label : ""}  </b> </span></>
                                        : <div className="forn-group-input">
                                            <label for="job title" className="d-flex form-label  ">Status<span className="text-red"> * </span>
                                                <OverlayTrigger trigger="hover" placement="auto"
                                                    overlay={<Popover className="  zindex " style={{ zIndex: "99999999999" }} >

                                                        <div className="tooltip-header bg-gray">
                                                            <h5>Information</h5>
                                                        </div>
                                                        <div className="tooltip-meta">

                                                            {gContext.userInfo.userType == 1 && values.status && values.status.code == 100 && <p>Client must update milestone status to "In Progress" before starting work. <br />Prompt client to update status.
                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && values.status && values.status.code == 200 && <p> Upon Job completion update the status to completed, and submit for Client approval.

                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && values.status && values.status.code == 300 && <p> Milestone will be ready for review upon job completion.Upon Job completion update the status to completed, and submit for Client approval.
                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && values.status && values.status.code == 350 && <p>Milestone approved by client; Payment initiation underway.

                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && values.status && values.status.code == 450 && <p>Change requested by Client.<br /> Milestone will be ready for review upon job completion.

                                                            </p>}
                                                            {gContext.userInfo.userType == 1 && values.status && values.status.code == 500 && <p>Milestone not  approved, you get paid 50% of the cost.

                                                            </p>}

                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 100 && <p>   To start the Milestone, change status to "In Progress" from dropdown.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 200 && <p> The expert will mark the milestone as completed when finished, ready for your review.





                                                            </p>}

                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 300 && (!mileStones.changereq1 || !mileStones.changereq2) && <p>Approve or request changes via dropdown menu.


                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 300 && (mileStones.changereq1 && mileStones.changereq2) && <p>Approve or Not approved via dropdown menu.


                                                            </p>}

                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 350 && <p> Milestone approved, Initiate payment.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 450 && <p>Two change requests can be initiated.

                                                            </p>}
                                                            {gContext.userInfo.userType == 2 && values.status && values.status.code == 500 && <p>Expert to get paid 50% of the Milestone.

                                                            </p>}
                                                        </div>



                                                    </Popover>} >
                                                    <span className="text-theme m-icon material-icons m-l-5 m-b-5  f-24 pointer" >info</span>
                                                </OverlayTrigger>

                                            </label>
                                            <Select options={statusList}
                                                placeholder="Select..." value={values.status}
                                                className={`form-control  padding-select ${(values.error.status.length > 0) ? ' error' : ''}`}
                                                border={false} onChange={(e) => handleSelectChange(e, 'status')}
                                            />
                                            {values.error.status.length > 1 && (
                                                <span className="text-red">{values.error.status}</span>
                                            )}

                                        </div>}


                                </div>


                                }


                                {((gContext.userInfo.userType == 2 && (values.status && values.status.code == 500 && values.statusCode != 500)) && mode == "edit") && <>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="forn-group-input">
                                            <label className="form-label m-b-10 ">Rejected Reason<span className="text-red"> * </span></label>

                                            {((gContext.userInfo.userType == 2 && (values.status && values.status.code == 500)) && mode == "edit") &&
                                                <>
                                                    <ReactQuill modules={modules} style={{ height: '100px' }}
                                                        formats={formats} theme="snow" id="rejectedReason"
                                                        onKeyDown={rejectedReasoneditorKeyDown}
                                                        ref={rejectedReasonQuillRef}
                                                        value={rejectedReason.editvalue.replaceAll("''", "'")} onChange={rejectedReasoneditorChange}
                                                    ></ReactQuill>
                                                    {values.error.rejectedReason.length > 1 && (
                                                        <span className="text-red m-t-40 ">{values.error.rejectedReason}</span>
                                                    )}</>}


                                        </div>
                                    </div></>
                                }

                                {((gContext.userInfo.userType == 2 && (values.status && values.status.code == 450 && values.statusCode != 450)) && mode == "edit") && <>  <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">
                                        <label className="form-label m-b-10 ">Change Request Due Date   {((gContext.userInfo.userType == 2 && (values.status && values.status.code == 450)) && mode == "edit") && <span className="text-red"> * </span>}</label>
                                        {values.crduedate && <>{(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 300)) &&
                                            <span className="form-label m-b-10">  <b>{values.crduedate ? getMonthDateYear(values.crduedate) : ""}  </b> </span>
                                        }</>}
                                        {((gContext.userInfo.userType == 2 && (values.status && values.status.code == 450)) && mode == "edit") &&
                                            <>
                                                <CustomDatePicker className="form-control "
                                                    placeholderText="Due Date"
                                                    selected={values.crdueDate}

                                                    onChange={(date) => (handleDateChange('crdueDate', date))}
                                                    minDate={new Date()}
                                                />
                                                {values.error.crdueDate.length > 1 && (
                                                    <span className="text-red ">{values.error.crdueDate}</span>
                                                )}</>}


                                    </div>
                                </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="forn-group-input">
                                            <label for="job title" className="form-label m-b-10">    Change Request Notes <b>{mileStones.changereq1 ? "(2 of 2)" : "(1 of 2)"}</b><span className="text-red"> * </span></label>
                                            {(gContext.userInfo.userType == 1 || (gContext.userInfo.userType == 2 && values.statusCode > 300)) &&
                                                <> {mileStones.changereq1 && <span className="form-label m-b-10">  <b>{"Note 1:"}  </b>{mileStones.changereq1.replaceAll("''", "'")}</span>}
                                                    <br />{mileStones.changereq2 && <span className="form-label m-b-10">  <b>{"Note 2:"}  </b>{mileStones.changereq2.replaceAll("''", "'")}</span>}</>
                                            }
                                            {(gContext.userInfo.userType == 2 && (values.status && values.status.code == 450 && values.statusCode != 450) && mode == "edit") &&
                                                <>{!mileStones.changereq1 && <>

                                                    <ReactQuill modules={modules} style={{ height: '100px' }}
                                                        formats={formats} theme="snow" id="crNote1editor"
                                                        onKeyDown={crNote1editorKeyDown}
                                                        ref={crNote1QuillRef}
                                                        value={crNote1.editvalue.replaceAll("''", "'")} onChange={crNote1editorChange}
                                                    ></ReactQuill>


                                                    {values.error.crNote1.length > 1 && (
                                                        <span className="text-red m-t-40">{values.error.crNote1}</span>
                                                    )}</>}{mileStones.changereq1 && <>
                                                        <ReactQuill modules={modules} style={{ height: '100px' }}
                                                            formats={formats} theme="snow" id="crNote2editor"
                                                            onKeyDown={crNote2editorKeyDown}
                                                            ref={crNote2QuillRef}
                                                            value={crNote2.editvalue.replaceAll("''", "'")} onChange={crNote2editorChange}
                                                        ></ReactQuill>{values.error.crNote2.length > 1 && (
                                                            <span className="text-red m-t-40">{values.error.crNote2}</span>
                                                        )}</>}</>}
                                        </div>
                                    </div></>}
                                {gContext.userInfo.userType == 1 && (values.statusCode == 200 || values.statusCode == 450) && <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">
                                        <label for="hoursSpent" className="form-label m-b-10">{values.statusCode == 450 ? "Additional" : ""} Hours Spent<span className="text-red">*  </span></label>
                                        <input name="hoursSpent" disabled={!(values.statusCode == 200 || values.statusCode == 450)}
                                            type="number" Value={values.hoursSpent} className={`form-control m-t-5  ${(values.error.hoursSpent.length > 0) ? ' error' : ''}`}
                                            onKeyDown={(e) => { if (e.key == '.' || e.key.toLocaleLowerCase() == 'e' || e.key == '-') { e.preventDefault(); } }}
                                            onPaste={(e) => { e.preventDefault(); }}

                                            id="hoursSpent" onKeyUp={handleFieldsChange}
                                            placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                        />
                                        {values.error.hoursSpent.length > 1 && (
                                            <span className="text-red ">{values.error.hoursSpent}</span>
                                        )}


                                    </div>
                                </div>}

                                {gContext.userInfo.userType == 1 && (values.statusCode == 200 || values.statusCode == 450) &&
                                    <>  <label className="d-flex f-16">Upload Attachments <OverlayTrigger trigger="hover" placement="auto" overlay={popoverAttachment} ><span className="text-theme m-icon material-icons m-l-5" style={{ cursor: "pointer" }}  >info</span></OverlayTrigger> </label>

                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped w-full">
                                                    <thead>
                                                        <tr>

                                                            <th className="min-lg-cell">Attachment Name</th>

                                                            <th className="min-md-cell w-50"><button type="button" onClick={() => { AddFile() }} className="btn btn-sm btn-orange-outline w-100 align-item-center m-l-20  "> Add  </button> </th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {

                                                            slice.map((data, index) => {
                                                                return (<>
                                                                    <tr key={index}>
                                                                        <td>

                                                                            <div className="company-meta  text-left">

                                                                                {(data.fileName == "") &&
                                                                                    <>
                                                                                        <label
                                                                                            htmlFor={"fileUpload" + index}
                                                                                            className="mb-0  "
                                                                                            style={{ cursor: 'pointer' }}>
                                                                                            {(!data.file) &&

                                                                                                <>    <div class="inline-icon-info">
                                                                                                    <div class="upload-file p-relative">
                                                                                                        <span class="text-light-gray m-icon material-icons m-r-10" data-toggle="tooltip" title="Click here to add file" >upload_file</span>Upload a attachment

                                                                                                    </div>
                                                                                                </div>


                                                                                                </>}

                                                                                        </label>


                                                                                        {(data.file) && <>

                                                                                            <div className="upload-doc-img m-b-10 ">
                                                                                                <div className="workers-img">
                                                                                                    <img src="../images/file.png" alt="" htmlFor="idUpload" className="w-50" />
                                                                                                    <div className="btn-icon-close" data-toggle="tooltip" title="Remove attachment" onClick={() => setValues({ ...values, ['attachment']: null, ['attachmentName']: values.resetattachmentName })}>
                                                                                                        <span className="m-icon material-icons">close</span>
                                                                                                    </div>
                                                                                                </div>




                                                                                            </div>


                                                                                        </>
                                                                                        }

                                                                                        {(data.file) && <span className="text-gray" > {(data.file) ? data.file.name : ''} </span>}




                                                                                        <input
                                                                                            type="file"
                                                                                            id={"fileUpload" + index} accept="image/*,application/pdf"
                                                                                            className="sr-only"
                                                                                            onChange={(e) => { onFileChange(e, index); }}
                                                                                        />

                                                                                    </ >}
                                                                                {(data.fileName != "") &&
                                                                                    <>

                                                                                        <div className="upload-doc-img m-b-5 w-full ">
                                                                                            <div className="workers-img d-flex pointer d-flex pointer attachments-img" data-toggle="tooltip" title="Click here to view." onClick={() => { window.open((data.file) ? window.URL.createObjectURL(data.file) : s3URL + data.fileName, "_blank"); }}>
                                                                                                <img src="../images/file.png" htmlFor="idUpload" className=" imgsize" />

                                                                                                {(data.fileName) && <span className="text-gray m-t-10 " > {data.fileName.split('/').pop()} </span>}

                                                                                                <div className="btn-icon-close d-none" data-toggle="tooltip" title="Remove attachment" onClick={() => setValues({ ...values, ['attachment']: null, ['attachmentName']: "" })}>
                                                                                                    <span className="m-icon material-icons">close</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>}

                                                                            </div></td> <td><a className="btn btn-circle light" title="Delete Row" onClick={() => { removeFile(index); }}  ><span className="text-theme m-icon material-icons">delete</span></a></td>


                                                                    </tr></>)
                                                            })
                                                        } </tbody>
                                                </table>

                                            </div>
                                            {(attachments && attachments.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}


                                        </div></>}
                                {values.statusCode > 200 && <div className="upload-doc-img m-b-10 d-none">
                                    <div className="workers-img pointer" data-toggle="tooltip" title="Click here to view." onClick={() => { window.open(s3URL + values.attachmentName, "_blank"); }}>
                                        <img src="../images/file.png" alt="" htmlFor="idUpload" />



                                        <div className="btn-icon-close d-none" data-toggle="tooltip" title="Remove attachment" onClick={() => setValues({ ...values, ['attachment']: null, ['attachmentName']: "" })}>
                                            <span className="m-icon material-icons">close</span>
                                        </div>
                                    </div>
                                </div>}
                                {(gContext.userInfo.userType == 2 && (values.statusCode == 200 || values.statusCode == 450)) && <div className="col-lg-12 col-md-12 col-sm-12 m-b-40"><div className="company-desc m-b-10">
                                    <div className="desc-info flex-column justify-content-start m-t-20">
                                        <label for="first name" className="form-label m-b-10"><b>Add Task Details</b><span className="text-red">  </span></label>
                                        <span className="text-gray">Remaining Character length:  <span className="text-green">{2000 - ((milestoneDetail.editLength) ? milestoneDetail.editLength + milestoneEditDetail.editLength : 0)}</span></span>
                                    </div>

                                    <ReactQuill modules={modules} style={{ height: '100px' }}
                                        formats={formats} theme="snow" id="addeditor"
                                        onKeyDown={addtaskeditorKeyDown}
                                        ref={addtaskQuillRef}
                                        value={values.taskEditDetail.replaceAll("''", "'")} onChange={addtaskeditorChange}
                                    ></ReactQuill>
                                    {values.error.taskEditDetail.length > 1 && (
                                        <span className="text-red m-t-40">{values.error.taskEditDetail}</span>
                                    )}

                                </div>
                                </div>
                                }



                            </div>
                                <div className="panel-action justify-content-end m-b-20 m-t-20 p-t-20">
                                    <button className="btn btn-sm btn-orange bg-green m-r-20 text-white" onClick={() => {
                                        AddMilestone();

                                    }}>Submit</button>
                                    <button type="button" className="btn btn-sm btn-dark-outline text-black d-flex align-item-center" onClick={() => {
                                        clearData();
                                        if (props.isPopup) { props.hideContract(); }
                                        setTogSetting(!togSetting);
                                    }}>Cancel</button>
                                </div>

                            </>
                        }
                        {mode == "view" && mode != " " &&


                            <>


                                <div className="d-flex justify-content-between m-b-20">

                                    <h6><a   >
                                        {(mileStones.task) ? mileStones.task.replaceAll("''", "'") : ''}
                                    </a></h6>



                                </div>

                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.taskdetails?.replaceAll("''", "'")).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p>

                                <div className="divider-line"></div>

                                <div className="row">


                                    <div className="col-lg-4 col-md-4 col-sm-6 m-t-10">
                                        <div className="icon-wrapper m-0">
                                            <span className="text-theme m-icon material-icons m-r-10">work</span>
                                            <div className="d-flex flex-column">
                                                <span className="m-b-5">Status</span>
                                                <b> {mileStones.msstatus}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10" >
                                        <div className="icon-wrapper m-0">
                                            <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                            <div className="d-flex flex-column">
                                                <span className="m-b-5"> Amount (USD)</span>
                                                <b> {(mileStones.amount) ? new Intl.NumberFormat('en-US').format(mileStones.amount) : ''}</b>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10">
                                        <div className="icon-wrapper m-0">
                                            <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                            <div className="d-flex flex-column">
                                                <span className="m-b-5">Due Date</span>
                                                <b>{getMonthDateYear(mileStones.duedate)}</b>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10">
                                        <div className="icon-wrapper m-0">
                                            <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                            <div className="d-flex flex-column">
                                                <span className="m-b-5">Hours Spent</span>
                                                <b>{mileStones.hoursspent}</b>
                                            </div>
                                        </div>
                                    </div>



                                    {mileStones.crduedate && <>    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10" > <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Change Request Due Date</span>
                                            <b>{getMonthDateYear(mileStones.crduedate)}</b>
                                        </div>
                                    </div></div></>}
                                    {mileStones.completeddate && <>    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10" > <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Completed Date</span>
                                            <b>{getMonthDateYear(mileStones.completeddate)}</b>
                                        </div>
                                    </div></div></>}
                                    {mileStones.datemodified && <>    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10" > <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Modified Date</span>
                                            <b>{getMonthDateYear(mileStones.datemodified)}</b>
                                        </div>
                                    </div></div></>}

                                    {mileStones.approveddate && <>    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10" > <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Approved Date</span>
                                            <b>{getMonthDateYear(mileStones.approveddate)}</b>
                                        </div>
                                    </div></div></>}
                                    {mileStones.rejecteddate && <>    <div className="col-lg-4 col-md-4 col-sm-6  m-t-10" > <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">timer</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Rejected Date</span>
                                            <b>{getMonthDateYear(mileStones.rejecteddate)}</b>
                                        </div>
                                    </div></div></>}

                                    {mileStones.crduedate && <>     <div className="divider-line"></div>  <div className="col-lg-12 col-md-12 col-sm-12  m-t-10" > <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10 "></span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5 f-16">Change Request Note</span>
                                            {mileStones.changereq1 && <><b>{"Note 1:"}</b> <p className="m-b-10  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.changereq1)?.replaceAll("''", "'").replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p>
                                            </>}
                                            {mileStones.changereq2 && <><b>{"Note 2:"}</b><p className="m-b-5  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.changereq2)?.replaceAll("''", "'").replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p><br /></>}
                                        </div>
                                    </div></div></>}
                                    <div className="divider-line"></div>
                                    {mileStones.rejectedreason && <>    <div className="col-lg-12 col-md-12 col-sm-12  m-t-5" > <div className="icon-wrapper m-0">

                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Rejected reason </span>
                                            <b><p className="m-b-10  " dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mileStones.rejectedreason).replaceAll("''", "'").replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p></b>
                                        </div>
                                    </div></div></>}
                                    <div className="col-lg-12 col-md-12 col-sm-12  m-t-5" > <div className="icon-wrapper m-0">

                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Payment Status </span>
                                            <b><p className="m-b-5  " >{(mileStones.mspaymentstatuscode && mileStones.mspaymentstatuscode <= 200) ? mileStones.mspaymentstatus + " - " + getStatusValue(mileStones) : mileStones.mspaymentstatus}</p></b>
                                        </div>
                                    </div></div>


                                    {mileStones.attachments && <>    <div className="col-lg-12 col-md-12 col-sm-12  m-t-5" >  <span className="m-b-5 m-l-5 f-26"> <b> </b></span> </div>
                                        <table className="table table-striped w-full">
                                            <thead>
                                                <tr>

                                                    <th className="min-lg-cell">Attachment Name</th>




                                                </tr>
                                            </thead>
                                            <tbody>

                                                {

                                                    mileStones.attachments.split(',').map((data, index) => {
                                                        return (

                                                            <tr key={index}>
                                                                <td>

                                                                    <div className="company-meta  text-left">



                                                                        <div className="upload-doc-img m-b-5 w-full ">
                                                                            <div className="workers-img d-flex pointer d-flex pointer attachments-img" data-toggle="tooltip" title="Click here to view." onClick={() => { window.open(s3URL + data, "_blank"); }}>
                                                                                <img src="../images/file.png" htmlFor="idUpload" className=" imgsize" />

                                                                                {(data) && <span className="text-gray m-t-10 " > {data.split("/").pop()} </span>}


                                                                            </div>
                                                                        </div></div>

                                                                </td></tr>





                                                        )
                                                    })}
                                            </tbody>
                                        </table>
                                    </>}




                                </div>
                            </>
                        }

                    </div>
                </ModalStyled>
            }
            <ModalStyled size="sm"
                centered backdrop="static"
                show={successModVisible}
                onHide={() => { setSuccessMessage(""); toggleSuccessModal(); clearData(); }}
            >
                <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/sucess-check.svg" alt="Success" />
                    <h5 className="m-b-10">Success!</h5>
                    <p className="text-light-gray">{successMessage}</p>

                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120" onClick={() => { handleClose() }}>Ok</button>
                    </div>

                </div>
            </ModalStyled>

        </>
    );
};
 