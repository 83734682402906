import React, { useState, useEffect } from "react";
import styled from "styled-components";  

const check = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 14px;
  color: inherit;
  &::before {
    content: "\f0c8";
    font-weight: 400;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;
const checkDisabled = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 14px;
  color: inherit;
  &::before {
    content: "\f0c8";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;
export default function Checkbox({ children, clear = false, value = "", field = "", handleChange, selectedValue = null, disable = false, showSelected = false }) {
    const [active, setActive] = useState(false);
    let [CheckStyled, setCheckStyled] = useState(check);
    useEffect(() => {
        if (clear) {
            setActive(false); 
        }
    }, [clear]);
    useEffect(() => {

        if (disable)
            setCheckStyled(checkDisabled);
        else {
            setCheckStyled(check);  
        }
    }, [disable]);
    useEffect(() => { 

        if (selectedValue != null && (field === "complianceCategoryIds" || field == "complianceStandardIds" || field === "industryIds" || field == "skillIds")) {
            let data = selectedValue.split(',').filter(item => item == value);
            if (data.length > 0)
                setActive(true);
        }
        else if (selectedValue != "" && selectedValue == value) {
            setActive(true);
        }
      

    }, [selectedValue]);
    
    return (
        <>
            <CheckStyled  
                className={`toggle-item d-none ${active ? "active" : ""} ${(!showSelected && !active) ? "d-none" : ''}`} style={{ pointerEvents: disable ? "none" : "auto" }}
                onClick={() => {
                    handleChange(!active, field, value, selectedValue);
                    setActive(!active);

                }}
            >
                {children}
            </CheckStyled>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" checked={active} disabled={disable} style={{ pointerEvents: disable ? "none" : "auto" }} onChange={() => {
                handleChange(!active, field, value, selectedValue);
                setActive(!active);

            }}/>
                <label className="form-check-label pointer" onClick={() => {
                    if (!disable) {
                        handleChange(!active, field, value, selectedValue);
                        setActive(!active);
                    } }}>{children}</label></div>
        </>
    );
};
