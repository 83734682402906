import React, { useContext } from "react";
import { useLocation } from 'react-router-dom';
import PageWrapper from "../components/PageWrapper";
import GlobalContext from "../context/GlobalContext";
import { Helmet } from "react-helmet";

export default function Deleted(props) {
    const pathName = useLocation().pathname.toLowerCase();
    const gContext = useContext(GlobalContext);
    if (pathName.trim().toLowerCase() === "/deletemessage")
        gContext.exitUser();
    return (
        <>
           
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>

                <span className="screen-darken"></span>

                <div className="page-wrapper" style={{height:"80vh"}}>
                {pathName.trim().toLowerCase() === "/deletemessage" && <section className="section create-job-block">
                        <div className="container-sm">
                            <div className="text-center">
                                

                                <h6 className="m-b-20 text-uppercase text-red">You are not authorized to access this page. Your account delete request is in progress.</h6>

                                <div className="d-flex justify-content-center">
                                    <a type="button" className="btn btn-orange" href="/">Go Back</a>
                                </div>
                            </div>
                        </div>
                    </section>}
                    {pathName.trim().toLowerCase() === "/notavailable" && <section className="section create-job-block">
                        <div className="container-sm">
                            <div className="text-center">
                                

                                <h6 className="m-b-20 text-uppercase text-red">Content not available.</h6>

                                <div className="d-flex justify-content-center">
                                    <a type="button" className="btn btn-orange" href="/">Go to Home</a>
                                </div>
                            </div>
                        </div>
                    </section>}
                    

                </div>

                     

               
            </PageWrapper>
        </>
    );
};
