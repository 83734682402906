import React, {   useEffect } from "react"; 
const Brands = (props) => {
     
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
     
    function sociallink(link) {

        var reg = new RegExp("^(http|https)://", "i");

        var match = reg.test(link);
         
        if (match == true) {
            return link;
        }
        else {
            link = 'https://' + link;
            return link;
        }



    }
   
    useEffect(() => {
        
    }, [props.bindData]);
  return (
    <>
      {/* <!-- Brands Area --> */}
          {(props.bindData && props.bindData.clients && props.bindData.clients.length > 0) &&

            <>  <section className="section-sm">
                            
                                <div className="company-logo">
                      <h6 className=" m-b-20">Get hired by top companies</h6>

                      <div className="logo-img-block m-t-20 text-center">
                          {(props.bindData && props.bindData.clients) && props.bindData.clients.map((client, index) => {
                             
                              return (
                                  <img key={index} className="pointer p-t-10 m-b-30 m-l-40 m-r-40" data-toggle="tooltip" onClick={() => { window.open((client.featuredlinktype == "W" && client.website && client.website != "") ? sociallink(client.website) : "/client/" + client.sub, "_blank"); }} title={ client.company } src={(client.featuredlogo) ? s3URL + client.featuredlogo : s3URL + client.logo} alt={client.company} key={index}/>
                                              )
                          })
                          }
            </div>
           
        </div>
      
    </section>
              <div className="bg-black-2 dark-mode-texts pt-10 pt-lg-18 pb-10 pb-lg-15 w-100 d-none">
                  <div className="container">
                      <div className="row">
                          <div className="col-12 w-100">
                              <div className="section-title mb-9 text-left text-lg-left">
                                  <h5 className="font-size-5 font-weight-normal text-gray">
                                      Get hired in top companies
                </h5>
                              </div>
                          </div>
                      </div>
                      {/* <!-- Brand Logos --> */}




                      <div className="row align-items-center text-center justify-content-center justify-content-lg-between w-auto">
                          {/* <!-- Single Brand --> */}

                          {(props.bindData && props.bindData.clients) && props.bindData.clients.map((client, index) => {

                              return (<div
                                  className="single-brand-logo mx-5   my-6 col-lg-2 col-xl-2 col-md-6 col-sm-12  align-items-center text-center justify-content-center   " key={index}
                                  data-aos="fade-in"
                                  data-aos-duration="800"
                                  data-aos-delay={index * 300}
                              >
                                  <a data-toggle="tooltip" title="Clieck to view "  href={(client.featuredlinktype == "W" && client.website && client.website != "") ? sociallink(client.website) : "/client/" + client.sub} target="_blank" rel="noopener noreferrer" className="w-100"> <img className="w-100" src={(client.featuredlogo) ? s3URL + client.featuredlogo : s3URL + client.logo} alt={client.company} /></a>
                              </div>
                              )
                          })
                          }

                      </div>
                      {/* <!-- End Brand Logos --> */}
                  </div>
              </div></>}
    </>
  );
};

export default Brands;
