import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { MessageSummary } from "../../utils/apiCalls";
import UserMessage from "../../sections/Message/Message";
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
const imgL = "assets/image/logo-main-black.png";
const Sidebar = (props) => { 
  const navigate = useNavigate();
  const pathName = useLocation().pathname.toLowerCase().trim();
  const gContext = useContext(GlobalContext); 
  const [showMessage, setShowMessage] = useState(false);
  const [openProfile, setOpenProfile] = useState(pathName == "/profile"); 
  const [openMessage, setOpenMessage] = useState(pathName == "/messages"); 
  const [previewUrl, setPreviewUrl] = useState("/client/" + gContext.userInfo.sub);
 

   

  return (
      <>
          {gContext.userInfo.idVerified && gContext.userInfo.profileComplete && 
              <li className="nav-item"> <a className={`nav-link  pointer ${(gContext.currentPage === "1" || gContext.currentPage === "11" || gContext.currentPage === "2" || gContext.currentPage === "3") ? 'active' : ''}`} style={{ cursor: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) ? '' : 'not-allowed' }} onClick={(e) => {
              e.preventDefault();
              if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) {
                      gContext.setVisibleProfileMessage(false);
                      setOpenProfile(false); gContext.setCurrentPage("1");
                      navigate("/dashboard", { replace: true }); props.setShowCanvas();
                  }
              }}>Dashboard</a> </li>}
         
          <li className="nav-item dropdown">
              <a className={`nav-link dropdown-toggle d-flex align-item-center pointer ${(gContext.currentPage === "5") ? 'active' : ''}`}  data-bs-toggle="dropdown" aria-expanded="false">Profile<span className="m-l-10 m-icon material-icons">expand_more</span></a>
              <ul className={`dropdown-menu  profile-list ${(gContext.currentPage === "5") ? 'hide' : ''}`}>               
                  <li><a className="dropdown-item pointer" onClick={(e) => { e.preventDefault(); gContext.setVisibleProfileMessage(false); setOpenProfile(!openProfile); gContext.setCurrentPage("5"); navigate("/profile", { replace: true }); props.setShowCanvas(); }}>Profile</a></li>

                  {gContext.userInfo.idVerified && gContext.userInfo.profileComplete && <li><a className="dropdown-item pointer" onClick={(e) => { e.preventDefault(); props.setShowCanvas(); window.location.href=previewUrl }}>Public View</a></li>}
              </ul>
          </li>
           
          <li className="nav-item"><a className={`nav-link d-flex pointer  ${(gContext.currentPage === "message") ? 'active' : ''}`} onClick={() => { setOpenMessage(!openMessage); gContext.setCurrentPage("message"); setShowMessage(true); navigate("/messages", { replace: true }); props.setShowCanvas();}}>Messages           
          {gContext.msgCount > 0 && <span className="count-msg  m-l-5">
                  {gContext.msgCount}
              </span>}
               </a></li>
        

         
      
    </>
  );
};

export default Sidebar;
