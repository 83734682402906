import React, { useContext, useEffect, useState } from "react";
import { SendMessage } from "../../utils/apiCalls";
import { isHtml, isInvalid } from "../../utils/helperFn";
import styled from "styled-components"; 
import GlobalContext from "../../context/GlobalContext"; 
import Message from "../../components/Message"; 
import validator from 'validator';
 
import { Modal } from "react-bootstrap";
 
 

const ModalStyled = styled(Modal)`
   &.modal {
     
  }
`;
export default function Feedback(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext); 
    const reactQuillRef = React.useRef();
    const [values, setValues] = useState({ message: "",  error: { message: "" } });
    const [userData, setUserdata] = useState({});   
    const [showUpdate, setShowUpdate] = React.useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const handleClose = () => {

    };
     
    function checkValidity() {
        let isValid = false; 
        let { error, ...rest } = values;  
        error.message = (rest.message.trim() == "") ? "Message is required" : (validator.isURL(rest.message) || isInvalid(rest.message)) ? "Invalid Message" : ""; 
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
   
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {
            case "message":
                error.message = validator.isEmpty(value) ? "Message is required" : "";
                break; 
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }
        setValues({ ...values, error, [name]: value }); 
    }
    async function SendNewMessage() {
        
        try {
            if (checkValidity()) { 
                
                  // email: "$email", fromTypeId: $fromTypeId, fromUserId: $fromUserId, message: "$message", toTypeId:
    //$toTypeId, toUserId: $toUserId gContext.userInfo.userID, gContext.userInfo.userType)
                        gContext.setLoaderModalVisible(true);
                        
                const result = await SendMessage({ attachment:"", email: gContext.userInfo.email, fromTypeId: gContext.userInfo.userType, fromUserId: gContext.userInfo.userID, message: values.message, toTypeId: 1, toUserId: userData.expertid});
               // const result = await SendMessage({ email: gContext.userInfo.email, fromTypeId: 1, fromUserId: userData.expertid, message: values.message, toTypeId: gContext.userInfo.userType, toUserId: gContext.userInfo.userID });
              
                        if (result.data.sendMessage.responseData.code == "200") { 
                            setUpdateStatus(true);
                        }
                 
                    gContext.setLoaderModalVisible(false); 

            } 
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }
    }
    useEffect(() => {
       
        if (props.userdata) { 
            setUserdata(props.userdata);
            
        } 
    }, [props.userdata]);

    useEffect(() => {
        if (!props.modalvisible  )
        { setUpdateStatus(false); setValues({ message: "", error: { message: "" } }); }

    }, [props.modalvisible]);


    return (
        <>
            <ModalStyled
                backdrop="static"
                centered size={updateStatus === true ?"sm":"lg"}
                show={props.modalvisible}
                onHide={props.togglemodal}
            >
                {updateStatus === false && props.modalvisible &&    
                    <div className="modal-content">


                        <div className="modal-header">
                            <h4 className="modal-title">Send Message</h4>        <button type="button" className="btn-close" onClick={() => {
                                props.togglemodal()
                            }}></button>

                        </div>
                       
                        <div className="modal-body">
                            {gContext.userInfo.userType == "1" &&
                                <div className="company-post align-item-center">
                                    <div className="medie-info-img">
                                    {(userData.logo) && <img src={s3URL + userData.logo}  alt="" />}
                                    </div>
                                    <div className="d-flex">
                                    <b> {(userData.company) ? userData.company : ''}</b>
                                </div>
                                <div className="d-flex">
                                    {(userData.comptagline) ? userData.comptagline : ''}
                                </div>
                                </div>}
                            {gContext.userInfo.userType == "2" &&
                                <a className="company-post align-item-center pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + (userData.expertsub ? userData.expertsub : userData.sub)} onClick={() => { window.open("/expert/" + (userData.expertsub ? userData.expertsub : userData.sub  ), "_blank") }}>
                                    <div className="medie-info-img">
                                    {(userData.avatar) && <img src={s3URL + userData.avatar} alt="" />}
                                    </div>
                                    <div className="d-flex">
                                    <b>  {userData.firstname + ' ' + userData.lastname}</b>
                                    </div>
                                    
                                </a>}

                            <div className="divider-line-20"></div>

                            <div className="company-desc m-0">
                                <div className="desc-info flex-column justify-content-start">
                                    <label for="first name" className="form-label m-b-10"><b>Message</b><span className="text-red"> * (Max 500 character )</span></label>
                                <span className="text-gray">Remaining Character length: <span className="text-green">{500 - ((values.message.length) ? values.message.length : 0) }</span></span>
                                </div>

                                <textarea
                                    name="message"
                                    id="message" maxLength="500"
                                    cols="30"
                                    rows="7" defaultValue={values.message} Value={values.message}
                                className={`form-control w-full ${values.error.message.length > 1? " error" :""} `}
                                    onKeyUp={handleFieldsChange} onChange={handleFieldsChange}
                                ></textarea>{values.error.message.length > 1 && (
                                    <span className="text-red">{values.error.message}</span>
                                )}

                            </div>


                        </div>


                        <div className="modal-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm m-r-20" onClick={() => {
                                    
                                    SendNewMessage();

                                }}>Send</button>
                                <button type="button" data-bs-dismiss="modal" className="btn btn-dark-outline btn-sm" onClick={props.togglemodal}>Cancel</button>
                            </div>
                        </div>

                    </div>
                }

                {updateStatus === true &&
                    
                        <div className="modal-body text-center">
                            <img className="m-b-20" src="../images/sucess-check.svg"   />
                            <h5 className="m-b-10">Successful!</h5>
                        <p className="text-light-gray">Message has been sent successfully</p>

                            <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-green w-120 pointer" onClick={ props.togglemodal }>Ok</button>
                            </div>

                        </div> 
                    
                }

                <Modal.Body className="p-0 d-none
">
                    <button
                        type="button"
                        className="d-none circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>

                    {updateStatus === false &&  
                        <div className="container px-0 py-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12  ">
                                    <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                        Send Message 
                                        </div>
                                </div>
                                <div className="col-xl-12 col-lg-12  mx-auto ">
                                    {userData.id && <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                        {/* <!-- Single Featured Job --> */}
                                        <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                            <div className="row mb-6">
                                                <div className="col-lg-6">
                                                    {gContext.userInfo.userType == "1" &&
                                                        <div className="media align-items-center">
                                                            {/* <!-- media logo start --> */}
                                                            <div className="square-72   d-block mr-8">
                                                                {(userData.logo) && <img src={s3URL + userData.logo} className="w-100 rounded" alt="" />}
                                                            </div>
                                                            {/* <!-- media logo end --> */}
                                                            {/* <!-- media texts start --> */}
                                                            <div>
                                                                <h3 className="font-size-6 mb-0">
                                                                    {(userData.company) ? userData.company : ''}
                                                                </h3>
                                                                <span className="font-size-3 text-gray line-height-2">
                                                                    {(userData.comptagline) ? userData.comptagline : ''}
                                                                </span><br />

                                                            </div>
                                                            {/* <!-- media texts end --> */}
                                                        </div>}
                                                    { gContext.userInfo.userType === "2" && <a className="media  pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + userData.expertsub}  >
                                                        <div className="  mr-6">
                                                            <img src={s3URL + userData.avatar} alt="" className=" circle-72 w-100" />
                                                        </div>
                                                        <h4 className="font-size-6 mb-0 font-weight-semibold text-black-2 pointer my-auto"   >
                                                            {userData.firstname + ' ' + userData.lastname}
                                                        </h4>
                                                    </a>}
                                                </div>
                                                <div className="col-md-6 pt-10 pt-md-0 mt-md-n1 text-right">


                                                </div>
                                            </div> 
                                             
                                        </div>
                                        {/* <!-- End Single Featured Job --> */}
                                         

                                  
                                         
                                            <div className="container px-5 pt-5">
                                                <div className="row justify-content-center"> 
                                                    
                                                    <div className="col-lg-12  pl-sm-10 pl-10 pr-sm-11 pr-4 pt-5   " >
                                                        <div className="form-group   ">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                                Message  <span className="error font-size-3">*  ( Max 500 Character)</span>
                                                        <br /><span className="font-size-3 text-green-2 font-weight-semibold">  Remaining Character length: {500 - ((values.message.length) ? values.message.length : 0)}</span>
                                                            </label>

                                                    <textarea
                                                        name="message"
                                                        id="message" maxLength="500"
                                                        cols="30"
                                                        rows="7" defaultValue={values.message} Value={values.message}
                                                        className={`border   ${(values.error.message.length > 0) ? 'border-red' : 'border-mercury'} text-gray w-100 pt-4 pl-6`}
                                                          onKeyUp={handleFieldsChange} onChange={handleFieldsChange}
                                                    ></textarea>{values.error.message.length > 1 && (
                                                        <span className="error font-size-3">{values.error.message}</span>
                                                    )}
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12  justify-content-md-end pr-10"><div className="media py-10 w-100  justify-content-md-end "  >

                                                        <div className="p-0 m-0 position-relative ">

                                                            <button
                                                                type="button" className="btn btn-green  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase"

                                                                onClick={() => {
                                                                    SendNewMessage();

                                                                }}>
                                                                Send
                            </button>
                                                        </div>

                                                        <button
                                                            type="button"

                                                            className="btn btn-green  font-size-3 font-weight-bold border-0 text-white 
                                                                    rounded-5 text-uppercase ml-5 ml-sm-3"

                                                            onClick={() => {
                                                                props.togglemodal();

                                                            }}>
                                                            Cancel
                            </button>

                                                    </div> </div>
                                                </div>

                                            </div>

                                      
                                       
                                    </div>}
                                </div>
                            </div>

                        </div>
                    }
                         
                       
                    {updateStatus === true &&
                        <div className="row  px-auto  text-center pt-10">
                    <Message message={'The message has been sent successfully'} /> 
                    
                        </div>
                    }

                </Modal.Body>
            </ModalStyled>
        </>
    );
};
