import React from 'react';
import {useLocation} from 'react-router-dom';
import { getRefreshToken } from "../utils/apiCalls";
const AuthProvider = () => {
   
    const pathName = useLocation().pathname; 

    if (localStorage.getItem("connect") && localStorage.getItem("connect").toString() !== "Invalid Refresh Token" ) {
        const user = JSON.parse(localStorage.getItem("connect"));
          
        if (user && typeof (user.payload) != "undefined") {
            const exp = user.payload["exp"];
            const expirationTime = (exp * 1000);

            if (expirationTime < Date.now()) {
                try {
                    getRefreshToken(user.payload["email"], user.refreshToken , user.payload["custom:usertype"]).then((result) => {
                         if (result.data.refreshToken)
                        localStorage.setItem('connect', JSON.stringify(JSON.parse(result.data.refreshToken.responseData.message)));
                    })
                }
                catch (error) {
                    console.log(error);
                    localStorage.removeItem('connect');
                    window.location.href = "/login";
                }
            }
        }
        else {
            localStorage.removeItem('connect');
            window.location.href = "/login";}
    } 
  return null;
};

export default AuthProvider;

