import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import Message from "../../components/Message"; 
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import validator  from 'validator';
import ReactQuill from 'react-quill';
import AlertUtils from "../../utils/AlertUtils";
import { isInvalid } from "../../utils/helperFn";
import { CreateOffer, CreateAlert } from "../../utils/apiCalls";
import { GetRateTypes, GetWeeklyHrs } from "../../utils/GetAPIUtils";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'isomorphic-dompurify'; 

const Offer = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const reactQuillRef = React.useRef();
    const gContext = useContext(GlobalContext);

    const [values, setValues] = React.useState({ rateType: null, rateTypeId: null, id: null, jobId: null, expertId: null, proposalId: null, clientId: gContext.userInfo.userID,dailyRate: null, fixedRate: null, hourlyRate: null, title: '', weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', startDate: "", error: { startDate: '', title: '', jobType: '', dailyRate: "", fixedRate: "", hourlyRate: "", weeklyHrs: "", desc: "", rateType: '' } });
    const [initialValues, setInitialValues] = React.useState({ rateType: null, rateTypeId: null, id: null, jobId: null, expertId: null, proposalId: null, clientId: gContext.userInfo.userID, dailyRate: null, fixedRate: null, hourlyRate: null, title: '', weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', startDate: "", error: { startDate: '', title: '', jobType: '', dailyRate: "", fixedRate: "", hourlyRate: "", weeklyHrs: "", desc: "", rateType: '' } });
    const [weeklyHours, setWeeklyHours] = useState([{}]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showPropsal, setShowPropsal] = useState(false);
    const [proposal, setProposal] = useState({});
    const [message, setMessage] = React.useState(false);
    const [editvalue, setEditvalue] = useState('');
    const [editorText, seteditorText] = useState('');
    const [editLength, setEditLength] = useState(0);

    const [updateStatus, setUpdateStatus] = useState(false);

    const [rateType, setRateType] = useState([]);

    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };


    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];

    useEffect(() => {
        if (props.job != null && props.mode == null) {
            try {
                let jobValue = props.job;
               
                let offerData = { ...values };
                offerData.title = jobValue.title;
                offerData.desc = jobValue.desc;
                offerData.weeklyHrsId = (jobValue.weeklyhrsid) ? jobValue.weeklyhrsid : 0;
                offerData.weeklyHrs = { value: jobValue.weeklyhrsid, label: jobValue.weeklyhrs };
                setProposal(props.proposal);
                setInitialValues(offerData);
                setValues(offerData);
                offerData.error.desc = ""; 
            }
            catch (e) {
                console.log(e);
            }
        }
    }, [props.job]);

    useEffect(() => {
        setEditvalue(values.desc);
    }, [values]);
    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }
    const editorChange = (content, delta, source, editor) => { 
        let { error, ...rest } = values;

        setEditvalue(editor.getHTML());
        seteditorText(editor.getText().trim());
        setEditLength(editor.getText().trim().length);

        if (isInvalid(editor.getText())) {
            error.desc = "Invalid format.";
             setValues({ ...values, error, ['descText']: editor.getText().trim(), ['desc']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
        }
        else {
            error.desc = "";
             setValues({ ...values, error, ['descText']: editor.getText().trim(), ['desc']: DOMPurify.sanitize(editor.getHTML().replaceAll('"',`'`) )});
        }
    };
    const editorKeyDown = (event) => {

        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 4000 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };
    const handleDateChange = (name, value) => {
        setMessage(false);
        let { error, ...rest } = values;
        error.startDate = "";
        setValues({
            ...values, error, [name]: value
        });
    }
    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = values; 
        error.title = validator.isEmpty(rest.title.trim()) ? "Title is required" : ((isHtml(rest.title) || isInvalid(rest.title)) ? "Invalid format" : "");
          error.rateType = (rest.rateType === null) ? "RateType is required." : error.rateType;
      

     if(rest.startDate == null || rest.startDate == '' || validator.isEmpty(rest.startDate.toString()) == true )
     {
        error.startDate = "Start date is required.";
     }
     else
     error.startDate =''; 
     
        if (rest.rateType && rest.rateType.code === 100) {
            error.dailyRate = "";
            error.fixedRate = "";
            error.hourlyRate = (rest.hourlyRate) ? validator.isDecimal(rest.hourlyRate.toString())? (values.hourlyRate < 3 || values.hourlyRate > 500)? "Hourly rate range is 3-500": "" :"Hourly rate is invalid." : "Hourly Rate is required";
        }
        else if (rest.rateType && rest.rateType.code === 200) {
            error.dailyRate = (rest.dailyRate) ? (validator.isDecimal(rest.dailyRate.toString() ) ? (( values.dailyRate < 10 || values.dailyRate > 5000)?'Daily rate range is 10-5000': "") :"Daily rate is invalid." ): "Daily Rate is required"; 
            error.hourlyRate = ""; 
            error.fixedRate = "";
        }
        else if (rest.rateType && rest.rateType.code === 300) {
            error.dailyRate = "";
            error.fixedRate = (rest.fixedRate) ? validator.isDecimal(rest.fixedRate.toString()) ? ( values.fixedRate < 10 || values.fixedRate > 5000)?'Fixed rate range is 10-5000':"" : "Fixed rate is invalid.":"Fixed Rate is required";
            error.hourlyRate = "";
        }      
        error.weeklyHrs = (rest.weeklyHrs) ? error.weeklyHrs : "Weekly hours is required";
        error.desc = validator.isEmpty(reactQuillRef.current.unprivilegedEditor.getText().trim()) ? "Description is required" : (isInvalid(reactQuillRef.current.unprivilegedEditor.getText()) ? "Invalid format" : "");

        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }

    async function SendNotification() { 

        try {
            
            const alert = AlertUtils.NewOffer.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", props.job.company).
                replace("[JOBTITLE]", props.job.title).
                replace("[JOBGUID]", props.job.guid) + " Check <a class='text-theme' href = '" + window.location.origin + "/dashboard' target = '_self'   > dashboard</a> Offer section to Accept or Reject.";


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: props.proposal.expertid });

            if (result.data.createAlert.responseData.code == "200") {


            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {

            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    async function updateOffer(posted) {
        
        if (checkValidity())
         {

             gContext.setLoaderModalVisible(true);
 
             var data = (props.mode && props.mode === "edit") ? { ...values, ['descText']: editorText, ['desc']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)) } : { ...values, ['descText']: editorText, ['desc']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)), ["jobId"]: (props.job) ? props.job.id : null, ["expertId"]: (props.proposal) ? props.proposal.expertid : null, ["proposalId"]: (props.proposal) ? props.proposal.id : null }
             try {
                 const result = await CreateOffer(data);
                 
                 if (result.data.offerCreate.responseData.code == "200") {
                     
                     setUpdateStatus(true);
                     props.setOfferStatus(true);
                     SendNotification(); 
                     gContext.toggleRefreshDashboard("offer");
                     
                 }
                 else {
                     setErrorMessage(JSON.parse(result.data.offerCreate.responseData.message));
                 }
             }
             catch (e) {
 
                 gContext.setLoaderModalVisible(false);
                 console.log(e);
                 setErrorMessage("Server error");
             }

           

            
        }

        

        gContext.setLoaderModalVisible(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    }

    const handleLeave = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {

            case "dailyRate":
                error.dailyRate = (value != '') ? validator.isDecimal(value) ? "" : "Daily rate is invalid" : '';
                error.dailyRate = (value != '') ?validator.isDecimal(value)?(value <= 5000) ? "" : "Max value  is 5000":"Daily rate is invalid":error.dailyRate;
                break;
            case "fixedRate":
               
                error.fixedRate = (value != '')?validator.isDecimal(value)?(value <= 5000) ? "" : "Max value  is 5000":"Fixed rate is invalid":error.fixedRate;
                break;
            case "hourlyRate":
                 
                error.hourlyRate = (value != '')?validator.isDecimal(value)?(value <= 500) ? "" : "Max value  is 500":"Hourly rate is invalid":error.hourlyRate;
                break;
        }

        if ((name == "fixedRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["dailyRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "dailyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "hourlyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["dailyRate"]: null });
        }
        else { setValues({ ...values, error, [name]: value }); }
    }

    const handleSelectChange = (e, name) => {

        const value = e;

        let error = { ...values.error };
        switch (name) {
            case "weeklyHrs":
                error.weeklyHrs = "";
                break;

            case "rateType":
               
                error.rateType = "";
                error.fixedRate = "";
                error.dailyRate = "";
                error.hourlyRate = "";
                break;
            default:
                break;
        }

        
        setValues({ ...values, error, [name]: value, [name + 'Id']: value.value });

    }


    
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        let data = { ...values };


        switch (name) {
            case "title":
                error.title = validator.isEmpty(value) ? "Title is required" : "";
                break;
            case "dailyRate":
                error.dailyRate = (value != '') ? validator.isDecimal(value) ? ((value < 10) || (value > 5000)) ? "Daily rate range is 10-5000" : "" : "Daily rate is invalid" : '';
                error.hourlyRate = "";
                error.fixedRate = "";
                break;
            case "fixedRate":
                error.fixedRate = (value != '') ? validator.isDecimal(value) ? ((value < 10) || (value > 5000)) ? "Fixed rate range is 10-5000" : "" : "Fixed rate is invalid" : '';
                error.hourlyRate = "";
                error.dailyRate = "";
                break;
            case "hourlyRate":
                error.hourlyRate = (value != '') ? validator.isDecimal(value) ? ((value < 3) || (value > 500)) ? "Hourly rate range is 3-500" : "" : "Hourly rate is invalid" : '';
                error.dailyRate = ""; error.fixedRate = "";
                break;
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }

        if ((name == "fixedRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["dailyRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "dailyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "hourlyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["dailyRate"]: null });
        }
        else { setValues({ ...values, error, [name]: value }); }

    } 
    async function getUtils() {

        if (rateType.length === 0) {
            const rateTypes = await GetRateTypes();

            if (rateTypes.data && rateTypes.data.rateTypes) {
                let type = [];
                rateTypes.data.rateTypes.map((value) => (
                    type.push({ code: value.code, value: value.id, label: value.rate })));

                setRateType(type);
            }
        }
        const weeklyhrsResponse = await GetWeeklyHrs();

        if (weeklyhrsResponse.data && weeklyhrsResponse.data.weeklyHours) {
            let weeklyhrsList = [];
            weeklyhrsResponse.data.weeklyHours.map((value) => (
                weeklyhrsList.push({ value: value.id, label: value.hours })));
            setWeeklyHours(weeklyhrsList);
        }       
    }
     

    async function BindOffer() {
        try {

            if (props.offer) {
                let offerValue = props.offer;
                
                let offerData = { ...values };
                offerData.jobId = offerValue.jobid;
                offerData.expertId = offerValue.expertid;
                offerData.proposalId = offerValue.proposalid;
                offerData.id = offerValue.id;
                offerData.title = offerValue.title;
                offerData.dailyRate = (offerValue.dailyrate) ? parseInt(offerValue.dailyrate) : null;
                offerData.hourlyRate = (offerValue.hourlyrate) ? parseInt(offerValue.hourlyrate) : null;
                offerData.fixedRate = (offerValue.fixedrate) ? parseInt(offerValue.fixedrate) : null;
                offerData.rateTypeId = offerValue.ratetypeid;
                offerData.rateType = { code: offerValue.ratetypecode, value: offerValue.ratetypeid, label: offerValue.ratetype };
                offerData.desc = offerValue.desc;
                offerData.weeklyHrsId = (offerValue.weeklyhrsid) ? offerValue.weeklyhrsid : 0;
                offerData.weeklyHrs = { value: offerValue.weeklyhrsid, label: offerValue.weeklyhrs };
                offerData.startDate = (offerValue.startdate) ? new Date(offerValue.startdate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "";
                offerData.error.desc = ""; 

                setInitialValues(offerData);
                setValues(offerData);
             
                if (props.proposal) {
                    let prop = props.proposal.shortlisted.filter(item => item["id"] === offerValue.proposalid);
                    setProposal((prop && prop.length > 0) ? prop[0] : {});
                }
            }

        }
        catch (e) {

        }
    }
    useEffect(() => {
        document.title = "Create Offer | Syncuppro";
        getUtils();
    }, []); 

    useEffect(() => {
        if (props.mode && props.mode === "edit") {
            BindOffer();
        }
    }, [props.offer]);

    useEffect(() => {
        if(editvalue)
             setEditLength(editvalue.replace(/(<([^>]+)>)/ig, '').length);
    }, [editvalue]);

    return (
        <>
            {updateStatus === false &&
                 
                        <div className="modal-content">

                            
        <div className="modal-header">
                                <h4 className="modal-title">Make an offer</h4>
                    <button type="button" className="btn-close" onClick={() => {
                        props.toggleOffer()
                    }}></button>
                            </div>

                           
        <div className="modal-body">

                                <div className="row">

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="forn-group-input">
                                            <label for="job title" className="form-label m-b-10">Offer Title <span className="text-red"> * </span></label>
                                <input
                                    type="text" defaultValue={values.title} Value={values.title}
                                    className={`form-control   ${(values.error.title.length > 0) ? ' error' : ''}`}
                                    id="Title"
                                    name="title"
                                    placeholder="eg.Software Engineer" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                />{values.error.title.length > 1 && (
                                    <span className="text-red">{values.error.title}</span>
                                )}
              </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="forn-group-input">
                                                <label for="job title" className="form-label m-b-10">Weekly hours <span className="text-red"> * </span></label>
                                <Select options={weeklyHours}
                                    placeholder="Select..." value={values.weeklyHrs}
                                    className={`form-control  padding-select ${(values.error.weeklyHrs.length > 0) ? ' error' : ''}`}
                                    border={false} onChange={(e) => handleSelectChange(e, 'weeklyHrs')}
                                />
                                {values.error.weeklyHrs.length > 1 && (
                                    <span className="text-red">{values.error.weeklyHrs}</span>
                                )}
              </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">
                                                    <label for="job title" className="form-label m-b-10">Start Date<span className="text-red"> * </span></label>
                                <CustomDatePicker className="form-control  "
                                    placeholderText="Start Date"
                                    selected={values.startDate}
                                    onChange={(date) => (handleDateChange('startDate', date))}
                                    minDate={new Date()}
                                ></CustomDatePicker>
                                {values.error.startDate.length > 1 && (
                                    <span className="text-red ">{values.error.startDate}</span>
                                )}

                
              </div>
                                                </div>

                                            </div>

          <div className="company-desc">
                                                <div className="desc-info flex-column justify-content-start">
                                                    <label for="first name" className="form-label m-b-10"><b>Description</b><span className="text-red"> * (Max 4000 character )</span></label>
                            <span className="text-gray">Remaining Character length:  <span className="text-green">{4000 - ((editLength) ? editLength : 0)}</span></span>
                                                </div>

                        <ReactQuill modules={modules}
                            formats={formats} theme="snow" id="editor"  
                            onKeyDown={editorKeyDown}
                            ref={reactQuillRef}
                            value={editvalue} onChange={editorChange}
                        ></ReactQuill>
                        {values.error.desc.length > 1 && (
                            <span className="text-red ">{values.error.desc}</span>
                        )}

                                            </div>

                                            <div className="row">

                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="forn-group-input">
                                                        <label for="experience level" className="form-label m-b-10">Rate Type<span className="text-red"> * </span></label>
                                <Select name="expLevel" value={values.rateType}
                                    options={rateType} placeholder="Select..."
                                    className={`form-control  padding-select   ${(values.error.rateType.length > 0) ? ' error' : ''}`}
                                    border={false} onChange={(e) => handleSelectChange(e, 'rateType')}
                                />
                                {values.error.rateType.length > 1 && (
                                    <span className="text-red">{values.error.rateType}</span>
                                )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                    <div className="forn-group-input">
                                                        <label for="last name" className="form-label m-b-10">Daily Rate <span className="text-red">(USD)</span></label>
                                {(values.rateType && values.rateType.code === 200) && <input name="dailyRate"
                                    type="text" Value={values.dailyRate} className={`form-control   ${(values.error.dailyRate.length > 0) ? ' error' : ''}`}

                                    id="dailyRate" onKeyUp={handleFieldsChange}
                                    placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                />}
                                {(!values.rateType || values.rateType.code !== 200) && <input name="dailyRate1" disabled="true"
                                    type="text" className={`form-control`}
                                    id="dailyRate"
                                />
                                }
                                {values.error.dailyRate.length > 1 && (
                                    <span className="text-red">{values.error.dailyRate}</span>
                                )}
              </div>
                                                    </div>

                                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                                        <div className="forn-group-input">
                                                            <label for="last name" className="form-label m-b-10">Fixed Rate <span className="text-red">(USD)</span></label>
                                {(values.rateType && values.rateType.code === 300) && <input name="fixedRate"
                                    type="text" className={`form-control   ${(values.error.fixedRate.length > 0) ? ' error' : ''}`}

                                    id="fixedRate" defaultValue={values.fixedRate} Value={values.fixedRate} onKeyUp={handleFieldsChange}
                                    placeholder=" " onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                />}
                                {(!values.rateType || values.rateType.code !== 300) && <input name="fixedRate1" disabled="true"
                                    type="text" className={`form-control`}
                                    id="fixedRate1"
                                />}
                                {values.error.fixedRate.length > 1 && (
                                    <span className="text-red">{values.error.fixedRate}</span>
                                )}
              </div>
                                                        </div>

                                                        <div className="col-lg-3 col-md-3 col-sm-6">
                                                            <div className="forn-group-input">
                                                                <label for="last name" className="form-label m-b-10">Hourly Rate <span className="text-red">(USD)</span></label>
                                {(values.rateType && values.rateType.code === 100) && <input name="hourlyRate"
                                    type="text" className={`form-control   ${(values.error.hourlyRate.length > 0) ? ' error' : ''}`}

                                    id="hourlyRate" defaultValue={values.hourlyRate} Value={values.hourlyRate}
                                    onKeyUp={handleFieldsChange} placeholder=" " onChange={handleFieldsChange}
                                    onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                />}
                                {(!values.rateType || values.rateType.code !== 100) && <input name="dailyRate1" disabled="true"
                                    type="text" className={`form-control`}
                                    id="dailyRate"
                                />
                                }
                                {values.error.hourlyRate.length > 1 && (
                                    <span className="text-red">{values.error.hourlyRate}</span>
                                )}
              </div>
                                                            </div>

                                                        </div> 

          <div className="panel-action justify-content-end m-b-20">
                        <button className="btn btn-sm btn-orange bg-green m-r-20 text-white" onClick={() => {
                            updateOffer(false);

                        }}>Submit</button>
                        <button type="button" className="btn btn-sm btn-dark-outline text-black d-flex align-item-center" onClick={() => {
                            props.toggleOffer()
                        }}>Cancel</button>
                                                        </div>

                                                        <div className="row m-b-20">

                        <div className="col-lg-6 col-md-6 col-sm-6 w-half">

                            {props.job && props.job.guid &&
                                    <a className=" d-flex align-item-center text-green" target="_blank" rel="noopener noreferrer" href={"job/" + props.job.guid} >View Job  </a>
                               }
                            {props.offer && props.offer.jobguid &&

                                    <a className=" d-flex align-item-center text-green" target="_blank" rel="noopener noreferrer" href={"job/" + props.offer.jobguid} >View Job </a>
                                }
                            
                                                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 text-right align-item-right w-half">
                                <a className=" d-flex  text-green  text-right align-item-right w-half pointer" onClick={(e) => { setShowPropsal(!showPropsal) }} >
                                {(showPropsal) ? "- Hide Proposal" : "+ View Proposal"}
                            </a>
                                                              
                                                            </div>

                                                        </div>
                    {proposal && showPropsal == true &&
                        <div className="card">
                            <h6 className="text-green m-b-30">Proposal Details </h6>

                            <div className="row">


                            <div className="col-lg-6 col-md-6 col-sm-12 ">
                                <a className="d-flex align-item-center" target="_blank" rel="noopener noreferrer" href={"/expert/" + proposal.sub} >
                                    <div className="medie-info-img">
                                        <img src={s3URL + proposal.avatar} alt="" />
                                    </div>
                                    <span className="expert-desg"><strong> {proposal.firstname + ' ' + proposal.lastname}</strong></span>
                                </a>
                            </div>


                            <div className="col-lg-6 col-md-6 col-sm-12 m-t-10">
                                <div className="forn-group-input ">
                                    <label for="first name" className="form-label m-b-10">Bid Rate (USD) <span className="text-red"> </span></label>

                                       ${(proposal.bid) ? new Intl.NumberFormat('en-US').format(proposal.bid) : ""}

                                </div>
                            </div>
                            <div className="company-desc ">
                                <div className="desc-info">
                                    <label className="form-label m-b-0 f-16"> Cover Letter    </label>
                                </div>

                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(proposal.cover_letter) }}></div>


                            </div>


                            {proposal.attachments && proposal.attachments !== "" && <div className="company-meta m-b-20">
                                <label className="d-flex form-label m-b-10 f-16">  Attachment  </label>

                                <a href={s3URL + proposal.attachments} rel="noopener noreferrer" target="_blank" data-toggle="tooltip" title="Click here to view.">  <img src="../images/file.png" htmlFor="idUpload" /> </a>




                            </div>}

                            </div>
                        </div>}


                                                            </div>



                                                        </div>
                                                   }

            {updateStatus === true &&
                <div className="row  px-auto  text-center pt-5 d-none">
                    <Message message={'Your offer has been submitted successfully'} />
                </div>
            }

        </>
    );
};

export default Offer;
