import React, { useState, useEffect, useContext, useRef } from "react";
import ScrollToTop from "react-scroll-to-top";
import styled, { ThemeProvider } from "styled-components";
//import Head from "next/head";
import AOS from "aos";
import { Helmet } from "react-helmet";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
 
 
import SidebarDashboard from "./SidebarDashboard/SidebarDashboard";
import ModalVideo from "./ModalVideo/ModalVideo";
import ModalApplication from "./ModalApplication/ModalApplication";
import ModalSignIn from "./ModalSignIn/ModalSignIn";
import ModalSignUp from "./ModalSignUp/ModalSignUp";
import ModalGeneral from "./ModalGeneral/ModalGeneral";

import ModalError from "./ModalError/ModalError";
import ModalSuccess from "./ModalSuccess/ModalSuccess";
import ModalConfirm from "./ModalConfirm/ModalConfirm";
import ModalLoader from "./ModalConfirm/ModalLoad";
import ModalPassword from "./ModalPassword/ModalPassword";
import ModalForgotPassword from "./ModalForgotPassword/ModalForgotPassword";
import Cookies from "./Cookies/Cookies";
import GlobalContext from "../context/GlobalContext";

import GlobalStyle from "../utils/globalStyle"; 
import { get, merge } from "lodash";
import { useLocation } from 'react-router-dom';
// the full theme object
import { theme as baseTheme } from "../utils";
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from "../chatbot/config"
import MessageParser from '../chatbot/MessageParser.js';
import ActionProvider from '../chatbot/ActionProvider.js';
import Zendesk, { ZendeskAPI, ZendeskEndChat } from "../ZendeskConfig";

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

  
 

const Layout = ({ children, pageContext }) => {
    const gContext = useContext(GlobalContext);
   
    const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [visibleLoader, setVisibleLoader] = useState(true);
    const [showCookies, setShowCookies] = useState(false);
    const [showScrollup, setShowScrollup] = useState(false);
    
    const pathName = useLocation().pathname.toLowerCase();
    function changeStatus() {
        // setStatus(navigator.onLine);
        // setErrorMessage(navigator.onLine ? "" : "No internet");
        if (!navigator.onLine && pathName.trim().toLowerCase() != "/servererror")
            window.location.href = "/servererror";
    }
    useEffect(() => {
         
        //changeStatus();
        //window.addEventListener("online", changeStatus);
        //window.addEventListener("offline", changeStatus);
        //return () => {
        //    window.removeEventListener("online", changeStatus);
        //    window.removeEventListener("offline", changeStatus);
        //};
        window.sessionStorage.clear();
      AOS.init({ once: true });
      setVisibleLoader(false);
      setShowCookies(document.cookie.indexOf("accepted_cookies=") < 0);      

  }, []);

    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);

  // Navbar style based on scroll
  const eleRef = useRef();

  
  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);
 
    const handleScrollupButton =()=> {

        
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0) {
            setShowScrollup(true)
        }
        else  {
            setShowScrollup(false)
        }
    }
    const scrollToTop=()=> {

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
           
        });
    }


  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
        >
            {(pathName.trim().toLowerCase() != "/" && pathName.trim().toLowerCase() != "/searchexpert" && pathName.trim().toLowerCase() != "/searchjob") && <Helmet>
                 
                <meta name="description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
                <meta name="og:description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
                <meta name="twitter:description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
            </Helmet>}
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle /> 
          
                
                <div className="site-wrapper overflow-hidden ShowScrollup" ref={eleRef}>
                    {children}
                    <Cookies/>
          </div>
                <ModalGeneral />
          <ModalVideo />
          <ModalApplication />
                {gContext.signInModalVisible && <ModalSignIn />}
                {gContext.signUpModalVisible && <ModalSignUp />}
                <ModalLoader />
                <ModalError />
                <ModalSuccess />
                <ModalPassword />
                {gContext.showBot && <div className="chatbot-overlay">  <Chatbot
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                    headerText={<> <text style={{ width: "340px" }}>Chat with Syncuppro &nbsp;</text> <a style={{ color: "#585858", fontWeight: "bold", borderRadius: "0px", width: "250", alignContent: "end", marginRight: "0" }} className="align-right" href="#" onClick={() => gContext.toggleBot((prev) => !prev)}><i className="fas fa-times"></i></a></>}
                    close="true"
                /></div>}
                {(gContext.showBotBubble && !gContext.showBot) && <div className="chatbot-bubble text-center align-center pointer" onClick={() => gContext.toggleBot(true)}>  <span className="f-24  text-white m-icon material-icons align-middle m-t-20">chat</span>
                </div>}
                <Zendesk defer zendeskKey={ZENDESK_KEY} />
        </div>
      </ThemeProvider>
    );
  }

  if (pageContext.layout === "dashboard") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
        > {(pathName.trim().toLowerCase() != "/" && pathName.trim().toLowerCase() != "/searchexpert" && pathName.trim().toLowerCase() != "/searchjob") && <Helmet>

            <meta name="description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
            <meta name="og:description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
            <meta name="twitter:description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
        </Helmet>}
        <div data-theme-mode-panel-active data-theme="light" >
          <GlobalStyle />
        
                    <Header ref={eleRef} isDark={gContext.headerDark} />
                
                        {children}


                    <Cookies />

                <ScrollToTop smooth="true" component={< i className="fa fa-arrow-up text-white  font-size-7"></i>} className="btn-circle  scroll-up media-icon     bg-green rounded-5  text-white  font-size-7  " />
                    <div  className="d-none smin-h-100vh w-100"></div>
                    <Footer isDark={gContext.footerDark} />
                        
           
         
                <ModalGeneral />
                <ModalVideo />
                <ModalConfirm />
          <ModalApplication />
               
                <ModalError />
                <ModalSuccess />
                {gContext.signInModalVisible && <ModalSignIn />}
                {gContext.signUpModalVisible && <ModalSignUp />}
                <ModalLoader />
                <ModalPassword />
                {gContext.showBot && <div className="chatbot-overlay">  <Chatbot
                    config={config}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                    headerText={<> <text style={{ width: "340px" }}>Chat with Syncuppro &nbsp;</text> <a style={{ color: "#585858", fontWeight: "bold", borderRadius: "0px", width: "250", alignContent: "end", marginRight: "0" }} className="align-right" href="#" onClick={() => gContext.toggleBot((prev) => !prev)}><i className="fas fa-times"></i></a></>}
                    close="true"
                /></div>}
                {(gContext.showBotBubble && !gContext.showBot) && <div className="chatbot-bubble text-center align-center pointer" onClick={() => gContext.toggleBot(true)}>  <span className="f-24  text-white m-icon material-icons align-middle m-t-20">chat</span>
                </div>}
                <Zendesk defer zendeskKey={ZENDESK_KEY} />
        </div>
      </ThemeProvider>
    );
  }
   
  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
          >
              {(pathName.trim().toLowerCase() != "/" && pathName.trim().toLowerCase() != "/searchexpert" && pathName.trim().toLowerCase() != "/searchjob") && <Helmet>

                  <meta name="description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
                  <meta name="og:description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
                  <meta name="twitter:description" content="Hire the Best Compliance Expert for your needs.Ensure compliance success with the industry's top expert by your side." />
              </Helmet>}
        <div data-theme-mode-panel-active data-theme="light">
          <GlobalStyle />  
          <div className="site-wrapper overflow-hidden" ref={eleRef}>
            <Header isDark={gContext.headerDark} />
            {children}
                      <Cookies />
                    
                      <div style={{ display: showScrollup ? 'inline' : 'none' }} className="btn-circle   scroll-up media-icon px-5 py-5 bg-green rounded-5" onClick={scrollToTop}>
                          
                      </div>
                      <ScrollToTop smooth="true" component={< i className="fa fa-arrow-up text-white  font-size-7"></i>} className="btn-cirlce  scroll-up media-icon     bg-green rounded-5  text-white  font-size-7 "/>
                      
                      <Footer isDark={gContext.footerDark} />
                      <ModalGeneral />
                      <ModalVideo />
                      <ModalApplication />
                      {gContext.signInModalVisible && <ModalSignIn />}
                      {gContext.signUpModalVisible && <ModalSignUp />}
                      <ModalError />
                      <ModalSuccess/>
                      <ModalConfirm/>
                      {gContext.forgotPasswordModalVisible && <ModalForgotPassword />}
                      <ModalLoader />
                      <ModalPassword />
                      {gContext.showBot && <div className="chatbot-overlay">  <Chatbot
                          config={config}
                          messageParser={MessageParser}
                          actionProvider={ActionProvider}
                          headerText={<> <text style={{ width: "340px" }}>Syncuppro ChatBot</text> <a style={{ color: "#585858", fontWeight: "bold", borderRadius: "0px", width: "250", alignContent: "end", marginRight: "0" }} className="align-right" href="#" onClick={() => gContext.toggleBot((prev) => !prev)}><i className="fas fa-times"></i></a></>}
                          close="true"
                      /></div>}
                      {(gContext.showBotBubble && !gContext.showBot)  && <div className="chatbot-bubble text-center align-center pointer" onClick={() => gContext.toggleBot(true)}>  <span className="f-24  text-white m-icon material-icons align-middle m-t-20">chat</span>
                      </div>}
                      <Zendesk defer zendeskKey={ZENDESK_KEY} />
          </div>

        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
