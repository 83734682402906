import React, { useContext,useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import { useParams } from "react-router-dom"; 
import ContractView from "../sections/ClientDashboard/ContractView";
import { Helmet } from "react-helmet";

export default function Job(props) {
    const { ID } = useParams();



    return (
        <>
             
            <PageWrapper headerConfig={{
               
                button: props.navType
            }}>
                <ContractView id={ ID} />

            </PageWrapper>
        </>
    );
};
