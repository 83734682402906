import React, { useState, useContext, useEffect } from "react";
import { APIClass } from "aws-amplify";
 
 
 
const Message = (props) => {
   
    
     
    return (
        <div className="    mb-5 text-green   ">
            <p className="  text-green f-18"> <i className="fa fa-check f-18   "></i> {props.message}</p>

        </div>
                            
                            
    );
};

export default Message;
