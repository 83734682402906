import React, { useContext,useEffect } from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import PageWrapper from "../../components/PageWrapper";
import { Select } from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";

const imgP1 = "../assets/image/table-one-profile-image-1.png";
const imgP2 = "../assets/image/table-one-profile-image-2.png";
const imgP3 = "../assets/image/table-one-profile-image-3.png";
const imgP4 = "../assets/image/table-one-profile-image-4.png";
const imgP5 = "../assets/image/table-one-profile-image-5.png";
const imgF1 = "../assets/image/l2/png/featured-job-logo-1.png";
const iconD = "../assets/image/svg/icon-dolor.svg";
const iconB = "../assets/image/svg/icon-briefcase.svg";
const iconL = "../assets/image/svg/icon-location.svg";


export default function Applicants() {
    const gContext = useContext(GlobalContext);
     useEffect(() => {
    
        document.title = "Applicants | Syncuppro";
    }, []);
    return (
        <>

       
                <div className="container"> 
                    
                    <div className="mt-10">
                      
                        <div className="bg-white pt-10  pb-8 ">
                            <div className="table-responsive">
                                <table className="table table-striped ">
                                    <thead>
                                        <tr className="bg-green-opacity-2 border text-green-2  ">
                                            <th
                                                scope="col"
                                                className="pl-5  border-0 font-size-4 font-weight-semibold"
                                            >
                                                Name
                        </th>
                                            <th
                                                scope="col"
                                                className="border-0 font-size-4 font-weight-semibold"
                                            >
                                                Applied as
                        </th>
                                            <th
                                                scope="col"
                                                className="border-0 font-size-4 font-weight-semibold"
                                            >
                                                Applied on
                        </th>
                                            <th
                                                scope="col"
                                                className="border-0 font-size-4 font-weight-semibold"
                                            ></th>
                                            <th
                                                scope="col"
                                                className="border-0 font-size-4 font-weight-semibold"
                                            ></th>
                                            <th
                                                scope="col"
                                                className="border-0 font-size-4 font-weight-semibold"
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border border-color-2">
                                            <th scope="row" className="pl-6 border-0 py-5 pr-0">

                                            <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={"/expert/f180bc26-27fa-4a1b-9b75-fee8dd832db0"}  >
                                                    <div className="circle-36 mr-6">
                                                        <img src={imgP1} alt="" className="w-100" />
                                                    </div>
                                                <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                        Nicolas Bradley
                              </h4>
                                                </a>

                                            </th>
                                            <td className="table-y-middle py-5 min-width-px-235 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    Senior Project Manager
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    12 July, 2020
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <div className="">
                                                    <a

                                                        className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            gContext.toggleApplicationModal();
                                                        }}
                                                    >

                                                    </a>
                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-110 pr-0">
                                                <div className="">

                                                    <a href="/contact" className="font-size-3 font-weight-bold text-green text-uppercase">
                                                        Contact
                              </a>

                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 pr-0">
                                                <div className="">

                                                    <a className="font-size-3 font-weight-bold text-red-2 text-uppercase">
                                                        Reject
                              </a>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border border-color-2">
                                            <th scope="row" className="pl-6 border-0 py-5 pr-0">

                                                <a  className="media min-width-px-235 align-items-center">
                                                    <div className="circle-36 mr-6">
                                                        <img src={imgP2} alt="" className="w-100" />
                                                    </div>
                                                    <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                        Elizabeth Gomez
                              </h4>
                                                </a>

                                            </th>
                                            <td className="table-y-middle py-5 min-width-px-235 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    Senior Project Manager
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    14 July, 2020
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <div className="">
                                                    <a

                                                        className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            gContext.toggleApplicationModal();
                                                        }}
                                                    >

                                                    </a>
                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-110 pr-0">
                                                <div className="">

                                                    <a href="/contact" className="font-size-3  font-weight-bold text-green text-uppercase">
                                                        Contact
                              </a>

                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 pr-0">
                                                <div className="">

                                                    <a className="font-size-3 font-weight-bold text-red-2 text-uppercase">
                                                        Reject
                              </a>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border border-color-2">
                                            <th scope="row" className="pl-6 border-0 py-5 pr-0">

                                                <a  className="media min-width-px-235 align-items-center">
                                                    <div className="circle-36 mr-6">
                                                        <img src={imgP3} alt="" className="w-100" />
                                                    </div>
                                                    <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                        Joe Wade
                              </h4>
                                                </a>

                                            </th>
                                            <td className="table-y-middle py-5 min-width-px-235 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    Head of Marketing
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    14 July, 2020
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <div className="">
                                                    <a

                                                        className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            gContext.toggleApplicationModal();
                                                        }}
                                                    >

                                                    </a>
                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-110 pr-0">
                                                <div className="">

                                                    <a href="/contact" className="font-size-3 font-weight-bold text-green text-uppercase">
                                                        Contact
                              </a>

                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 pr-0">
                                                <div className="">

                                                    <a className="font-size-3 font-weight-bold text-red-2 text-uppercase">
                                                        Reject
                              </a>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border border-color-2">
                                            <th scope="row" className="pl-6 border-0 py-5 pr-0">

                                                <a  className="media min-width-px-235 align-items-center">
                                                    <div className="circle-36 mr-6">
                                                        <img src={imgP4} alt="" className="w-100" />
                                                    </div>
                                                    <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                        Roger Hawkins
                              </h4>
                                                </a>

                                            </th>
                                            <td className="table-y-middle py-5 min-width-px-235 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    UI Designer
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    16 July, 2020
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <div className="">
                                                    <a

                                                        className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            gContext.toggleApplicationModal();
                                                        }}
                                                    >

                                                    </a>
                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-110 pr-0">
                                                <div className="">

                                                    <a href="/contact" className="font-size-3 font-weight-bold text-green text-uppercase">
                                                        Contact
                              </a>

                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 pr-0">
                                                <div className="">

                                                    <a className="font-size-3 font-weight-bold text-red-2 text-uppercase">
                                                        Reject
                              </a>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border border-color-2">
                                            <th scope="row" className="pl-6 border-0 py-5 pr-0">

                                                <a  className="media min-width-px-235 align-items-center">
                                                    <div className="circle-36 mr-6">
                                                        <img src={imgP5} alt="" className="w-100" />
                                                    </div>
                                                    <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2">
                                                        Marie Green
                              </h4>
                                                </a>

                                            </th>
                                            <td className="table-y-middle py-5 min-width-px-235 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    Senior Project Manager
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    21 July, 2020
                          </h3>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-170 pr-0">
                                                <div className="">
                                                    <a

                                                        className="font-size-3 font-weight-bold text-black-2 text-uppercase"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            gContext.toggleApplicationModal();
                                                        }}
                                                    >

                                                    </a>
                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-110 pr-0">
                                                <div className="">
                                                    <a href="/contact" className="font-size-3 font-weight-bold text-green text-uppercase">
                                                        Contact
                              </a>

                                                </div>
                                            </td>
                                            <td className="table-y-middle py-5 min-width-px-100 pr-0">
                                                <div className="">

                                                    <a className="font-size-3 font-weight-bold text-red-2 text-uppercase">
                                                        Reject
                              </a>

                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="pt-2">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination pagination-hover-primary rounded-0 ml-n2">
                                        <li className="page-item rounded-0 flex-all-center">
                                            <a

                                                className="page-link rounded-0 border-0 px-3active"
                                                aria-label="Previous"
                                            >
                                                <i className="fas fa-chevron-left"></i>
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a

                                                className="page-link border-0 font-size-4 font-weight-semibold px-3"
                                            >
                                                1
                        </a>
                                        </li>
                                        <li className="page-item">
                                            <a

                                                className="page-link border-0 font-size-4 font-weight-semibold px-3"
                                            >
                                                2
                        </a>
                                        </li>
                                        <li className="page-item">
                                            <a

                                                className="page-link border-0 font-size-4 font-weight-semibold px-3"
                                            >
                                                3
                        </a>
                                        </li>
                                        <li className="page-item disabled">
                                            <a

                                                className="page-link border-0 font-size-4 font-weight-semibold px-3"
                                            >
                                                ...
                        </a>
                                        </li>
                                        <li className="page-item ">
                                            <a

                                                className="page-link border-0 font-size-4 font-weight-semibold px-3"
                                            >
                                                7
                        </a>
                                        </li>
                                        <li className="page-item rounded-0 flex-all-center">
                                            <a

                                                className="page-link rounded-0 border-0 px-3"
                                                aria-label="Next"
                                            >
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
          
        </>
    );
};