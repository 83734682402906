import React from "react";
import StarRatings from 'react-star-ratings';
 
 
const StarRating = ({ 
    ...rest
}) => {
    return (
        <StarRatings
            starDimension="18px" 
            starSpacing="0px"
            starRatedColor="#FFD700" 
            starSelectingHoverColor="#FFD700"
            starHoverColor="#FFD700"
            {...rest}
        />
    );
};
export default StarRating;