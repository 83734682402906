import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

    const showContent = (mode,name,url) => {       
     
        let message;
        console.log(name,"name");
        message = createChatBotMessage("Click following link to view", {
            widget: mode
        });
        updateState(message, name);
        
    }
    
    const updateState = (message, checker) => {
        setState((prev) => ({
            ...prev,
            messages: [...prev.messages, message],
            checker,
        }))
    }

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        showContent                       
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;