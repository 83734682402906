import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import Message from "../../components/Message";
import validator from 'validator';
import { sinceDate } from "../../utils/addDays";
import { LoadQuestionsbyCategory, AssmntSubmit } from "../../utils/apiCalls";
import RadioButton from "../../components/Sidebar/RadioButton";
import ConfirmMessages from "../../utils/confirmMessages";
import { GetComplianceCategory, GetProjectLength, GetIndustryTypes, GetSkills, GetExpLevels, GetComplianceTypes, GetWeeklyHrs, GetCountry } from "../../utils/GetAPIUtils";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'isomorphic-dompurify';


const imgLoader = "../assets/image/svg/spinning-circles.svg";
const Instructions = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const reactQuillRef = React.useRef();
    const gContext = useContext(GlobalContext);
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const [timerAlert, setTimerAlert] = useState(false);
    const [categoryCode, setCategoryCode] = useState(['EN', 'PR', 'AU', 'CO', 'DS']);
    const [startAssessment, setStartAssessment] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [currentCategory, setCurrentCategory] = useState({ index: 0, category: categoryCode[0] });
    const [selectedAns, setSelectedAns] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [submitted, setSubmitted] = useState(null);
    const [questionLists, setQuestionLists] = useState(null);
    const [complete, setComplete] = useState(false);
    const [allowAssessment, setAllowAssessment] = useState(false);
    const [nextAssessment, setnextAssessment] = useState("");
    const [allQuestions, setAllQuestions] = useState([]);
    const profileInfo = gContext.userInfo.profileInfo;

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }
    const startTimer = (e) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            //disable={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans != "ans_a" : false}
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
            if (hours == 0 && minutes == 0 && seconds <= 30) {
                setTimerAlert(true);
            }
            else
                setTimerAlert(false);
            if (total == 0) {
                LoadQuestions(categoryCode[currentCategory.index + 1])
                setCurrentCategory({ index: currentCategory.index + 1, category: categoryCode[currentCategory.index + 1] });
            }

            //       clearTimer(getDeadTime());
        }

    }
    const getDeadTime = (timeallowedmins) => {
        let deadline = new Date();

        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + parseInt(timeallowedmins) * 60);
        return deadline;
    }

    function handleChange(active, field, value, multiple) {
        
        if (!selectedAns || selectedAns.length == 0) {
          
            if (active) { setSelectedAns([{ id: field, ans: value }]); }

        }
        else {
            let previousAns = selectedAns.filter(item => item.id == field);
            let ans = selectedAns.filter(item => item.id != field);
            if (active) {
                if (multiple) {
                    ans.push({ id: field, ans: ((previousAns.length > 0) ? previousAns[0].ans + "," + value : value) });
                    setSelectedAns(ans);
                }
                else {
                    ans.push({ id: field, ans: value });
                    setSelectedAns(ans);
                    
                  

                }
            }
            else {
                if (multiple) {
                    if (previousAns.length > 0) {
                        let lists = previousAns[0].ans.split(',').filter(item => item != value);
                        let key = '';
                        lists.map((data) => (key += data + ','));
                        ans.push({ id: field, ans: (key !== '') ? key.substring(0, key.length - 1) : "" });
                        setSelectedAns(ans);
                    }

                }
                else { setSelectedAns(selectedAns.filter(item => item.id != field)); }

            }
        

        }
        const remove = allQuestions.filter(item => item["id"] != field);
        const add = allQuestions.filter(item => item["id"] == field);
        if (add.length > 0) {
            add[0]["ans"] = (active) ? value : "";
            remove.push(add[0]);
        }
        setAllQuestions(remove);
      
    }

    async function LoadQuestions(catCode) {
        try {
             
            setTimerAlert(false);
            
            const result = await LoadQuestionsbyCategory(catCode);
            let allQues = allQuestions;
            let loadQues = [];
            if (result.data.loadQuestions.responseData.code == "200") {
                let data = JSON.parse(result.data.loadQuestions.responseData.message);
               
                setCurrentQuestion(1);
                setQuestionLists(data);
                if (data && data.length > 0) {
                    clearTimer(getDeadTime(data[0].timeallowedmins), data[0].timeallowedmins);
                    if (catCode != "DS") {

                        data.map((row) => {
                            allQues.push({
                                 ...row,["ans"]:''
                            });
                        });
 
                        setAllQuestions(allQues);
                    }
                }
            }
           // gContext.setLoaderModalVisible(false);
        }
        catch (e) {
           // gContext.setLoaderModalVisible(false);
           
        }
    }
    async function submitAnswer() {
        try {

            gContext.setLoaderModalVisible(true);
            let ans = "";
            let ques = "";
            allQuestions.map((row, index) => {
                ans += (index + 1 == allQuestions.length) ? row.ans : row.ans + ","; 
                ques += (index + 1 == allQuestions.length) ? row.id : row.id + ","; 
            });
            
            const result = await AssmntSubmit(gContext.userInfo.userID,  ans,ques);
            
            if (result.data.assmntSubmit.responseData.code == "200") {
                let data = JSON.parse(result.data.assmntSubmit.responseData.message);
                gContext.getUpdatedInfo(gContext.userInfo.userType, gContext.userInfo.email, gContext.userInfo.sub);
                setSubmitted(data);
                setCurrentQuestion(1);
                setComplete(false);
            }
            else if(result.data.assmntSubmit.responseData.code == "400")
            {
                gContext.setLoaderModalVisible(false);
            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
             
        }

    }
    
    useEffect(() => {
        //gContext.setLoaderModalVisible(true);
    }, []);
    useEffect(() => {
          
    }, [allQuestions]);
    useEffect(() => {
        if (gContext.userInfo.profileInfo) {

            if (submitted ==null) {
                if (gContext.userInfo.profileInfo[0].assessment == false && ((gContext.userInfo.profileInfo[0].assmntdate) || gContext.userInfo.profileInfo[0].assmntdate != "")) {
                    setnextAssessment("");
                    const assmntRetryDate = gContext.userInfo.profileInfo[0].assmntdate;
                    const assmntRetryCount = gContext.userInfo.profileInfo[0].assmntretrycount;
                    const assmntRetryLevel = gContext.userInfo.profileInfo[0].assmntretrylevel;

                    if (assmntRetryCount && assmntRetryCount < 3)
                        setAllowAssessment(true);
                    else if (assmntRetryCount && assmntRetryCount === 3) {
                        let newDate;
                        if (assmntRetryLevel === 1) {
                           
                            newDate = new Date(new Date(assmntRetryDate).setDate(new Date(assmntRetryDate).getDate() + 7));
                            
                        }
                        else if (assmntRetryLevel === 2) {
                            newDate = new Date(new Date(assmntRetryDate).setDate(new Date(assmntRetryDate).getDate() + 30));
                        }
                        else if (assmntRetryLevel === 3) {
                            newDate = new Date(new Date(assmntRetryDate).setDate(new Date(assmntRetryDate).getDate() + 90));
                        }
                        if (newDate < new Date()) {
                            
                            setAllowAssessment(true);
                        }
                        else {

                            setAllowAssessment(false);
                            setnextAssessment(sinceDate(new Date().toString(), newDate.toString()));
                        }

                    }

                }


                setTimeout(() => {
                    setLoaded(true);
                  //  gContext.setLoaderModalVisible(false);

                }, 2000);
            }

        }
    }, [gContext.userInfo.profileInfo]);
    const clearTimer = (e, timeallowedmins) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next    
        setTimer('00:' + timeallowedmins + ':00');

        // If you try to remove this line the 
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }
    useEffect(() => {
        document.title = "Assessment | Syncuppro";
        //if (currentQuestion == ((currentCategory.index + 1) * 5) + 1) {
        //    LoadQuestions(categoryCode[currentCategory.index + 1])
        //    setCurrentCategory({ index: currentCategory.index + 1, category: categoryCode[currentCategory.index + 1] });

        //}


    }, [currentQuestion]);

    async function LoadNextQuestion() {
       
        if (currentCategory.index == 4 && currentQuestion === questionLists.length) {




            setComplete(true); return;
        }
        if (currentQuestion === questionLists.length) {
            
            LoadQuestions(categoryCode[currentCategory.index + 1])
            setCurrentCategory({ index: currentCategory.index + 1, category: categoryCode[currentCategory.index + 1] });

        }
        else { setCurrentQuestion(currentQuestion + 1) }

    }

    return (
        <> 
                <div className="breadcrumb d-none">
                    <div className="container-fluid">

                        <div className="breadcrumb-wrapper">
                            <h4>Assessment</h4>

                            <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / Assessment</span>
                            </div>

                        </div>

                    </div>
                </div>
                <section className="section create-job-block">
                    <div className="container-fluid">               
                        {(loaded && !startAssessment) && <>
                            <div className="card panel p-0">

                                {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo[0].assessment == false && gContext.userInfo.profileInfo[0].assmntdate && allowAssessment) && <>
                                    <div className="card-header">
                                        <h4>INSTRUCTIONS </h4>
                                    </div>

                                    <div className="card-body p-b-0">

                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                   We're thrilled to have you here and are excited to embark on this journey of assessing your skills and expertise.
                                            </span>
                                        </p><p className="f-16">
                                            <span className="text-light-gray">

                                                Assessments are a crucial part of our platform as they help us understand your strengths, expertise, and unique capabilities. They allow us to match you with the right opportunities and showcase your skills to potential clients.
                                            </span></p>
                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                There are 5  individualized topic specific tests: </span></p>
                                        <ul className="assessment-list">
                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Auditor</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Consultant</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">English</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Project Management</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">DISC</span>
                                            </li>


                                        </ul>

                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                All the assessment are timed and you won't be allowed to change your answers on a question after it's submitted.</span></p>
                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                A target score of 70% is set as the baseline for expert approval. </span></p>
                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                <b>Special Instructions:</b>  </span></p>

                                        <ul>
                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Please note that each question has individual objective answer.</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">We recommend taking this assessment in single take.</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">
                                                    When you have selected the correct answer, hit continue to proceed.</span>
                                            </li>


                                        </ul>




                                    </div>

                                    <div className="card-footer">
                                        <div className="panel-action">
                                            <button type="button" className="btn btn-green" onClick={() => {
                                                setStartAssessment(true); LoadQuestions(currentCategory.category);
                                            }}>Start</button>
                                        </div>
                                    </div></>}

                                {gContext.userInfo.profileInfo && (gContext.userInfo.profileInfo[0].assessment == false || !gContext.userInfo.profileInfo[0].assessment) && !gContext.userInfo.profileInfo[0].assmntdate && <>
                                    <div className="card-header">
                                        <h4>INSTRUCTIONS </h4>
                                    </div>

                                    <div className="card-body p-b-0">

                                        <p className="f-16">
                                            <span className="text-light-gray"> 
                                                
                                                <b> Welcome aboard to Syncuppro!</b> We're thrilled to have you here and are excited to embark on this journey of assessing your skills and expertise.
                                            </span>
                                        </p><p className="f-16">
                                            <span className="text-light-gray">

                                        Assessments are a crucial part of our platform as they help us understand your strengths, expertise, and unique capabilities. They allow us to match you with the right opportunities and showcase your skills to potential clients.
                                            </span></p>
                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                There are 5  individualized topic specific tests: </span></p>
                                        <ul className="assessment-list">
                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Auditor</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Consultant</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">English</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Project Management</span>
                                            </li>

                                            <li>
                                                <span className="m-icon material-icons">check</span>
                                                <span className="f-16">DISC</span>
                                            </li>

                                           
                                        </ul>

                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                All the assessment are timed and you won't be allowed to change your answers on a question after it's submitted.</span></p>
                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                A target score of 70% is set as the baseline for expert approval. </span></p>
                                        <p className="f-16">
                                            <span className="text-light-gray">
                                                <b>Special Instructions:</b>  </span></p>
                                        
<ul>
                                        <li>
                                            <span className="m-icon material-icons">check</span>
                                                <span className="f-16">Please note that each question has individual objective answer.</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">check</span>
                                                <span className="f-16">We recommend taking this assessment in single take.</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">check</span>
                                                <span className="f-16">
                                                    When you have selected the correct answer, hit continue to proceed.</span>
                                        </li>


                                    </ul>
                                        

                                       

                                    </div>

                                    <div className="card-footer">
                                        <div className="panel-action">
                                            <button type="button" className="btn btn-green" onClick={() => {
                                                setStartAssessment(true); LoadQuestions(currentCategory.category);
                                            }}>Starts</button>
                                        </div>
                                    </div></>}

                                {gContext.userInfo.profileInfo && gContext.userInfo.profileInfo[0].assessment == true &&

                                    <>
                                        <div className="card-header">
                                            <h4>INSTRUCTIONS </h4>
                                        </div>

                                        <div className="card-body p-b-0">

                                            <p className="f-16">
                                                <span className="text-light-gray">
                                                    {ConfirmMessages.AssmtCompleted}
                                                </span>
                                            </p>



                                        </div></>



                                }
                                {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo[0].assessment == false && gContext.userInfo.profileInfo[0].assmntdate && !allowAssessment && nextAssessment != "") &&
                                    <>   <div className="card-header">
                                        <h4>INSTRUCTIONS </h4>
                                    </div>

                                        <div className="card-body p-b-0">

                                            <p className="f-16">
                                                <span className="text-red">
                                                    {/*You've reached the maximum assessment attempts. Please try again after {nextAssessment.replace("ago", ".")}*/}

                                                {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assmntretrylevel == 1) && <>Failed! We've noticed you've had three consecutive unsuccessful attempts at the assessment. For security and fairness, we encourage you to take a break and review your approach before attempting again. You'll need to wait for 7 days before making another attempt. Take this time to brush up on your skills, and we look forward to your next attempt.</>}
                                            {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assmntretrylevel == 2) && <>Failed! It seems you've made another three unsuccessful attempts at the assessment. To ensure a fair and accurate evaluation, a 30-day waiting period is now in place before your next attempt. Use this time to focus on areas you find challenging, seek additional resources, or seek assistance from our support team if needed. We're here to help you succeed!</>}
                                            {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assmntretrylevel == 3) && <>Failed! We've observed three more unsuccessful attempts at the assessment. For your account's security and to maintain the integrity of the assessment process, there's now a waiting period of 90 days before your next attempt. During this time, consider revisiting your preparation strategy or accessing supplementary materials to enhance your skills. Feel free to contact our support team if you require any guidance. We're rooting for your success!</>}
                                                
                                            </span>
                                            </p>



                                        </div>  </>
                                }
                            </div>
                        </>}
                        {!loaded &&

                            <div className="tab-pane active show" role="tabpanel">
                                <div className="card panel  p-0">

                                    <div className="card-header">

                                        <div className="client-title movingLoader w-150">
                                            <h6>&nbsp;</h6>
                                        </div>

                                        <div className="panel-action">
                                            <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>

                                        </div>

                                    </div>

                                    <div className="card-body p-b-0">


                                        <div className="row">



                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">

                                                    <input name="fName"

                                                        className={`form-control   movingLoader }`}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">

                                                    <input name="fName"

                                                        className={`form-control   movingLoader }`}

                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="forn-group-input">

                                                    <input name="fName"

                                                        className={`form-control   movingLoader }`}

                                                    />
                                                </div>
                                            </div>





                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <div className="panel-action">
                                            <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>
                                        </div>
                                    </div>

                                </div></div>
                        }  
                        {submitted == null && startAssessment && questionLists && questionLists.length > 0 && !complete && <>
                        <div className="card panel p-0">

                            <div className="card-header">
                                    <h5> {questionLists[currentQuestion - 1].category}</h5>
                                    {timerAlert && <span className="text-red "> {timer}</span>}
                                    {!timerAlert && <span className="text-green"> {timer}</span>}
                               
                            </div>

                            <div className="card-body p-b-0">
                                <div className="d-flex flex-column">
                                        <spam className="f-16 m-b-30"> {questionLists[currentQuestion - 1].section}</spam>
                                        <span className="f-16 m-b-30"> {questionLists[currentQuestion - 1].title}</span>
                                </div>
                                <ul className="quiz">
                                    <li>
                                            <h6> {currentQuestion}. {questionLists[currentQuestion - 1].question}</h6>
                                            <ul className="choices"> 

                                            <li className="m-b-10 width-max">
                                                    <RadioButton  handleChange={handleChange} value={'A'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'A'}. {questionLists[currentQuestion - 1].ans_a}</RadioButton> 
                                            </li>

                                            <li className="m-b-10 width-max">
                                                    <RadioButton handleChange={handleChange} value={'B'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'B'}. {questionLists[currentQuestion - 1].ans_b}</RadioButton> </li>
                                            <li className="m-b-10 width-max">
                                                    <RadioButton handleChange={handleChange} value={'C'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'C'}. {questionLists[currentQuestion - 1].ans_c}</RadioButton> </li>

                                            <li className="m-b-10 width-max" >
                                                    <RadioButton handleChange={handleChange} value={'D'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'D'}. {questionLists[currentQuestion - 1].ans_d}</RadioButton> </li>

                                            {questionLists[currentQuestion - 1].ans_e && questionLists[currentQuestion - 1].ans_e != "" && <li className=" m-b-20  width-max">     <RadioButton handleChange={handleChange} value={'E'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'E'}. {questionLists[currentQuestion - 1].ans_e}</RadioButton> </li>}
                                        </ul>
                                    </li>
                                </ul>


                            </div> 

          <div className="card-footer">
                                    <div className="panel-action">

                                       
                                        {currentQuestion !== 1 && <button
                                            type="button"
                                            className="btn btn-dark-outline m-r-20 "

                                            onClick={() => {
                                                setCurrentQuestion(currentQuestion - 1);
                                            }}>
                                            {"<< Prev"}
                                        </button>}   <button
                                            type="button"
                                            className="btn btn-green  "

                                            onClick={() => {
                                                LoadNextQuestion();
                                            }}>
                                            Next >>
                            </button>
                                    
                                </div>
                            </div>

                            </div></>}


                        {submitted == true && <>


                            <div className="card panel p-0">

                                <div className="card-body">
                                    <p className="f-24 m-b-0">  {ConfirmMessages.AssmtCompleted}</p>
                                </div>  
                            </div> 

                           </>}

                        {submitted == false &&
                            <>

                            <div className="card panel p-0">

                                <div className="card-body">
                                    <p className="f-24 m-b-0 text-red"> {ConfirmMessages.AssmtnotCompleted}</p>
                                </div> 

          <div className="card-footer">
                                <div className="panel-action">
                                    <button type="button" className="btn btn-green " onClick={() => {
                                        window.location.href = "/assessment";
                                    }}>Try Again</button>
                                        
                                    </div>
                                </div>

                            </div>
                                
                            </>}

                        {complete && <>


                            <div className="card panel p-0">

                                <div className="card-body">
                                    <p className="f-24 m-b-0">{ConfirmMessages.AssmtSubmit}</p>
                                </div> 

          <div className="card-footer">
                                    <div className="panel-action">
                                        <button type="button" className="btn btn-green m-r-10" onClick={() => {
                                            submitAnswer();

                                        }}>Submit</button>
                                        <button type="button" className="btn btn-dark-outline" onClick={() => {
                                            window.location.href = "/assessment";
                                        }}>Cancel</button>
                                    </div>
                                </div>

                            </div>
                             
                        </>}


                    </div>
                </section>
            

            <div
                className={`dashboard-main-container mt-20 mt-lg-25 position-relative  min-h-100vh d-none`}
                id="dashboard-body"
            >

                <div className="container">


                    <div className="mb-15 mb-lg-23 pr-10">

                        {(loaded && !startAssessment) && <>
                            <div className="row  rounded rounded-4 border pl-8 py-7 pr-7 ml-5  mb-5 w-100 bg-white ">
                                <div className="col-xxxl-12  ">


                                    <>
                                        <div
                                        >

                                            {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo[0].assessment == false && gContext.userInfo.profileInfo[0].assmntdate && allowAssessment) &&
                                                <> <div className="pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0  pr-md-13 pr-xl-15 pr-xxl-25 pr-0 ">
                                                    {/* <!-- content-1 section-title start --> */}
                                                    <h2 className="font-size-7 mb-8 pr-xxl-15">
                                                        Instructions
                </h2>
                                                    <p className="text-gray font-size-5 mb-10 mb-lg-16">
                                                        You are highly encouraged to refer to the Instructions and System Requirements before starting the assessment and ensure that you are completing the entire assessment process in one sitting without a break.
                </p>
                                                    {/* <!-- content-1 section-title end --> */}
                                                    {/* <!-- content-1 list-group start --> */}
                                                    <ul className="list-unstyled pl-0">
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                   Assessment have 5 sections. Each section carries 5 questions.
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Five sections are English Proficiency, Project Management,Auditor Competency,Consultant Competency,DISC Profile.                 </li>

                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                  Maximum allotted time to finish each section will be displayed at the top right corner of the assessment sceen  (e.g. English section - 00:10:00 (timer))
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">         <i className="fas fa-check font-size-4 text-green mr-6"></i>
                  When maximum allotted time elapsed screen will automatically move to next section. You will be notified 30 seconds before maximum allotted time elapse.
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Overall percentage required to pass the assessment (70%)
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Please make sure that you complete the test in a single go.
Please do not take breaks during the test.
                  </li>
                                                    </ul>
                                                    {/* <!-- content-1 list-group end --> */}
                                                </div> <div className="col-lg-12 text-right justify-content-md-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-green btn-medium  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                                            onClick={() => {
                                                                setStartAssessment(true); LoadQuestions(currentCategory.category);
                                                            }}>
                                                            Start >>
                            </button>
                                                    </div> </>}

                                            {(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo[0].assessment == false && gContext.userInfo.profileInfo[0].assmntdate && !allowAssessment && nextAssessment != "") &&
                                                <div className="pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0   pr-md-13 pr-xl-15 pr-xxl-25 pr-0 ">
                                                    {/* <!-- content-1 section-title start --> */}
                                                    <h2 className="font-size-7 mb-8 pr-xxl-15">
                                                        Instructions
                </h2>
                                                    <p className="text-gray font-size-5 mb-10 mb-lg-16">
                                                        You've reached the maximum assessment attempts. Please try again after {nextAssessment.replace("ago", ".")}
                                                    </p>
                                                    {/* <!-- content-1 section-title end --> */}

                                                    {/* <!-- content-1 list-group end --> */}
                                                </div>
                                            }
                                            {gContext.userInfo.profileInfo && (gContext.userInfo.profileInfo[0].assessment == false || !gContext.userInfo.profileInfo[0].assessment) && !gContext.userInfo.profileInfo[0].assmntdate &&
                                                <>
                                                    <div className="pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-md-13 pr-xl-15 pr-xxl-25 pr-0 ">
                                                    {/* <!-- content-1 section-title start --> */}
                                                    <h2 className="font-size-7 mb-8 pr-xxl-15">
                                                        Instructions
                </h2>
                                                    <p className="text-gray font-size-5 mb-10 mb-lg-16">
                                                        <strong>  Welcome!</strong> You are highly encouraged to refer to the Instructions and System Requirements before starting the assessment and ensure that you are completing the entire assessment process in one sitting without a break.
                </p>
                                                    {/* <!-- content-1 section-title end --> */}
                                                    {/* <!-- content-1 list-group start --> */}
                                                    <ul className="list-unstyled pl-0">
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                   Assessment have 5 sections. Each section carries 5 questions.
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Five sections are English Proficiency, Project Management,Auditor Competency,Consultant Competency,DISC Profile.                 </li>

                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                  Maximum allotted time to finish each section will be displayed at the top right corner of the assessment sceen  (e.g. English section - 00:10:00 (timer))
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">         <i className="fas fa-check font-size-4 text-green mr-6"></i>
                  When maximum allotted time elapsed screen will automatically move to next section. You will be notified 30 seconds before maximum allotted time elapse.
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Overall percentage required to pass the assessment (70%)
                  </li>
                                                        <li className="font-weight-semibold border-0 d-flex mb-7 heading-default-color">
                                                            <i className="fas fa-check font-size-4 text-green mr-6"></i>
                    Please make sure that you complete the test in a single go.
Please do not take breaks during the test.
                  </li>
                                                    </ul>
                                                    {/* <!-- content-1 list-group end --> */}
                                                </div>
                                                <div className="col-lg-12 text-right justify-content-md-end">
                                                        <button
                                                            type="button"
                                                            className="btn btn-green btn-medium  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                                            onClick={() => {
                                                                setStartAssessment(true); LoadQuestions(currentCategory.category);
                                                            }}>
                                                            Start >>
                            </button>
                                                    </div></>
                                            }
                                            {gContext.userInfo.profileInfo && gContext.userInfo.profileInfo[0].assessment == true &&
                                                <div className="pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-md-13 pr-xl-15 pr-xxl-25 pr-0 ">
                                                    {/* <!-- content-1 section-title start --> */}
                                                    <h2 className="font-size-7 mb-8 pr-xxl-15">
                                                        Instructions
                </h2>
                                                    <p className="text-gray font-size-5 mb-10 mb-lg-16">
                                                    {ConfirmMessages.AssmtCompleted}
                </p>
                                                    {/* <!-- content-1 section-title end --> */}

                                                    {/* <!-- content-1 list-group end --> */}
                                                </div>
                                            }
                                        </div>
                                    </>



                                </div>
                            </div></>}


                           
                        {submitted == null && startAssessment && questionLists && questionLists.length > 0 && !complete && <>

                            <div className="row  rounded rounded-4 border pl-8 py-7 pr-7 ml-5  mb-5 w-100 bg-white ">
                                <div className="col-xxxl-12   ">
                                    <h2 className="font-size-4 mb-8 pr-xxl-15 justify-content-md-end text-right d-none">
                                        {timerAlert && <span className="text-red "> {timer}</span>}
                                        {!timerAlert && <span className="text-green-2"> {timer}</span>}
                                    </h2>
                                    <div
                                    >
                                        
                                        <div className="pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15   pr-0 ">
                                            
                                            <h2 className="font-size-7 text-green-2 mb-4 pr-xxl-15">
                                                {questionLists[currentQuestion - 1].category}
                                            </h2>
                                            <div className="row w-100"><div className="col-lg-8"> <h2 className="font-size-5 mb-8 pr-xxl-15 text-left">
                                                {questionLists[currentQuestion - 1].section}
                                            </h2> </div><div className="col-lg-4 text-right font-size-4 mb-8 pr-xxl-10 justify-content-md-end text-right">
                                                    {timerAlert && <span className="text-red justify-content-md-end text-right font-weight-semibold mr-0"> {timer}</span>}
                                                    {!timerAlert && <span className="text-green-2 justify-content-md-end text-right font-weight-semibold mr-0"> {timer}</span>}
                                                </div></div>
                                            <h2 className="font-size-4 mb-8 pr-xxl-15 text-left"> {questionLists[currentQuestion - 1].title}</h2>
                                            <span style={{ wordWrap: "break-word" }} className=" heading-default-color  font-size-4 mb-5 mb-lg-5 font-weight-semibold">
                                                {currentQuestion}.({ questionLists[currentQuestion - 1].id }) {questionLists[currentQuestion - 1].question}
                                            </span>
                                             
                                            <ul className="list-unstyled pl-0">
                                                <li className="font-weight-semibold border-0 d-flex text-gray mb-7 heading-default-color">     <RadioButton handleChange={handleChange} value={'A'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'A'}. {questionLists[currentQuestion - 1].ans_a}</RadioButton> </li>
                                                <li className="font-weight-semibold border-0 d-flex text-gray mb-7 heading-default-color">     <RadioButton handleChange={handleChange} value={'B'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'B'}. {questionLists[currentQuestion - 1].ans_b}</RadioButton> </li>
                                                <li className="font-weight-semibold border-0 d-flex text-gray mb-7 heading-default-color">     <RadioButton handleChange={handleChange} value={'C'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'C'}. {questionLists[currentQuestion - 1].ans_c}</RadioButton> </li>
                                                <li className="font-weight-semibold border-0 d-flex text-gray mb-7 heading-default-color">     <RadioButton handleChange={handleChange} value={'D'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'D'}. {questionLists[currentQuestion - 1].ans_d}</RadioButton> </li>
                                                {questionLists[currentQuestion - 1].ans_e && questionLists[currentQuestion - 1].ans_e != "" && <li className="font-weight-semibold border-0 d-flex text-gray mb-7 heading-default-color">     <RadioButton handleChange={handleChange} value={'E'} field={questionLists[currentQuestion - 1].id} showSelected={true} selectedValue={(selectedAns && (selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id).length > 0)) ? selectedAns.filter(item => item.id == questionLists[currentQuestion - 1].id)[0].ans : ""} multiple={questionLists[currentQuestion - 1].type !== "O"} >{'E'}. {questionLists[currentQuestion - 1].ans_e}</RadioButton> </li>}



                               </ul>
                                            
                                        </div>
                                         

                                    </div>

                                </div>
                                <div className="col-lg-12 text-right media justify-content-md-end">
                                    {currentQuestion !== 1 && <button
                                        type="button"
                                        className="btn btn-green btn-small font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                        onClick={() => {
                                            setCurrentQuestion(currentQuestion - 1);
                                        }}>
                                        {"<< Prev"}
                                    </button>}   <button
                                        type="button"
                                        className="btn btn-green btn-small  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                        onClick={() => {
                                            LoadNextQuestion();
                                        }}>
                                        Next >>
                            </button>
                                </div>

                            </div> </>}

                        {submitted == true &&
                            <>
                                <div className="row  rounded rounded-4 border pl-8 py-7 pr-7 ml-5  mb-5 w-100 bg-white ">

                                <div className="col-xxxl-12 text-black  font-weight-semibold ">
                                    
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( ConfirmMessages.AssmtSuccess.replace("[USERNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "") ) }}></p>
                                </div>
                                <div className="col-lg-12 justify-content-lg-center justify-content-sm-center media px-auto pt-20">
                                    <a

                                        href="/profile" className="btn btn-green btn-small font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                    >
                                       View Profile
                            </a>

                                </div>
                                </div>
                            </>}
                        {submitted == false &&
                            <>
                                <div className="row  rounded rounded-4 border pl-8 py-7 pr-7 ml-5  mb-5 w-100 bg-white ">

                                <div className="col-xxxl-12   text-red  font-weight-semibold ">
                                     
                                   { ConfirmMessages.AssmtnotCompleted}
                                </div>
                                <div className="col-lg-12 justify-content-lg-center justify-content-sm-center   media px-auto pt-20">
                                    <a
                                         
                                        href="/assessment"  className="btn btn-green btn-small font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                         >
                                        Try again
                            </a>
                                   
                                </div>
                                </div>
                            </>}

                        {complete && <>
                            <div className="row  rounded rounded-4 border pl-8 py-7 pr-7 ml-5  mb-5 w-100 bg-white ">

                                <div className="col-xxxl-12  text-black  font-weight-semibold  ">
                                    {ConfirmMessages.AssmtSubmit}

                                </div>
                                <div className="col-lg-12 justify-content-lg-end justify-content-sm-center   media px-auto pt-10">
                                    <button
                                        type="button"
                                        className="btn btn-green btn-small font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                        onClick={() => {
                                            submitAnswer();

                                        }}>
                                        Submit
                            </button>
                                    <button
                                        type="button"
                                        className="btn btn-green btn-small  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase mr-5"

                                        onClick={() => {
                                            window.location.href = "/assessment";
                                        }}>
                                        Cancel
                            </button>
                                </div>
                            </div>
                        </>}


                    </div>
                </div>

            </div>

        </>
    );
};

export default Instructions;
