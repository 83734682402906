import React from "react";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";

const imgError = "../assets/image/svg/404.svg";

export default function ErrorPage(props) {
    return (
        <>
             
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>


                <span className="screen-darken"></span>

                <div className="page-wrapper">

                    <section className="section create-job-block">
                        <div className="container-sm">
                            <div className="text-center">
                                <img src={window.location.origin +"/images/404.svg"} className="m-b-40" alt="404" />

                                <h3 className="m-b-20 text-uppercase">Page not found</h3>

                                <div className="d-flex justify-content-center">
                                    <a type="button" className="btn btn-orange" href="/">Go Back</a>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>


                 
            </PageWrapper>
        </>
    );
};
