const data = {   
        faq: {
            name: "FAQ",             
            link: "https://resource.syncuppro.com/faq/"
    }, 
    contract: {
        name: "Contract Lifecycle",
        link: "https://resource.syncuppro.com/terms#contractlifecycle"
    },
    privacy: {
        name: "Privacy Policy",
        link: "https://resource.syncuppro.com/policy"
    },
    terms: {
        name: "Terms of Service",
        link: "https://resource.syncuppro.com/terms"
    },
    cookie: {
        name: "Cookie Policy",
        link: "https://resource.syncuppro.com/policy#cookie"
    },
    conduct: {
        name: "Code of Conduct",
        link: "https://resource.syncuppro.com/terms#codeofconduct"
    },
    payment: {
        name: "Payment Terms",
        link: "https://resource.syncuppro.com/terms#paymentterms"
    },
}

export default data;