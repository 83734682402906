import React, { useContext, useState, useMutation, useRef ,useEffect} from "react";
import styled from "styled-components"; 
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import validator from 'validator';
import { register } from './../../graphql/mutations';
import { registerConfirm } from './../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify'; 
import Message from "../../components/Message";
import { SiteObjectCounts } from "../../utils/GetAPIUtils";
import ConfirmMessages from "../../utils/confirmMessages";
import { getEncodedEmail } from "../../utils/helperFn"; 
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import ModalUserType from "./ModalUserType";
import Icon from '@mui/material/Icon';
import { useLocation } from 'react-router-dom';
const imgLoader = "../assets/image/svg/spinning-circles.svg";


const ModalStyled = styled(Modal)`
  &.modal {
    z-index: 10050;

  }  
`;


const ModalSignUp = (props) => {
    const gContext = useContext(GlobalContext);
    const [visibleLoader, setVisibleLoader] = useState(false);
    const pathName = useLocation().pathname.toLowerCase();
    const siteKey = process.env.REACT_APP_CAPTCHA_SITEKEY;
    const secretKey = process.env.REACT_APP_CAPTCHA_SECRETCODE;
    const apiURL = process.env.REACT_APP_CAPTCHA_APIURL;
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const captchaRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [showPassFirst, setShowPassFirst] = useState(true);
    const [showPassSecond, setShowPassSecond] = useState(true);
    const [selectedOption, setSelectedOption] = useState(pathName == "/be-an-expert" ? "expert" : gContext.defaultUserType);
    const [terms, setTerms] = useState(false);
    const [values, setvalues] = React.useState({ email: '', password1: '', password2: '', error: { email: '', password1: '', password2: '', terms: '', userType: '' } });
    const [showMessage, setShowMessage] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState(false);
    const [confirmMessage, setConfirmMessage] = React.useState(false);
    const [errorPassword, setErrorPassword] = React.useState({ min8: false, upperCase: false, lowerCase: false, numeric: false, special: false });
    const [captchaMessage, setCaptchaMessage] = React.useState(false);   
    const [videoUrl, setVideoUrl] = React.useState("");
    const [showContent, setShowContent] = React.useState(true);
    const [totalExperts, setTotalExperts] = React.useState('');
    const [totalJobs, setTotalJobs] = React.useState('');
    const [confirmationValues, setConfirmationValues] = React.useState({ code: '', error: '' });
    const [fbResponse, setfbResponse] = React.useState(null);
    

    const responseFacebook = (response) => {
        
        setfbResponse(response);
        setShowModal(!showModal);
        gContext.toggleSignUpModal();
    }

    function toggleModal(data) {
        alert(successMessage);
        alert(confirmMessage);
        setSuccessMessage(false);
        setConfirmMessage(false);
        alert(successMessage);
        alert(confirmMessage);
        setShowModal(!showModal);
        gContext.toggleSignUpModal();
    }
    function handleClose() {

        setErrorMessage("");
        setSelectedOption('');
        setvalues({ email: '', password1: '', password2: '',  error: { email: '', password1: '', password2: '', terms: '', userType: '' } });
        setSuccessMessage(false);
        setConfirmMessage(false);        
        gContext.toggleSignUpModal();

        if (pathName == "/signupcode_expert" || pathName == "/signupcode_client")
            window.location.href = "/";
    }
    function handleOption(value) {        
        setSelectedOption(value); 
        let error = { ...values.error }; 
        error.userType = "";        
        setvalues({ ...values, error });
         
    }
    function checkValidity() {
        let inValid = false;
        let { error, ...rest } = values;
        
        error.email = validator.isEmpty(rest.email) ? "Email is required" : error.email;
        error.password1 = validator.isEmpty(rest.password1) ? "Password is required" : error.password1;
        error.password2 = validator.isEmpty(rest.password2) ? "Confirm Password is required" : error.password2;
        error.terms = (!terms) ? "You must agree to terms and conditions to register an account." : error.terms;
        error.userType = (selectedOption == "") ? "Select user type" : error.userType;
        setvalues({ ...values, error });  

       
        Object.values(rest).forEach(val => {
            if (val == '') {
                inValid = true;
            }  
        }); 
       
        Object.values(error).forEach(val => {
            if (val.length > 0) { 
                
                if (val != "valid")
                    inValid = true;
            } 
        });
        if (inValid) { return false; }
        else { return true;}
    }
    function validatePassword(value) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const lowerChars = /[a-z]/;
        const upperChars = /[A-Z]/;
        const numericChars = /[0-9]/;
        
        setErrorPassword({ min8: (value.length >= 8)  , upperCase: upperChars.test(value)  , lowerCase: lowerChars.test(value)  , numeric: numericChars.test(value), special: specialChars.test(value) });
         
    }
    const handleConfirmationChange = (e) => {
        const { name, value } = e.target;
        setConfirmationValues({ code: value, error: (value == "") ? "Code is required" : (value.match(/[0-9]$/) ? "" : "Invalid format") });
    }

    const handleChange = (e) => {
         
        setErrorMessage("");
        const { name, value } = e.target;        
        let error = { ...values.error };
        setShowMessage(false);
 
        switch (name) {
            case "password1":
                if (value != "") {
                    error.password1 = validator.isStrongPassword(value, {
                        minLength: 8, minLowercase: 1,
                        minUppercase: 1, minNumbers: 1, minSymbols: 1
                    }) ? "valid" : "*";

                    validatePassword(value);
                    error.password2 = (values.password2 != '' && values.password2 != value) ? "Confirm password is not match with password." : "";
                
                }
                else
                
                    error.password1 = "";
                break;
            case "password2":                               

                error.password2 = (value != '' && values.password1 != value) ? "Confirm password is not match with password." : validator.isEmpty(value) ? "Confirm Password is required" : "";
                
                break; 
            case "email":
                error.email = validator.isEmail(value) ? "" : "Invalid email";
                break;
            case "terms":                   
                error.terms = (e.target.checked) ? "" : "You must agree to terms and conditions to register an account.";
                break;
            default:
                break;
        }
        if (name == "terms") {
            
            setTerms(e.target.checked);
            setvalues({ ...values, error});
        }
        else
            setvalues({ ...values, error, [name]: value }); 

    }
     
  const togglePasswordFirst = () => {
    setShowPassFirst(!showPassFirst);
  };

  const togglePasswordSecond = () => {
    setShowPassSecond(!showPassSecond);
    };
  async  function checkConfirmation() {
      if (confirmationValues.code == "")
      {
            setConfirmationValues({ ...confirmationValues, error:"Confirmation code is required" });
            return;
      }
      else{
          if (confirmationValues.error != "")
              return;
      }

      gContext.setLoaderModalVisible(true);
      let userData = localStorage.getItem('userEmail') ? JSON.parse(localStorage.getItem('userEmail')) : null;
      if (userData) {
          let data = registerConfirm.replace("$email", userData.email).replace("$code", confirmationValues.code.trim());
          try {
              const result = await API.graphql(graphqlOperation(data));

              if (result.data && result.data.registerConfirm.responseData) {
                  if (result.data.registerConfirm.responseData.code == "200") {
                      if (userData.type)
                          window.location.href = (userData.type == "expert") ? "/signupsuccess_expert" : "/signupsuccess_client";
                      else
                          window.location.href ="/signupsuccess";
                       
                  }
                  else {
                      setErrorMessage(JSON.parse(result.data.registerConfirm.responseData.message));
                  }
              }
              gContext.setLoaderModalVisible(false);
          }
          catch (e) {
              console.log(e);
              gContext.setLoaderModalVisible(false);
              setErrorMessage("Server error");
          }

      }
     

    }
     

    async function signUpUser(e) {
        e.preventDefault();
      setErrorMessage("");
      setCaptchaMessage(false);
      
      if (checkValidity()) {  
           
             
             gContext.setLoaderModalVisible(true);
             
              signUp(); 
           
        }
    }

    async function signUp()
    { 
       let data= register.replace("$email", values.email).replace("$pwd", values.password1).replace("$type", (selectedOption == "expert") ? '1' : '2');
         try { 
        const result = await API.graphql(graphqlOperation(data));
       
            if (result.data && result.data.register.responseData) {
                 
                if (result.data.register.responseData.code == "200")
                {
                    
                    localStorage.setItem('userEmail', JSON.stringify({ email: values.email, type: selectedOption }));
                    
                    window.location.href = (selectedOption == "expert") ? '/signupcode_expert' : '/signupcode_client';  ;
                }
                else {
                    setErrorMessage(JSON.parse(result.data.register.responseData.message));
                }
            }
        }
         catch (e) {
             console.log(e);
             setErrorMessage("Server error");
            
             gContext.setLoaderModalVisible(false);
        }
      
        gContext.setLoaderModalVisible(false);
    }

    useEffect(() => {
      
        if ((pathName == "/signupcode" || pathName == "/signupcode_expert" || pathName == "/signupcode_client") && localStorage.getItem('userEmail'))
        {
            setSuccessMessage(true);
        }
        if ((pathName == "/signupsuccess" || pathName == "/signupsuccess_expert" || pathName == "/signupsuccess_client") && localStorage.getItem('userEmail')) {
            setSuccessMessage(true);
            setConfirmMessage(true);
            localStorage.removeItem('userEmail');
        }
        
    }, []);
    useEffect(() => {
        if(gContext.defaultUserType !="")
           setSelectedOption(gContext.defaultUserType); 
        gContext.setDefaultUserType("");
    }, [gContext.defaultUserType]);
   
  return (
   <> <ModalStyled
          backdrop="static"
          size="lg" className="modal fade"
          centered
          show={gContext.signUpModalVisible}
          onHide={() => { gContext.toggleSignUpModal(); window.location.href = "/"; }}
      >


            

                     
        <div className="modal-body">
              {showContent && <>  <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { handleClose(); }}></button>

                  <div className="row">

                      <div className="col-lg-6 col-md-6 col-sm-6">
                          <div className="login-info">


                              <h1>   {(successMessage === true) ? "Welcome " : "Create an  account"}</h1>
                              <p className="m-0 f-18"> {(selectedOption == "") ? "Connect, Engage, and Grow. " : (selectedOption == "expert") ? "Get hired and with great project opportunities." : "Hire the best Expert to support your compliance needs."}</p>
                              <div className=" p-b-20 m-b-30  m-t-50   bottom-0 start-0 justify-content-bottom text-left w-full vheight"   >
                                  <ul><li><span className="f-24 "> Watch an intro video on </span></li>
                                      <li className="m-b-5 m-t-10 text-left"><span className="f-18  ">*<a onClick={() => { setVideoUrl(s3URL +"website/ExpertDemo.mp4"); setShowContent(false);  }} className="pointer"><b> Expert registration</b></a></span></li>
                                      <li className="text-left"><span className="f-18  ">* <a onClick={() => { setVideoUrl(s3URL +"website/ClientDemo.mp4"); setShowContent(false);  }} className="pointer"><b>Client registration</b></a></span></li>
                                  </ul>


                              </div>

                          </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-6">

                          <div className="login-info-text">
                              {(confirmMessage === false) && <div className="errortext p-b-10">All * fields are mandatory <br /></div>}

                              {successMessage === false && <>
                                  <form>
                                      {errorMessage !== "" && <div className="alert alert-danger">
                                          {errorMessage}
                                      </div>}
                                      <div className="form-group icon-input">
                                          <label for="email" className="form-label">E-mail<span>*</span></label>
                                          <Icon className="text-green m-icon material-icons ">mail</Icon>

                                          <input
                                              type="email"
                                              name="email"
                                              className={`form-control   ${(values.error.email.length > 0) ? 'error' : ''}`}
                                              placeholder="example@gmail.com"
                                              id="email " required onChange={(e) => (handleChange(e))}
                                          />
                                          {values.error.email.length > 1 && (
                                              <span className="errortext">{values.error.email}</span>
                                          )}

                                      </div>
                                      <div className="form-group icon-input">



                                          <label className="form-label">Password<span>*</span></label>





                                          <Icon className="text-theme m-icon material-icons">lock</Icon>
                                          <Icon className="text-light-gray m-i-icon material-icons" onClick={(e) => {
                                              e.preventDefault();
                                              togglePasswordFirst();
                                          }}> {showPassFirst ? "visibility_off" : "visibility_on"}</Icon>

                                          <input
                                              type={showPassFirst ? "password" : "text"}
                                              className={`form-control    ${((values.error.password1 != "valid" && values.error.password1.length > 0)) ? 'error' : ''}`} name="password1"
                                              id="password1" minLength="12" placeholder="Enter password" required onChange={(e) => (handleChange(e))}
                                          />
                                          {values.error.password1.length > 1 && (
                                              <span className="errortext">{values.error.password1 !== "valid" && values.error.password1}</span>
                                          )}
                                          {(values.error.password1.length === 1) && (<>
                                              <span className={`${(errorPassword.min8) ? 'text-green' : 'errortext'} font-size-3`}> * Password must have minimum 8 characters</span><br />
                                              <span className={`${(errorPassword.upperCase) ? 'text-green' : 'text-error'} font-size-3`}>  * 1 uppercase letter</span> <br />
                                              <span className={`${(errorPassword.lowerCase) ? 'text-green' : 'text-error'} font-size-3`}>  * 1 lowercase letter</span> <br />
                                              <span className={`${(errorPassword.numeric) ? 'text-green' : ' text-error'} font-size-3`}> * 1 numeric value</span><br />
                                              <span className={`${(errorPassword.special) ? 'text-green' : ' text-error'} font-size-3`}> * 1 special character</span>
                                          </>)}
                                      </div>
                                      <div className="form-group m-b-30 icon-input">
                                          <label for="password2" className="form-label">Confirm Password<span>*</span></label>
                                          <Icon className="text-theme m-icon material-icons">lock</Icon>
                                          <Icon className="text-light-gray m-i-icon material-icons" onClick={(e) => {

                                              e.preventDefault();
                                              togglePasswordSecond();
                                          }}> {showPassSecond ? "visibility_off" : "visibility_on"}</Icon>

                                          <input
                                              type={showPassSecond ? "password" : "text"}
                                              className={`form-control   ${(values.error.password2.length > 0) ? 'error' : ''}`}
                                              id="password2" name="password2"
                                              placeholder="Confirm password" required onChange={(e) => (handleChange(e))}
                                          />
                                          {values.error.password2.length > 1 && (
                                              <span className="errortext">{values.error.password2 !== "valid" && values.error.password2}</span>
                                          )}
                                      </div>
                                      <div className="form-group d-flex align-item-center justify-content-between">
                                          <label for="email" className="form-label">Select user type<span>*</span>
                                              <br />  {values.error.userType.length > 1 && (
                                                  <>  <span className="errortext">{values.error.userType}</span></>
                                              )}
                                          </label>

                                          <div className="d-flex">
                                              <button type="button" className={`btn   ${selectedOption == 'client' ? "btn-orange" : "btn-dark-outline"}  shadow-none m-r-10`} onClick={() => handleOption('client')}>Clients</button>
                                              <button type="button" className={`btn ${selectedOption == 'expert' ? " btn-orange" : "btn-dark-outline"} shadow-none`} onClick={() => handleOption('expert')}>Experts</button>
                                          </div>
                                      </div>

                                      <div className="form-check d-flex align-item-center  ">
                                          <input
                                              name="terms"
                                              className="form-check-input m-r-10 "

                                              type="checkbox"
                                              id="terms-check2"
                                              onChange={(e) => { handleChange(e); }}
                                          />

                                          <label className="form-check-label p-t-10 " for="check-select">Agree to the <a href="/terms" target="_blank" className="text-gray pointer" >Terms & Conditions </a></label>




                                      </div>
                                      {values.error.terms.length > 1 && (<><span className="errortext">{values.error.terms}</span></>
                                      )}
                                  </form>

                                  <div className="d-grid m-t-20 w-full">
                                      <button type="button" onClick={signUpUser} id="signup2"  className="btn btn-green btn-block w-150 m-auto">Sign up</button></div>

                                  <div className="or-divider d-none">
                                      <span>Or continue with</span>
                                  </div>
                                  <div className="m-t-20 m-b-20 p-t-20 p-b-20">

                                  </div>
                                  <div className="social-icon d-none">
                                      <ul className="list-type d-flex">
                                          <li>
                                              <a className="btn-circle google" href="#"><img src="../images/google-icon.svg" alt="google-icon" /></a>
                                          </li>
                                          <li>  <FacebookLogin
                                              appId="1204734280171739"
                                              fields="name,email,picture" autoLoad={false}
                                              render={renderProps => (
                                                  <a className="btn-circle facebook" onClick={renderProps.onClick} href="#"><img src="../images/facebook-icon.svg" alt="facebook-icon" /></a>)}
                                              callback={responseFacebook} />

                                          </li>
                                          <li>
                                              <a className="btn-circle linked" href="#"><img src="../images/linked-icon.svg" alt="linked-icon" /></a>
                                          </li>
                                      </ul>
                                  </div>

                                  <span className="text-light-gray d-flex align-item-center justify-content-center m-b-10">Already have an account?</span>
                                  <div class="d-grid w-full ">  <button className="btn btn-dark-outline btn-block w-150 m-auto" href="#" onClick={(e) => {
                                      e.preventDefault();
                                      gContext.toggleSignInModal();
                                      handleClose();
                                  }}>Login</button> </div>  </>}

                              {(successMessage === true && confirmMessage === true) && <>

                                  <Message message={ConfirmMessages.SignupStep2Confirmation} />
                                  <div class="d-grid">  <button className="btn btn-dark-outline btn-block" href="#" onClick={(e) => {
                                      e.preventDefault();
                                      gContext.toggleSignInModal();
                                      handleClose();
                                  }}>Login</button></div>

                              </>}

                              {(successMessage === true && confirmMessage === false) && <>



                                  <p class="m-b-10">{ConfirmMessages.SignupStep1Confirmation.replace("[$email]", getEncodedEmail(values.email))}</p>
                                  <form >
                                      {errorMessage != "" && <><div class="alert alert-danger">
                                          {errorMessage}
                                      </div></>}

                                      <div className="form-group">
                                          <label className="form-label">Confirmation Code<span>*</span></label>
                                          <Icon className="text-green m-icon material-icons "> </Icon>

                                          <input
                                              type="code" maxLength="6"
                                              name="code" required onChange={handleConfirmationChange} 
                                              className={`form-control   ${(confirmationValues.error.length > 0) ? 'error' : ''}`}
                                              placeholder="Confirmation Code"
                                              id="code " onKeyPress={(e) => {
                                                  if (e.charCode === 13 || e.key === "Enter") {
                                                      e.preventDefault();
                                                      checkConfirmation();
                                                  }
                                              }}
                                          />
                                          {confirmationValues.error.length > 1 && (
                                              <span className="errortext ">{confirmationValues.error}</span>
                                          )}

                                      </div>
                                  </form>

                                  <button type="button" className="btn btn-green btn-block"

                                      onClick={(e) => {
                                          e.preventDefault();
                                          checkConfirmation();
                                      }}>
                                      Submit
                                  </button> 

                              </>}

                          </div>

                      </div>




                  </div> </>}

              {!showContent && <>  <button type="button" className="btn-close pointer" data-bs-dismiss="modal" onClick={() => { setShowContent(true); setVideoUrl(""); }}></button>

                  <div className="row">

                      <div  className="col-lg-12 col-md-12 col-sm-12 p-t-30">

                          <video width="100%" height="100%" controls autoplay="true">
                              <source src={videoUrl } type="video/mp4"/>
                          </video>
                          
                      </div> 

                  </div> </>}

        </div>

                                        
   
      <Modal.Body className="p-0  d-none ">
              <button
                  type="button"
                  className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10 z-index-supper"
                  onClick={() => {handleClose();}}
        >
          <i className="fas fa-times"></i>
        </button>
        <div className="login-modal-main bg-white rounded-8 overflow-hidden">
          <div className="row no-gutters">
            <div className="col-lg-3 col-md-6">
              <div className="pt-10 pb-6 pl-11 pr-12 bg-green-2 h-100 d-flex flex-column dark-mode-texts">
                <div className="pb-9">
                                    <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                                      {(successMessage === true) ? "Welcome ": "Create an  account"}
                  </h3>
                  <p className="mb-0 font-size-4 text-white">
                                      {(selectedOption == "") ? " Connect, Engage, and Grow." : (selectedOption == "expert") ? "Get hired and with great project opportunities." : "Hire the best Expert to support your compliance needs."}
                  </p>
                </div>
                <div className="border-top border-default-color-2 mt-auto">
                  <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{totalJobs}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        Jobs posted 
                      </p>
                    </div>
                    <div className="pt-5 px-9">
                      <h3 className="font-size-7 text-white">{totalExperts}</h3>
                      <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                        New experts registered
                      </p>
                    </div>
                  </div>
                </div>
              </div>
                      </div>
                      <div className="col-lg-9 col-md-6 pt-5 pl-10">
                          {(successMessage === true && confirmMessage ===true) && <>  <div className="row mt-10 mr-10">
                             
                              <Message message={ConfirmMessages.SignupStep2Confirmation} />
                                  <div className=" col-12 form-group mb-8 mt-10 text-center">
                                  <button id="signup02" type="button" className="btn btn-primary btn-medium w-50 rounded-5 text-uppercase" onClick={(e) => {
                                              e.preventDefault();
                                              gContext.toggleSignInModal();
                                              handleClose();
                                          }}>
                                      <i className="fa fa-sign-in-alt mr-2 pt-1" > </i>   Login 
                                          </button>
                                      </div>
                                    
                                 
                                 
                          </div>
                          </>}


                          {(successMessage === true && confirmMessage === false) && <>  <div className="row mt-10 mr-10">

                              <Message message={ConfirmMessages.SignupStep1Confirmation.replace("[$email]", getEncodedEmail(values.email))} />

                              <div className=" col-12 form-group mb-8 mt-10 ">
                                  <form >
                                      {errorMessage != "" && <><span className="error py-2   font-weight-semibold line-height-reset px-2 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span><br /></>}
                                      <div className="form-group">

                                          <label
                                              htmlFor="code"
                                              className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                          >
                                              Confirmation Code <span className="error font-size-3">*</span>
                                          </label>
                                          <input
                                              type="code" name="code"
                                              className={`form-control    ${(confirmationValues.error.length > 0) ? 'form-control-error' : ''}`}
                                              placeholder="Confirmation Code" onKeyPress={(e) => {
                                                  if (e.charCode === 13 || e.key === "Enter") {
                                                      e.preventDefault();
                                                      checkConfirmation();
                                                  }
                                              }}
                                              id="code" required onChange={handleConfirmationChange}
                                          />
                                          {confirmationValues.error.length > 1 && (
                                              <span className="text-red">{confirmationValues.error}</span>
                                          )}
                                      </div></form>

                                  <button type="button" className="btn btn-primary btn-medium  rounded-5 text-uppercase" 
                                 style={{ minWidth: '90px', height: '40px' }}
                                  onClick={(e) => {
                                      e.preventDefault();
                                      checkConfirmation();
                                  }}>
                                      <i className="fa fa-sign-in-alt mr-2 " > </i>   Submit
                                          </button>
                              </div>



                          </div>
                          </>}
                            <div className="row  ">

 {successMessage === false && <>  
                            
                              

                              <div className="col-lg-12 col-md-12 ">
                                  <div className="bg-white-2 h-100 px-5 px-lg-7 pt-7 pb-7 ">
                                          <div className="error font-size-4 pt-2 pb-3">All * fields are mandatory <br /></div>

                                      <form >
                                          {errorMessage !== "" && <span className="error py-2   font-weight-semibold line-height-reset px-2 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span>}

                                              <div className="form-group icon-input">

                                              <label
                                                  htmlFor="email "
                                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                              >
                                                  E-mail <span className="error font-size-3">*</span>
                                              </label>
                                              <input
                                                  type="email"
                                                  name="email"
                                                  className={`form-control   ${(values.error.email.length > 0) ? 'form-control-error' : ''}`}
                                                  placeholder="example@gmail.com"
                                                  id="email " required onChange={(e) => (handleChange(e))}
                                              />
                                              {values.error.email.length > 1 && (
                                                  <span className="errortext font-size-3">{values.error.email}</span>
                                              )}
                                          </div>
                                          <div className="form-group">
                                              <label
                                                  htmlFor="password1"
                                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                              >
                                                  Password <span className="error font-size-3">*</span>
                                              </label>
                                              <div className="position-relative">
                                                  <input
                                                      type={showPassFirst ? "password" : "text"}
                                                      className={`form-control font-weight-normal  ${((values.error.password1 != "valid" && values.error.password1.length > 0)) ? 'form-control-error' : ''}`} name="password1"
                                                      id="password1" minLength="12" placeholder="Enter password" required onChange={(e) => (handleChange(e))}
                                                  />
                                                  <a
                                                      href="/#"
                                                      className="pos-abs-cr fas mr-6 text-black-2"
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          togglePasswordFirst();
                                                      }}
                                                  >
                                                      {showPassFirst ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash " ></i>}

                                                  </a>
                                              </div>
                                              {values.error.password1.length > 1 && (
                                                  <span className="error font-size-3">{values.error.password1 !== "valid" && values.error.password1}</span>
                                              )}
                                              {(values.error.password1.length === 1) && (<>
                                                  <span className={`${(errorPassword.min8) ? 'text-green' : 'error'} font-size-3`}> * Password must have minimum 8 characters</span>
                                                  <span className={`${(errorPassword.upperCase) ? 'text-green' : 'error'} font-size-3`}>  * 1 uppercase letter</span> <br />
                                                  <span className={`${(errorPassword.lowerCase) ? 'text-green' : 'error'} font-size-3`}>  * 1 lowercase letter</span> <br />
                                                  <span className={`${(errorPassword.numeric) ? 'text-green' : 'error'} font-size-3`}> * 1 numeric value</span><br />
                                                  <span className={`${(errorPassword.special) ? 'text-green' : 'error'} font-size-3`}> * 1 special character</span>
                                              </>)}
                                          </div>
                                              <div className="form-group icon-input">
                                              <label
                                                  htmlFor="password2"
                                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                              >
                                                  Confirm Password <span className="error font-size-3">*</span>
                                              </label>
                                              <div className="position-relative">
                                                  <input
                                                      type={showPassSecond ? "password" : "text"}
                                                      className={`form-control font-weight-normal  ${(values.error.password2.length > 0) ? 'form-control-error' : ''}`}
                                                      id="password2" name="password2"
                                                      placeholder="Confirm password" required onChange={(e) => (handleChange(e))}
                                                  />
                                                  <a
                                                      href="/#"
                                                      className=" pos-abs-cr fas mr-6 text-black-2"
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          togglePasswordSecond();
                                                      }}
                                                  >  {showPassSecond ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash " ></i>}
                                                      <span className="d-none">none</span>
                                                  </a>
                                              </div>
                                              {values.error.password2.length > 1 && (
                                                  <span className="errortext  font-size-3">{values.error.password2}</span>
                                              )}
                                              </div>
                                              <div className="form-group">
                                                  <label
                                                      htmlFor="password2"
                                                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                                  >
                                                      Select User Type <span className="error font-size-3">*</span>
                                                  </label>
                                                    test
                                                  <div className="media mb-2   row  ">
                                                      {/* <!-- media icon start --> */}
                                                    
                                                      <div className="media-icon text-center  col-6  col-lg-6" onClick={() => handleOption('expert')} style={{ cursor: 'pointer', }}>

                                                          <div className="row font-size-4  font-weight-semibold">
                                                              <div className="col-2 py-1 "> 
                                                              <input
                                                                  name="expert"
                                                                  className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px' }}
                                                                  type="checkbox"
                                                                  id="expert-check" onChange={() => handleOption('expert')} checked={selectedOption == 'expert'}
                                                              />
                                                            
                                                              <div className="border-1-green text-green font-size-3  d-none" style={{ width: '18px', height: '18px' }}>
                                                                {selectedOption === 'expert' && <> <i className="fa fa-check text-right pos-abs-cr mr-0"  ></i> </>} 
                                                                </div>
                                                            </div>
                                                              <div className="col-9 text-left ">  <i className="fa fa-user text-yellow font-size-4"></i> {' '} Expert</div>

                                                          </div>
                                                      </div>
                                                      <div className="media-icon col-6 text-center  col-lg-6" onClick={() => handleOption('client')} style={{ cursor: 'pointer', }}>
                                                          <div className="row font-size-4 font-weight-semibold">
                                                              <div className="col-2 py-1  ">
                                                                  <input
                                                                      name="client"
                                                                      className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px' }}
                                                                      type="checkbox"
                                                                      id="client-check" onChange={() => handleOption('client')} checked={selectedOption == 'client'}
                                                                  />
                                                                  <div className="d-none border-1-green text-green font-size-3   " style={{ width: '18px', height: '18px' }}>{selectedOption === 'client' && <i className="fa fa-check  pos-abs-cr"  ></i>} </div></div>
                                                              <div className="col-9  text-left  ">  <i className="fa fa-user-tie text-yellow font-size-4"></i> {' '} Client</div>
                                                          </div> </div>  

                                                  </div>
                                                  {values.error.userType.length > 1 && (
                                                      <span className="errortext   font-size-3">{values.error.userType}</span>
                                                  )}
                                              </div>


                                          <div className="form-group d-flex flex-wrap justify-content-between mb-1 pt-10">
                                              <label
                                                  htmlFor="terms-check2"
                                                  className="gr-check-input d-flex  mr-3 "
                                              >
                                                  <input
                                                      name="terms"
                                                      className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px', color: 'green' }}
                                                      type="checkbox"
                                                      id="terms-check2" onChange={(e) => (handleChange(e))}
                                                  />
                                                  <span className=" d-none checkbox px-5 mr-5 bg-white">  </span>

                                                  <span className=" d-none border-1-green text-green font-size-3 mr-5 px-0 py-0" style={{ width: '18px', height: '18px' }}  >{terms === true && <i className="fa fa-check ml-1 pb-10  mt-0" ></i>} </span>
                                                  <span className="font-size-4 mb-0 line-height-reset d-block">
                                                      Agree to the{" "}
                                                      <a href="/terms" target="_blank" className="text-primary">
                                                          Terms &amp; Conditions </a></span> </label>
                                              {values.error.terms.length > 1 && (<span className="error font-size-3 line-height-reset">{values.error.terms}</span>
                                              )}
                                          </div>
                                            
                                              <div className="col-lg-12  justify-content-md-end">
                                                        <div className="media py-10 w-100  justify-content-md-end ml-5"  >
                                                            <div className="p-0 m-0 position-relative">
                                                          <button type="button" id="signup2"
                                                    className="btn-green font-weight-bold  font-size-3 border-0 rounded-5 text-uppercase" 
                                                    style={{ width: '110px', height: '40px'}}
                                                    onClick={signUpUser}>
                                                      Sign Up{" "}   
                                                  </button>
                                                  </div></div>
                                                 
                                          </div>
                                          <p className="font-size-4 text-center heading-default-color">
                                              Already have an account?{" "}
                                              <a href="/#" className="text-primary"
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      gContext.toggleSignInModal();
                                                      handleClose();
                                                  }}>
                                                  Log in
                    </a>
                                              </p>

                                              <div className="row ">
                                                  <div className="col-10 col-xs-6 ml-7  ml-lg-5 pb-7   ">
                                                      <a
                                                          href="/#"
                                                          className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "                                             >
                                                          <i className="fab fa-linkedin pos-abs-cl font-size-6 ml-xs-4 px-5"></i>{" "}
                                                          <span>
                                                              Import with  LinkedIn
                                                  </span>
                                                      </a>
                                                  </div>
                                                  <div className="col-10 col-xs-6  ml-7  ml-lg-5 pb-7 ">
                                                      <a
                                                          href="/#"
                                                          className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "
                                                      >
                                                          <i className="fab fa-google pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                                          <span >
                                                              Import with Google
                      </span>
                                                      </a>
                                                  </div>
                                                  <div className="col-10 col-xs-6  ml-7  ml-lg-5  pb-7">

                                                      <FacebookLogin
                                                          appId="1204734280171739"
                                                          fields="name,email,picture" autoLoad={false}
                                                          render={renderProps => (
                                                              <a
                                                                  onClick={renderProps.onClick}
                                                                  className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4 "
                                                              >
                                                                  <i className="fab fa-facebook-square pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                                                  <span >
                                                                      Import with Facebook
                      </span>
                                                              </a>)}
                                                          callback={responseFacebook} />
                                                  </div>
                                              </div>
                                      </form>

                                  </div>

                            
                              
                                      <div className="bg-white-2 h-100 pr-11 pt-11 pb-7 ">
                                          <div className="bg-white-2 my-auto d-none d-xs-block  "></div>
                                          <div className="row ">
                                              <div className="col-12 col-xs-12 ml-7  ml-lg-5 pb-7   ">
                                              <a
                                                  href="/#"
                                                  className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "                                             >
                                                  <i className="fab fa-linkedin pos-abs-cl font-size-6 ml-xs-4 px-5"></i>{" "}
                                                  <span>
                                                      Import with  LinkedIn
                                                  </span>
                                              </a>
                                          </div>
                                          <div className="col-12 col-xs-12  ml-7  ml-lg-5 pb-7 ">
                                              <a
                                                  href="/#"
                                                  className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "
                                              >
                                                  <i className="fab fa-google pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                                  <span >
                                                      Import with Google
                      </span>
                                              </a>
                                          </div>
                                          <div className="col-12 col-xs-12  ml-7  ml-lg-5  pb-7">
                                              
                                                  <FacebookLogin
                                                      appId="1204734280171739"                                                      
                                                      fields="name,email,picture" autoLoad={false}
                                                      render={renderProps => ( 
                                                          <a
                                                              onClick={renderProps.onClick}
                                                              className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4 "
                                                          >
                                                              <i className="fab fa-facebook-square pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                                              <span >
                                                                  Import with Facebook
                      </span>
                                                          </a> )}
                                                      callback={responseFacebook} />
                                          </div>
                                      </div>
                                  </div>

                              </div>
                              </>}
                          </div>
                      </div>
        
          </div>
        </div>
      </Modal.Body>
      </ModalStyled>
          <ModalUserType modalvisible={showModal} togglemodal={toggleModal} fbResponse={fbResponse} /></>
  );
};

export default ModalSignUp;
