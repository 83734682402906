import React, { useContext, useState, useEffect } from "react";
import { ClientJobsRead, ExpertSaveJob } from "../../utils/apiCalls";
import { sinceDate } from "../../utils/addDays";
import GlobalContext from "../../context/GlobalContext";
import DOMPurify from 'isomorphic-dompurify';
import ModalJobProposal from "../../components/JobProposal/JobProposal";
import ReactCountryFlag from "react-country-flag";
import ClipLoader from "react-spinners/ClipLoader";
import Icon from '@mui/material/Icon';
  

export default function SearchGrid(props) {
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL; 
    const [id, setID] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [appliedIds, setappliedIds] = useState([]);
    const [savedJobIds, setsavedJobIds] = useState([]);

    const [proposalModalVisible, setProposalModalVisible] = useState(false);
    const user = localStorage.getItem('connect')
    function OpenApply(e, clientid, guid) {
        if (!user || gContext.userInfo.userID && gContext.userInfo.userID !== clientid && gContext.userInfo.userType == '2') {
            e.preventDefault(); gContext.toggleSignInModal();
        }
        
        else if (gContext.userInfo.userType == '1' && gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment && gContext.userInfo.profileInfo[0].exptcerts && gContext.userInfo.profileInfo[0].exptcerts.length > 0) {

            setID(guid);
            toggleProposalModal();
        }
        else {
            setID(null);
            toggleProposalModal();
        }
    }
    const toggleProposalModal = () => {
        setProposalModalVisible(!proposalModalVisible);
    };


    const SaveJob = async (e, isSave, id) => {
        if (!user ) {
            e.preventDefault(); gContext.toggleSignInModal();
            return;
        }
        gContext.setLoaderModalVisible(true);

        if (gContext.userInfo.userType == '1' && gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) {
            try {

                const result = await ExpertSaveJob(gContext.userInfo.userID, isSave, id);


                if (result.data.saveJob.responseData.code == "200") {
                    gContext.getUpdatedInfo(gContext.userInfo.userType, gContext.userInfo.email, gContext.userInfo.sub).then((value) => setsavedJobIds(value));
                    
                }

            }
            catch (e) {
                console.log(e); 
            }

        }
        else {

            toggleProposalModal(); 
        }
        gContext.setLoaderModalVisible(false);

    }
    const [jobs, setJobs] = useState([{}]);

    
    useEffect(() => {
        if (gContext.userInfo.userType == '1') {
            setappliedIds(gContext.userInfo.appliedJobIds);
            setsavedJobIds(gContext.userInfo.savedJobIds);
        }

        
    }, [gContext.userInfo.savedJobIds]);
    useEffect(() => {
        setJobs(props.jobsList);
        
    }, [props.jobsList]);
    useEffect(() => {
        if (gContext.userInfo.userType == '1' && gContext.userInfo.appliedJobIds && gContext.userInfo.appliedJobIds.length > 0)
            setappliedIds(gContext.userInfo.appliedJobIds);

    }, [gContext.userInfo]);
    return (
        <>

         
                <div className="grid-wrapper-block">

                <div className="row w-full">
                        {(jobs) && jobs.map((job, index) => {

                            return (
                                <div class="col-lg-6 col-md-6 col-sm-12 m-b-20 ">

                            <div className="card list-block grid-block">
                                <div className="expert-info-details">
                                    <div className="expert-data">
                                        <div className="expert-profile m-0">
                                                    <div className="img-verified-block">
                                                        <strong>   <h1 style={{ wordBreak: "break-all" }} className="expert-name m-0 pointer" onClick={() => { window.location.href = "job/" + job.guid; }}> {(job.title) ? job.title : ''} </h1> </strong>
                          </div>

                                                <div className="d-flex align-item-center">

                                                    <div className="medie-info-img">
                                                            {(job.logo) && <img src={s3URL + job.logo} onClick={() => { window.location.href = "client/" + job.sub; }} className="pointer" />}
                            </div>

                                                        <span className="expert-desg pointer" onClick={() => { window.location.href = "client/" + job.sub; }}><strong>{(job.company) ? job.company : ""}</strong></span>

                                                        <div className="info-meta flag">
                                                            <Icon className="text-theme m-icon material-icons m-r-10">location_on</Icon><span className="one-line text-black m-r-10"> 
                                                            <span className={`text-black ${(job.city != null && job.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{job.city != null?job.city.toLowerCase():''}</span>



                                                            , {job.countrycode}</span>
                                                            <ReactCountryFlag
                                                                countryCode={job.countrycode}
                                                                svg

                                                            />
                                                           
                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                 
                                            {(job.ratefrom && job.rateto) ? <>
                                                <div className="work-pay  ">
                                                      ${new Intl.NumberFormat('en-US').format(parseInt(job.ratefrom))} - {new Intl.NumberFormat('en-US').format(parseInt(job.rateto))} <span className="text-sm text-capi">{job.ratetype ? job.ratetype.toLowerCase() : ""}</span>
                                                </div>
                                            </> : <><div className="work-pay  ">
                                                {(job.dailyrate) ? <>
                                                       ${new Intl.NumberFormat('en-US').format(parseInt(job.dailyrate))} <span className="text-sm ">Daily</span>
                                                </> : ''}


                                                {(job.fixedrate) ? <>
                                                      ${new Intl.NumberFormat('en-US').format(parseInt(job.fixedrate))} <span className="text-sm  ">Fixed</span>
                                                </> : ''}

                                                {(job.hourlyrate) ? <>
                                                       ${new Intl.NumberFormat('en-US').format(parseInt(job.hourlyrate))} <span className="text-sm">Hourly</span>
                                                </> : ''}</div>
                                                </>}


                                            </div> 

                    <div className="month-data text-light-gray">
                                            <span>Posted on <strong><span className="text-black">{(job.dateposted) && sinceDate(job.dateposted, new Date())}</span></strong></span>
                                            </div>

                                        <div className="type-name">
                                            <div className="info-meta">
                                                <Icon className="text-theme m-icon material-icons m-r-10">home</Icon><span className="text-capi "> {(job.type) ? job.type.toLowerCase() : ''}</span>
                                            </div>

                                            <div className="info-meta">
                                                <Icon className="text-theme m-icon material-icons m-r-10">work</Icon><span>{(job.explevel) ? job.explevel : ''}</span>
                                            </div>

                                            <div className="info-meta">
                                                <Icon className="text-theme m-icon material-icons m-r-10">calendar_month</Icon><span>{(job.projectlength) ? job.projectlength : ''}</span>
                                            </div>
                                        </div> 
                                           
                                        <p className="text-gray three-line m-b-20 pointer" onClick={() => { window.location.href = "job/" + job.guid; }}>
                                            {(job.jobdesc) ? job.jobdesc.replace(/(<([^>]+)>)/ig, '').replaceAll("&nbsp", "") : ""}
                                        </p>
                     

                                            <div className="expert-action">

                                            <a href={"job/" + job.guid} target="_self"  className="btn btn-link">View More Details<Icon className="m-icon material-icons m-l-10">arrow_forward</Icon></a>

                                                <div className="action-btns">
                                                   
                                                {gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && !gContext.userInfo.profileInfo[0].flagged && <>    {(!appliedIds || appliedIds.filter(item => item["jobid"] == job.id).length === 0) &&

                                                    <> {gContext.userInfo.userType != '2' && <>
                                                        <button type="button" className="btn btn-orange "
                                                            onClick={(e) => { OpenApply(e, job.clientid, job.guid); }}>
                                                            Apply
                                                        </button></>}</>}
                                                    {appliedIds && appliedIds.filter(item => item["jobid"] == job.id).length > 0 &&
                                                        <button className="btn btn-orange applied-btn" disabled="true" >
                                                            <Icon className="material-icons">done</Icon> Applied
                                                        </button>}</>}

                                                {gContext.userInfo.userType != '2' && <>
                                                    {savedJobIds && (savedJobIds.filter(item => item["jobid"] == job.id).length > 0) ?
                                                        <button className="btn btn-dark-outline text-black d-flex align-item-center" onClick={(e) => { SaveJob(e, false, job.id); }} >
                                                            <Icon className="m-icon material-icons m-r-5">bookmark</Icon>
                                                        Unsave  &nbsp; {showLoader && (id == job.id) && <ClipLoader color="#ffff" size="20px" />}
                                                        </button> : <button className="btn btn-dark-outline text-black d-flex align-item-center"
                                                            onClick={(e) => { SaveJob(e, true, job.id); }}>
                                                            <Icon className="m-icon material-icons m-r-5">bookmark_border</Icon>
                                                        Save &nbsp; {showLoader && (id == job.id) && <ClipLoader color="#569d11" size="20px" />}
                                                        </button>} </>}


                                                </div>

                                            </div>

                                        </div> 

                </div>

                            )
                        })}

                                    

                                </div>

                            </div>

                         




            <div className="pt-6 d-none">
            <div className="row justify-content-left">
                {(jobs) && jobs.map((job, index) => {

                    return (

                        <div className="col-sm-12 col-lg-6  " key={index}>
                            {/* <!-- Start Feature One --> */}
                            <div className="bg-white px-8 pt-3 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">

                                <div className=" align-items-center row">
                                    <div className="col-3 ">  <div className="square-72 d-block   mb-5">
                                        <a href={"client/" + job.sub} target="blank">
                                            {(job.logo) && <img src={s3URL + job.logo} className="w-100 rounded " alt="" />}
                                        </a>
                                    </div></div>
                                    <div className="col-9 ">
                                        <h3 className="mt-0 mb-0">

                                            <a className="font-size-6 heading-default-color one-line" href={"job/" + job.guid} rel="noopener noreferrer" target="blank">
                                                {(job.title) ? job.title : ''}
                                            </a>

                                        </h3>

                                        <span className="font-size-3 text-default-color line-height-2">
                                            {(job.company) ? <>{job.company}  <i className="icon icon-pin-3 ml-2 mr-1 font-weight-bold"></i>{" "}
                                            <span className={`text-black ${(job.city != null && job.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{job.city != null?job.city.toLowerCase():''}</span>
                                                
                                                
                                                , {job.countrycode} </> : ''}
                                        </span>
                                        <ul className="list-unstyled mb-1 card-tag-list  ">
                                            <li>

                                                <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-3  px-2">
                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                    {(job.type) ? job.type : ''}
                                                </a>

                                            </li>
                                            <li>

                                                <a className="bg-regent-opacity-15 text-green-2 font-size-3 rounded-3  px-2">
                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                    {(job.explevel) ? job.explevel : ''}
                                                </a>

                                            </li>
                                            

                                        </ul>
                                    </div>
                                    <div > 
                                     <ul className="list-unstyled mb-1 card-tag-list  ">

                                        <li>

                                            <a className="bg-regent-opacity-15 text-blue font-size-3 rounded-3  px-2">
                                                <i className="fa fa-ruler-horizontal mr-2 font-weight-bold"></i>{" "}
                                                {(job.projectlength) ? job.projectlength : ''}
                                            </a>

                                        </li>
                                        <li >

                                                <a className="bg-regent-opacity-15 text-gray font-size-3 rounded-3  px-2">
                                                    <i className="fa fa-clock mr-2 font-weight-bold"></i>{" "}
                                                    {(job.dateposted) && sinceDate(job.dateposted, new Date())}
                                                </a>

                                            </li>
                                        </ul></div>
                                    <div className="d-none col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
                                        <ul className="list-unstyled mb-1 card-tag-list  ">
                                            <li >

                                                <a className="bg-regent-opacity-15 text-gray font-size-3 rounded-3  px-2">
                                                    <i className="fa fa-clock mr-2 font-weight-bold"></i>{" "}
                                                    {(job.dateposted) && sinceDate(job.dateposted, new Date())}
                                                </a>

                                            </li></ul></div>

                                    <div className="text-right pt-0 pb-9 pl-7 pt-md-5">
                                        <div className="media justify-content-md-end">

                                            {(job.ratefrom && job.rateto) ? <>
                                                <p className="font-weight-bold font-size-6 text-hit-gray mb-0">
                                                    <i className="fa-solid fa-dollar font-size-6 text-green-2"></i><span className="text-black-2"> {new Intl.NumberFormat('en-US').format(parseInt(job.ratefrom))}</span>&nbsp;<a className="font-weight-normal font-size-3 text-hit-gray mb-0"> </a>
                                                </p> -  <p className="font-weight-bold font-size-6 text-hit-gray mb-0">
                                                    <i className="fa-solid fa-dollar font-size-6 text-green-2"></i><span className="text-black-2"> {new Intl.NumberFormat('en-US').format(parseInt(job.rateto))}</span>&nbsp;<a className="font-weight-normal font-size-3 text-hit-gray mb-0"> </a><a className="font-weight-normal font-size-3 text-hit-gray mb-0"> {job.ratetype ? job.ratetype : ""}</a>
                                                </p>
                                            </> : <>
                                                <div className="image   ">
                                                    {(job.dailyrate) ? <>
                                                        <p className="font-weight-bold font-size-6 text-hit-gray mb-0">
                                                            <i className="fa-solid fa-dollar font-size-6 text-green-2"></i><span className="text-black-2"> {new Intl.NumberFormat('en-US').format(parseInt(job.dailyrate))}</span>&nbsp;<a className="font-weight-normal font-size-3 text-hit-gray mb-0">Daily</a>
                                                        </p></> : ''}
                                                </div>&nbsp;
                                            <div className="image   ">
                                                    {(job.fixedrate) ? <>
                                                        <p className="font-weight-bold font-size-6 text-hit-gray mb-0">
                                                            <i className="fa-solid fa-dollar font-size-6 text-green-2"></i><span className="text-black-2"> {new Intl.NumberFormat('en-US').format(parseInt(job.fixedrate))}</span>&nbsp;<a className="font-weight-normal font-size-3 text-hit-gray mb-0">Fixed</a>
                                                        </p></> : ''}
                                                </div>&nbsp;
                                            <div className="image   ">
                                                    {(job.hourlyrate) ? <>
                                                        <p className="font-weight-bold font-size-6 text-hit-gray mb-0">
                                                            <i className="fa-solid fa-dollar font-size-6 text-green-2"></i><span className="text-black-2"> {new Intl.NumberFormat('en-US').format(parseInt(job.hourlyrate))}</span>&nbsp;<a className="font-weight-normal font-size-3 text-hit-gray mb-0">Hourly</a>
                                                        </p></> : ''}
                                                </div>
                                            </>}


                                        </div>
                                    </div>
                                    
                                    <div className="d-none col-xxl-6 col-xl-6 col-lg-6 col-md-6 ">
                                        <ul className="list-unstyled mb-1 card-tag-list  ">
                                            <li>

                                                <a className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3">
                                                    <i className="fa fa-dollar-sign  font-weight-bold"></i>{" "}
                                                    {(job.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(job.dailyrate)) : ((job.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(job.fixedrate)) : (job.ratefrom && job.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(job.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(job.rateto)) : '')}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                
                                <a href={"job/" + job.guid} rel="noopener noreferrer" target="blank" className=" w-100">
                                    <div className=" font-size-4 text-gray mb-3 w-100" style={{ textOverflow: 'ellipsis', height: '73px', overflow: 'hidden', wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.jobdesc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                    </div><br/> {(DOMPurify.sanitize(job.jobdesc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 150) &&
                                        <div className="mt-n7    text-right w-100"><i className="fa-solid fa-ellipsis mr-6"></i> </div>}</a>
                                 

                                <div className=" col-lg-12  justify-content-md-end ">
                                    <div className="media py-7 w-100  justify-content-md-end ml-9"  >
                                        <div className="p-0 m-0 position-relative">
                                            {(!appliedIds || appliedIds.filter(item => item["jobid"] == job.id).length === 0) &&

                                                <> {gContext.userInfo.userType != '2' && <>
                                                    <a className="btn btn-green text-uppercase btn-medium rounded-3 " style={{ minWidth: '120px', height: '40px' }}
                                                        onClick={(e) => { OpenApply(e, job.clientid, job.guid); }}>
                                                        Apply Now
                                                    </a></>}</>}
                                            {appliedIds && appliedIds.filter(item => item["jobid"] == job.id).length > 0 &&
                                                <button className="btn btn-gray text-uppercase btn-medium rounded-3" style={{ minWidth: '100px', height: '40px' }} disabled="true" >
                                                    Applied
                                                </button>}
                                            {gContext.userInfo.userType != '2' && <>
                                                {savedJobIds && (savedJobIds.filter(item => item["jobid"] == job.id).length > 0) ?
                                                    <button className="btn btn-green  text-uppercase btn-medium rounded-3 ml-7" style={{ minWidth: '110px', height: '40px' }} onClick={(e) => { SaveJob(e, false, job.id); }} >
                                                        <i className="icon icon-bookmark-2 font-weight-bold mr-4 font-size-4"></i>{" "}
                                                        Unsave
                                                    </button> : <a className="btn btn-outline-mercury    text-green  text-uppercase border-green border-1  btn-medium  font-size-3 rounded-3 ml-7" style={{ minWidth: '110px', height: '40px' }}
                                                        onClick={(e) => { SaveJob(e, true, job.id); }}>
                                                        <i className="icon icon-bookmark-2 text-green font-weight-bold mr-4 font-size-4"></i>{" "}
                                                        Save
                                                    </a>}</>}</div>
                                    </div>
                                </div>


                            </div>



                            {/* <!-- End Feature One --> */}
                        </div>
                    )
                })}
            </div>
        </div>
            {proposalModalVisible && <ModalJobProposal id={id} proposalModalVisible={proposalModalVisible} toggleProposalModal={toggleProposalModal} />}

        </>
    );
};
