import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { AccountDelete } from "../../utils/apiCalls";
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmMessages from "../../utils/confirmMessages";
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalConfirm = (props) => {

    const gContext = useContext(GlobalContext);
    const [disabled, SetDisabled] = useState(true);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const navigate = useNavigate();
    //var deletetext = '' ;
   
    const handleClose = () => {
        SetDisabled(true);
        gContext.setconfirmModal({ text: '', ["confirmed"]: false });
        gContext.toggleConfirmModal();
    };
    async function confirmMessage() {
        let deletetext;


        if (gContext.confirmModal.deleteaccount != null && gContext.confirmModal.deleteaccount == true) 
        {
            try {
                if (gContext.userInfo.userID) {
                    const result = await AccountDelete(gContext.userInfo.userID, gContext.userInfo.userType);
     
                    if (result.data.accountDelete.responseData.code == "200") {
                        gContext.setLoaderModalVisible(true);
    
                         deletetext = ["Your request to delete your account has been submitted successfully. ",
                        <br />, <br />,
                        "It may take 7 to 10 business days to complete your request."];

                        setDeleteStatus("true");




                        
    
                    }
                    if (result.data.accountDelete.responseData.code == "400") {
                        gContext.setLoaderModalVisible(true);
    
                       // setDeleteText( "Your request to delete your account cannot be submitted since you have an active contract. ");
                          deletetext = ["Your request to delete your account cannot be submitted since you have an active contract. "];
                          
                          setDeleteStatus("false");
                       
                         
    
                    }
    
                    gContext.setLoaderModalVisible(false);
                   
                }
    
            }
            catch (e) {
                console.log(e); gContext.setLoaderModalVisible(false);
            }
           
            //accountDelete() ;
            
             

            gContext.setconfirmModal({ header: "Delete confirmation", ["text"]: deletetext, ["confirmed"]: false, showButton: true, ["confirmedflag"]: true });


        }
        if (gContext.confirmModal.deleteaccount == null) {

            SetDisabled(true);
            gContext.setconfirmModal({ ...gContext.confirmModal, ["confirmed"]: true });
            gContext.toggleConfirmModal(); setDeleteConfirm(false);
        }
    }

    function confirmDelete() { //gContext.toggleConfirmModal();
        
        if (deleteStatus == "true")
        {
        localStorage.removeItem('jobSearch');
        localStorage.removeItem('countryCode');
        localStorage.removeItem('jobhistory');
        localStorage.removeItem('experthistory');
        gContext.logout();
        }

        else if (deleteStatus == "false")
    
        {  
            handleClose();
            /*gContext.setCurrentPage('5');
            gContext.setVisibleProfileMessage(false); 
            navigate("/profile", { replace: true }); */
        }



    }


    async function accountDelete() {
        gContext.setLoaderModalVisible(false);
        try {
            if (gContext.userInfo.userID) {
                const result = await AccountDelete(gContext.userInfo.userID, gContext.userInfo.userType);

               
                if (result.data.accountDelete.responseData.code == "200") {
                    gContext.setLoaderModalVisible(true);

                   

                }
                if (result.data.accountDelete.responseData.code == "400") {
                    gContext.setLoaderModalVisible(true);

                     

                }

                gContext.setLoaderModalVisible(false);
               
            }

        }
        catch (e) {
            console.log(e); gContext.setLoaderModalVisible(false);
        }
    }


    const handleChange = (e) => {

        if (e.target.value.toLowerCase() == "delete") { SetDisabled(false); }
        else { SetDisabled(true); setDeleteConfirm(false); }
    }


    useEffect(() => {
        // SetDisabled(true);

        if (gContext.confirmModal.mode && gContext.confirmModal.mode != "delete") {

            SetDisabled(false); setDeleteConfirm(false);
        }
        else {  SetDisabled(true); setDeleteConfirm(false); }

        if (gContext.confirmModal.header == 'Delete confirmation') { setDeleteConfirm(true); }
        else { setDeleteConfirm(false); }
         

    }, [gContext.confirmModal]);
    return (
        <ModalStyled
            size="sm"
            className="modal"
            backdrop="static"
            centered
            show={gContext.confirmModalVisible}
            onHide={gContext.toggleConfirmModal}
        >


             
            


            <div className="modal-body text-center">
                <button type="button" className="btn-close d-none" onClick={handleClose}></button>
                
                {gContext.confirmModal.showInfo && gContext.confirmModal.showInfo == true
                    && <><span className="text-theme m-icon material-icons m-l-5 align-middle f-52"  >info</span>
                       <h5 className="m-b-10 m-t-5"> Information </h5></>
                }
                    <h5 className="m-b-10"> </h5>
                    <p className="text-light-gray m-b-20"> {gContext.confirmModal.text}</p>

                 {(deleteConfirm == false && !gContext.confirmModal.mode && gContext.confirmModal.mode != 'post') &&
                    <> {gContext.confirmModalVisible && !gContext.confirmModal.mode && gContext.confirmModal.mode != 'post' && <img className="m-b-20" src="../images/delete-icon.svg" alt="delete-icon" />}
                    <div className="d-flex m-b-20 w-full">
                        <input
                            name="txtDelete"
                            type="text"
                            className="form-control w-full"
                            id="txtDelete"
                            placeholder={"Enter 'delete'"} onChange={handleChange}
                        />
                    </div></>}

                {deleteConfirm == true ?
                    <>
                        <div className="d-flex ">
                            <button type="button" className="btn btn-small btn-green btn-block m-r-50 m-l-50" onClick={() => { confirmDelete(); }}>OK</button>
                           
                        </div>
                         </>

                    : <>
                        {!gContext.confirmModal.companyMode && <>
                            <div className="d-flex">
                                <button disabled={disabled} style={{ cursor: (!disabled) ? '' : 'not-allowed' }} type="button" className="btn btn-green btn-block m-r-30" onClick={() => {
                                    confirmMessage();

                                }}>Yes</button>
                                <button type="button" onClick={() => { handleClose();}} className="btn btn-dark-outline btn-block">No</button>
                            </div>
                            </>}</>}
                    

        </div>

                    
            <Modal.Body className="p-0 d-none">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                    onClick={handleClose}
                >
                    <i className="fas fa-times"></i>
                </button>
              
                <div className="row no-gutters w-100">

                    <div className="col-md-12 col-12 w-100 px-5 py-5">

                        <div className=" align-items-center px-5 py-5">
                            <div className="font-size-4   font-weight-semibold  line-height-2">
                                {gContext.confirmModal.text}
                            </div>
                            {(deleteConfirm == false && !gContext.confirmModal.mode && gContext.confirmModal.mode != 'post') &&
                                <div className="font-size-4 pt-5   font-weight-semibold  line-height-2">
                                <input
                                    name="txtDelete"
                                    type="text"
                                    className="form-control h-px-40"
                                    id="txtDelete"
                                    placeholder={"Enter 'delete'"} onChange={handleChange}
                                />
                            </div>}


                            {deleteConfirm == true ?
                                <>
                                <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">
                                    <button type="button"
                                        className=" btn-green   font-size-3 font-weight-bold rounded-5 border-0 text-uppercase"
                                        style={{ width: '55px', height: '34px' }} onClick={() => { confirmDelete(); }}>
                                        OK
                                    </button>
                                </div></>

                                : <>
                                    {!gContext.confirmModal.companyMode && <> <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">
                                        <button type="button" disabled={disabled}
                                            className=" btn-green   font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" style={{ width: '55px', height: '34px' }} onClick={() => {
                                                confirmMessage();

                                            }}>
                                            Yes
                                        </button><button onClick={handleClose} type="button" className="btn-green  border-0  font-size-3 font-weight-bold ml-2   rounded-5 text-uppercase" style={{ width: '50px', height: '34px', fontFamily: 'sans-serif' }} >
                                            No
                                        </button></div></>}</>}
                        </div>

                    </div>


                </div>

            </Modal.Body>
        </ModalStyled>
    );
};

export default ModalConfirm;
