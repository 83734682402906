import React, { useState, useEffect } from "react";
import { Select } from "../../components/Core";
import { Range, getTrackBackground } from "react-range";
import  Checkbox  from "../../components/Sidebar/Checkbox"; 
import { GetIndustryTypes, GetComplianceCategory, GetProjectLength, GetSkills, GetExpLevels, GetComplianceTypes, GetWeeklyHrs,  GetJobUtils } from "../../utils/GetAPIUtils";
import Icon from '@mui/material/Icon';
const STEP = 1;
 


 

const ExpertSidebar = (props) => {
    const hide = true;
    const [defaultRange, setDefaultRange] = useState({ min:10, max:250});
    const [rangeValues, setRangeValues] = useState([10, 10000]);
    const [rating, setRating] = useState([{ id: "0", code: "0", name: "All" }, { id: "3", code: "3", name: " >= 3 " }, { id: "4", code: "4", name: " >= 4 " }, { id: "5", code: "5", name: " = 5 " }]);
    const [jobSuccessRate, setJobSuccessRate] = useState([{ id: "0", code: "0", name: "All" }, { id: "50", code: "50", name: " >= 50 % " }, { id: "75", code: "75", name: " >= 75 %" }, { id: "90", code: "90", name: " >=90 %" }]);
    const [postedSince, setPostedSince] = useState([{ id: 1, code: "1week", name: "Week ago" }, { id: 2, code: "2weeks", name: "2 week ago" }, { id: 3, code: "1month", name: "Month ago" }, { id: 4, code: "all", name: "All" }]);
    const [experience, setExperienceList] = useState([{}]);
    const [compliances, setComplianceList] = useState([]);
    const [clearAll, setclearAll] = useState(false);
    const [showskillsMore, setshowskillsMore] = useState(false);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [showcompMore, setshowcompMore] = useState(false);
    const [skills, setSkillsList] = useState([]);     
    const [showIndustryMore, setshowIndustryMore] = useState(true);
    const [weeklyHours, setWeeklyHours] = useState([{}]);
    const [compCategory, setCompCategory] = useState([]);
    const [projectLengthList, setProjectLengthList] = useState([]);
    const [selected, setSelected] = useState({ jobSuccessRate:"", avgRating:"", jobTypes: "", expLvlIds: '', skillIds: '', complianceCategoryIds: '', complianceStandardIds: '', weeklyHrsIds: '', projLengthIds: '', period: '', rateRangeFrom: '', rateRangeTo: '', rateType:'' });
    const [showDrop, setShowDrop] = useState({ otherLangs: false, skillIds: false, industryIds: false, dateSince: false , jobSuccessRate:false, avgRating:false,jobTypes: false, expLvlIds: false, skillIds: false, complianceCategoryIds: false, complianceStandardIds: false, weeklyHrsIds: false, projLengthIds: false  });
    const [industryTypeList, setIndustryTypeList] = useState([]);  


    async function getUtils() {
        try {

            try {
                const result = await GetJobUtils();
                if (result.data && result.data.jobUtils && result.data.jobUtils.responseData.code == "200") {

                    let data = JSON.parse(result.data.jobUtils.responseData.message);

                    setExperienceList(data.expLevels);
                    setWeeklyHours(data.weeklyHours);
                    setSkillsList(data.skills);                    
                    setComplianceList(data.complianceTypes);                
                    setIndustryTypeList(data.industryTypes);
                    setCompCategory(data.complianceCategory);                   
                } 
            } catch (e) {
                console.log(e);
            }


            //const result = await GetExpLevels();
            //if (result.data && result.data.expLevels) {
            //    setExperienceList(result.data.expLevels);
            //}
            //const response = await GetSkills();
            //if (response.data && response.data.skills) {
            //    setSkillsList(response.data.skills);
            //}
            //const responseCompliance = await GetComplianceTypes();
            //if (responseCompliance.data && responseCompliance.data.complianceTypes) {
            //    setComplianceList(responseCompliance.data.complianceTypes);

            //}
            //const industry = await GetIndustryTypes();
            //if (industry.data && industry.data.industryTypes) {
            //    setIndustryTypeList(industry.data.industryTypes);
            //}
            //const weeklyhrsResponse = await GetWeeklyHrs();

            //if (weeklyhrsResponse.data && weeklyhrsResponse.data.weeklyHours) {

            //    setWeeklyHours(weeklyhrsResponse.data.weeklyHours);
            //}
            const projLengthResponse = await GetProjectLength();

            if (projLengthResponse.data && projLengthResponse.data.projectLengths) {

                setProjectLengthList(projLengthResponse.data.projectLengths);
            }
            //const complianceCategory = await GetComplianceCategory();

            //if (complianceCategory.data && complianceCategory.data.complianceCategory) {

            //    setCompCategory(complianceCategory.data.complianceCategory);

            //}
        }
        catch (e) {
            console.log(e);
        }
    }
    const handleValueChange = (e, name) => {

        const value = e;
        switch (name) {
            case "otherLangs":
                //setSelected({ ...selected, ["otherLangs"]: e.target.value });
                props.SetSearchCriteria("otherLangs", e.target.value);
                break;
            default:
                break;
        }
    }
    function ApplyFilter() {
        props.setShowFilter();
        props.ApplySearch();
    }
    function ClearAll() {
        props.setShowFilter();
        setRangeValues([10, 10000]);
        setclearAll(true);
        props.clearFilterData("side");
    }
    function handleChange(active, field, value,data) {
        let filter = { ...props.filterData };

        if (active) {
            if ( filter[field] == "") {
                // setSelected({ ...selected, [field]: value.toString() }); 
                props.SetSearchCriteria(field, value.toString());
            }
            else {
                if (filter[field] != "") {
                    let lists = filter[field].split(',');
                    lists.push(value);
                    let key = '';
                    lists.map((data) => (key += data + ','));
                    //  setSelected({ ...selected, [field]: (key !== '') ? key.substring(0, key.length - 1) : '' })
                    props.SetSearchCriteria(field, (key !== '') ? key.substring(0, key.length - 1) : '');
                }
            }
        }
        else {
            if (filter[field] && filter[field] != "") {
                let lists = filter[field].split(',');
                const selectedData = lists.filter(item => item != value);
                let key = '';
                selectedData.map((data) => (key += data + ',')); 
               //setSelected({ ...selected, [field]: (key !== '') ? key.substring(0, key.length - 1) : '' })
                props.SetSearchCriteria(field, (key !== '') ? key.substring(0, key.length - 1) : '');
            }
        } 
    }
    useEffect(() => {
        if (clearAll)
            setclearAll(false);

    }, [clearAll]);
    useEffect(() => {
        getUtils(); 
    }, []);
    function handleRateChange(e, field) {

        if (field == "rateRangeFrom") {
            setRangeValues([e.target.value, rangeValues[1]]);
        }
        else if (field == "rateRangeTo") {
            setRangeValues([rangeValues[0], e.target.value]);
        } 
    }
    function handleRateLeave(e, field) {         

        if (e.target.value == "") {
            if (field == "rateRangeFrom")
                setRangeValues([10, rangeValues[1]]);
            else
                setRangeValues([rangeValues[0], 10000]);

            return;
        } 

        if (field == "rateRangeFrom" && e.target.value < rangeValues[1] && e.target.value >= 10  ) {

            setRangeValues([e.target.value, rangeValues[1]]);
        }
        else if (field == "rateRangeTo" && e.target.value > rangeValues[0] &&  e.target.value <= 10000) {
            setRangeValues([rangeValues[0], e.target.value]);

        }
        else {
            if (field == "rateRangeFrom") {
                setRangeValues([10, rangeValues[1]]);
            }
            else if (field == "rateRangeTo") {
                setRangeValues([rangeValues[0], 10000]);
            }
        }      
           
    }

    useEffect(() => {
        props.SetSearchCriteria(null, null, { ["rateRangeFrom"]: (rangeValues[0]).toString(), ["rateRangeTo"]: (rangeValues[1]).toString() });
      //  props.SetSearchCriteria("rateRangeTo", (rangeValues[1]).toString());
       //  setSelected({ ...selected, ["rateRangeFrom"]: (rangeValues[0] ).toString(), ["rateRangeTo"]: (rangeValues[1]).toString() });
        //props.ApplySearch({ ...selected, ["rateRangeFrom"]:( rangeValues[0] ).toString(), ["rateRangeTo"]:( rangeValues[1]).toString()  }, false);

    }, [rangeValues]);

    //useEffect(() => {
    //   // props.ApplySearch({ ...selected }, false);

    //}, [selected]);
    useEffect(() => {
        if (props.ClearFilter) {
            
            ClearAll(); 
        }
    }, [props.ClearFilter]);
    //useEffect(() => { 
    //    setSelected({ ...selected, ...props.filterData });       
    //}, [weeklyHours]);
    //useEffect(() => { 
    //  //  props.ApplySearch({ ...selected }, true);
    //}, [props.filterData]);
    return (
        <>

            <div className={`sidebar   collapse  ${props.showFilter?"show":""}`} id="sidebar-menu">

                <div className="sidebar-btn d-flex justify-content-end">

                    <button type="button" className="btn bg-green text-white m-r-20" onClick={() => ApplyFilter()}>Apply</button>
                    <button type="button" className="btn btn-dark-outline" onClick={() => ClearAll()}>Clear</button>
                </div>

                <div id="accordion">

                    <div className="accordion-wrapper">



                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-compliance-category" aria-expanded="false" onClick={() => { setShowDrop({ ...showDrop, ["complianceCategoryIds"]: !showDrop.complianceCategoryIds }) }}>Compliance Category
            <Icon className="m-icon material-icons indicator show">expand_more</Icon>
                            <Icon className="m-icon material-icons indicator hide">expand_less</Icon>
                        </a>

                        <div id="accordion-compliance-category" className="collapse" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className=" d-flex flex-column">

                                    {(compCategory) && compCategory.map((data, index) => {
                                        const { id, code, category } = data;
                                        return (

                                            <Checkbox key={index} field="complianceCategoryIds" selectedValue={props.filterData.complianceCategoryIds} showSelected={showDrop.complianceCategoryIds} handleChange={handleChange} value={id} clear={clearAll}>{category}</Checkbox>

                                        )
                                    })}




                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-wrapper">

                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-compliance-standard" aria-expanded="false" onClick={() => { setShowDrop({ ...showDrop, ["complianceStandardIds"]: !showDrop.complianceStandardIds }) }}>Compliance Standard
            <span className="m-icon material-icons indicator show">expand_more</span>
                            <span className="m-icon material-icons indicator hide">expand_less</span>
                        </a>

                        <div id="accordion-compliance-standard" className="collapse" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className=" d-flex flex-column">

                                    {(compliances) && (compliances.length > 10 && !showcompMore) ? compliances.slice(0, 9).map((data, index) => {

                                        const { id, code } = data;
                                        return (


                                            <Checkbox key={index} field="complianceStandardIds" selectedValue={props.filterData.complianceStandardIds} showSelected={showDrop.complianceStandardIds} handleChange={handleChange} value={id} clear={clearAll}>{code}</Checkbox>

                                        )
                                    }) : compliances.map((data, index) => {

                                        const { id, code } = data;
                                        return (


                                            <Checkbox key={index} field="complianceStandardIds" selectedValue={props.filterData.complianceStandardIds} showSelected={showDrop.complianceStandardIds} handleChange={handleChange} value={id} clear={clearAll}>{code}</Checkbox>

                                        )
                                    })}
                                    {showDrop.complianceStandardIds && <>  {!showcompMore && <a className=" p-t-5  pointer text-green" onClick={() => setshowcompMore(!showcompMore)}> + More</a>}
                                        {showcompMore && <a className="  pointer text-green" onClick={() => setshowcompMore(!showcompMore)}> - Hide</a>}</>}

                                </div>
                            </div>
                        </div>
                    </div>

                     


                    <div className="accordion-wrapper">




                        <a className="accordion-btn collapsed" onClick={() => { setShowDrop({ ...showDrop, ["expLvlIds"]: !showDrop.expLvlIds }) }} data-bs-toggle="collapse" href="#accordion-experience-level" aria-expanded="false">Experience Level
            <span className="m-icon material-icons indicator show">expand_more</span>
                            <span className="m-icon material-icons indicator hide">expand_less</span>
                        </a>

                        <div id="accordion-experience-level" className="collapse" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className=" d-flex flex-column">

                                    {(experience) && experience.map((data, index) => {
                                        const { value, label } = data;
                                        
                                        return (


                                            <Checkbox key={index} field="expLvlIds" showSelected={showDrop.expLvlIds} handleChange={handleChange} value={value} clear={clearAll} disable={(props.filterData.expLvlIds === "") ? false : (props.filterData.expLvlIds === value?.toString()) ? false : true} selectedValue={props.filterData.expLvlIds}>{label}</Checkbox>

                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>


                     


                    <div className="accordion-wrapper">



                        <a className="accordion-btn collapsed" onClick={() => { setShowDrop({ ...showDrop, ["weeklyHrsIds"]: !showDrop.weeklyHrsIds }) }} data-bs-toggle="collapse" href="#accordion-weekly-hours" aria-expanded="false">Weekly Hours
              <span className="m-icon material-icons indicator show">expand_more</span>
                            <span className="m-icon material-icons indicator hide">expand_less</span>
                        </a>

                        <div id="accordion-weekly-hours" className="collapse" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className=" d-flex flex-column">

                                    {(weeklyHours) && weeklyHours.map((data, index) => {
                                        const { value, label } = data;
                                        return (


                                            <Checkbox key={index} field="weeklyHrsIds" showSelected={showDrop.weeklyHrsIds} handleChange={handleChange} value={value} clear={clearAll} disable={(props.filterData.weeklyHrsIds === "") ? false : (props.filterData.weeklyHrsIds === value?.toString()) ? false : true} selectedValue={props.filterData.weeklyHrsIds} >{label}</Checkbox>

                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="accordion-wrapper">



                        <a className="accordion-btn collapsed" onClick={() => { setShowDrop({ ...showDrop, ["avgRating"]: !showDrop.avgRating }) }} data-bs-toggle="collapse" href="#accordion-rating" aria-expanded="false">Rating
              <span className="m-icon material-icons indicator show">expand_more</span>
                            <span className="m-icon material-icons indicator hide">expand_less</span>
                        </a>

                        <div id="accordion-rating" className="collapse" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className=" d-flex flex-column">

                                    {(rating) && rating.map((data, index) => {
                                        const { id, name } = data;
                                        return (
                                            <Checkbox field="avgRating" showSelected={showDrop.avgRating} handleChange={handleChange} value={id} clear={clearAll} disable={(props.filterData.avgRating === "") ? false : (props.filterData.avgRating === id.toString() || props.filterData.avgRating === id) ? false : true} selectedValue={props.filterData.avgRating} >{name}</Checkbox>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="accordion-wrapper">



                        <a className="accordion-btn collapsed" onClick={() => { setShowDrop({ ...showDrop, ["jobSuccessRate"]: !showDrop.jobSuccessRate }) }} data-bs-toggle="collapse" href="#accordion-jobSuccessRate" aria-expanded="false"> Job Success Rate 
              <span className="m-icon material-icons indicator show">expand_more</span>
                            <span className="m-icon material-icons indicator hide">expand_less</span>
                        </a>

                        <div id="accordion-jobSuccessRate" className="collapse" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className=" d-flex flex-column">

                                    {(jobSuccessRate) && jobSuccessRate.map((data, index) => {
                                        const { id, name } = data;
                                        return (
                                            <Checkbox key={index} field="jobSuccessRate" showSelected={showDrop.jobSuccessRate} handleChange={handleChange} value={id} clear={clearAll} disable={(props.filterData.jobSuccessRate === "") ? false : (props.filterData.jobSuccessRate === id.toString() || props.filterData.jobSuccessRate === id) ? false : true} selectedValue={props.filterData.jobSuccessRate} >{name}</Checkbox>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                  

                    <div className="accordion-wrapper p-relative">

                        <a className="accordion-btn" data-bs-toggle="collapse" href="#accordion-rate-range" aria-expanded="false">Rate Range</a>


                        <div id="accordion-rate-range" className="collapse show" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                <div className="range-slider m-b-10">
                                    <Range
                                        values={rangeValues}
                                        step={STEP}
                                        min={10}
                                        max={10000}
                                        onChange={(values) => {
                                            setRangeValues(values);
                                        }}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                role="button"
                                                tabIndex={0}
                                                onMouseDown={props.onMouseDown}
                                                onTouchStart={props.onTouchStart}
                                                style={{
                                                    ...props.style,
                                                    height: "7px",
                                                    display: "flex",
                                                    width: "100%",
                                                }}
                                            >
                                                <div
                                                    ref={props.ref}
                                                    style={{
                                                        height: "7px",
                                                        width: "100%",
                                                        borderRadius: "4px",
                                                        background: getTrackBackground({
                                                            values: rangeValues,
                                                            colors: ["#d6d9db", "#F7921E", "#d6d9db"],
                                                            min: 10,
                                                            max: 10000,
                                                        }),
                                                        alignSelf: "center",
                                                    }}
                                                >
                                                    {children}
                                                </div>
                                            </div>
                                        )}
                                        renderThumb={({ props, isDragged }) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    height: "17px",
                                                    width: "17px",
                                                    borderRadius: "50%",
                                                    backgroundColor: "#F7921E",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    boxShadow: "none !important",
                                                    outline: "none !important",
                                                }}
                                                css={`
                    &:focus {
                      outline: none !important;
                    }
                  `}
                                            ></div>
                                        )}
                                    />
                                    
              </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex flex-column m-r-20">
                                            <label for="email" className="form-label f-13 m-b-10">Min ($)</label>
                                        <input type="number" min="10" max="10000" value={rangeValues[0]} defaultValue={rangeValues[0]} onChange={(e) => { handleRateChange(e, "rateRangeFrom") }} onBlur={(e) => { handleRateLeave(e, "rateRangeFrom") }} className="form-control sm w-100 " placeholder="10"/>
                </div>

                                            <div className="d-flex flex-column">
                                                <label for="email" className="form-label f-13 m-b-10">Max ($)</label>
                                        <input type="number" min="10" max="10000" value={rangeValues[1]} defaultValue={rangeValues[1]} onChange={(e) => { handleRateChange(e, "rateRangeTo") }} onBlur={(e) => { handleRateLeave(e, "rateRangeTo") }} className="form-control sm w-100" placeholder="10000"/>
                </div>  

                                            </div>
                                        </div>
                                    </div>
                         

                        <div id="accordion-rate-range" className="collapse show d-none" data-bs-parent="#accordion">
                            <div className="accordion-body">
                                 
                                <span className="text-green text-center m-b-10">
                                   
                                </span>
                                <div className="range-slider">
                                    <>

                                        <Range
                                            values={rangeValues}
                                            step={STEP}
                                            min={10}
                                            max={10000}
                                            onChange={(values) => {
                                                setRangeValues(values);
                                            }}
                                            renderTrack={({ props, children }) => (
                                                <div
                                                    role="button"
                                                    tabIndex={0}
                                                    onMouseDown={props.onMouseDown}
                                                    onTouchStart={props.onTouchStart}
                                                    style={{
                                                        ...props.style,
                                                        height: "15px",
                                                        display: "flex",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <div
                                                        ref={props.ref}
                                                        style={{
                                                            height: "5px",
                                                            width: "100%",
                                                            borderRadius: "4px",
                                                            background: getTrackBackground({
                                                                values: rangeValues,
                                                                colors: ["#ccc", "#F7921E", "#ccc"],
                                                                min: 10,
                                                                max: 10000,
                                                            }),
                                                            alignSelf: "center",
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                </div>
                                            )}
                                            renderThumb={({ props, isDragged }) => (
                                                <div
                                                    {...props}
                                                    style={{
                                                        ...props.style,
                                                        height: "24px",
                                                        width: "24px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "gray",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        boxShadow: "none !important",
                                                        outline: "none !important",
                                                    }}
                                                    css={`
                    &:focus {
                      outline: none !important;
                    }
                  `}
                                                ></div>
                                            )}
                                        />
                                    </>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                    <div className="accordion-wrapper">

                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-filter" aria-expanded="false">
                            <div className="filter-icon">
                                <span className="m-icon material-icons m-r-20">filter_alt</span>
                                <strong>Advanced Filter</strong>
                            </div>

                            <div className="filter-expand d-flex">
                                <span className="indicator show">Show</span><span className="m-icon material-icons indicator show">expand_more</span>
                                <span className="indicator hide">Hide</span> <span className="m-icon material-icons indicator hide">expand_less</span>
                            </div>
                        </a>


                        <div id="accordion-filter" className="collapse  " data-bs-parent="#accordion">

                            <div className="accordion-wrapper">

                                <a className="accordion-btn collapsed" data-bs-toggle="collapse"   onClick={() => { setShowDrop({ ...showDrop, ["industryIds"]: !showDrop.industryIds }) }} href="#accordion-industry-type" aria-expanded="false">Industry Type
                                    <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-industry-type" className="collapse" data-bs-parent="#accordion">
                                    <div className="accordion-body">
                                        <div className=" d-flex flex-column">

                                            {(industryTypeList) && (industryTypeList.length > 10 && !showIndustryMore) ? industryTypeList.slice(0, 9).map((data, index) => {

                                                const { id, name } = data;
                                                return (


                                                    <Checkbox key={index} field="industryIds" selectedValue={props.filterData.industryIds} showSelected={showDrop.industryIds} handleChange={handleChange} value={id} clear={clearAll}>{name}</Checkbox>

                                                )
                                            }) : industryTypeList.map((data, index) => {

                                                const { id, name } = data;
                                                return (


                                                    <Checkbox key={index} field="industryIds" selectedValue={props.filterData.industryIds} showSelected={showDrop.industryIds} handleChange={handleChange} value={id} clear={clearAll}>{name}</Checkbox>

                                                )
                                            })}
                                            {showDrop.industryIds && <>  {!showIndustryMore && <a className="  pointer text-green p-t-5" onClick={() => setshowIndustryMore(!showIndustryMore)}> + More</a>}
                                                {showIndustryMore && <a className="   pointer text-green p-t-5" onClick={() => setshowIndustryMore(!showIndustryMore)}> - Hide</a>}</>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-wrapper">

                                <a className="accordion-btn collapsed" onClick={() => { setShowDrop({ ...showDrop, ["skillIds"]: !showDrop.skillIds }) }} data-bs-toggle="collapse" href="#accordion-skill-set" aria-expanded="false">Skill Set
                            <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-skill-set" className="collapse" data-bs-parent="#accordion">
                                    <div className="accordion-body">
                                        <div className=" d-flex flex-column">
                                            {(skills) && (skills.length > 10 && !showskillsMore) ? skills.slice(0, 9).map((data, index) => {

                                                const { id, skill } = data;
                                                return (


                                                    <Checkbox key={index} field="skillIds" selectedValue={props.filterData.skillIds} showSelected={showDrop.skillIds} handleChange={handleChange} value={id} clear={clearAll}>{skill}</Checkbox>

                                                )
                                            }) : skills.map((data, index) => {

                                                const { id, skill } = data;
                                                return (


                                                    <Checkbox key={index} field="skillIds" selectedValue={props.filterData.skillIds} showSelected={showDrop.skillIds} handleChange={handleChange} value={id} clear={clearAll}>{skill}</Checkbox>

                                                )
                                            })}
                                            {showDrop.skillIds && <>  {!showskillsMore && <a className=" pointer text-green" onClick={() => setshowskillsMore(!showskillsMore)}> + More</a>}
                                                {showskillsMore && <a className=" pointer text-green" onClick={() => setshowskillsMore(!showskillsMore)}> - Hide</a>}</>}                                              </div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-wrapper"> 

                                <a className="accordion-btn collapsed" onClick={() => { setShowDrop({ ...showDrop, ["dateSince"]: !showDrop.dateSince }) }} data-bs-toggle="collapse" href="#accordion-posted-since" aria-expanded="false">Active Since
                            <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-posted-since" className="collapse" data-bs-parent="#accordion">
                                    <div className="accordion-body">
                                        <div className="flex-column">
                                            {(postedSince) && postedSince.map((data, index) => {
                                                const { code, name } = data;
                                                return (


                                                    <Checkbox field="dateSince" showSelected={showDrop.dateSince} handleChange={handleChange} value={code} clear={clearAll} disable={(props.filterData.dateSince === "") ? false : (props.filterData.dateSince === code.toString()) ? false : true}>{name}</Checkbox>


                                                )
                                            })}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-wrapper">

 

                                <a className="accordion-btn collapsed" data-bs-toggle="collapse" onClick={() => { setShowDrop({ ...showDrop, ["otherLangs"]: !showDrop.otherLangs }) }} href="#accordion-other-language" aria-expanded="false">Other Language
                <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-other-language" className="collapse" data-bs-parent="#accordion">
                                    <div className="accordion-body">
                                        <div className="d-flex textarea-resize">
                                            <input
                                                className={`form-control  `}
                                                type="text"
                                                id="otherLangs"
                                                name="otherLangs"   value={props.filterData.otherLangs}
                                                placeholder="Other Language" onChange={(e) => handleValueChange(e, 'otherLangs')} onKeyUp={(e) => handleValueChange(e, 'otherLangs')}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

             

            </div>

              
            {!hide && <>
                <div className="widgets mb-11 media d-none">
                    <button
                        type="button"


                        className="btn btn-primary  btn-mini btn-green   text-white  rounded-5 text-uppercase pt-2" onClick={() => ApplyFilter()}  >
                        Apply
                            </button>



                    <button
                        type="button"

                        className="btn btn-primary   btn-mini btn-green   text-white  ml-5 rounded-5 text-uppercase pt-2" onClick={() => ClearAll()} >
                        Clear
                            </button>
                </div>
                <div className="widgets mb-5">
                    <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["complianceCategoryIds"]: !showDrop.complianceCategoryIds }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">Compliance Category</h4></div> <h4 className="text-right mr-0 col-2 text-green-2   font-weight-semibold">{(showDrop.complianceCategoryIds) ? "-" : "+"}</h4></div>
                    <ul className="list-unstyled filter-check-list">
                        {(compCategory) && compCategory.map((data, index) => {
                            const { id, code, category } = data;
                            return (
                                < li className="mb-2" key={index} >
                                    <Checkbox field="complianceCategoryIds" selectedValue={props.filterData.complianceCategoryIds} showSelected={showDrop.complianceCategoryIds} handleChange={handleChange} value={id} clear={clearAll}>{category}</Checkbox>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="widgets mb-5">
                    <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["complianceStandardIds"]: !showDrop.complianceStandardIds }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">Compliance Standard</h4></div> <h4 className="text-right mr-0 col-2 text-green-2   font-weight-semibold">{(showDrop.complianceStandardIds) ? "-" : "+"}</h4></div>
                    <ul className="list-unstyled filter-check-list">
                        {(compliances) && (compliances.length > 10 && !showcompMore) ? compliances.slice(0, 9).map((data, index) => {

                            const { id, code } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="complianceStandardIds" selectedValue={props.filterData.complianceStandardIds} showSelected={showDrop.complianceStandardIds} handleChange={handleChange} value={id} clear={clearAll}>{code}</Checkbox>
                                </li>
                            )
                        }) : compliances.map((data, index) => {

                            const { id, code } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="complianceStandardIds" selectedValue={props.filterData.complianceStandardIds} showSelected={showDrop.complianceStandardIds} handleChange={handleChange} value={id} clear={clearAll}>{code}</Checkbox>
                                </li>
                            )
                        })}

                    </ul>
                    {showDrop.complianceStandardIds && <>  {!showcompMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowcompMore(!showcompMore)}> + More</a>}
                        {showcompMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowcompMore(!showcompMore)}> - Hide</a>}</>}
                </div>

                <div className="widgets mb-5">

                    <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["expLvlIds"]: !showDrop.expLvlIds }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">  Experience Level{" "}</h4></div> <h4 className="text-right mr-0 col-2 text-green-2 font-weight-semibold  ">{(showDrop.expLvlIds) ? "-" : "+"}</h4></div>

                    <ul className="list-unstyled filter-check-list">

                        {(experience) && experience.map((data, index) => {
                            const { id, level } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="expLvlIds" showSelected={showDrop.expLvlIds} handleChange={handleChange} value={id} clear={clearAll} disable={(props.filterData.expLvlIds === "") ? false : (props.filterData.expLvlIds === id.toString()) ? false : true} selectedValue={props.filterData.expLvlIds}>{level}</Checkbox>
                                </li>
                            )
                        })}

                    </ul>
                </div>

                <div className="widgets mb-5">

                    <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["weeklyHrsIds"]: !showDrop.weeklyHrsIds }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">  Weekly Hours{" "}</h4></div> <h4 className="text-right mr-0 col-2 text-green-2 font-weight-semibold  ">{(showDrop.weeklyHrsIds) ? "-" : "+"}</h4></div>

                    <ul className="list-unstyled filter-check-list">

                        {(weeklyHours) && weeklyHours.map((data, index) => {
                            const { id, hours } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="weeklyHrsIds" showSelected={showDrop.weeklyHrsIds} handleChange={handleChange} value={id} clear={clearAll} disable={(props.filterData.weeklyHrsIds === "") ? false : (props.filterData.weeklyHrsIds === id.toString()) ? false : true} selectedValue={props.filterData.weeklyHrsIds} >{hours}</Checkbox>
                                </li>
                            )
                        })}

                    </ul>
                </div>
                <div className="widgets mb-5">

                    <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["avgRating"]: !showDrop.avgRating }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">  Rating {" "}</h4></div> <h4 className="text-right mr-0 col-2 text-green-2 font-weight-semibold  ">{(showDrop.avgRating) ? "-" : "+"}</h4></div>

                    <ul className="list-unstyled filter-check-list">

                        {(rating) && rating.map((data, index) => {
                            const { id, name } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="avgRating" showSelected={showDrop.avgRating} handleChange={handleChange} value={id} clear={clearAll} disable={(props.filterData.avgRating === "") ? false : (props.filterData.avgRating === id.toString() || props.filterData.avgRating === id) ? false : true} selectedValue={props.filterData.avgRating} >{name}</Checkbox>
                                </li>
                            )
                        })}

                    </ul>
                </div>

                <div className="widgets mb-5">
                    <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["jobSuccessRate"]: !showDrop.jobSuccessRate }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6"> Job Success Rate  {" "}</h4></div> <h4 className="text-right mr-0 col-2 text-green-2 font-weight-semibold  ">{(showDrop.jobSuccessRate) ? "-" : "+"}</h4></div>
                    <ul className="list-unstyled filter-check-list">
                        {(jobSuccessRate) && jobSuccessRate.map((data, index) => {
                            const { id, name } = data;
                            return (
                                <li className="mb-2" key={index}>
                                    <Checkbox field="jobSuccessRate" showSelected={showDrop.jobSuccessRate} handleChange={handleChange} value={id} clear={clearAll} disable={(props.filterData.jobSuccessRate === "") ? false : (props.filterData.jobSuccessRate === id.toString() || props.filterData.jobSuccessRate === id) ? false : true} selectedValue={props.filterData.jobSuccessRate} >{name}</Checkbox>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <div className="widgets mb-5 d-none">
                    <h4 className="font-size-4 font-weight-semibold mb-6">
                        Skill Set{" "}
                    </h4>

                    <ul className="list-unstyled filter-check-list">

                        {(skills) && (skills.length > 10 && !showskillsMore) ? skills.slice(0, 9).map((data, index) => {
                            const { id, skill } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="skillIds" showSelected={showDrop.skillIds} handleChange={handleChange} value={id} clear={clearAll}>{skill}</Checkbox>
                                </li>
                            )
                        }) : skills.map((data, index) => {
                            const { id, skill } = data;
                            return (

                                <li className="mb-2" key={index}>
                                    <Checkbox field="skillIds" showSelected={showDrop.skillIds} handleChange={handleChange} value={id} clear={clearAll}>{skill}</Checkbox>
                                </li>
                            )
                        })}

                    </ul>
                    {!showskillsMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowskillsMore(!showskillsMore)}> + More</a>}
                    {showskillsMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowskillsMore(!showskillsMore)}> - Hide</a>}
                </div>
                <div className="widgets mb-5 ">
                    <div className="d-flex align-items-center pr-5 pr-xs-0 pr-md-0 pr-xl-5 w-100">
                        <h4 className="font-size-4 font-weight-semibold mb-6 w-75">
                            Rate Range
          </h4>

                        <div className="slider-price w-100 text-left mr-2 pb-5">
                            <div className="slider-price w-100 text-center mr-7">
                                <p>   <span
                                    className="text-primary font-weight-semibold font-size-3 "
                                    css={` white-space: nowrap; `}
                                >
                                    $ {rangeValues[0].toFixed()} - $ {new Intl.NumberFormat('en-US').format(rangeValues[1])}
                                </span></p>

                            </div>
                        </div>
                    </div>

                    <div className="range-slider">
                        <>

                            <Range
                                values={rangeValues}
                                step={STEP}
                                min={10}
                                max={10000}
                                onChange={(values) => {
                                    setRangeValues(values);
                                }}
                                renderTrack={({ props, children }) => (
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onMouseDown={props.onMouseDown}
                                        onTouchStart={props.onTouchStart}
                                        style={{
                                            ...props.style,
                                            height: "15px",
                                            display: "flex",
                                            width: "220px",
                                        }}
                                    >
                                        <div
                                            ref={props.ref}
                                            style={{
                                                height: "5px",
                                                width: "100%",
                                                borderRadius: "4px",
                                                background: getTrackBackground({
                                                    values: rangeValues,
                                                    colors: ["#ccc", "#F7921E", "#ccc"],
                                                    min: 10,
                                                    max: 10000,
                                                }),
                                                alignSelf: "center",
                                            }}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                )}
                                renderThumb={({ props, isDragged }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            height: "24px",
                                            width: "24px",
                                            borderRadius: "50%",
                                            backgroundColor: "#FFF",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            boxShadow: "none !important",
                                            outline: "none !important",
                                        }}
                                        css={`
                    &:focus {
                      outline: none !important;
                    }
                  `}
                                    ></div>
                                )}
                            />
                        </>
                    </div>
                </div>

                <div className="widgets mb-5 mt-10 ">
                    <div className="d-flex align-items-center pr-5 pr-xs-0 pr-md-0 pr-xl-5 w-100">
                        <a onClick={() => { setShowAdvanceSearch(!showAdvanceSearch); }} className="pointer text-green-2 font-size-3  rounded-3 px-2   hover-shadow-1">
                            <i className="fa fa-filter mr-2 font-weight-bold"></i>{" "}
                            {showAdvanceSearch ? "Hide" : "Show"}  advanced filter
                                </a></div>
                </div>

                {showAdvanceSearch && <>
                    <div className="widgets mb-5">
                        <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["industryIds"]: !showDrop.industryIds }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">Industry Type</h4></div> <h4 className="text-right mr-0 col-2 text-green-2   font-weight-semibold">{(showDrop.industryIds) ? "-" : "+"}</h4></div>
                        <ul className="list-unstyled filter-check-list">
                            {(industryTypeList) && (industryTypeList.length > 10 && !showIndustryMore) ? industryTypeList.slice(0, 9).map((data, index) => {

                                const { id, name } = data;
                                return (

                                    <li className="mb-2" key={index}>
                                        <Checkbox field="industryIds" selectedValue={props.filterData.industryIds} showSelected={showDrop.industryIds} handleChange={handleChange} value={id} clear={clearAll}>{name}</Checkbox>
                                    </li>
                                )
                            }) : industryTypeList.map((data, index) => {

                                const { id, name } = data;
                                return (

                                    <li className="mb-2" key={index}>
                                        <Checkbox field="industryIds" selectedValue={props.filterData.industryIds} showSelected={showDrop.industryIds} handleChange={handleChange} value={id} clear={clearAll}>{name}</Checkbox>
                                    </li>
                                )
                            })}

                        </ul>
                        {showDrop.industryIds && <>  {!showIndustryMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowIndustryMore(!showIndustryMore)}> + More</a>}
                            {showIndustryMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowIndustryMore(!showIndustryMore)}> - Hide</a>}</>}
                    </div>

                    <div className="widgets mb-5">
                        <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["skillIds"]: !showDrop.skillIds }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-6">Skill Set</h4></div> <h4 className="text-right mr-0 col-2 text-green-2   font-weight-semibold">{(showDrop.skillIds) ? "-" : "+"}</h4></div>
                        <ul className="list-unstyled filter-check-list">
                            {(skills) && (skills.length > 10 && !showskillsMore) ? skills.slice(0, 9).map((data, index) => {

                                const { id, skill } = data;
                                return (

                                    <li className="mb-2" key={index}>
                                        <Checkbox field="skillIds" selectedValue={props.filterData.skillIds} showSelected={showDrop.skillIds} handleChange={handleChange} value={id} clear={clearAll}>{skill}</Checkbox>
                                    </li>
                                )
                            }) : skills.map((data, index) => {

                                const { id, skill } = data;
                                return (

                                    <li className="mb-2" key={index}>
                                        <Checkbox field="skillIds" selectedValue={props.filterData.skillIds} showSelected={showDrop.skillIds} handleChange={handleChange} value={id} clear={clearAll}>{skill}</Checkbox>
                                    </li>
                                )
                            })}

                        </ul>
                        {showDrop.skillIds && <>  {!showskillsMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowskillsMore(!showskillsMore)}> + More</a>}
                            {showskillsMore && <a className="font-size-3 font-weight-semibold pointer text-green-2" onClick={() => setshowskillsMore(!showskillsMore)}> - Hide</a>}</>}
                    </div>


                    <div className="widgets mb-5">

                        <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["dateSince"]: !showDrop.dateSince }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-3">  Active Since{" "}</h4></div> <h4 className="text-right mr-0 col-2 text-green-2 font-weight-semibold">{(showDrop.dateSince) ? "-" : "+"}</h4></div>

                        <div className="list-unstyled filter-check-list  ">

                            {(postedSince) && postedSince.map((data, index) => {
                                const { code, name } = data;
                                return (
                                    <div key={index} className={`  ${((!showDrop.dateSince) && props.filterData.dateSince.split(',').filter(item => item == code).length == 0) ? 'd-none' : ''}`} >
                                        <li className="mb-2" >
                                            <Checkbox field="dateSince" showSelected={showDrop.dateSince} handleChange={handleChange} value={code} clear={clearAll} disable={(props.filterData.dateSince === "") ? false : (props.filterData.dateSince === code.toString()) ? false : true}>{name}</Checkbox>
                                        </li>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div className="widgets mb-5">

                        <div className="pointer media w-100 row hover-shadow-1 " onClick={() => { setShowDrop({ ...showDrop, ["otherLangs"]: !showDrop.otherLangs }) }}><div className="col-10"><h4 className="font-size-4 font-weight-semibold mb-3">  Other Language{" "}</h4></div> <h4 className="text-right mr-0 col-2 text-green-2 font-weight-semibold">{(showDrop.otherLangs) ? "-" : "+"}</h4></div>
                        {(showDrop.otherLangs) && <input
                            className={`form-control focus-reset   font-size-4 w-50`}
                            type="text"
                            id="otherLangs"
                            name="otherLangs" defaultValue={props.filterData.otherLangs} Value={props.filterData.otherLangs}
                            placeholder="Other Language" onChange={(e) => handleValueChange(e, 'otherLangs')} onKeyUp={(e) => handleValueChange(e, 'otherLangs')}
                        />
                        }
                    </div></>}

                <div className="widgets mb-5 media mt-15">
                    <button
                        type="button"


                        className="btn btn-primary  btn-mini btn-green   text-white  rounded-5 text-uppercase pt-2" onClick={() => ApplyFilter()}  >
                        Apply
                            </button>
                    <button
                        type="button"

                        className="btn btn-primary   btn-mini btn-green   text-white  ml-5 rounded-5 text-uppercase pt-2" onClick={() => ClearAll()} >
                        Clear
                            </button>
                </div></>}
            {/* <!-- Sidebar End --> */}
        </>
    );
};

export default ExpertSidebar;
