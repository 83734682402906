import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import StarRating from "../../components/Core/Rating";
import { GetExpertPreviewLoad } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays";
import DOMPurify from 'isomorphic-dompurify'; 
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const FeedbackDetails = (props) => {

   
    const [reportedIssues, setReportedIssues] = useState([]);
   
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    
    async function ExpertPreviewInfo() {
        
        const result = await GetExpertPreviewLoad();
        
        if (result && result.data && result.data.expertAllLoad) {
           
          
            setReportedIssues(result.data.expertAllLoad);       


        }
         

    }

    function toggleModal() {

        props.togglemodal();
       
    }



    useEffect(() => {
        ExpertPreviewInfo();
    }, []);

    function roundOne(n) { 

        if ((n - Math.floor(n)) != 0) {
            var dec_value = parseFloat(n).toFixed(1);
            return dec_value;

        }
        else {
            return n;
        }

    }
    return (
        <>

            <ModalStyled
                backdrop="static"
                size={"lg"}

                centered
                show={props.modalvisible}
                onHide={toggleModal}
            >
                {props.feedback != null && <>




                    <div className="modal-content">


                        <div className="modal-header">
                            <h4 className="modal-title">Feedback Details</h4>
                            <button type="button" className="btn-close" onClick={() => {
                               
                               toggleModal();

                           }}></button>
                           

                        </div>

                        
                                <div className="modal-body">

                            <div className="profile-details-img p-b-0 m-b-0">


                            
                                <div className="company-post">
                                   
                                            <div className="medie-info-img">
                                                {(props.feedback.clientlogo) && <img src={s3URL + props.feedback.clientlogo} alt="" />}
                                            </div>

                                    <div className="d-flex flex-column">
                                        <a className="expert-name m-0 m-b-5 pointer f-16" href={"/job/" + props.feedback.jobguid} target="_self" > <b>{(props.feedback.jobtitle) ? props.feedback.jobtitle : ''}</b></a>
                                        <a className="m-b-5" target="_self" href={"../client/" + props.feedback.clientsub}><b> {(props.feedback.companyname) ? props.feedback.companyname : ''}</b></a>
                                                

                                                     
                                                    <div className="info-meta f-12">
                                            {(props.feedback.rating == null) ? '' :
                                                <>
                                                    <StarRating rating={(props.feedback.rating) ? props.feedback.rating : ''} />
                                                    <strong className="m-t-5 m-l-5" >{"  "}  {(props.feedback.rating) ? roundOne(props.feedback.rating) + "/5" : ''}</strong>
                                                      
                                                    </>}
                                    
                                </div>
                                                
                                            </div>
                                        </div>
                                    
                                <div className="d-flex align-item-center">
                                    <span className="text-light-gray m-r-20">  {getMonthDateYear(props.feedback.datecreated)}</span>
                                    
                                </div>
                            </div>

                            <div className="divider-line"></div>


                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.feedback.feedback) }}></p>


                            <div className="divider-line"></div>


                                   
                                   

                               
                                   
                                   


                                    
                                   


                                </div>


                                
                               
                           

                        
                    </div>

                </>}


               


               
            </ModalStyled>
        </>
    );
};


export default FeedbackDetails;
