import React, { useState, useContext, useEffect } from "react";

import { useLocation, useNavigate } from 'react-router-dom';


import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import Message from "../../components/Message";
import { GetIssueCategory } from "../../utils/GetAPIUtils";
import { CreateReportIssue } from "../../utils/apiCalls";
import validator from 'validator';
import ReactQuill from 'react-quill';
import DOMPurify from 'isomorphic-dompurify';



export default function ReportIssue(props) {
    const reactQuillRef = React.useRef();
    const gContext = useContext(GlobalContext);
    const [editvalue, setEditvalue] = useState('');
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = React.useState('');
    const [editLength, setEditLength] = useState(0);
    const [values, setValues] = React.useState({ issueCategoryId: 0, issue: '', issueCategory: null, descdetails: '', description: '', error: { issue: '', issueCategory: '', descdetails: '', description: '' } });
    const [initialValues, setInitialValues] = React.useState({
        issueCategoryId: 0, issue: '', issueCategory: null, descdetails: '', description: '', error: {
            issue: '', issueCategory: '',
            descdetails: '', description: ''
        }
    });
    const [showUpdate, setShowUpdate] = React.useState(false);
    const [issueCategoryList, setIssueCategoryList] = useState([]);



    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };


    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];



    useEffect(() => {

        setShowUpdate(false);

    }, []);

    async function getUtils() {

        const issueCategory = await GetIssueCategory();

        if (issueCategory.data && issueCategory.data.issueCategory) {
            let issuecat = [];
             
            issueCategory.data.issueCategory.map((value) => (
                issuecat.push({ label: value.category, value: value.id })));
           
            setIssueCategoryList(issuecat);
        }

    }


    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }


    async function createReportIssue() {

        //setShowUpdate(true);


        if (checkValidity()) {
            try {
                gContext.setLoaderModalVisible(true);
                var data = { ...values, ["userId"]: gContext.userInfo.userID };
                const result = await CreateReportIssue(data);

                if (result.data.createIssueReport.responseData.code == "200") {
                  setShowUpdate(true);
                   gContext.setSuccessMessage("Your issue has been submitted successfully");
            gContext.toggleSuccessModal();

                    gContext.setVisibleProfileMessage(true);

                    gContext.setLoaderModalVisible(false);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'

                    });
                }
                else {
                   gContext.setLoaderModalVisible(false); setErrorMessage(JSON.parse(result.data.createReportIssue.responseData.message));
                }

            }
            catch (e) {
                console.log(e);
                setErrorMessage("Server error");
                gContext.setLoaderModalVisible(false);
            }
        }
        else {
            gContext.setLoaderModalVisible(false);
        }gContext.setLoaderModalVisible(false);
        gContext.setVisibleProfileMessage(false);

    }
    // }




    function cancelEdit() {

        if (gContext.userInfo.userType == "1") //expert
        { 
            if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) {
                gContext.setCurrentPage('17');
                navigate("/dashboard", { replace: true });}

            else { gContext.setCurrentPage('5');
             navigate("/profile", { replace: true });}
        }

        else { //client

            if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) {

                gContext.setCurrentPage('1'); navigate("/dashboard", { replace: true });
            }

            else { gContext.setCurrentPage('4');  navigate("/profile", { replace: true });
            }
        }



    }



    const editorKeyDown = (event) => {

        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor
         
        if (unprivilegedEditor.getText().length > 1000 && event.key !== 'Backspace') {

            event.preventDefault();
        }

    };

    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }
    //const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
   
   
    /*const editorChange = (content, delta, source, editor) => {
        let { error, ...rest } = values;
        //error.cdesc = "Invalid content." 
        setEditvalue(editor.getHTML());
        setEditLength(editor.getText().trim().length);

        if (isHtml(editor.getText())) {
            setValues({ ...values, error, ['descdetails']: editor.getText().trim(), ['description']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            //alert('description',editvalue);

        }
        else {
            error.desc = "";
            setValues({ ...values, error, ['descdetails']: editor.getText().trim(), ['description']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
        }
    };*/

    const editorChange = (content, delta, source, editor) => {
        let { error, ...rest } = values;
        
        if (editor.getText().trim().length <= 1000) {
            setEditvalue(editor.getHTML());
            setEditLength(editor.getText().length);

            if (isHtml(editor.getText())) {
                error.description = "Invalid format.";
                setValues({ ...values, error, ['descdetails']: editor.getText() , ['description']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });

            }
            else {
                error.description = "";
                setValues({ ...values, error, ['descdetails']: editor.getText() , ['description']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
        }
        else {
            setEditvalue(editvalue + " ");
            setEditLength(editLength);
        }
    };

    /*function counter()
    {  
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        var countervalue = 1000 - unprivilegedEditor.getLength() ;
        console.log(countervalue);
        return countervalue;
    }*/
    
    const checkCharacterCount = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    var countervalue = 1000 - unprivilegedEditor.getLength() ;
            return countervalue;
    };
    

    function checkValidity() {
         
        let inValid = false;
        let { error, ...rest } = values;
        
        error.description = ((reactQuillRef.current.unprivilegedEditor.getText().trim().length > 0) ? "" : "Description is required");

        error.issue = validator.isEmpty(rest.issue.trim()) ? "Issue is required" : ""; 
        error.issueCategory = (rest.issueCategory) ? "" : "Issue Category is required";
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });
        if (inValid)
            return false;
        else
            return true;
    }

    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };


        switch (name) {
            case "issue":

                error.issue = validator.isEmpty(value) ? "Issue is required" : "";
                setValues({ ...values, error, [name]: value });
                break;




            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }
         
    }

    const handleSelectChange = (e, name) => {

        const value = e;

        let error = { ...values.error };
        switch (name) {


            case "issueCategory":
                error.issueCategory = "";
                setValues({ ...values, error, [name]: value, issueCategoryId: value.value });
                break;
            default:
                break;
        }
        

    }

   
    useEffect(() => {
        document.title = "Report Issue | Syncuppro";
        getUtils();
    }, []);

    return (
        <>

            <span className="screen-darken"></span>

            <div className="page-wrapper">


                <div className="breadcrumb">
                    <div className="container-fluid">

                        <div className="breadcrumb-wrapper">
                            <h4>Report Issues</h4>

                            <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / Report Issues</span>
                            </div>

                        </div>

                    </div>
                </div>
                {(showUpdate === false) &&
                    <section className="section create-job-block">
                        <div className="container-fluid">
                            <div className="card panel p-0">
                                <div className="card-body p-b-0">

                                    <div className="row">


                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="forn-group-input">
                                                <label for="job title" className="form-label m-b-10">Title <span className="text-red"> * </span></label>
                                            <input type="text" defaultValue={values.issue} Value={values.issue}
                                                className={`form-control   ${(values.error.issue.length > 0) ? ' error' : ''}`}
                                                id="issue"
                                                name="issue"
                                                placeholder="eg.Unable to upload files" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                            />
                                            {values.error.issue.length > 1 && (
                                                <span className="text-red">{values.error.issue}</span>
                                            )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="forn-group-input">
                                                <label for="experience level" className="form-label m-b-10">Issue Category <span className="text-red"> * </span></label>
                                            <Select name="issueCategory" value={values.issueCategory}
                                                options={issueCategoryList} placeholder="Select..."
                                                className={`form-select  padding-select ${(values.error.issueCategory.length > 0) ? ' error' : ''}`}
                                                border={false} onChange={(e) => handleSelectChange(e, 'issueCategory')}
                                            />

                                            {values.error.issueCategory.length > 1 && (
                                                <span className="text-red">{values.error.issueCategory}</span>
                                            )}
                                            </div>
                                        </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="desc-info">
                                                <label for="first name" className="form-label m-b-0"><b>Profile Summary</b><span className="text-red"> * (Max 1000 character )</span></label>
                                            <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green">
                                                    {1000 - ((editLength != 0) ? editLength : 0)}/1000
                                                </span></p> 
                                            </div>
                                        <ReactQuill modules={modules}  
                                                formats={formats} theme="snow" id="editor" className=" m-b-40  w-full h-80" preserveWhitespace={true}
                                            onKeyDown={editorKeyDown} style={{height:"150px"}}
                                            ref={reactQuillRef}
                                            defaultValue={editvalue} onChange={editorChange}
                                        ></ReactQuill>
                                        {values.error.description.length > 1 && (
                                            <span className="text-red m-t-40 m-b-10 ">{values.error.description} </span>
                                        )}
                                            
                                        </div>

                                    </div>

                                </div>

                                <div className="card-footer m-t-60">
                                    <div className="panel-action">
                                    <button type="button" className="btn btn-green m-r-20" onClick={() => {
                                        createReportIssue();
                                    }}>Submit</button>
                                    <button type="button" className="btn btn-dark-outline" onClick={() => {
                                        cancelEdit();

                                    }}>Cancel</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>}
  
                {(showUpdate == true) && 
                    <section className="section create-job-block" style={{height:"50vh"}}>
                        <div id="successDiv" className="container-sm">
                            <div className="text-center"> 

                                <div className="d-flex justify-content-center">
                                    <a type="button" className="btn btn-orange"  onClick={() => {
                                cancelEdit();
                            }}>Go to Home</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    }
                    
                    

  </div>


             

        </>
    );
};


