import React, { useContext, useEffect, useState } from "react";
import StarRatings from 'react-star-ratings';
import styled from "styled-components";
import {  ExpertContractUpdate, ClientContractComplete, ClientContractTerminate, CreateAlert } from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import CreateContract from "../../sections/ClientDashboard/CreateContract"; 
import { Select } from "../../components/Core";
import { getMonthDateYear } from "../../utils/addDays";
import ReactQuill from 'react-quill';
import { Modal } from "react-bootstrap";
import DOMPurify from 'isomorphic-dompurify';
import ReactCountryFlag from "react-country-flag";
import { LoadOffer, LoadContract } from "../../utils/apiCalls";
import { GetFlagReasons } from "../../utils/GetAPIUtils";
import AlertUtils from "../../utils/AlertUtils";
import Milestones from "../../sections/ClientDashboard/Milestones";
import { isInvalid } from "../../utils/helperFn";
const ModalStyled = styled(Modal)`
   &.modal {
    

  }
`;
export default function Contract(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    const [milestones, setMilestones] = useState([]);
    const [offer, setOffer] = useState({});
    const reactQuillRef = React.useRef();
    const [mode, setMode] = useState("view");
    const [hideContract, setHideContract] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [updateStatus, setUpdateStatus] = useState(false);
    const [editvalue, setEditvalue] = useState('');
    const [editorText, seteditorText] = useState('');
    const [editLength, setEditLength] = useState(0);
    const [contract, setContract] = useState({}); 
    const [reasonData, setReasonData] = useState([]);
    const [termValues, settermValues] = useState({ termId: null, error: { termId: "" } });
    const [values, setValues] = useState({ name: "", date: null, terms: false, payment: false, error: { terms: "", payment :""} });
    const [compvalues, setCompValues] = useState({ feedbackText: "", feedback: "", rating: 0, error: { feedback: "", rating: "" } });
    const handleClose = () => {
        props.togglecontractmodal();
    };
    const modules = {

        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };
    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];
    const editorChange = (content, delta, source, editor) => {
        //  alert(10);
        let { error, ...rest } = compvalues;
        if (editor.getText().trim().length <= 500) {
            setEditvalue(editor.getHTML());
            seteditorText(editor.getText().trim());
            setEditLength(editor.getText().trim().length);

            if (editor.getText() !="" && isInvalid(editor.getText())) {
                error.feedback = "Invalid format.";
                setCompValues({ ...compvalues, error, ['feedbackText']: editor.getText().trim(), ['feedback']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
            else {
                error.feedback = "";
                setCompValues({ ...compvalues, error, ['feedbackText']: editor.getText().trim(), ['feedback']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
        } else {
            setEditvalue(editvalue + " ");
            seteditorText(editor.getText().trim() + " ");
            setEditLength(editLength);
        }
    };
    const editorKeyDown = (event) => {

        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 500 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };
    function changeRating(rating) {

        setCompValues({ ...compvalues, ["rating"]: rating });
    }
    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }


    async function SendEsignNotificationtoExpert() {

        const profileInfo = gContext.userInfo.profileInfo;


        try { 
            let alert = AlertUtils.ActiveContracttoExpert.
                replace("[JOBTITLE]", contract.jobtitle ? contract.jobtitle : "").
                replace("[CONTRACTTITLE]", contract.title).
                replace("[CGUID]", contract.contractguid).
                replace("[JOBGUID]", contract.jobguid);

            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: gContext.userInfo.userID });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    async function SendEsignNotificationtoClient() {

        const profileInfo = gContext.userInfo.profileInfo;


        try {
           
            const alert = AlertUtils.ActiveContracttoClient.
                replace("[EXPERTSUB]", gContext.userInfo.sub).
                replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                replace("[JOBTITLE]", contract.jobtitle ? contract.jobtitle : "").
                replace("[CONTRACTTITLE]", contract.title).
                replace("[CGUID]", contract.contractguid).
                replace("[JOBGUID]", contract.jobguid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: contract.clientid });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    const handleChange = (e) => {
        setErrorMessage("");
        const { name, value } = e.target;
        let error = { ...values.error };
        switch (name) {

            case "terms":
                error.terms = (e.target.checked) ? "" : "You must agree terms of service to e-Sign the contract.";
                break;
            case "payment":
                error.payment = (e.target.checked) ? "" : "You must agree payment terms to e-Sign the contract.";
                break;
            default:
                break;
        }
        if (name == "terms") {
            setValues({ ...values, ["terms"]: e.target.checked, error });
        }
        if (name == "payment") {
            setValues({ ...values, ["payment"]: e.target.checked, error });
        }

    }
    const handleSelectChange = (e) => {

        const value = e;

        let error = { ...termValues.error };
        error.termId = "";
        settermValues({
            ...termValues, error, ["termId"]: value
        });

    }
    async function TerminateContract() {
        try {
            if (termValues.termId) {
                const profileInfo = gContext.userInfo.profileInfo;
                if (gContext.userInfo.userType == 2) {
                    gContext.setLoaderModalVisible(true);

                    const result = await ClientContractTerminate(props.id, contract.clientid, termValues.termId.value, values.name, 400);
                    
                    if (result.data.contractClientStatusUpdate.responseData.code == "200") {

                        let alerttoExpert = AlertUtils.TerminatedbyClienttoExpert.replace("[JOBTITLE]", contract.title).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[REASON]", termValues.termId.label);;
                        await CreateAlert({ id: null, alert: alerttoExpert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: contract.expertid });

                        let alerttoClient = AlertUtils.TerminatedbyClienttoClient.replace("[JOBTITLE]", contract.title).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[REASON]", termValues.termId.label);;
                        await CreateAlert({ id: null, alert: alerttoClient.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: contract.clientid });

                        gContext.toggleRefreshDashboard("contract");
                        setUpdateStatus(true);
                    } 

                }
                else if (gContext.userInfo.userType == 1) {

                    gContext.setLoaderModalVisible(true);
                    const result = await ExpertContractUpdate(props.id, null, contract.expertid, termValues.termId.value, values.name, 400);
                  
                    if (result.data.expertContractUpdate.responseData.code == "200") {

                        let alerttoClient = AlertUtils.TerminatedbyExperttoClient.replace("[JOBTITLE]", contract.title).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[REASON]", termValues.termId.label);;
                        await CreateAlert({ id: null, alert: alerttoClient.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: contract.clientid });

                        let alerttoExpert = AlertUtils.TerminatedbyExperttoExpert.replace("[JOBTITLE]", contract.title).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[JOBGUID]", contract.jobguid).replace("[REASON]", termValues.termId.label);;
                        await CreateAlert({ id: null, alert: alerttoExpert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: contract.expertid });

                        gContext.toggleRefreshDashboard("contract");
                        setUpdateStatus(true);
                    }
                    else if (result.data.expertContractUpdate.responseData.code == "400") {
                        setErrorMessage( result.data.expertContractUpdate.responseData.message);
                    }


                }
            }
            else {
                let error = { ...termValues.error };
                error.termId = "Terminate reason is required";
                settermValues({
                    ...termValues, error
                });
            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }
    }
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    async function CompleteContract() {
        try {

            let error = { ...compvalues.error };
            error.rating = compvalues.rating == 0 ? "Select rating" : "";
            error.feedback = compvalues.feedback == "" ? "Enter feedback" : (isInvalid(compvalues.feedback) ? "Invalid format" : error.feedback);
            setCompValues({ ...compvalues, error });
            if (compvalues.rating == 0 || compvalues.value)
                return;
            if (compvalues.feedbackText.trim() != "") {
                gContext.setLoaderModalVisible(true);

                const result = await ClientContractComplete({ contractId: props.id, clientId: contract.clientid, expertId: contract.expertid, statusCode: 300, jobId: contract.jobid, feedback: compvalues.feedback, rating: (compvalues.rating == 0) ? null : compvalues.rating });

                if (result.data.contractClientStatusUpdate.responseData.code == "200") {

                    let alerttoClient = AlertUtils.CompletedContract.replace("[TITLE]", contract.title).replace("[GUID]", contract.contractguid);
                    await CreateAlert({ id: null, alert: alerttoClient.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: contract.clientid });
                    await CreateAlert({ id: null, alert: alerttoClient.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: contract.expertid });

                    const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
                    let alerttoExpert = AlertUtils.FeedbacktoExpert.replace("[CLIENTSUB]", gContext.userInfo.sub).replace("[JOBTITLE]", contract.title).replace("[GUID]", contract.contractguid).
                        replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company : "");

                    await CreateAlert({ id: null, alert: alerttoExpert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: contract.expertid });

                    gContext.toggleRefreshDashboard("contract");
                    setUpdateStatus(true);
                }
                else if (result.data.contractClientStatusUpdate.responseData.code == "400") {
                    setErrorMessage(JSON.parse(result.data.contractClientStatusUpdate.responseData.message));
                }
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }
    }
    async function UpdateStatus() {
        try {
            if (values.terms && values.payment) {
                const profileInfo = gContext.userInfo.profileInfo;
                gContext.setLoaderModalVisible(true);
                const result = await ExpertContractUpdate(props.id, (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "", gContext.userInfo.userID, null, null, 200, contract.jobid);
               
                if (result.data.expertContractUpdate.responseData.code == "200") {
                    SendEsignNotificationtoExpert();
                    SendEsignNotificationtoClient();


                    gContext.toggleRefreshDashboard("contract");
                    setUpdateStatus(true);
                }
                else if (result.data.expertContractUpdate.responseData.code == "400") {
                    setErrorMessage(result.data.expertContractUpdate.responseData.message);
                }               
                    gContext.setLoaderModalVisible(false);
            }
            else {
                let { error, ...rest } = values;
                error.terms = (!values.terms) ? "You must agree terms of service to e-Sign the contract.":"";
                error.payment = (!values.payment) ? "You must agree  payment services to e-Sign the contract.":"";
                setValues({ ...values, error });
                setUpdateStatus(false);

            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }

    }
    async function BindOffer() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await LoadOffer(props.id);

            if (result.data.loadOffer && result.data.loadOffer.responseData.code == "200") {

                let data = JSON.parse(result.data.loadOffer.responseData.message);

                if (data.length > 0) {

                    if (data[0].statuscode == 200)
                        setOffer(data[0]);
                }
                gContext.setLoaderModalVisible(false);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false); 
        }
    }
    async function BindContract() {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await LoadContract(props.guid);
            
            if (result.data.loadContract && result.data.loadContract.responseData.code == "200") {

                let data = JSON.parse(result.data.loadContract.responseData.message);
               
                if (data.length > 0) {
                    
                    setContract(data[0]);
                    setMilestones(data[0].milestones);
                }
            } gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false); 
        }
    }
    async function BindUtils() {
        try {
            const response = await GetFlagReasons();

            if (response.data && response.data.flagReasons) {
                
                let list = [];
                response.data.flagReasons.filter(item => item["usertypeid"] == gContext.userInfo.userType).map((value) => (
                    list.push({ value: value.id, label: value.reason })));
                setReasonData(list);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            
        }
    }
    function getUser() {
        if (gContext.userInfo.userType == 1)
            setValues({ name: (gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0) ? gContext.userInfo.profileInfo[0].firstname + " " + gContext.userInfo.profileInfo[0].lastname : "", date: new Date(), terms: false, error: { terms: "" } })
        else {
            const data = JSON.parse(gContext.userInfo.profileInfo);

            setValues({ name: (data.length > 0) ? data[0].firstname + " " + data[0].lastname : "", date: null, terms: false, error: { terms: "" } })
        }

    }

    useEffect(() => {
        if (props.contractmodalvisible) {
            getUser();
            BindUtils();
            if (props.mode == "add") {
                BindOffer();
            }
            else
                BindContract();
        }
        else { setOffer({}); props.updateData(); settermValues({ termId: null, error: { termId: "" } }); setUpdateStatus(false); setEditvalue(""); seteditorText(""); setEditLength(0); setValues({ terms: false, error: { terms: "" } }); setCompValues({ feedbackText: "", feedback: "", rating: 0, error: { feedback: "", rating: "" } }); }

    }, [props.contractmodalvisible]);


    return (
        <>
            <ModalStyled
                backdrop="static"
                size={(updateStatus === true) ? "sm" : "lg"}

                centered
                show={props.contractmodalvisible}
                onHide={() => { props.togglecontractmodal()}}
            > 

                {updateStatus === false && <> 
                    <div className={`modal-content ${hideContract == true ? "pop-greyed" : ""}`}>


                        <div className="modal-header ">
                            <h4 className="modal-title">{(props.mode !== "view" && props.mode !== "accept" && props.mode !== "complete" && props.mode !== "terminate") ? (props.mode == "edit" ? "Edit Contract" : "Create Contract") : "Contract Details"}</h4>
                            <button type="button" className="btn-close" onClick={() => {
                                props.togglecontractmodal();
                            }}></button>
                        </div>

                        {contract.id && props.mode !== "edit" &&
                            <>
                                <div className={`modal-body ${hideContract == true ? "pop-greyed" : ""}`}> 
                                    {contract.clientid !== gContext.userInfo.userID && gContext.userInfo.userType != "2" &&
                                        <div className="company-post">
                                            <div className="medie-info-img">
                                                {(contract.logo) && <img src={s3URL + contract.logo} alt="" />}
                                            </div>
                                            <div className="d-flex flex-column">
                                                <a className="m-b-10" href="#"><b> {(contract.company) ? contract.company : ''}</b></a>
                                                <span>  <div className="info-meta flag">
                                                    {contract.countrycode && <ReactCountryFlag
                                                        countryCode={contract.countrycode}
                                                        svg
                                                        style={{
                                                            width: '1.5em',
                                                            height: '.7em',
                                                        }}
                                                        title={contract.countrycode}
                                                    />}
                                                    <span className="one-line text-black m-l-5">
                                                        <span className={`    ${(contract.city != null && contract.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(contract.city) ? contract.city.toLowerCase() : "" + ' '}</span>
                                                        {' ' + contract.countrycode}</span>
                                                </div></span>
                                            </div>
                                        </div>
                                }
                                
                                {contract.clientid === gContext.userInfo.userID && gContext.userInfo.userType === "2" &&
                                    

                                    <a className="   pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + (contract.expertsub ? contract.expertsub : (milestones != null && milestones.length > 0 && milestones[0] && milestones[0].sub) ? milestones[0].sub : "")}  >
                                            <div className="company-post">
                                                <div className="medie-info-img">
                                                    <img src={s3URL + contract.avatar} alt="" />
                                                </div>

                                            <div className="d-flex flex-column">
                                                <a className="m-b-10 m-t-10" rel="noopener noreferrer" target="_blank" href={"/expert/" + (contract.expertsub ? contract.expertsub : (milestones != null && milestones.length > 0 && milestones[0] && milestones[0].sub) ? milestones[0].sub : "")}  ><b>{contract.firstname + ' ' + contract.lastname}</b></a>

                                                </div>
                                            </div>

                                        </a>}




                                    <div className="d-flex justify-content-between m-b-20">
                                        <h6> {(contract.title) ? contract.title : ''}</h6>
                                    </div>

                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contract.desc) }}></p>

                                    <div className="divider-line"></div>

                                    <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 m-b-20 ">
                                        <div className="info-meta pointer" onClick={(e) => { window.location.href = "/job/" + contract.jobguid; }}>
                                                <span className="text-theme m-icon material-icons m-r-10">work</span> Job : <span className="align-middle f-18"> <b> {(contract.jobtitle)}</b>   </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="info-meta">
                                                <span>Status: <b>{(contract.status)}</b></span>
                                            </div>
                                        </div>

                                        {(contract.startdate) && <>  <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="info-meta">
                                                <span className="text-theme m-icon material-icons m-r-10">timer</span><span>  Start Date : <b>{getMonthDateYear(contract.startdate)}</b></span>
                                            </div>
                                        </div></>}

                                        {(contract.enddate) && <>   <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="info-meta">
                                                <span className="text-theme m-icon material-icons m-r-10">timer</span><span>  End Date : <b>{getMonthDateYear(contract.enddate)}</b></span>
                                            </div>
                                        </div></>}
                                        {props.mode === "accept" &&
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="info-meta">
                                                    <span className="text-theme m-icon material-icons m-r-10">person</span><span>Hiring Person: <b> {contract.hiringperson}</b></span>
                                                </div>
                                            </div>}

                                    </div>
                                    <div className="divider-line"></div>

                                    <div className="row">

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10">monitor</span>
                                                <div className="d-flex flex-column">
                                                    <span className="m-b-5">Rate type</span>
                                                    <b>{contract.rate}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                <div className="d-flex flex-column">
                                                    <span className="m-b-5">Weekly Hours</span>
                                                    <b>{(contract.hours) ? contract.hours : ''}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                                <div className="d-flex flex-column">
                                                    <span className="m-b-5"> {(contract.dailyrate) ? "Daily" : ((contract.fixedrate) ? "Fixed" : (contract.hourlyrate ? "Hourly" : ""))}  Rate</span>
                                                    <b>{(contract.dailyrate) ? "$" + new Intl.NumberFormat('en-US').format(parseInt(contract.dailyrate)) : ""}
                                                        {(contract.hourlyrate) ? "$" + new Intl.NumberFormat('en-US').format(parseInt(contract.hourlyrate)) : ""}
                                                        {(contract.fixedrate) ? "$" + new Intl.NumberFormat('en-US').format(parseInt(contract.fixedrate)) : ""}
                                                    </b>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="divider-line"></div>
                                <Milestones milestones={milestones} isPopup={true} hideContract={() => setHideContract(!hideContract)} contract={contract} contractId={contract.id} mode={mode} contractMode={props.mode } BindContract={() => { BindContract(); }} />
                                    <div className="divider-line"></div>
                                    {props.mode === "accept" && <>
                                        <div className="contract-agreement-block">

                                            <h6 className="m-b-20">Contract Agreement</h6>

                                            <div className="row">

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input m-b-20">
                                                        <label for="first name" className="form-label m-b-10">Signed By</label>
                                                        <input
                                                            name="fName" defaultValue={values.name} Value={values.name}
                                                            disabled="true"
                                                            type="text"
                                                            className={`form-control  `}
                                                            id="fName"
                                                            placeholder="Name" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input m-b-20">
                                                        <label for="first name" className="form-label m-b-10">Signed Date</label>
                                                        <input
                                                            name="lName" defaultValue={getMonthDateYear(new Date())} Value={getMonthDateYear(new Date())}
                                                            disabled="true"
                                                            type="text"
                                                            className={`form-control  `}
                                                            id="lName"
                                                            placeholder=" Signed Date  "
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        <p>  Syncuppro is a marketplace solution that is designed to allow Client to secure compliance services from experts registered as 'Expert'.   </p>

                                        <p> Syncuppro offers a platform that enables Clients and Experts to find one another, enter into service relationships and agreements, receive service from Expert and make /receive payments. Syncuppro neither performs nor employs Experts to perform services. You acknowledge and agree that Syncuppro does not supervise, direct, control or monitor Expert in the performance of any contractual obligations they may have under agreement between the Client and Expert and agree that: (a) Syncuppro is not responsible for offering, performance, or procurement of services, (b) Syncuppro does not make any representations about or guarantee any particular Expert's offered services, and (c) nothing will create an employment, agency or joint venture releationship between Syncuppro, Client and or the Expert. </p>

                                        </div>

                                        <div className="d-flex align-item-center m-r-30">
                                            <div className="form-check m-r-10">
                                                <input
                                                    name="terms"
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="terms-check2" onChange={(e) => { handleChange(e) }}
                                                />
                                            </div>
                                        <span className="text-red ">I, {values.name}, agree to the  <a href={process.env.REACT_APP_EXURL + "/terms"} target="_blank" > <span className="text-green m-l-5"> Terms of Service</span></a></span>

                                    </div>
                                    {values.error.terms.length > 1 && (<span className="text-red w-full">{values.error.terms}</span>
                                    )}
                                    <div className="d-flex align-item-center m-r-30">
                                        <div className="form-check m-r-10">
                                            <input
                                                name="payment"
                                                className="form-check-input"
                                                type="checkbox"
                                                id="payment" onChange={(e) => { handleChange(e) }}
                                            />
                                        </div>
                                        <span className="text-red "> agree to the  <a href={process.env.REACT_APP_EXURL + "/terms#paymentterms" } target="_blank" > <span className="text-green m-l-5"> Payment Terms</span></a></span>

                                    </div>
                                    {values.error.payment && values.error.payment.length > 1 && (<span className="text-red w-full">{values.error.payment}</span>
                                    )}
                               
                            </>}



                                    {props.mode === "terminate" && <>
                                        <div className="terminated-contract-block">

                                            <h6 className="m-b-20">    Terminate Contract  </h6>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="forn-group-input m-b-20">
                                                    <label for="first name" class="form-label m-b-10">Terminated by: <span class="text-red">{values.name} </span></label>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <div class="forn-group-input m-b-20">
                                                    <label for="first name" class="form-label m-b-10"><b>Terminated on:</b>{getMonthDateYear(new Date())}</label>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="forn-group-input m-0">
                                                    <label for="experience level" class="form-label m-b-10">Termination Reason<span class="text-red"> * </span></label>
                                                    <Select name="Terminate" value={termValues.termId}
                                                        options={reasonData} placeholder="Select..."
                                                        className={`form-control padding-select ${(termValues.error.termId.length > 0) ? ' error' : ''}`}
                                                        border={false} onChange={(e) => handleSelectChange(e)}
                                                    />
                                                    {termValues.error.termId.length > 1 && (
                                                        <span className="text-red">{termValues.error.termId}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>




                                    </>}

                                    {props.mode === "accept" && <div className="modal-footer">
                                        <div className="panel-action">
                                            <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                UpdateStatus();

                                            }}>Submit</button>
                                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                                props.togglecontractmodal();

                                            }}>Cancel</button>
                                        </div>
                                    </div>}


                                    {props.mode === "terminate" && <div className="modal-footer">
                                        <div className="panel-action">
                                            <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                TerminateContract();

                                            }}>Submit</button>
                                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                                props.togglecontractmodal();

                                            }}>Cancel</button>
                                        </div>
                                    </div>}

                                    {props.mode === "complete" && <>
                                        <div className="contract-agreement-block m-t-20">

                                            <h6 className="m-b-20">  Contract Complete</h6>

                                            <div className="row">

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input m-b-20">
                                                        <label for="first name" className="form-label m-b-10">Rating<span className="text-red">*</span></label>
                                                        < StarRatings starDimension="24px"
                                                            starSpacing="2px"
                                                            starRatedColor="#F7921E"
                                                            starSelectingHoverColor="#F7921E"
                                                            starHoverColor="#F7921E" rating={compvalues.rating} changeRating={(e) => { changeRating(e); }} isSelectable="true" />
                                                        {compvalues.error.rating.length > 1 && (
                                                            <span className="text-red m-t-20">{compvalues.error.rating}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="company-desc ">
                                                    <div className="desc-info">
                                                        <label className="form-label m-b-0 f-16"> Feedback   <span className="text-red"> * (Max 500 character )</span></label>
                                                        <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green">   {500 - ((editLength) ? editLength : 0)}</span></p>
                                                    </div>


                                                    <ReactQuill modules={modules}
                                                        formats={formats} theme="snow" id="editor" style={{ height: "10vh" }} className="m-b-30"
                                                        onKeyDown={editorKeyDown}
                                                        ref={reactQuillRef}
                                                        value={editvalue} onChange={editorChange}
                                                    ></ReactQuill>
                                                    {compvalues.error.feedback.length > 1 && (
                                                        <span className="text-red m-t-20">{compvalues.error.feedback}</span>
                                                    )}


                                                </div>




                                            </div>


                                        </div>

                                    </>}
                                    {props.mode === "complete" && <div className="modal-footer">
                                        <div className="panel-action">
                                            <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                CompleteContract();

                                            }}>Submit</button>
                                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                                props.togglecontractmodal();

                                            }}>Cancel</button>
                                        </div>
                                    </div>}

                                </div>

                            </>}

                        {updateStatus === false && props.mode !== "view" && props.mode !== "accept" && props.mode !== "complete" && props.mode !== "terminate" && <>   <CreateContract setUpdateStatus={(status) => { setUpdateStatus(status) }} contract={contract} offer={offer} mode={props.mode} togglepopup={() => props.togglecontractmodal()} />  </>}

                    </div>

                </>}


                {updateStatus === true &&
                    <div className="modal-body text-center">
                        <img className="m-b-20" src="../images/sucess-check.svg" alt="sucess-check" />
                        <h5 className="m-b-10">Successful!</h5>
                        <p className="text-light-gray">  {props.mode === "accept" && <span> Contract signed successfully. Await client to start Milestone.
 </span>}
                            {props.mode === "complete" && <span>The contract has been completed successfully.</span>}
                            {props.mode === "terminate" && <span>The contract has been terminated successfully.</span>}
                            {props.mode !== "view" && props.mode !== "accept" && props.mode !== "complete" && props.mode !== "terminate" && <span> The contract has been updated successfully. Await expert's e-Signature. </span>}</p>

                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-green w-120" onClick={handleClose}>Ok</button>
                        </div>

                    </div>
                } 
                 
            </ModalStyled>
        </>
    );
};
