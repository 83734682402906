import React, { useContext, useState, useEffect} from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";

const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const ModalError = (props) => {
   
  const gContext = useContext(GlobalContext);

    const handleClose = () => {
        gContext.setErrorModal('');
    gContext.toggleErrorModal();
  };
    useEffect(() => {
        if (gContext.errorModalVisible ==false)
        gContext.setErrorModal('');
    }, [gContext.errorModalVisible]);
  return (
      <ModalStyled size="sm"
          centered backdrop="static"
          show={gContext.errorModalVisible}
          onHide={() => { gContext.setErrorModal(""); gContext.toggleErrorModal(); }}
    >
          <div className="modal-body text-center">
              <img className="m-b-20" src="../images/unsuccess-cross.svg" alt="error" />
              <h5 className="m-b-10">Error!</h5>
              <p className="text-light-gray">{gContext.errorModal}</p>

              <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-green w-120" onClick={() => { handleClose() }}>Ok</button>
              </div>

          </div>
    </ModalStyled>
  );
};

export default ModalError;
