import React, { useContext, useEffect, useState } from "react";
import StarRating from "../../components/Core/Rating";
import styled from "styled-components";
import { ClientJobsRead } from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import CreateOffer from "../../sections/ClientDashboard/CreateOffer";
import Message from "../../components/Message";
import { getMonthDateYear } from "../../utils/addDays";
import ReactCountryFlag from "react-country-flag";
import { Modal } from "react-bootstrap";

import DOMPurify from 'isomorphic-dompurify';
import { LoadOffer } from "../../utils/apiCalls";

const ModalStyled = styled(Modal)`
   &.modal {
     
  }
`;
export default function Offer(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    const [offer, setOffer] = useState({});
    const [proposal, setProposal] = useState({});
    const [values, setValues] = React.useState({ proposalSummary: '', proposalSummaryText: '', bidRate: null, resetResumeName: '', resumeName: '', resume: null, error: { bidRate: "", proposalSummary: "" } });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showUpdate, setShowUpdate] = React.useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const [offerStatus, setOfferStatus] = useState(false);
    const [toggleSetting, setToggleSetting] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const handleClose = () => {

    };

    async function BindOffer() {
        try {
            setLoaded(false);
             
            const result = await LoadOffer(props.id);

            if (result.data.loadOffer && result.data.loadOffer.responseData.code == "200") {

                let data = JSON.parse(result.data.loadOffer.responseData.message);
                
                if (data.length > 0) { 
                        setOffer(data[0]);
                }
            }
            setLoaded(true);
        }
        catch (e) {

            //  window.location.href = "/error";
        }
    }
    function updateMessage() {
        setShowUpdate(true);
    }
    useEffect(() => {

    }, [props.mode]);

    useEffect(() => {
        if (props.offermodalvisible) {
            setOfferStatus(false);
            gContext.setLoaderModalVisible(true);
            BindOffer();
            gContext.setLoaderModalVisible(false);
        }
        else { setShowUpdate(false); setOffer({}); props.updateData(); }

    }, [props.offermodalvisible]);


    return (
        <>
            <ModalStyled  
                centered size={offerStatus === true ? "sm" : "lg"}

                backdrop="static"
                show={props.offermodalvisible}
                onHide={props.toggleoffermodal}
            >

                {offerStatus == false && <>   {props.mode === "view" && <>
                    <div className="modal-content">


                        <div className="modal-header">
                            <h4 className="modal-title">Offer Details</h4>
                            <button type="button" className="btn-close" onClick={() => {
                                props.toggleoffermodal();
                            }}></button>

                        </div>

                        {loaded &&
                            <div className="modal-body">
                            {offer.id && <>
                                {offer.clientid !== gContext.userInfo.userID && gContext.userInfo.userType != "2" &&
                                 
                                    <div className="company-post">
                                            <a className="   pointer" target="_blank" rel="noopener noreferrer" href={"/client/" + offer.clientsub}  >  <div className="medie-info-img">
                                        {(offer.logo) && <img src={s3URL + offer.logo}   alt="" />}
                                            </div></a>

                                        <div className="d-flex flex-column">
                                                    <a className="m-b-10" target="_blank" rel="noopener noreferrer" href={"/client/" + offer.clientsub}><b>{(offer.company) ? offer.company : ''}</b></a>
                                                    <div className="info-meta flag m-r-20">
                                                        <span className="text-theme m-icon material-icons m-r-5">location_on</span><span className="one-line text-black m-r-5">
                                                            <span className={`    ${(offer.clientcity != null && offer.clientcity.length > 2) ? 'text-capi' : 'text-upper'}`}>{offer.clientcity ? offer.clientcity.toLowerCase() : "" + ' '}</span>
                                                            {''}    <span className={`    ${(offer.clientstate != null && offer.clientstate.length > 2) ? 'text-capi' : 'text-upper'}`}>{offer.clientstate ? offer.clientstate.toLowerCase() : ""}</span>{' ' + offer.clientcountrycode}</span>

                                                        <ReactCountryFlag
                                                            countryCode={(offer.clientcountrycode) ? offer.clientcountrycode : ''}
                                                            svg
                                                            style={{
                                                                width: '1em',
                                                                height: '.7em',
                                                            }}
                                                            title={(offer.clientcountryname) ? offer.clientcountryname : ''}
                                                        />
                                                    </div>
                                        </div>
                                    </div>

                                     }
                                {offer.clientid === gContext.userInfo.userID && gContext.userInfo.userType === "2" &&
                                   
                                    <div className="company-post">
                                            <a className="   pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + offer.expertsub}  > <div className="medie-info-img">
                                            <img src={s3URL + offer.avatar} alt="" />
                                        </div></a>

                                            <div className="d-flex flex-column">
                                                <a className="m-b-10 m-t-10" target="_blank" rel="noopener noreferrer" href={"/expert/" + offer.expertsub} ><b >{offer.firstname + ' ' + offer.lastname}</b></a>
                                                    <div className="info-meta flag m-r-20">
                                                        <span className="text-theme m-icon material-icons m-r-5">location_on</span><span className="one-line text-black m-r-5">
                                                            <span className={`    ${(offer.expertcity != null && offer.expertcity.length > 2) ? 'text-capi' : 'text-upper'}`}>{offer.expertcity ? offer.expertcity.toLowerCase() : "" + ' '}</span>
                                                            {''}    <span className={`    ${(offer.expertstate != null && offer.expertstate.length > 2) ? 'text-capi' : 'text-upper'}`}>{offer.expertstate ? offer.expertstate.toLowerCase() : ""}</span>{' ' + offer.expertcountrycode}</span>

                                                        <ReactCountryFlag
                                                            countryCode={(offer.expertcountrycode) ? offer.expertcountrycode : ''}
                                                            svg
                                                            style={{
                                                                width: '1em',
                                                                height: '.7em',
                                                            }}
                                                            title={(offer.expertcountryname) ? offer.expertcountryname : ''}
                                                        />
                                                    </div>
                                        </div>
                                </div>
                                    }
                                 
                                  

                                            {gContext.userInfo.userType === "2" && offer.statuscode === 200 && <>
                                    <div className="modal-footer m-t-5" > 
                                        <div className="panel-action"> <a className="btn btn-orange btn-sm   m-r-10 text-white" onClick={() => { if (gContext.userInfo.paymentConnected) { props.createContract(offer.id); props.toggleoffermodal(); } else { props.toggleoffermodal(); setToggleSetting(true);  } }}
                                            >
                                            Create Contract
                                                </a>    </div>
                                    </div>  </>}

                                            {gContext.userInfo.userType === "1" && offer.statuscode === 100 && <>   <div className="modal-footer m-t-5" >
                                            <div className="panel-action"><button disabled={offer.statuscode === 200} className="btn btn-orange btn-sm   m-r-10 text-white" onClick={(e) => { props.updateStatus(offer.id, 'accept', offer); props.toggleoffermodal(); }}
                                            >
                                                Accept
                                        </button> <button disabled={offer.statuscode === 300} className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={(e) => { props.toggleModal(offer); props.toggleoffermodal(); }}
                                                >
                                                    Reject
                                                </button>   </div>
                                        </div>  </>}

                                            
                                      

                                    <div className="d-flex justify-content-between m-b-20">
                                    <h6> <a   target="_blank" rel="noopener noreferrer" href={"/job/" + offer.jobguid}   >
                                        {(offer.title) ? offer.title : ''}
                                    </a></h6>
                                    </div>

                                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer.desc) }}></p>

                                   
                                    <div className="row">

                                    {(offer.offerdate) && <>  <div className="col-lg-4 col-md-4 col-sm-6">
                                        <div className="info-meta">
                                            <span className="text-theme m-icon material-icons m-r-10">timer</span><span>Offer Date : <b>{getMonthDateYear(offer.offerdate)}</b></span>
                                        </div>
                                    </div></>}

                                    {(offer.offerenddate) && <>  <div className="col-lg-4 col-md-4 col-sm-6">
                                        <div className="info-meta">
                                            <span className="text-theme m-icon material-icons m-r-10">timer</span><span>Offer End Date : <b> {getMonthDateYear(offer.offerenddate)}</b></span>
                                        </div>
                                    </div></>}

                                    </div>
                                    <div className="divider-line"></div>

                                    <div className="row">

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper">
                                                <span className="text-theme m-icon material-icons m-r-10">monitor</span>
                                                <div className="d-flex flex-column">
                                                    <span className="m-b-5">Rate type</span>
                                                <b>{offer.ratetype}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                <div className="d-flex flex-column">
                                                    <span className="m-b-5">Weekly Hours</span>
                                                <b>{(offer.weeklyhrs) ? offer.weeklyhrs : ''}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10">calendar_month</span>
                                                <div className="d-flex flex-column">
                                                    <span className="m-b-5">Start Date</span>
                                                <b>{getMonthDateYear(offer.startdate)}</b>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                                <div className="d-flex flex-column">
                                                <span className="m-b-5"> {(offer.dailyrate) ? "Daily" : ((offer.fixedrate) ? "Fixed" : (offer.hourlyrate ? "Hourly" : ""))}  Rate (USD)</span>
                                                <b>${(offer.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(offer.dailyrate)) : ""}
                                                    {(offer.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(offer.hourlyrate)) : ""}
                                                    {(offer.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(offer.fixedrate)) : ""}</b>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="divider-line"></div>
                            </>}
                                </div>}

                    </div></>}
                    {!loaded &&

                        <> 



                            <div className="   m-t-20 m-b-20 p-5 ">

                               



                                    <div className="company-post">
                                        <div className="btn-circle movingLoader">

                                        </div>

                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div></span>
                                        </div>
                                    </div> 
                                   

                                    
                            <div className="divider-line"></div>
                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                            </div>

                                    <div className="d-flex justify-content-between m-b-0   movingLoader">
                                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>     <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>     <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>     <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                          
                               
                                    </div>
                                    <div className="divider-line"></div>
                                    <div className="row ">

                                        <div className="col-lg-4 col-md-4 col-sm-6 m-t-5">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10"> </span>
                                                <div className="d-flex flex-column w-50  ">
                                                    <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6  m-t-5">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10"> </span>
                                                <div className="d-flex flex-column w-50  ">
                                                    <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-6  m-t-5">
                                            <div className="icon-wrapper m-0">
                                                <span className="text-theme m-icon material-icons m-r-10"></span>
                                                <div className="d-flex flex-column w-50  ">
                                                    <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                                     
                                                </div>
                                            </div>
                                        </div>

                                    </div>
  
                            <div className="divider-line"></div>
                            <div className="row ">

                                <div className="col-lg-4 col-md-4 col-sm-6 m-t-5">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10"> </span>
                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6  m-t-5">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10"> </span>
                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6  m-t-5">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10"></span>
                                        <div className="d-flex flex-column w-50  ">
                                            <a className="m-l-10 movingLoader w-100 m-b-5" ><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </b></a>
                                            <span>  <div className="info-meta m-l-10 flag w-200 movingLoader">

                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </div></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                                </div></>
                    }
                    {props.mode === "edit" && loaded && <>  <CreateOffer setOfferStatus={(status) => { setOfferStatus(status) }} proposal={props.proposalData} offer={offer} mode="edit" toggleOffer={() => props.toggleoffermodal()} statusUpdate={updateMessage} /> </>}
                    </>}
            {offerStatus === true &&

                <div className="modal-body text-center  ">
                    <img className="m-b-20" src="../images/sucess-check.svg" />
                    <h5 className="m-b-10">Successful!</h5>
                    <p className="text-light-gray">Your offer has been submitted successfully.</p>

                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120 pointer" onClick={props.toggleoffermodal}>Ok</button>
                    </div>

                </div>

            }
                <Modal.Body className="p-0 d-none">
                    <button
                        type="button"
                        className="d-none circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    

                        <div className="container px-0 py-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12  ">
                                    <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                        Offer Detail
                  </div>
                                </div>
                                {loaded && <div className="col-xl-12 col-lg-12  mx-auto ">
                                    {offer.id && <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                        {/* <!-- Single Featured Job --> */}
                                        <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                            <div className="row mb-6">
                                                <div className="col-lg-6">
                                                    {offer.clientid !== gContext.userInfo.userID && gContext.userInfo.userType != "2" &&
                                                        <div className="media align-items-center">
                                                            {/* <!-- media logo start --> */}
                                                            <a className="media  pointer" target="_blank" rel="noopener noreferrer" href={"/client/" + offer.clientsub}  >  <div className="square-72   d-block mr-8">
                                                                {(offer.logo) && <img src={s3URL + offer.logo} className="w-100 rounded" alt="" />}
                                                            </div>
                                                                {/* <!-- media logo end --> */}
                                                                {/* <!-- media texts start --> */}
                                                                <div>
                                                                    <h3 className="font-size-6 mb-0">
                                                                        {(offer.company) ? offer.company : ''}
                                                                    </h3>
                                                                    <span className="font-size-3 text-gray line-height-2">
                                                                        {(offer.comptagline) ? offer.comptagline : ''}
                                                                    </span><br />

                                                                </div></a>
                                                            {/* <!-- media texts end --> */}
                                                        </div>}
                                                    {offer.clientid === gContext.userInfo.userID && gContext.userInfo.userType === "2" && <a className="media  pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + offer.expertsub}  >
                                                        <div className="  mr-6">
                                                            <img src={s3URL + offer.avatar} alt="" className=" circle-72 w-100" />
                                                        </div>
                                                        <h4 className="font-size-6 mb-0 font-weight-semibold text-black-2 pointer my-auto"   >
                                                            {offer.firstname + ' ' + offer.lastname}
                                                        </h4>
                                                    </a>}
                                                </div>
                                                <div className="col-md-6 pt-10 pt-md-0 mt-md-n1 text-right">

                                                {gContext.userInfo.userType === "2" && offer.statuscode === 200 && gContext.userInfo.paymentConnected && <> <a className="btn btn-green text-uppercase btn-small rounded-3 px-5 w-150 mr-4 mb-5 pointer" onClick={(e) => { props.createContract(offer.id); props.toggleoffermodal(); }}
                                                    >
                                                        Create Contract
                                                </a>   </>}

                                                    {gContext.userInfo.userType === "1" && offer.statuscode === 100 && <> <button disabled={offer.statuscode === 200} className="btn btn-green text-uppercase btn-small rounded-3 px-5 w-150 mr-4 mb-5 pointer" onClick={(e) => { props.updateStatus(offer.id, 'accept', offer); props.toggleoffermodal(); }}
                                                    >
                                                        Accept
                                                </button> <button disabled={offer.statuscode === 300} className="btn btn-outline-red   text-uppercase btn-small rounded-3 px-5 w-150 mr-4 mb-5 pointer" onClick={(e) => { props.updateStatus(offer.id, 'reject', offer); props.toggleoffermodal(); }}
                                                        >
                                                            Reject
                                                </button>  </>}
                                                </div>
                                            </div>

                                            <div className="row mb-6 mt-10">
                                                <div className="col-lg-6 mb-10">

                                                    <a className="font-size-5 text-black-2 font-weight-semibold pointer" target="_blank" rel="noopener noreferrer" href={"/job/" + offer.jobguid}   >
                                                        {(offer.title) ? offer.title : ''}
                                                    </a>

                                                </div>

                                                <div className="col-xl-12 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-10">
                                                    <div className=" font-size-4 text-gray mb-0 " style={{ textOverflow: 'ellipsis', overflow: 'hidden', whitespace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offer.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                                    </div>  {(DOMPurify.sanitize(offer.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 4000) && <div className="mt-n7  mb-7 text-right w-100"><i className="fa-solid fa-ellipsis mr-5"></i> </div>}
                                                </div>

                                                <div className="col-md-4 text-left pt-0 pt-md-0 mt-md-n1">
                                                    {/* <!-- media date start --> */}
                                                    <div className="media  ">
                                                        <p className="font-size-4 text-gray mb-0">
                                                            {(offer.offerdate) && <>   <h5 className="font-size-4 text-right   text-black-2 pt-5 mr-0 font-weight-semibold  media"><div className="image mr-5">
                                                                <i className="fa-regular fa-clock text-green-2"></i>
                                                            </div> <span className="font-size-4 text-gray font-weight-semibold">
                                                                    Offer Date : &nbsp;
                                                </span>   {getMonthDateYear(offer.offerdate)}</h5></>}
                                                        </p>
                                                    </div>
                                                    {/* <!-- media date end --> */}
                                                </div>
                                                <div className="col-md-4 text-left pt-0 pt-md-0 mt-md-n1">
                                                    {/* <!-- media date start --> */}
                                                    <div className="media ">
                                                        <p className="font-size-4 text-gray mb-0">
                                                            {(offer.offerenddate) && <>   <h5 className="font-size-4 text-right   text-black-2 pt-5 mr-0 font-weight-semibold  media"><div className="image mr-5">
                                                                <i className="fa-regular fa-clock text-green-2"></i>
                                                            </div> <span className="font-size-4 text-gray font-weight-semibold">
                                                                    Offer End Date : &nbsp;
                                                </span>   {getMonthDateYear(offer.offerenddate)}</h5></>}
                                                        </p>
                                                    </div>
                                                    {/* <!-- media date end --> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Single Featured Job --> */}
                                        <div className="row bg-white px-5 m-0 mt-1 border-bottom border-width-1 border-default-color"><div className=" col-12   ">

                                            <div className="job-details-content pt-3 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-5    light-mode-texts">


                                                <div className="row">

                                                    <div className="col-md-4  pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6  ">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa fa-money text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Rate Type
                                                </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {offer.ratetype}

                                                        </h6>
                                                    </div>



                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-business-time text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0 ">
                                                                Weekly Hours
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {(offer.weeklyhrs) ? offer.weeklyhrs : ''}

                                                        </h6>
                                                    </div>

                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa fa-calendar text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Start Date
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">{getMonthDateYear(offer.startdate)}

                                                        </h6>
                                                    </div>


                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-dollar-sign  text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0 pl-2 ">
                                                                {(offer.dailyrate) ? "Daily" : ((offer.fixedrate) ? "Fixed" : (offer.hourlyrate ? "Hourly" : ""))}  Rate
                                                        </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-8 pl-2">
                                                        {(offer.dailyrate) ? "$" +new Intl.NumberFormat('en-US').format(parseInt(offer.dailyrate)) : ""}
                                                        {(offer.hourlyrate) ? "$" +new Intl.NumberFormat('en-US').format(parseInt(offer.hourlyrate)) : ""}
                                                        {(offer.fixedrate) ? "$" +new Intl.NumberFormat('en-US').format(parseInt(offer.fixedrate)) : ""}

                                                        </h6>
                                                    </div>


                                                </div>


                                            </div>
                                        </div></div>



                                    </div>}
                                </div>}
                              
                            </div>

                        </div>

                    
                       
                </Modal.Body>
            </ModalStyled>
            <ModalStyled size="sm"
                centered
                show={toggleSetting}
                onHide={() => setToggleSetting(!toggleSetting)}
            >

                <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/unsuccess-cross.svg" alt="error" />
                    <h5 className="m-b-10">Error!</h5>
                    <p className="text-light-gray">Your payment account is not connected. Update payment details in settings page. </p>

                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-orange m-r-10" onClick={() => { window.location.href = "/settings"; }}>Settings</button> <button type="button" className="btn btn-dark-outline " onClick={() => { setToggleSetting(!toggleSetting); }}>Ok</button>
                    </div>

                </div>
            </ModalStyled >
        </>
    );
};
