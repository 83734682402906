import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import StarRating from "../../components/Core/Rating";
import { GetExpertPreviewLoad } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays";
import DOMPurify from 'isomorphic-dompurify';
import Icon from '@mui/material/Icon';
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const FeedbackDetails = (props) => {
     
    const [reportedIssues, setReportedIssues] = useState([]);
   
    const s3URL = process.env.REACT_APP_AWS_S3_URL;  

    async function ExpertPreviewInfo() {
       
        const result = await GetExpertPreviewLoad();
         
        if (result && result.data && result.data.expertAllLoad) {
             
            let list = [];
           
            setReportedIssues(result.data.expertAllLoad);
            
        }
        
    }

    function toggleModal() {

        props.togglemodal();
        
    }



    useEffect(() => {
        ExpertPreviewInfo();
    }, []);

    function roundOne(n) { 

        if ((n - Math.floor(n)) != 0) {
            var dec_value = parseFloat(n).toFixed(1);
            return dec_value;

        }
        else {
            return n;
        }

    }


    return (
        <ModalStyled
            {...props}
            size="lg"
            centered
            show={props.modalvisible}
            onHide={toggleModal}
        >
           {props.feedback != null && <>




<div className="modal-content">


    <div className="modal-header">
        <h4 className="modal-title">Feedback Details</h4>
        <button type="button" className="btn-close" onClick={() => {
           
           toggleModal();

       }}></button>
       

    </div>

    
            <div className="modal-body">





                 <div className="company-post">
                        <div className="medie-info-img">
                            {(props.feedback.exptavatar) && <img src={s3URL + props.feedback.exptavatar} alt="" />}
                        </div>

                            <div className="d-flex flex-column">
                                <a className="expert-name m-0 m-b-5 pointer f-16" href={"/job/" + props.feedback.jobguid} target="_self" > <b>{(props.feedback.jobtitle) ? props.feedback.jobtitle : ''}</b></a>
                                <a className="m-b-10" target="_self" href={"../client/" + props.feedback.exptsub}>
                                <b>  {props.feedback.exptfirstname} {props.feedback.exptlastname} </b></a>
                            <span> 
                                
                            <div className="info-meta flag m-r-20">
                                    <Icon className="text-theme m-icon material-icons m-r-5">timer</Icon>
                                        
                                    <span className="font-size-3 text-gray font-weight-semibold">{getMonthDateYear(props.feedback.datecreated)}</span>
                                </div>
                                </span>

                                <div className="info-meta ">
                                <div className="info-meta f-12">
                                            {(props.feedback.rating == null) ? '' :
                                                <>
                                                    <StarRating rating={(props.feedback.rating) ? props.feedback.rating : ''} />
                                                    <strong className="m-t-5 m-l-5" >{"  "}  {(props.feedback.rating) ? roundOne(props.feedback.rating) + "/5" : ''}</strong>
                                                      
                                                    </>}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                    

                    <div className="divider-line"></div>


                <div className="d-flex justify-content-between m-b-20">
                    <h6> Feedback</h6>
                </div>

                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.feedback.feedback) }}></p>



                
                <div className="divider-line"></div>

               

           
               
               


                
               


            </div>


            
           
       

    
</div>

</>}
        </ModalStyled >
    );
};

export default FeedbackDetails;
