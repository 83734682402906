import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";

const img = "assets/image/l1/jpg/faq.jpg";

export default function Policy( props) {
    const [openItem, setOpenItem] = useState(1);
    return (
        <>  
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>
             
                <span className="screen-darken"></span>

                <div className="page-wrapper">


                    <div className="breadcrumb">
                        <div className="container-fluid">

                            <div className="breadcrumb-wrapper justify-content-center">
                                <h4>Privacy and Cookie Policy</h4>
                            </div>

                        </div>
                    </div>

                    <section className="section privacy-block">
                        <div className="container-fluid">
                            <p>Date of Last Revision:<b> September 10, 2022 </b></p>

                            <p>SyncupPro (hereinafter referred to as ‘Company’), a company incorporated under the laws of United States of America bearing registration number 83-4714409 and having its registered office at [insert], manages <a href="https://syncuppro.com">https://syncuppro.com/</a> and other associated website and mobile application(s) (hereinafter collectively referred to as ‘Platform’).</p>

                            <p>The Platform is a marketplace solution that is designed to allow Users to order services from experts registered on the Platform (‘Expert’). This Privacy Policy applies to all users (including Experts) of the Platform (hereinafter referred to as ‘User’). If the User is using or accessing the Platform on behalf of another, the User hereby and warrant represent that you are authorized by such individual or entity to accept this policy on such individual’s or entity’s behalf.</p>

                            <p>The purpose of this Privacy Policy, which may be amended from time to time, aims to provide the Users with an understanding on how we collect, use and disclose the information you provide to us.</p>

                            <div className="privacy-block-wrapper">
                                <h6 className="m-b-20">Information we collect</h6>

                                <p>The information may be divided into the following general categories:</p>
                            </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>1. Information you provide to us</h5>
                                </div>

                                <div className="card-body p-b-0">

                                    <p>When a User registers with the Platform, we may ask the User to voluntarily provide us certain information that helps facilitate the use of the Platform and helps us stay compliant with our legal obligations.</p>

                                    <h6 className="m-b-10">1.1. Personal Information</h6>
                                    <p>When the User register with this Platform or orders/ accepts any service through the Platform, we may ask you to voluntarily provide us certain information that personally identifies you or could be used to personally identify your entity (hereinafter referred to ‘Personal Information’). Personal information may include, but is not limited to, the following categories of information: name, age, zip code/pin code, address, contact number, email or other contact information and other relevant data as the case may be. Questions or comments which you submit may also be regarded as Personal Information.</p>
                                    <p>In the event, there is any change in the Personal Information or if you need to update or correct such Personal Information, or you have any grievance with respect to the processing or use of your Personal Information, for any reason, you may send updates and corrections to the Company at contact@syncuppro.com and Company will take all reasonable efforts to incorporate the changes within a reasonable period of time.</p>

                                    <h6 className="m-b-10">1.2. Communications with us and other Users </h6>
                                    <p>When the User communicate with us or use the Platform to communicate with other Users, we collect information about your communication and any information you choose to provide. We collect this information for the purposes of maintaining safety, ensuring quality, and optimizing our Platform.</p>

                                    <h6 className="m-b-10"> 1.3. Payment Information</h6>
                                    <p>While using our Platform to make or accept payments, we may ask you to provide us with certain information that will help us to process such payments. Information may include, but is not limited to, your name, address, email address, and phone number(s). We may use third party payment service provider provides to process any payment. You hereby acknowledge and understand that use of information collected by such third-party service provider is governed by their privacy policy.</p>

                                    <h6 className="m-b-10"> 1.4. Login through Gmail </h6>
                                    <p>We allow you to login though Gmail before or after you register with us. If you provide your third-party account credentials to us, you understand some content and/or information in those accounts ("Third Party Account Information") may be transmitted into your account with us, and that Third Party Account Information transmitted to us is covered by this Privacy Policy. Certain information may be required to register with us or to take advantage of some of our features. By giving us this information, you consent to this information being collected, used, or disclosed and stored by us, as described in our Terms of Use and in this Privacy Policy.</p>
                                </div> 

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>2. Information which we automatically collect</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>When a User registers with the Platform or orders/ accepts any service through the Platform, we may automatically collect certain information that helps facilitate your use of the Platform and helps us stay compliant with our legal obligations. We may also collect certain information from third-parties when you access the Platform of a third party or avail the services of a third party.</p>

                                    <h6 className="m-b-10">2.1. Non-Personal Information</h6>
                                    <p>We may additionally collect and track Non-Personal Information about your visit to the Platform including the domain name and the name of the web page from which you access the Platform, the time you spend on each of our web pages, the Internet Protocol (IP) address associated with your computer, mobile application, operating system, transaction history, web pages you viewed, information searched for or requested for and other relevant statistics.</p>
                                    <p>Your messages can be stored to process it, to compile statistical information, to improve our Platform and support, or to get in touch with you.</p>

                                    <h6 className="m-b-10">  2.2. Location Information</h6>
                                    <p>When you use certain features on our Platform, we may collect information about your precise or approximate location through third party Platform(s) to offer you an improved user experience. Mobile devices allow you to control or disable the use of location services. Disabling such services may result in limited access to certain features on our Platform.</p>

                                    <h6 className="m-b-10"> 2.3. Billing, Collection and Payment Information</h6>
                                    <p>When you make/ accept a payment through the Platform, your payment information may be processed by a third-party payment service provider. With your authorization, they may collect provided payment information necessary to process your payment. The associated partner may use and store information it collects through its payment service provider, which is governed by its applicable terms of service and privacy policy. We may receive certain information from the associated payment processor which will help us facilitate any payments and your use of the Platform.</p>

                                </div> 

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>How we use the Information</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>We use the personal information you provided to us along with automatically collected information we receive to facilitate and optimize your use of the Platform. This conducted by us through, but not limited to customization, Platform development, maintaining a safe environment and compliance to our legal obligation. </p>

                                    <h6 className="m-b-10">1. Facilitate your use of the Platform</h6>
                                    <p>We use the information we collect about you to establish your account and use the Platform; provide customized and analytical content to you including relevant key performance indicators based on other users usage of the Platform and such end user’s behavioral insights; communicate with you through email and phone, send you push notification and emails to inform you about our new products, features services and offers and take action regarding unlawful or harmful activities. You may opt out of such notification or promotional emails anytime by intimating us of your preferences.</p>

                                    <h6 className="m-b-10">2. Promote and Customize the Platform</h6>
                                    <p>We may use your email address or phone number (if provided) to contact you or send push notification to you regarding any changes to the Platform; system maintenance and outage issues; account issues; or otherwise to troubleshoot problems. We may also send you information about related services and selected third party information.</p>
                                    <p>Further, we use the data collected to create accurate customer profile based on your interests and behavior on the Platform offer you products and services that the User may be interested in.</p>

                                    <h6 className="m-b-10">3. Creating a Safer Platform</h6>
                                    <p>We may limit or restrict your use of our Platform and its associated features in response to possible signs of abuse. We may remove content, under our own discretion, that may be illegal or we may deem as inappropriate.</p>

                                    <h6 className="m-b-10">4. Disclaimer</h6>
                                    <p>We do not sell, rent or trade your personal information to third parties for marketing purposes. The user hereby agrees and understands that this obligation does not include passing on your information to trusted third parties who assists us in operating our Platform, conducting our business, but we ensure that these parties agree to keep this information confidential. Non-personal identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
                                </div> 

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>Disclosure of your Information</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>We may disclose your information to third parties; as stated below, which helps us facilitate your use of the Platform and helps us comply with our legal obligations. By using this Platform, you hereby give us the permission to disclose your information as per the terms of this policy. We will never sell your personal information, or pass it to third parties for marketing purposes without your permission.</p>

                                    <h6 className="m-b-10">1. Business Transactions</h6>
                                    <p>We may disclose your personal information:</p>

                                    <ul className="policy-list">
                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">If we are approached by a potential buyer of our business; </span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">If we are required to do so by law or in good-faith believing such action is necessary to comply with the law.</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">In case of a sale of assets, a bankruptcy, or an insolvency event, then we may sell, transfer or share some or all of our assets, including your information to another party.</span>
                                        </li>

                                    </ul>

                                    <p>In such events, we will notify you before your personal information is transferred and/or becomes subject to a different privacy policy.</p>

                                    <h6 className="m-b-10">2. Legal Obligations</h6>
                                    <p>We will disclose your Information, as collected subject to the terms of this policy, where we are required to do so by law, under a subpoena, or if we believe that such action is necessary to comply with the law. We may also disclose personal information to protect the security or integrity of our service on reasonable requests from law enforcement agency.</p>

                                    <h6 className="m-b-10">3. Data Processor</h6>
                                    <p>We may disclose your personal information with associated third parties such as our subcontractors or our service providers who act on our behalf. We may also use a third-party data processor to process your information on our behalf. They may be identified as follows: </p>

                                    <h6 className="m-b-10">3.1. Service Providers</h6>
                                    <p>We, in the course of the business, employ third party companies and/or individuals to facilitate the use of our Platform, to operate our Platform on our behalf, to perform services which are related to our Platform, or to assist us in analyzing the usage of our Platform including, but not limited to our IT service and infrastructure provider engaged for development of the Platform. Where necessary, these third parties may be provided with access to your personal information (‘Shared Information’)for the sole purpose of performing these tasks, as mentioned, on our behalf and are obligated not to disclose or use it for any other purpose. Upon request, the Company agrees to furnish information about the Shared Information and its use by such third parties. </p>

                                    <h6 className="m-b-10">3.2. Communications</h6>
                                    <p>We may use your personal information to contact or inform you with newsletters, promotional materials and/or other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by disabling notification or following the unsubscribe link or instructions provided in any of the emails we have sent.</p>

                                    <h6 className="m-b-10">3.3. Payment Service Provider</h6>
                                    <p>To facilitate the payment process, we may provide a third-party payment provider with relevant personal information to processes all payments you may make on our Platform.</p>

                                    <h6 className="m-b-10">3.4. Analytics Information</h6>
                                    <p>In addition, we collect, monitor and analyze information in order to increase our Platform's functionality and we may use third party services, including but not limited to, Google Analytics. Please go through these third-party service provider’s privacy policies which addresses how they use such information.</p>

                                    <h6 className="m-b-10">3.5. Public Profile</h6>
                                    <p>The profile you create on our Site will be publicly accessible unless otherwise indicated. This means it will be visible to the entire Internet, including clients, freelancers, visitors, and search engines such as Google and Bing, and may appear on Upwork partners' sites. You may change the privacy settings of your profile through your account portal.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>Other Important Information</h5>
                                </div>

                                <div className="card-body p-b-0">

                                    <h6 className="m-b-10">1. Retention Period</h6>
                                    <p>The information collected namely personal information, log data, analytical information, location information and other information as stated in this policy will be retained by Company for the period wherein the data is essential for achieving the purpose of data processing.</p>
                                    <p>In addition to the above, Company will also retain any and all of your information as necessary to comply with legal obligations, resolve disputes and/or enforce agreements.</p>

                                    <h6 className="m-b-10">2. Links to other Website/ Application</h6>
                                    <p>This privacy policy applies to our Platform. The Platform may contain links to other website/ application over which we have no control over and thus not responsible for. These website/ application(s) may have their own policies regarding privacy which may be different than ours. We are not responsible for the privacy policies or practices of other website or Platforms which you access from this Platform. If you choose to visit an individual, a business, or advertiser’s website/ mobile application by “clicking on,” or subsequently redirected to a third party link located on our Platform, you will be directed to that third party’s website /application and subject to their terms & agreement and privacy policy. When we link a website/ application or present an advertisement, it is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of any such third party’s privacy security policies or practices.</p>

                                    <h6 className="m-b-10">3. Access/Accuracy</h6>
                                    <p>We aim to maintain accuracy of the personal information which you provide us at all times and if you seek to update or correct such Information for any reason, please update this directly yourself or send updates/corrections to us at contactus@syncuppro.com. Company aims to take all reasonable efforts to incorporate such changes in your Personal Information within a reasonable period of time.</p>

                                    <h6 className="m-b-10">4. Children's Privacy</h6>
                                    <p>Protecting the privacy of every minor is equally important to us. The Platform are not structured to attract minor children and you hereby acknowledge that you are above the age of sixteen (16) years while accessing the Platform</p>

                                    <h6 className="m-b-10">5. Security of Information</h6>
                                    <p>The security of your information is important to Company, and Company shall take all reasonable measures to protect the security of such information. However, there is always some risk that an unauthorized third party may access the Company systems or that transmissions of your information over the internet may be intercepted, accessed, used, or released in a manner that is inconsistent with this policy. Company expressly disclaim any representation or warranty, whether express or implied, with respect to ensuring, guaranteeing, or otherwise offering any definitive promise of security in connection with your information, which may be intercepted by third parties. </p>

                                </div> 

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>Data Subject’s Rights</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>In accordance with applicable law, the User may have the right to require information about your data being processed and correction of any incorrect data. Such a User may also have the right to lodge a complaint with a supervisory authority, right of Data Erasure, Data Portability and Right to Access in accordance with applicable law. The information, within the meaning of this clause, shall be provided free of charge unless the request is ‘manifestly unfounded or excessive”.</p>
                                </div>

                            </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>Updates to Privacy Policy</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>We reserve the right to modify this privacy policy at any time. All changes made to this privacy policy will be posted on our Platform, as a blog post, by email, or through any method we determine. The most recent privacy policy will be posted first and will be identified at the top of this privacy policy page by its effective date. If you disagree with the privacy policy, you must stop using the Platform.</p>
                                </div>
                            </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>Contact Us</h5>
                                </div>
                                <div className="card-body p-b-0">
                                    <p>If you have any questions about this Privacy Policy or for exercising the right of Data Erasure, Data Portability and Right to Access subject to the provisions of applicable law. please feel free to contact us at contactus@syncuppro.com.</p>
                                </div>

                            </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>Cookie Policy</h5>
                                </div>

                                <div className="card-body p-b-0">

                                    <h6 className="m-b-10">1. Cookie Policy</h6>
                                    <p>This Platform uses cookies to enhance the user’s experience on our Platform. Where applicable, this Platform uses a cookie control system allowing the user to choose whether to allow the use of cookies on their computer / device on their first visit. This policy complies with recent legislation requirements for Platforms to obtain explicit consent from users before leaving behind or reading files such as cookies on a user’s computer / device.</p>
                                    <p>This Cookie Policy explains what cookies are and how we use them, the types of cookies we use i.e, the information we collect using cookies and how that information is used, and how to control the cookie preferences. For further information on how we use, store, and keep your personal data secure, see our Privacy Policy. You can at any time change or withdraw your consent from the Cookie Declaration on our Platform.</p>

                                    <h6 className="m-b-10">What are cookies?</h6>
                                    <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
                                    <p>The cookies in use can be categorized as follows:</p>

                                    <h6 className="m-b-10">1.1. Analytical cookies</h6>
                                    <p>Analytical cookies anonymously remember your computer or mobile device when you visit our website. They keep track of browsing patterns and build up a profile of how our readers use the website. We might use the given information to display relevant advertisements to you on our websites.</p>

                                    <h6 className="m-b-10">1.2. Product cookies</h6>
                                    <p>Product cookies help us to make our website work as efficiently as possible such as remembering your registration and login details, remembering your settings preferences, and metering the number of pages you are viewing for the purpose of administering subscriptions.</p>

                                    <h6 className="m-b-10">1.3. Third party cookies</h6>
                                    <p>Third party cookies such as advertising and analytics cookies are placed by or on behalf of independent advertisers who are advertising on our website. These cookies may be placed within the advertisement or elsewhere on our site. They are anonymous cookies and hence cannot identify individuals. These cookies are used for statistical analysis and allow the advertiser to ascertain the number of people who have seen their advertisement or have seen it more than once. These cookies also help the advertisers to tailor advertising to you when you visit other websites. The user hereby agrees and understands that we have no access to such cookies. These third parties’ privacy policy applies on such cookies.</p>

                                    <h6 className="m-b-10">2. How do we use cookies?</h6>
                                    <p>As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data. The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p>

                                    <h6 className="m-b-10">3. How can I control the cookie preferences?</h6>
                                    <p>Should you decide to change your preferences later through your browsing session, you can click on the “Privacy & Cookie Policy” tab on your screen. This will display the consent notice again enabling you to change your preferences or withdraw your consent entirely. </p>

                                    <h6 className="m-b-10">4. Disabling Cookies</h6>
                                    <p>If you would like to restrict the use of cookies, you can control this function in your internet browser. Links to steps on how to do this for the most popular Internet browsers are provided below for your convenience. These links may be updated or moved from time to time. We are not liable for any information provided on these third-party websites.</p>

                                    <h6 className="m-b-10">4.1. Internet Explorer</h6>
                                    <p>Link:<a className="text-green" href="http://windows.microsoft.com/en-GB/windows7/Block-enable-or-allow-cookies"> http://windows.microsoft.com/en-GB/windows7/Block-enable-or-allow-cookies </a></p>

                                    <h6 className="m-b-10">4.2. Google Chrome</h6>
                                    <p>Link:<a className="text-green" href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647&amp;p=cpn_cookies"> https://support.google.com/chrome/bin/answer.py?hl=en-GB&amp;answer=95647&amp;p=cpn_cookies </a></p>

                                    <h6 className="m-b-10">4.3. Mozilla Firefox</h6>
                                    <p>Link:<a className="text-green" href="http://support.mozilla.org/en-US/kb/Blocking%20cookies ">http://support.mozilla.org/en-US/kb/Blocking%20cookies </a></p>

                                    <h6 className="m-b-10">4.4. Apple Safari</h6>
                                    <p>Link:<a className="text-green" href="http://docs.info.apple.com/article.html?artnum=32467"> http://docs.info.apple.com/article.html?artnum=32467 </a></p>
                                </div>

                            </div>

                        </div> 
    </section>



                </div>
      </PageWrapper>
    </>
  );
};
