import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { FlagUser } from "../../utils/apiCalls";
import { GetFlagReasons  } from "../../utils/GetAPIUtils";
import { Select } from "../../components/Core";
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalFlagReason = (props) => {

    const gContext = useContext(GlobalContext);
    const [reasonId, setReasonId] = useState(null);
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [reasonData, setReasonData] = useState([]);

    async function getUtils() { 
        const response = await GetFlagReasons(); 
     
        if (response.data && response.data.flagReasons) {  
             
            let list = [];
            response.data.flagReasons.filter(item => item["usertypeid"] ==1).map((value) => (
                list.push({  value: value.id, label: value.reason })));
            setReasonData(list);
        } 
    }

    function toggleModal() {   
        setReasonId(null);
        setError("");
        props.submitflag(null);
        props.togglemodal(); 
    }
 
    function submitFlag(e) { 
        if (reasonId) {
            props.submitflag(reasonId.value);
            setReasonId(null);
            props.togglemodal();
        }
        else {
            setError("error");
        } 
    }
    const handleChange = (e) => {
        setReasonId(e);
        setError("");
    }
    useEffect(() => {
        getUtils();
    }, []); 

 
    return (
        <ModalStyled
            {...props}
            size="lg"
            backdrop="static"
            centered  
            show={props.modalvisible}
            onHide={toggleModal}
        >
            <div className="modal-content"   >


                <div className="modal-header">
                    <h4 className="modal-title">Reject Offer</h4>
                    <button type="button" className="btn-close" onClick={toggleModal}></button>
                </div>


                <div className="modal-body" >
                    <div className="terminated-contract-block">
                    <div className="row"> 
                        

                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ height: "50vh", overflow: "visible" }}>
                                <div className="forn-group-input" >
                                <label for="job title" className="form-label m-b-10">Select reject reason <span className="text-red"> * </span></label>
                                <Select
                                    options={reasonData} value={reasonId}
                                    className={`form-control padding-select   ${(error !== "") ? ' error' : ''}`}

                                    border={false} onChange={(e) => handleChange(e)}
                                />{error !== "" && (
                                    <span className="text-red">  Reason is required </span>
                                )}
                            </div>
                        </div>

                        </div>

                    </div>

                     

                     

                    <div className="panel-action justify-content-end m-b-20">
                        <button className="btn btn-sm btn-orange bg-green m-r-20 text-white" onClick={(e) => {
                            submitFlag(e);

                        }}>Submit</button>
                        <button type="button" className="btn btn-sm btn-dark-outline text-black d-flex align-item-center" onClick={() => {
                            toggleModal();
                        }}>Cancel</button>
                    </div>

                     
                   


                </div>



            </div>



            <Modal.Body className="p-0 d-none">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                    onClick={toggleModal}
                >
                    <i className="fas fa-times"></i>
                </button>
               
                    <div className="row no-gutters w-100">

                    <div className="col-md-12 col-12 w-100 px-5 py-5">
                           
                            <div className=" align-items-center px-5 py-5">
                                    <div className="font-size-4   font-weight-semibold  line-height-2 pb-5">
                                      Select reject reason
                                    </div>
                            <div><Select
                                options={reasonData} value={reasonId}
                                className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(error !== "") ? 'form-control-error' : ''}`}

                                border={false} onChange={(e) => handleChange(e)} 
                            />{error !== "" && (
                                    <span className="error font-size-3"> Flag reason is required </span>
                            )}</div>
                            <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">
                                <button type="button" 
                                    className=" btn-green   font-size-3 font-weight-bold rounded-5 border-0 text-uppercase"     onClick={(e) => {
                                        submitFlag(e);

                                    }}>
                                    Submit
                                          </button><button onClick={() => toggleModal()} type="button" className="btn-green  border-0  font-size-3 font-weight-bold ml-2   rounded-5 text-uppercase"   >
                                            Cancel
                                          </button></div>
                                </div>
                           
                        </div>


                    </div>
               
            </Modal.Body>
        </ModalStyled>
    );
};

export default ModalFlagReason;
