import React, { useState, useContext, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext";
import { MessageSummary } from "../../utils/apiCalls"; 
import { useLocation, useNavigate } from 'react-router-dom'; 

const Sidebar = (props) => { 
    const gContext = useContext(GlobalContext);
     const navigate = useNavigate();
    const [fromId, setfromId] = useState(null);
    const pathName = useLocation().pathname.toLowerCase().trim();
    const [openProfile, setOpenProfile] = useState(pathName == "/profile"); 
    const [openMessage, setOpenMessage] = useState(pathName  == "/messages"); 
    const [messageSummary, setMessageSummary] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [clientPreviewUrl, setClientPreviewUrl] = useState("/ExpertClientPreview");
    const [previewUrl, setPreviewUrl] = useState("/expertPublicPreview");
    const [profileInfo, setProfileData] = React.useState('data');
    
    
   
    /* To bind on load */
    useEffect(() => {
        gContext.setVisibleProfileMessage(false);

    }, [gContext.currentPage]); 
    

    useEffect(() => { 
    }, [gContext.msgCount]);

    useEffect(() => {
        
        setProfileData(gContext.userInfo.profileInfo); 
        if (pathName.toLowerCase().trim() == "/dashboard") {
            if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) {
                gContext.setVisibleProfileMessage(false);
                setOpenProfile(false); gContext.setCurrentPage("17");
            } else { gContext.setVisibleProfileMessage(false); setOpenProfile(true); gContext.setCurrentPage("5");}
        }
    }, [gContext.userInfo]);

    return (
        <>
            {gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment && <li className="nav-item">
                <a className={`nav-link  pointer ${(gContext.currentPage === "17") ? 'active' : ''}`} style={{ cursor: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) ? '' : 'not-allowed' }}
                    onClick={(e) => {
                        e.preventDefault();
                        if ( gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) {
                            if (!gContext.pageLoading) {
                                gContext.setPageLoading(true);
                                gContext.setVisibleProfileMessage(false);
                                setOpenProfile(false); gContext.setCurrentPage("17");
                                navigate("/dashboard", { replace: true });
                                props.setShowCanvas();
                            }
                        }
                    }}>Dashboard</a>
                <a className={`nav-link  pointer  d-none   ${(gContext.currentPage === "17") ? 'active' : ''}`} style={{ cursor: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) ? '' : 'not-allowed' }}
                    href="/dashboard" >Dashboard</a></li>}
            <li className="nav-item dropdown">
                <a className={`nav-link dropdown-toggle d-flex align-item-center pointer ${(gContext.currentPage === "5") ? 'active' : ''}`}
                   
                    data-bs-toggle="dropdown" aria-expanded="false">Profile<span className="m-l-10 m-icon material-icons">expand_more</span></a>

                <ul className={`dropdown-menu  profile-list ${(gContext.currentPage === "5") ? 'hide' : ''}`}>
                    <li><a className={`dropdown-item pointer  `} onClick={(e) => { e.preventDefault(); if (!gContext.pageLoading) { gContext.setPageLoading(true); gContext.setVisibleProfileMessage(false); setOpenProfile(!openProfile); gContext.setCurrentPage("5"); navigate("/profile", { replace: true }); props.setShowCanvas(); } }}>Profile</a></li>
                    {gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment && <>  <li><a className={`dropdown-item pointer  `} onClick={(e) => { e.preventDefault(); props.setShowCanvas(); window.location.href = clientPreviewUrl; }}>Client View</a></li>
                        <li><a className="dropdown-item pointer" onClick={(e) => { e.preventDefault(); props.setShowCanvas(); window.location.href=previewUrl; }}>Public View</a></li></>}
                </ul>
            </li>
            <li className="nav-item  d-none">
                <a className={`nav-link  pointer ${(gContext.currentPage === "assessment") ? 'active' : ''}`} style={{ cursor: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 ) ? '' : 'not-allowed' }}
                    onClick={(e) => {
                        e.preventDefault();
                        gContext.setPageLoading(false);
                        if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) { gContext.setCurrentPage("assessment"); gContext.setVisibleProfileMessage(false); navigate("/profile#assessment", { replace: true }); props.setShowCanvas();}
                    }}>Assessment  </a> </li>

           
            <li className="nav-item"><a className={`nav-link d-flex pointer  ${(gContext.currentPage === "message") ? 'active' : ''}`}
                onClick={(e) => { e.preventDefault(); gContext.setPageLoading(false); setOpenMessage(!openMessage); gContext.setCurrentPage("message"); setShowMessage(true); navigate("/messages", { replace: true }); props.setShowCanvas(); }}>Messages
          {gContext.msgCount > 0 && <span className="count-msg  m-l-5">
                    {gContext.msgCount}
                </span>}
            </a></li>

          
        </>
        
    );
};

export default Sidebar;
