import React, { useState, useEffect, useContext} from "react";
import { Button } from "react-bootstrap";
import { Select } from "../../components/Core";
import { getCountry } from './../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify'; 
import GlobalContext from "../../context/GlobalContext";
import Icon from '@mui/material/Icon';
import { Helmet } from "react-helmet";
const imgP = "assets/image/patterns/globe-pattern.png";
const imgH = "assets/image/l3/png/hero-image-1.png"; 
 

const Hero = () => {
    const gContext = useContext(GlobalContext);
    const [toggleSearchMenu, setToggleSearchMenu] = React.useState(false);
    const [searchMode, setSearchMode] = React.useState('expert');
    const [countriesList, setCountriesList] = useState([{}]);
    const [values, setValues] = React.useState({  keyword: '' });
    useEffect(() => {

        getCountries();

    }, []);
    const handleChange = (e, name) => {

        const value = e;
        switch (name) {
            case "country":
                setValues({ ...values, ["country"]: value.value, ["countryId"]: value });
                break;
            case "keyword":
                var regex = new RegExp('[^0-9a-zA-Z-,_.\:\s ]+');

                if (!regex.test(e.target.value)) {
                    setValues({ ...values, ["keyword"]: e.target.value });                   
                }                
                break;
            default:
                break;
        }
    }
 
    function navigateSearch() {
        
        localStorage.setItem('jobSearch', JSON.stringify(values));
        if (searchMode == 'job') {
            window.location.href = "/searchjob";
             
        }
        else
            window.location.href = "/searchexpert";

    }
    async function getCountries() {
        const result = await API.graphql(graphqlOperation(getCountry));
        if (result.data && result.data.countries) {
            setCountriesList(result.data.countries)
                       
            let countlist = [];
            result.data.countries.map((value) => (
                countlist.push({ code:value.code,value: value.id, label: value.name })));
            setCountriesList(countlist);
            gContext.setCountriesList(countlist);
           
        } 
    }
    function toggleSearch() {
        let current = toggleSearchMenu;
        setToggleSearchMenu(!current);

    }
    function toggleSearchMode(mode)
    {
        setSearchMode(mode);
        toggleSearch();
        document.getElementById("keyword").focus();

    }
    function showSearchOption()
    {
        setToggleSearchMenu(true);
       
    }
    const handleKeyDown = (e) => {
        if (e.charCode === 13) {
           
            navigateSearch();
        }
    };
    return (
        <>
            <Helmet>
                <title>Auditing Quality Management Systems | Syncuppro</title>
                <meta name="description" content="Ensure compliance and enhance efficiency with our auditing services for quality management systems. Identify risks and achieve continuous improvement." />
                <link rel="canonical" href={window.location.origin} />
            </Helmet>

            <div className="page-wrapper">




                <section className="section welcome-sec bg-gray p-relative gradient-img ">
                    <div className="left-sec">
                        <div className="section-title">
                            
                            <h1>Hire the <span className="text-theme">Ideal Compliance Expert</span> for your unique needs</h1>
                            <p className="section-desc">Supercharge your compliance journey with the industry's premier experts by your side.</p>
                        </div>
                         

                        <div className="search-group ">
                            <div className="search-info p-relative  ">
                                <a className="btn btn-link d-flex align-item-center  " style={{ cursor:"default"} }>
                                    <Icon className="text-theme m-icon material-icons">{(searchMode == 'job') ? "work" : "person"}</Icon><span className="text-black">{(searchMode == 'job') ? "Jobs" : "Experts"}</span><Icon className="text-black m-l-10 m-r-0 m-icon material-icons d-none">expand_more</Icon>
                                </a>
                                <ul className="dropdown-menu job-list">
                                    <li><a className="dropdown-item d-flex align-item-center" href="#" onClick={() => (toggleSearchMode('job'))}><Icon className="f-18 text-theme m-icon material-icons">work</Icon>Jobs</a></li>
                                    <li><a className="dropdown-item d-flex align-item-center" href="#" onClick={() => (toggleSearchMode('expert'))}><Icon className="f-18 text-theme m-icon material-icons">person</Icon>Experts</a></li>
                                </ul>
                                <div className="divider"></div>
                                <input type="text" className="form-control form-control-lg  d-none d-lg-block d-xl-block d-md-block " placeholder={(searchMode == 'job') ? "Compliance Standard i.e.'ISO 9001 Consultant'" : " Compliance Standard i.e.'ISO 9001 Consultant'"} onKeyPress={(e) => handleKeyDown(e)} onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')}/>

                                <input type="text" className="form-control form-control-lg  d-block d-sm-none " placeholder={(searchMode == 'job') ? "ISO 9001 Consultant" : "ISO 9001 Consultant"} onKeyPress={(e) => handleKeyDown(e)} onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} /><button onClick={() => {
                                    navigateSearch();

                                }} className="btn btn-orange search-btn"><Icon className="text-white m-icon material-icons">search</Icon><span className="text-none">Search</span></button>
          </div>
                            </div>

                        </div> 

      <div className="right-sec">
                            <div className="img-wrapper">
                                <img className="img-block" src="images/office-staff.svg" alt="office-staff-image"/>
        </div>
                            </div> 
    </section> 

    

                                    

                

     

   


            </div>


            <div className="bg-squeeze pt-26 pt-md-26 pt-lg-26 pt-xl-26 position-relative z-index-1 overflow-hidden d-none">
                {/* <!-- .Hero pattern --> */}
                <div className="pos-abs-tr w-50 z-index-n2 h-100">
                    <img src={imgP} alt="" className="gr-opacity-1" />
                </div>
                {/* <!-- ./Hero pattern --> */}
                <div className="container">
                    <div className="row position-relative align-items-center">
                        <div
                            className="col-xxl-6 col-xl-7 col-lg-8 col-md-12 pt-lg-13 pb-lg-33 pb-xl-34 pb-md-33 pb-10"
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="500"
                        >
                            <h2 className="font-size-10 mb-12 pr-md-30 pr-lg-0">
                                Hire the Best Compliance Expert for your needs
              </h2>
                            <p className="font-size-5 w-75">
                                Ensure compliance success with the industry's top expert by your side.
                  </p>
                            <div className="">
                                {/* <!-- .search-form --> */}
                                <form

                                    className="search-form"
                                    data-aos="fade-up"
                                    data-aos-duration="800"
                                    data-aos-delay="500"
                                >
                                    <div className="filter-search-form-2 bg-white rounded-sm shadow-7 py-1 pl-6">
                                        <div className="filter-inputs position-relative" onMouseLeave={toggleSearch}>
                                            <div className=" " onMouseOver={showSearchOption} >

                                                <input
                                                    className="form-control focus-reset pl-5 "
                                                    type="text"
                                                    id="keyword"
                                                    name="keyword" defaultValue={values.keyword} Value={values.keyword}
                                                    placeholder={(searchMode == 'job') ? "Compliance Standard i.e.'ISO 9001 Consultant'" : " Six Sigma Specialist"} onKeyPress={(e) => handleKeyDown(e)} onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')}
                                                />
                                                {toggleSearchMenu === true && <div className="focus-reset mt-12  w-100 pos-abs-tl   z-index-supper bg-white ">

                                                    <a className="dropdown-item  font-size-4 font-weight-semibold line-height-1p2 text-gray " onClick={() => (toggleSearchMode('job'))}>
                                                        <i className="fa fa-briefcase text-green px-3"></i>Jobs<br />
                                                        <span className="font-size-2 px-10" > "ISO 27001"</span>
                                                    </a>


                                                    <a className="dropdown-item  font-size-4 font-weight-semibold line-height-1p2 text-gray " onClick={() => (toggleSearchMode('expert'))}>
                                                        <i className="fa fa-user text-green px-3"></i>Experts<br />
                                                        <span className="font-size-2 px-10" > "Six Sigma Specialist"</span>
                                                    </a>

                                                </div>}

                                                {/*    <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6 pointer d-none" style={{ cursor: 'pointer' }} onClick={toggleSearch} data-toggle="tooltip" data-placement="right" title="Click here to change search option.">
                                                    <i className="icon icon-zoom-2 text-primary font-weight-bold pointer"></i>
                                                 
                                                </span> */}




                                            </div>

                                            {/* <!-- .select-city starts --> */}
                                            <div className="form-group position-relative d-none">
                                                <Select name="country" options={countriesList} placeholder="Select..." value={values.countryId}
                                                    options={countriesList}
                                                    className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100"
                                                    border={false}
                                                    onChange={(e) => handleChange(e, 'country')}
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>


                                        </div>
                                        <div className="button-block pt-5 justify-content-end ">
                                            <Button
                                                type="button"
                                                className="line-height-reset btn btn-submit btn-small   px-3 mx-auto "
                                                style={{ minWidth: '100px', height: '40px' }}
                                                onClick={() => {
                                                    navigateSearch();

                                                }}>

                                                <i className="icon icon-zoom-2 text-white font-weight-bold pointer font-size-5 pr-3"></i> {(searchMode == 'job') ? "Jobs" : "Experts"}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                                {/* <!-- ./search-form --> */}

                            </div>
                        </div>
                        {/* <!-- Hero Right Image --> */}
                        <div
                            className="col-lg-6 col-md-4 col-sm-6 col-xs-6 col-8 pos-abs-tr z-index-n1 position-static position-md-absolute mx-auto ml-md-auto"
                            data-aos="fade-left"
                            data-aos-duration="1000"
                            data-aos-delay="500"
                        >
                            <div className=" ml-xxl-23 ml-xl-12 ml-md-7">
                                <img src={imgH} alt="" className="w-100" />
                            </div>
                        </div>
                        {/* <!-- ./Hero Right Image --> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
