import React, { useState, useContext, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext";
import { MessageSummary } from "../../utils/apiCalls";
import UserMessage from "../../sections/Message/Message";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag"; 

const Sidebar = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL; 
    const gContext = useContext(GlobalContext);
     const navigate = useNavigate();
    const [fromId, setfromId] = useState(null);
    const [currentSender, setCurrentSender] = useState(null);
    const pathName = useLocation().pathname.toLowerCase().trim();
    const [openProfile, setOpenProfile] = useState(pathName == "/profile"); 
    const [openMessage, setOpenMessage] = useState(pathName  == "/messages"); 
    const [selectedIndex, setSelectedIndex] = useState(0);  
    const [messageSummary, setMessageSummary] = useState([]);
    const [showMessage, setShowMessage] = useState(false); 
    const [profileInfo, setProfileData] = React.useState('data');
    const [showSideMenu, setShowSideMenu] = useState(false);
   
    /* To bind on load */
    useEffect(() => {
        gContext.setVisibleProfileMessage(false);
    }, [gContext.currentPage]);

    async function BindMessageSummary() {
        try {
            if (gContext.userInfo.userID) {
                const result = await MessageSummary(gContext.userInfo.userID, gContext.userInfo.userType);
                
                if (result.data.messageSummary.responseData.code == "200") {

                    let data = JSON.parse(result.data.messageSummary.responseData.message);
                   
                    if (data.length > 0) {
                        
                        setMessageSummary(data);
                        setfromId(data[0].userid);
                        setCurrentSender(data[0]);
                    }
                    else
                        setMessageSummary([]);

                    if (pathName.toLowerCase() == "/messages") {
                        setOpenMessage(true);
                        gContext.setCurrentPage("message");
                        setShowMessage(true);
                    }
                }
            }
        }
        catch (e) {
            console.log("error",e);
        }
    } 
     

    useEffect(() => {
        if (pathName.toLowerCase().trim() == "/messages" && gContext.userInfo.userID && gContext.userInfo.userType) {
            setProfileData(gContext.userInfo.profileInfo);
            BindMessageSummary();
        }
        if (pathName.toLowerCase().trim() == "/dashboard") {
            if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) {
                gContext.setVisibleProfileMessage(false);
                setOpenProfile(false); gContext.setCurrentPage("17");
            } else { gContext.setVisibleProfileMessage(false); setOpenProfile(true); gContext.setCurrentPage("5");}
        }
    }, [gContext.userInfo, gContext.msgCount]);

    return (
        <>


            <span className="screen-darken"></span>

            <div className="page-wrapper">
                <div className="message-wrapper-block">

                    {(!messageSummary || (messageSummary && messageSummary.length == 0))  && gContext.currentPage === "message" && <div style={{ height: "90vh" }} className="d-flex  f-18  align-item-center justify-content-center w-full ">
                        <span className="text-light-gray m-icon material-icons m-r-10">chat</span>
                        <span> </span>
                    </div>}
                    {((messageSummary && messageSummary.length > 0)) &&
                        <div className={`user-list  ${showSideMenu ? "show" : "hide"}`} id="user-list-menu">


                            <div className="search-info d-none">
                                <input className="form-control" type="text" placeholder="Search contact" />
                                <span className="text-theme input-m-icon material-icons">search</span>
                            </div>

                            <div className="contacts-block-wrapper">

                                <div className="contacts-block m-0 display-none">

                                    <span className="title-sm">Favorites</span>

                                    <ul className="contact-list">
                                        <li>
                                            <a href="#">
                                                <span className="chat-time">12:20</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/ray tanaka.svg" alt="ray tanaka" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Ray Tanaka</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">

                                                <span className="chat-time">02:20</span>
                                                <span className="chat-count">6</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/hillary reyes.svg" alt="hillary reyes" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Hillary Reyes</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">

                                                <span className="chat-time">02:20</span>
                                                <span className="chat-count">6</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/hillary reyes.svg" alt="hillary reyes" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Hillary Reyes</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">

                                                <span className="chat-time">02:20</span>
                                                <span className="chat-count">6</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/hillary reyes.svg" alt="hillary reyes" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Hillary Reyes</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">

                                                <span className="chat-time">02:20</span>
                                                <span className="chat-count">6</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/hillary reyes.svg" alt="hillary reyes" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Hillary Reyes</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">

                                                <span className="chat-time">02:20</span>
                                                <span className="chat-count">6</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/hillary reyes.svg" alt="hillary reyes" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Hillary Reyes</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="#">

                                                <span className="chat-time">02:20</span>
                                                <span className="chat-count">6</span>

                                                <div className="d-flex align-items-center">
                                                    <div className="contact-img">
                                                        <img src="images/hillary reyes.svg" alt="hillary reyes" />
                                                        <span className="online_icon"></span>
                                                    </div>

                                                    <div className="contact-meta">
                                                        <span className="contact-name one-line">Hillary Reyes</span>
                                                        <div className="info-meta flag">
                                                            <span className="inline-meta m-r-10 one-line">New york, US</span>
                                                            <img src="images/flag.svg" alt="flag" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>

                                    </ul>

                                </div>

                                {(messageSummary && messageSummary.length > 0) && <div className="contacts-block">

                                    <span className="title-sm">Messages</span>

                                    <ul className="contact-list">

                                        {openMessage && <>


                                            {messageSummary.map((data, index) =>


                                                <li key={index}>
                                                    <a href="#" className={selectedIndex == index ? "active":""} onClick={() => {
                                                        setShowSideMenu(false);  setSelectedIndex(index);  setCurrentSender(data); gContext.setCurrentPage("message"); navigate("/messages", { replace: true }); setfromId(data.userid); setShowMessage(true);
                                                    }}>

                                                        
                                                        {(data.count && (data.count.toString() == "0" || data.count >0) ) ? <span className="chat-count  ">{data.count}</span>:""}

                                                        <div className="d-flex align-items-center">
                                                            <div className="contact-img">
                                                                {(data.logo) ? <img src={s3URL + data.logo} alt="" /> : <img src="../images/user-default.png" alt="" />}
                                                                <span className="online_icon"></span>
                                                            </div>

                                                            <div className="contact-meta">
                                                                <span className="contact-name one-line  d-none"> {(data.firstname) ? data.firstname : ''}  {(data.lastname) ? data.lastname : ''}</span>
                                                                <span className="contact-name one-line"> {data.company ? data.company : ""}</span>
                                                                <div className="info-meta flag ">
                                                                    <span className="inline-meta m-r-10 one-line"><span className="text-capi">{data.state}</span>, {data.code}</span>
                                                                    <ReactCountryFlag
                                                                        countryCode={data.code}
                                                                        svg

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </a>
                                                </li>


                                            )}



                                        </>}




                                    </ul>

                                </div>}

                            </div>

                        </div>}

                   
                    {(showMessage && gContext.currentPage === "message") ? <> <UserMessage currentSender={currentSender} showSideMenu={showSideMenu } setShowSideMenu={() => setShowSideMenu(!showSideMenu)} fromUserID={fromId} fromTypeId="2" /> </> : ''}
                </div></div>



           
        </>
        
    );
};

export default Sidebar;
