import React, { useState, useEffect } from "react";
 
export default function Cookies() {
    const [showCookies, setShowCookies] = useState(false);

    useEffect(() => {
        
        setShowCookies(document.cookie.indexOf("accepted_cookies=") < 0);

    }, []);
    const handleCookiesClose = () => {
        setShowCookies(false);
    };
    const handleCookiesAccept = () => {
        document.cookie = "accepted_cookies=yes;";
        setShowCookies(false);
    };
  return (
    <>
          <div  className={` ${(showCookies) ? '' : 'd-none'}     cookie-overlay p-5 d-none`}>
              <div className="d-flex pb-5 ">
                  <div className="mr-3">We use cookies to improve user experience and analyze website traffic. By clicking "Accept", you agree to our website's cookie use as described in our <a target='_blank' href="/policy">'Cookie Policy'</a>.</div>
                  <i className="fas fa-times ml-auto close-cookies" onClick={handleCookiesClose} ></i>
              </div>
              <button className="btn btn-primary  accept-cookies"
              style={{ minWidth: '70px', height: '30px'}}
               onClick={handleCookiesAccept}>Accept</button>
              <button className="btn btn-outline-primary  accept-cookies mx-2" 
              style={{ minWidth: '50px', height: '30px'}}
              onClick={handleCookiesClose}>Close</button>
          </div>


          <div className={` ${(showCookies) ? '' : 'd-none'}     alert cookie-overlay card alert-dismissible`}  >
              <button type="button" className="btn-close" data-bs-dismiss="alert"></button>
              <p className="m-b-10">
                  We use cookies to improve user experience and analyze website traffic. By clicking "Accept", you agree to our website's cookie use as described in our {" "}
                  <a target='_blank' href={process.env.REACT_APP_EXURL + "/policy#cookie"}>Cookie Policy</a>
              </p>
              <div className="d-flex">
                  <button className="btn btn-sm btn-orange m-r-10" onClick={handleCookiesAccept}>Accept</button>
                  <button className="btn  btn-sm btn-dark-outline" onClick={handleCookiesClose}>Close</button>
              </div>

          </div>

    </>
  );
};

