import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { useLocation } from 'react-router-dom'; 
import validator from 'validator';
import Message from "../../components/Message";
import ConfirmMessages from "../../utils/confirmMessages";
import { getEncodedEmail } from "../../utils/helperFn"; 
import { SiteObjectCounts } from "../../utils/GetAPIUtils";
import { signInCall, confirmCode, codeResend, AccountFedLogin, LoginMfaConfirm, LoginMfaResend } from "../../utils/apiCalls"; 
import Icon from '@mui/material/Icon';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';


const ModalStyled = styled(Modal)`
   &.modal {
    
  }
`;

const ModalSignIn = (props) => { 

    const [email, setEmail] = useState('');
    const [showPass, setShowPass] = useState(true);
    const [hideResend, setHideResend] = useState(true);
    const [loginConfirm, setLoginConfirm] = useState(false);    
    const gContext = useContext(GlobalContext);
    const [values, setvalues] = React.useState({ email: '', password: '', error: { email: '', password: '' } });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [MFAconfirmationValues, setMFAConfirmationValues] = React.useState({ code: '', error: '' });
    const [codeConfirm, setCodeConfirm] = React.useState('');
    const [totalExperts, setTotalExperts] = React.useState('');
    const [totalJobs, setTotalJobs] = React.useState('');
    const [showModal, setShowModal] = useState(false);
    const [confirmationValues, setConfirmationValues] = React.useState({ code: '', error: '' });
    const pathName = useLocation().pathname;
    const [loginData, setLoginData] = React.useState(null);
    
    const handleClose = () => {
        setErrorMessage('');
        setCodeConfirm('');
        setLoginConfirm(false);
        setConfirmationValues({ code: '', error: '' });
        setMFAConfirmationValues({ code: '', error: '' });
        setvalues({ email: '', password: '', error: { email: '', password: '' } });
        gContext.toggleSignInModal();
    };
    function toggleModal(data) {

        setShowModal(!showModal);
        gContext.toggleSignInModal();
    }

    const responseFacebook = (response) => {
        
        AccountLogin(response);
        toggleModal(null);
    }
    async function AccountLogin(response) {
        
        try {
            const result = await AccountFedLogin(JSON.stringify(response));
            if (result.data && result.data.loginFed.responseData) {
               
                 
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }
    }
    const togglePassword = () => {
        setShowPass(!showPass);
    };

    const handleConfirmationChange = (e) => {
        setErrorMessage("");
        const { name, value } = e.target;
        setConfirmationValues({ code: value, error: (value.trim() == "") ? "Code is required" : (value.match(/[0-9]$/) ? "" : "Invalid format") });
    }
    const handleMFAConfirmationChange = (e) => {
        setErrorMessage("");
        const { name, value } = e.target;       

        setMFAConfirmationValues({ code: value, error: (value.trim() == "") ? "Code is required" : (value.match(/[0-9]$/) ?"" : "Invalid format") });
    }
    async function verifyEmail() {
     
        try {
            const result = await codeResend(email);

            if (result.data && result.data.registerCodeResend.responseData) {
                 
                if (result.data.registerCodeResend.responseData.code == "200") {
                    localStorage.setItem("userEmail", JSON.stringify({ email: email }));
                    window.location.href = "/signupcode";                    
                }
                else {
                    setErrorMessage(JSON.parse(result.data.registerCodeResend.responseData.message));
                }
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }
    }

    async function resendMFACode() {

        try {
            const result = await LoginMfaResend(values.email);

            if (result.data && result.data.loginMfaResend.responseData) {

                if (result.data.loginMfaResend.responseData.code == "200") {
                    //localStorage.setItem("userEmail", JSON.stringify({ email: email }));
                    //window.location.href = "/signupcode";
                }
                
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }
    }

    async function checkMFAConfirmation() {
        setErrorMessage("");
        if (MFAconfirmationValues.code == "") {
            setMFAConfirmationValues({ ...MFAconfirmationValues, error: "MFA Confirmation code is required" });
            return;
        } else
        {
            if (MFAconfirmationValues.error != "")
                return;
        }
        
        try {

            gContext.setLoaderModalVisible(true);

            const result = await LoginMfaConfirm(loginData.payload["sub"], MFAconfirmationValues.code);

            if (result.data && result.data.loginMfaConfirm.responseData)
            {

                if (result.data.loginMfaConfirm.responseData.code == "200") {                   

                    localStorage.setItem('connect', JSON.stringify(loginData));
                    localStorage.setItem('userProfile', JSON.stringify({ name: values.email.split('@')[0], avatar:   "../images/user-default.png", countryCode: "", countryValue: "", countryLabel: "" }));
                    if (pathName == "/") {
                        gContext.toggleSignInModal();
                        window.location.href = "/Auth";
                    } else if (pathName == "/login" || pathName == "/signupcode" || pathName == "/signupsuccess" || pathName == "/signupcode_client" || pathName == "/signupsuccess_client" || pathName == "/signupcode_expert" || pathName == "/signupsuccess_expert")
                        window.location.href = "/Auth";
                    else
                        window.location.href = pathName;
                }
                else {
                    setErrorMessage(JSON.parse(result.data.loginMfaConfirm.responseData.message));
                }
            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    async function checkConfirmation() {

        setErrorMessage("");

        if (confirmationValues.code == "") {
            setConfirmationValues({ ...confirmationValues, error: "Confirmation code is required" });
            return;
        }
        else {
            if (confirmationValues.error != "")
                return;
        }
         
        try {

            const result = await confirmCode(email, confirmationValues.code.trim());

            if (result.data && result.data.registerConfirm.responseData) {
                 
                if (result.data.registerConfirm.responseData.code == "200") {

                    setCodeConfirm('step3');
                }
                else {
                    setErrorMessage(JSON.parse(result.data.registerConfirm.responseData.message));
                }
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }

    }
    function checkValidity() {
        let isValid = false;
        let { error, ...rest } = values;

        Object.values(rest).forEach(val => {
            if (val === '') {
                isValid = true;
            }
        });

        error.email = validator.isEmpty(rest.email) ? "Email is required" : error.email;

        error.password = validator.isEmpty(rest.password) ? "Password is required" : error.password;

        setvalues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    const handleChange = (e) => {
        setCodeConfirm('');
        const { name, value } = e.target;

        let error = { ...values.error };
        setErrorMessage("");

        switch (name) {

            case "password":
                error.password = validator.isEmpty(value) ? "*" : "";
                break;
            case "email":
                error.email = validator.isEmail(value) ? "" : "Invalid email";
                break;
            default:
                break;
        }

        setvalues({ ...values, error, [name]: value });

    }
    function loginUser() {

        setErrorMessage("");
        if (checkValidity()) {             
           
            gContext.setLoaderModalVisible(true);
            signIn();
            
        }
    } 

    useEffect(() => { 
        setErrorMessage("");
        getUtils();  
    }, []);


    async function getUtils() {
        try {
            const result = await SiteObjectCounts();
            if (result.data && result.data.siteObjectCounts) {
                setTotalExperts(result.data.siteObjectCounts.totalExperts);
                setTotalJobs(result.data.siteObjectCounts.totalJobs);
               
            }
        }
        catch(e)
        {
            console.log(e);
        }
    }  
    async function signIn() {
         
        try {
            const result = await signInCall(values.email, values.password);
             

            if (result.data && result.data.login.responseData) {

                if (result.data.login.responseData.code == "200") {


                    let response = JSON.parse(result.data.login.responseData.message);
                   

                    if (response.payload["custom:usertype"] !== "3") {

                        setLoginConfirm(true);
                        setLoginData(JSON.parse(result.data.login.responseData.message));
                        //localStorage.setItem('connect', JSON.stringify(JSON.parse(result.data.login.responseData.message)));
                        //localStorage.setItem('userProfile', JSON.stringify({ name: values.email.split('@')[0], avatar:   "../images/user-default.png", countryCode: "", countryValue: "", countryLabel: "" }));
                        //if (pathName == "/") {
                        //    gContext.toggleSignInModal();
                        //    window.location.href = "/Auth";
                        //} else if (pathName == "/login" || pathName == "/signupcode" || pathName == "/signupsuccess" || pathName == "/signupcode_client" || pathName == "/signupsuccess_client" || pathName == "/signupcode_expert" || pathName == "/signupsuccess_expert")
                        //    window.location.href = "/Auth";
                        //else
                        //    window.location.href = pathName;
                    } else
                        setErrorMessage("Invalid user.");
                }
                else {
                    if (JSON.parse(result.data.login.responseData.message) == 'UserNotConfirmedException')
                    {                        
                        setEmail(values.email);
                        setCodeConfirm('step1');
                    }
                    else if (JSON.parse(result.data.login.responseData.message) == 'UserNotFoundException')
                    {
                        setErrorMessage("User not found.");
                    }  
                    else if (JSON.parse(result.data.login.responseData.message) == 'NotAuthorizedException')
                    {
                        setErrorMessage("User not authorized.");
                    }  
                    else
                        setErrorMessage(JSON.parse(result.data.login.responseData.message));
                }
            }
        }
        catch (error) {
           
            gContext.setLoaderModalVisible(false);          
            setErrorMessage("Server error");
        }

        
        gContext.setLoaderModalVisible(false);
    }
    function handleLoginKeypress(e) {
        //it triggers by pressing the enter key 
 
        if (e.charCode === 13 || e.key === "Enter") {
            loginUser();
        }
    };

    return (
        <>
           
                 
            <ModalStyled className="modal fade" backdrop="static"
                size="lg"
           centered
            show={gContext.signInModalVisible}
            onHide={gContext.toggleSignInModal}
            > 
                        <div className="modal-body">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose}></button>

                    {(!loginConfirm) && <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="login-info">
                                <h1>Login to your Account</h1>
                                <p className="m-0 f-18">Connect, Engage, and Grow. </p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6">

                            <div className="login-info-text">


                                {errorMessage != "" && <>   <div className="alert alert-danger">
                                    {errorMessage}

                                </div>

                                </>
                                }
                                {codeConfirm === 'step1' && <>
                                    {ConfirmMessages.ResendSignupConfirmation} <br />
                                    <div className="p-t-10"> <button type="button" className="btn btn-orange w-150 m-t-10 m-auto"

                                        onClick={() => { verifyEmail(); }}>
                                        Verify Email
                                    </button></div> 
                                    <br /></>}

                                {(codeConfirm === 'step2') && <>
                                    <span className="verification-mail p-b-30">{ConfirmMessages.SignupStep1Confirmation.replace("[$email]", getEncodedEmail(values.email))}</span>
                                    <form >
                                        {errorMessage != "" && <><div class="alert alert-danger">
                                            {errorMessage}
                                        </div></>}

                                        <div className="form-group icon-input">
                                            <label className="form-label">Confirmation Code<span>*</span></label>
                                            <Icon className="text-green m-icon material-icons "> </Icon>

                                            <input

                                                type="code" maxLength="6"
                                                name="code" required onChange={handleConfirmationChange}
                                                className={`form-control   ${(confirmationValues.error.length > 0) ? 'error' : ''}`}
                                                placeholder="Confirmation Code" onKeyPress={(e) => {
                                                    if (e.charCode === 13 || e.key === "Enter") {
                                                        e.preventDefault();
                                                        checkConfirmation();
                                                    }
                                                }}
                                                id="code "
                                            />
                                            {confirmationValues.error.length > 1 && (
                                                <span className="errortext ">{confirmationValues.error}</span>
                                            )}

                                        </div>
                                    </form>

                                    <button type="button" className="btn btn-green-full"

                                        onClick={(e) => {
                                            e.preventDefault();
                                            checkConfirmation();
                                        }}>
                                        Submit
                                    </button>



                                </>}
                                {(codeConfirm === 'step3') ?

                                    <Message message={ConfirmMessages.SignupStep2Confirmation} /> : ''}

                                {(codeConfirm != 'step2') ?
                                    <>  <form>

                                        <div className="form-group icon-input">
                                            <label for="email" className="form-label">E-mail<span>*</span></label>
                                            <Icon className="text-green m-icon material-icons ">mail</Icon>

                                            <input
                                                type="email"
                                                name="email"
                                                className={`form-control   ${(values.error.email.length > 0) ? 'error' : ''}`}
                                                placeholder="example@gmail.com"
                                                id="email " required onChange={(e) => (handleChange(e))}
                                            />
                                            {values.error.email.length > 1 && (
                                                <span className="errortext">{values.error.email}</span>
                                            )}

                                        </div>
                                        <div className="form-group icon-input">
                                            <label for="password2" className="form-label">Password<span>*</span></label>
                                            <Icon className="text-theme m-icon material-icons">lock</Icon>
                                            <Icon className="text-light-gray m-i-icon material-icons" onClick={(e) => {

                                                e.preventDefault();
                                                togglePassword();
                                            }}> {showPass ? "visibility_off" : "visibility_on"}</Icon>

                                            <input
                                                type={showPass ? "password" : "text"}
                                                className={`form-control   ${(values.error.password.length > 0) ? 'error' : ''}`}
                                                id="password" name="password" onKeyPress={(e) => (handleLoginKeypress(e))}
                                                placeholder="Password" required onChange={(e) => (handleChange(e))}
                                            />
                                            {values.error.password.length > 1 && (
                                                <span className="errortext">{values.error.password !== "valid" && values.error.password}</span>
                                            )}
                                        </div>
                                    </form>

                                        <div className="d-flex align-item-center justify-content-center m-b-20">
                                            <button className="btn btn-link " href="#" onClick={(e) => {
                                                e.preventDefault();
                                                gContext.toggleForgotPasswordModal();
                                                handleClose();
                                            }}>Forgot password?</button>
                                        </div>
                                        <div class="d-grid w-full p-t-10">
                                            <button className="btn btn-green btn-block m-auto w-150" onClick={loginUser}>Login</button> </div></> : ""}

                                <div className={process.env.REACT_APP_SHOW_FEDERATED == "false" ? "or-divider d-none" : "or-divider "}  >
                                    <span className="d-none">Or continue with </span>
                                </div>
                                <div className={process.env.REACT_APP_SHOW_FEDERATED == "false" ? "m-t-20 m-b-20 p-t-20 p-b-20" : "d-none "} >

                                </div>

                                <div className={process.env.REACT_APP_SHOW_FEDERATED == "false" ? "social-icon d-none" : "social-icon "} >
                                    <ul className="list-type d-flex">
                                        <li>
                                            <a className="btn-circle google" href="#"><img src="../images/google-icon.svg" alt="google-icon" /></a>
                                        </li>
                                        <li>
                                            <FacebookLogin
                                                appId="1593837744846369"
                                                autoLoad={false}

                                                render={renderProps => (
                                                    <a onClick={renderProps.onClick} className="btn-circle facebook pointer"  ><img src="../images/facebook-icon.svg" alt="facebook-icon" /></a>


                                                )}
                                            />

                                        </li>
                                        <li>
                                            <a className="btn-circle linked" href="#"><img src="../images/linked-icon.svg" alt="linked-icon" /></a>
                                        </li>
                                    </ul>
                                </div>

                                <span className="text-light-gray d-flex align-item-center justify-content-center m-b-10">Don’t have account?</span>
                                <div class="d-grid w-full">  <button className="btn btn-dark-outline w-150 btn-block m-auto" type="button" onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleSignUpModal();
                                    handleClose();
                                }}>Sign up</button></div>

                            </div>

                        </div>

                    </div>}

                    {(loginConfirm) && <>
                        <div className="row">

                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="login-info">
                                    <h2>Complete your Sign in</h2>
                                     
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 p-10">
                              
                                <h5 style={{ fontWeight: "normal" }} className="  m-b-20 m-t-20 text-center">Enter the MFA verification code we sent to your email</h5>



                                <p class="m-b-10 m-t-15 d-none">{ConfirmMessages.SigninMFAConfirmation.replace("[$email]", getEncodedEmail(values.email))}</p>

                                <form className="m-b-20 m-t-20">
                                    {errorMessage != "" && <><div class="alert alert-danger">
                                        {errorMessage}
                                    </div></>} 
                                   
                                    <div className="form-group m-t-25 ">
                                        <label className="form-label m-t-20">Enter the 5-digit code:<span>*</span></label>
                                        <Icon className="text-green m-icon material-icons "> </Icon>
                                        <input
                                            type="code" maxLength="5"
                                            name="code" required onChange={handleMFAConfirmationChange}
                                            className={`form-control   ${(MFAconfirmationValues.error.length > 0) ? 'error' : ''}`}
                                            placeholder="Confirmation Code" onKeyPress={(e) => {
                                                if (e.charCode === 13 || e.key === "Enter") {
                                                    e.preventDefault();
                                                    checkMFAConfirmation(); } } }
                                            id="code "
                                        />
                                        {MFAconfirmationValues.error.length > 1 && (
                                            <span className="errortext ">{MFAconfirmationValues.error}</span>
                                        )}

                                    </div>


                                </form>
                                {hideResend && < div className="mt-20 m-t-20 m-b-20">If you haven't received the code, click  <a href="#" className="text-green f-16" onClick={(e) => { setHideResend(false); resendMFACode(); }}> here </a> to resend the code.</div>}
                                {!hideResend && < div className="m-20 ">&nbsp;</div>}
                                <button type="button" className="btn btn-green btn-block m-t-25 m-b-25" onClick={(e) => {
                                    e.preventDefault();
                                    checkMFAConfirmation();
                                }}>
                                    Submit
                                </button>

                                <h6 style={{ fontWeight: "normal" }} className="f-16 m-t-20">Note: The code expires after 30 minutes.</h6>

                            </div>
                        </div>
                       
                    </>}

                        </div>

                    
                
            
        </ModalStyled>
       </>
    );
};

export default ModalSignIn;
