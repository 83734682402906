import React from 'react';
import ReactDOM from 'react-dom/client';
import Amplify from 'aws-amplify'
import aws_exports from './aws-exports'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
 
const root = ReactDOM.createRoot(document.getElementById('root'));

if (localStorage.getItem("connect") && localStorage.getItem("connect").toString() !== "Invalid Refresh Token") {
    
    const user = JSON.parse(localStorage.getItem("connect"));
   
    Amplify.configure(aws_exports, {
        API: {
            GraphQL: {
                headers: async () => {
                    return {
                        Authorization: user.accessToken,
                    }
                },
            },
        },
    });
    }
    else
    {
        Amplify.configure(aws_exports);
    }


 

root.render(
   
     
            <App />
         
  
    //</GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
