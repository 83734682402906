import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import Message from "../../components/Message";
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import validator from 'validator';
import ReactQuill from 'react-quill';
import { CreateContract, CreateAlert } from "../../utils/apiCalls";
import { GetRateTypes, GetWeeklyHrs } from "../../utils/GetAPIUtils";
import { isInvalid } from "../../utils/helperFn";
import 'react-quill/dist/quill.snow.css'; 
import DOMPurify from 'isomorphic-dompurify';
import AlertUtils from "../../utils/AlertUtils";

const Contract = (props) => {
   
    const reactQuillRef = React.useRef();
    const taskQuillRef = React.useRef();
    const gContext = useContext(GlobalContext);
    const [values, setValues] = React.useState({ task: "", amount: null, taskDetailText: "", taskDetail: "", dueDate: "", rateTypeId: null, rateType: null, dailyRate: 0, fixedRate: 0, hourlyRate: 0, id: null, jobId: null, expertId: null, offerId: null, proposalId: null, clientId: gContext.userInfo.userID, title: '', weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', startDate: "", endDate: "", hiringPerson: "", error: { endDate: "", startDate: '', title: '', dailyRate: "", fixedRate: "", hourlyRate: "", weeklyHrs: "", desc: "", rateType: "", task: "", amount: "",  taskDetail: "", dueDate: "" } });
    const [initialValues, setInitialValues] = React.useState({ rateTypeId: null, rateType: null, dailyRate: 0, fixedRate: 0, hourlyRate: 0, id: null, jobId: null, expertId: null, offerId: null, proposalId: null, clientId: gContext.userInfo.userID, title: '', weeklyHrsId: null, weeklyHrs: null, descText: '', desc: '', startDate: "", endDate: "", hiringPerson: "", error: { endDate: "", startDate: '', title: '', dailyRate: "", fixedRate: "", hourlyRate: "", weeklyHrs: "", desc: "", rateType: "" } });
    const [weeklyHours, setWeeklyHours] = useState([{}]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = React.useState(false);
    const [editvalue, setEditvalue] = useState('');
    const [editorText, seteditorText] = useState('');
    const [editLength, setEditLength] = useState(0);
    const [milestoneDetail, setMilestoneDetail] = React.useState({ editvalue: "", editTest: "", editLength:0});
    const [updateStatus, setUpdateStatus] = useState(false);

    const [rateType, setRateType] = useState([]);

    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };


    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];


    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }

    const taskeditorChange = (content, delta, source, editor) => {
        
        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 500) {
           
            setMilestoneDetail({ ...milestoneDetail, editvalue: editor.getHTML(), editTest: editor.getText().trim(), editLength: editor.getText().trim().length });
            if (isInvalid(editor.getText())) {
                error.taskDetail = "Invalid format.";
                
            }
            else {
                error.taskDetail = "";
                 
            }
        }
        else {
            setMilestoneDetail({ ...milestoneDetail, editvalue: editor.getHTML()+" ", editTest: editor.getText().trim(), editLength: editor.getText().trim().length });
        }

    };
    const taskeditorKeyDown = (event) => {

        const unprivilegedEditor = taskQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 500 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };


    const editorChange = (content, delta, source, editor) => {
         
        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 4000) {
            setEditvalue(editor.getHTML());
            seteditorText(editor.getText().trim());
            setEditLength(editor.getText().trim().length);

            if (isInvalid(editor.getText())) {
                error.desc = "Invalid format.";
                
            }
            else {
                error.desc = "";
                 
            }
        }
        else {
            setEditvalue(editvalue+" ");
            seteditorText(editorText );
            setEditLength(editLength);}

    };
    const editorKeyDown = (event) => {

        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 4000 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };
    const handleDateChange = (name, value) => {

        setMessage(false);
        let { error, ...rest } = values;
        error[name] = "";
        setValues({
            ...values, error, [name]: (value) ? value : ""
        });
    }
    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = values; 

        error.title = (validator.isEmpty(rest.title.trim()) ? "Title is required" : ((isHtml(rest.title) || isInvalid(rest.title)) ? "Invalid format" : ""));
        error.rateType = (rest.rateType === null) ? "RateType is required" : error.rateType;      
      
         
        if (rest.rateType && rest.rateType.code === 100) {
            error.dailyRate = "";
            error.fixedRate = "";           
           error.hourlyRate = (rest.hourlyRate)?validator.isDecimal(rest.hourlyRate.toString())? (values.hourlyRate < 3 || values.hourlyRate > 500)? "Hourly rate range is 3-500": "" :"Hourly rate is invalid." : "Hourly Rate is required";
        }
        else if (rest.rateType && rest.rateType.code === 200) {
            error.dailyRate = (rest.dailyRate) ? validator.isDecimal(rest.dailyRate.toString()) ? ( values.dailyRate < 10 || values.dailyRate > 5000)?'Daily rate range is 10-5000': "" :"Daily rate is invalid." : "Daily Rate is required"; 
            error.hourlyRate = "";
        }
        else if (rest.rateType && rest.rateType.code === 300) {
            error.dailyRate = "";
            error.fixedRate = (rest.fixedRate) ? validator.isDecimal((rest.fixedRate.toString())) ? (values.fixedRate < 10 || values.fixedRate > 5000)?'Fixed rate range is 10-5000':"" : "Fixed rate is invalid.":"Fixed Rate is required";
            error.hourlyRate = "";
        }  
      
        if(rest.startDate == '')
       {
          error.startDate = "Start date is required";
          if(rest.endDate != '')
      {
        error.endDate  = '';

      }
       }
      if(rest.startDate != '')
      {
        error.startDate  = (rest.startDate > rest.endDate?"Start date should be lesser than enddate":'');

      }


      if(rest.endDate == '')
      { 
        
         if(rest.startDate != '')
      {
        error.startDate  = '';

      }
      }
     if(rest.endDate != '')
     {
        error.endDate  = (rest.startDate > rest.endDate?"End date should be greater than startdate":'');

     }
      
        error.weeklyHrs = (rest.weeklyHrs) ? error.weeklyHrs : "Weekly hours is required";

        error.desc = validator.isEmpty(reactQuillRef.current.unprivilegedEditor.getText().trim()) ? "Description is required" : (isInvalid(reactQuillRef.current.unprivilegedEditor.getText()) ? "Invalid format" : "");

        error.startDate = validator.isEmpty(rest.startDate.toString()) ? "Start Date is required" : error.startDate;
     
        if (!props.mode || props.mode !== "edit") {
            if (rest.dueDate == "") { error.dueDate = "Due date is required"; }
            error.task = (validator.isEmpty(rest.task.trim()) ? "Task Title is required" : (isInvalid(rest.task) ? "Invalid input." : ''));
            error.amount = ((rest.amount) ? (validator.isDecimal(rest.amount.toString()) ? "" : "Amount is invalid ") : "Amount is required");
            
        }
         
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    async function SendNotificationfromClient() {

        const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
       

        try {
            
            const alert = AlertUtils.NewContract.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company : "").
                replace("[JOBTITLE]", props.offer.title).
                replace("[JOBGUID]", props.offer.jobguid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: (props.offer) ? props.offer.expertid : null });

            if (result.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {

            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    async function updateContract() {
        if (checkValidity()) {
             
            gContext.setLoaderModalVisible(true);
            const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
            var data = (props.mode && props.mode === "edit") ? { ...values, ['taskDetailText']: milestoneDetail.editorText, ['taskDetail']: DOMPurify.sanitize(milestoneDetail.editvalue.replaceAll('"', `'`)), ['descText']: editorText, ['desc']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)), ["hiringPerson"]: (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "" } : { ...values, ['descText']: editorText, ['desc']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)), ["jobId"]: (props.offer) ? props.offer.jobid : null, ["expertId"]: (props.offer) ? props.offer.expertid : null, ["proposalId"]: (props.offer) ? props.offer.proposalid : null, ["offerId"]: (props.offer) ? props.offer.id : null , ['taskDetailText']: milestoneDetail.editorText, ['taskDetail']: DOMPurify.sanitize(milestoneDetail.editvalue.replaceAll('"', `'`)), ["hiringPerson"]: (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "" }
            try {
             
                const result = await CreateContract(data);

                if (result.data.contractCreate.responseData.code == "200" || result.data.updateContract.responseData.code == "200") {
                    setUpdateStatus(true);
                    props.setUpdateStatus(true);
                    SendNotificationfromClient();
                    gContext.toggleRefreshDashboard("contract");
                }
                else {
                    //setErrorMessage(JSON.parse(result.data.contractCreate.responseData.message));
                }
                gContext.setLoaderModalVisible(false);
            }
            catch (e) {

                gContext.setLoaderModalVisible(false);
                console.log(e);
                setErrorMessage("Server error");
            }
        }


    }

    const handleLeave = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {

            case "dailyRate":
                
                error.dailyRate = (value != '') ?validator.isDecimal(value)?(value <= 5000) ? "" : "Max value  is 5000":"Daily rate is invalid":'';
                error.hourlyRate = "";
                error.fixedRate = "";
                break;
            case "fixedRate":
                
                error.fixedRate = (value != '')?validator.isDecimal(value)?(value <= 5000) ? "" : "Max value  is 5000":"Fixed rate is invalid":'';
                error.hourlyRate = "";
                error.dailyRate = "";
                break;
            case "hourlyRate":
                 
               error.hourlyRate = (value != '')?validator.isDecimal(value)?(value <= 500) ? "" : "Max value  is 500":"Hourly rate is invalid":'';
               error.dailyRate = ""; error.fixedRate = "";
                break;
            case "amount":
                 
                error.amount = (value != '') ? (validator.isDecimal(value) ? ""  : "Amount is invalid") : '';
                 
                break;
           
        }

        if ((name == "fixedRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["dailyRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "dailyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "hourlyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["dailyRate"]: null });
        }
        else { setValues({ ...values, error, [name]: value }); }
    }

    const handleSelectChange = (e, name) => {

        const value = e;

        let error = { ...values.error };
        switch (name) {
            case "weeklyHrs":
                error.weeklyHrs = "";
                break;
            case "rateType":
                error.rateType = "";
                error.fixedRate = "";
                error.dailyRate = "";
                error.hourlyRate = "";
                break;
            default:
                break;
        }

        setValues({ ...values, error, [name]: value, [name + 'Id']: value.value });

    }
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        let data = { ...values };


        switch (name) {
            case "title":
                error.title = validator.isEmpty(value) ? "Title is required" : "";
                break;

            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;

                case "dailyRate":
               
                error.dailyRate = (value != '') ?validator.isDecimal(value)?(value <= 5000) ? "" : "Max value  is 5000":"Daily rate is invalid":'';
                error.hourlyRate = "";
                error.fixedRate = "";
                break;
            case "fixedRate":
                 
                error.fixedRate = (value != '')?validator.isDecimal(value)?(value <= 5000) ? "" : "Max value  is 5000":"Fixed rate is invalid":'';
                error.hourlyRate = "";
                error.dailyRate = "";
                break;
            case "hourlyRate":
              
               error.hourlyRate = (value != '')?validator.isDecimal(value)?(value <= 500) ? "" : "Max value  is 500":"Hourly rate is invalid":'';
               error.dailyRate = ""; error.fixedRate = "";
                break;
            case "amount":

                error.amount = (value != '') ? (validator.isDecimal(value) ? "" : "Amount is invalid") : '';

                break;
        }
        if ((name == "fixedRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["dailyRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "dailyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["hourlyRate"]: null });
        }
        else if ((name == "hourlyRate") && value != "") {
            setValues({ ...values, error, [name]: value, ["fixedRate"]: null, ["dailyRate"]: null });
        }
        else { setValues({ ...values, error, [name]: value }); }
         

    }


    async function getUtils() {
        setShowLoader(true);

        const weeklyhrsResponse = await GetWeeklyHrs();

        if (weeklyhrsResponse.data && weeklyhrsResponse.data.weeklyHours) {
            let weeklyhrsList = [];
            weeklyhrsResponse.data.weeklyHours.map((value) => (
                weeklyhrsList.push({ value: value.id, label: value.hours })));
            setWeeklyHours(weeklyhrsList);
        }
        if (rateType.length === 0) {
            const rateTypes = await GetRateTypes();

            if (rateTypes.data && rateTypes.data.rateTypes) {
                let type = [];
                rateTypes.data.rateTypes.map((value) => (
                    type.push({ code: value.code, value: value.id, label: value.rate })));

                setRateType(type);
            }
        }
    }

    async function BindContract() {
        try {

            if (props.contract) {
                let contractValue = props.contract;
            
                let contractData = { ...values };
                contractData.jobId = contractValue.jobid;
                contractData.expertId = contractValue.expertid;
                contractData.proposalId = contractValue.proposalid;
                contractData.offerId = contractValue.offerid;
                contractData.id = contractValue.id;
                contractData.title = contractValue.title;
                contractData.dailyRate = (contractValue.dailyrate) ? parseInt(contractValue.dailyrate) : null;
                contractData.hourlyRate = (contractValue.hourlyrate) ? parseInt(contractValue.hourlyrate) : null;
                contractData.fixedRate = (contractValue.fixedrate) ? parseInt(contractValue.fixedrate) : null;
                contractData.rateTypeId = contractValue.ratetypeid;
                contractData.rateType = { code: contractValue.ratetypecode, value: contractValue.ratetypeid, label: contractValue.rate };
                contractData.desc = contractValue.desc;
                contractData.weeklyHrsId = (contractValue.weeklyhrsid) ? contractValue.weeklyhrsid : 0;
                contractData.weeklyHrs = { value: contractValue.weeklyhrsid, label: contractValue.hours };
                contractData.startDate = (contractValue.startdate) ? new Date(contractValue.startdate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "";
                contractData.endDate = (contractValue.enddate) ? new Date(contractValue.enddate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "";
                contractData.error.desc = "";
                setEditvalue(contractValue.desc); 

                setInitialValues(contractData);
                setValues(contractData);
            }

        }
        catch (e) {

        }
    }
    useEffect(() => {
        document.title = "Create Contract | Syncuppro";
        getUtils();
    }, []);
    useEffect(() => {
        if (props.mode && props.mode === "edit") {
            BindContract();
        }
    }, [weeklyHours]);
    useEffect(() => {

        if (props.offer != null && props.mode == "add" && props.offer.id) {

            let jobValue = props.offer;
          
            let offerData = { ...values };
            offerData.title = jobValue.title;
            offerData.desc = jobValue.desc;
            offerData.startDate = (jobValue.startdate) ? new Date(jobValue.startdate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "";
            offerData.weeklyHrsId = (jobValue.weeklyhrsid) ? jobValue.weeklyhrsid : null;
            offerData.weeklyHrs = { value: jobValue.weeklyhrsid, label: jobValue.weeklyhrs };
            offerData.rateTypeId = (jobValue.ratetypeid) ? jobValue.ratetypeid : null;
            offerData.rateType = { code: jobValue.ratetypecode, value: jobValue.ratetypeid, label: jobValue.ratetype };
            offerData.fixedRate = (jobValue.fixedrate) ? parseInt(jobValue.fixedrate) : null;
            offerData.dailyRate = (jobValue.dailyrate) ? parseInt(jobValue.dailyrate) : null;
            offerData.hourlyRate = (jobValue.hourlyrate) ? parseInt(jobValue.hourlyrate) : null;
            offerData.error.desc = "";
            setEditvalue(jobValue.desc);
            setInitialValues(offerData);
            setValues(offerData);
        }
    }, [props.offer]);
    useEffect(() => {
        if (props.mode && props.mode === "edit" && props.contract.id) {
            BindContract();
        }

    }, [props.contract]);
    useEffect(() => {
        if (editvalue)
        setEditLength(editvalue.replace(/(<([^>]+)>)/ig, '').length);
    }, [editvalue]);
    return (
        <>
            {updateStatus === false &&
                <div className="modal-body">

                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label for="Title" className="form-label m-b-10">  Contract Title <span className="text-red"> * </span></label>
                                <input
                                    type="text" defaultValue={values.title} Value={values.title}
                                    className={`form-control   ${(values.error.title.length > 0) ? ' error' : ''}`}
                                    id="Title"
                                    name="title"
                                    placeholder="eg.Software Engineer" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                />{values.error.title.length > 1 && (
                                    <span className="text-red">{values.error.title}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label for="job title" className="form-label m-b-10">Weekly hours <span className="text-red"> * </span></label>
                                <Select options={weeklyHours}
                                    placeholder="Select..." value={values.weeklyHrs}
                                    className={`form-control  padding-select ${(values.error.weeklyHrs.length > 0) ? ' error' : ''}`}
                                    border={false} onChange={(e) => handleSelectChange(e, 'weeklyHrs')}
                                />
                                {values.error.weeklyHrs.length > 1 && (
                                    <span className="text-red">{values.error.weeklyHrs}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label for="job title" className="form-label m-b-10">Start Date<span className="text-red"> * </span></label>
                                <CustomDatePicker className="form-control  "
                                    placeholderText="Start Date"
                                    selected={values.startDate}
                                    onChange={(date) => (handleDateChange('startDate', date))}
                                    minDate={new Date()}
                                ></CustomDatePicker>
                                {values.error.startDate.length > 1 && (
                                    <span className="text-red ">{values.error.startDate}</span>
                                )}


                            </div>
                    </div> <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="forn-group-input">
                            <label for="job title" className="form-label m-b-10">End Date<span className="text-red">  </span></label>
                            <CustomDatePicker className="form-control "
                                placeholderText="End Date"
                                selected={values.endDate}

                                onChange={(date) => (handleDateChange('endDate', date))}
                                minDate={(values.startDate) ? values.startDate : new Date()}
                            />
                            {values.error.endDate.length > 1 && (
                                <span className="text-red ">{values.error.endDate}</span>
                            )}


                        </div>
                    </div>

                    </div>

                <div className="company-desc">
                    <div className="desc-info flex-column justify-content-start">
                        <label for="first name" className="form-label m-b-10"><b>Description</b><span className="text-red"> * (Max 4000 character )</span></label>
                        <span className="text-gray">Remaining Character length:  <span className="text-green">{4000 - ((editLength) ? editLength : 0)}</span></span>
                    </div>

                    <ReactQuill modules={modules}
                        formats={formats} theme="snow" id="editor"
                        onKeyDown={editorKeyDown}
                        ref={reactQuillRef}
                        value={editvalue} onChange={editorChange}
                    ></ReactQuill>
                    {values.error.desc.length > 1 && (
                        <span className="text-red ">{values.error.desc}</span>
                    )}

                </div>

                    <div className="row m-b-10">

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="forn-group-input">
                                <label for="experience level" className="form-label m-b-10">Rate Type<span className="text-red"> * </span></label>
                                <Select name="expLevel" value={values.rateType}
                                    options={rateType} placeholder="Select..."
                                    className={`form-control  padding-select   ${(values.error.rateType.length > 0) ? ' error' : ''}`}
                                    border={false} onChange={(e) => handleSelectChange(e, 'rateType')}
                                />
                                {values.error.rateType.length > 1 && (
                                    <span className="text-red">{values.error.rateType}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="forn-group-input">
                                <label for="last name" className="form-label m-b-10">Daily Rate <span className="text-red">(USD)</span></label>
                                {(values.rateType && values.rateType.code === 200) && <input name="dailyRate"
                                    type="text" Value={values.dailyRate} className={`form-control   ${(values.error.dailyRate.length > 0) ? ' error' : ''}`}

                                    id="dailyRate" onKeyUp={handleFieldsChange}
                                    placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                />}
                                {(!values.rateType || values.rateType.code !== 200) && <input name="dailyRate1" disabled="true"
                                    type="text" className={`form-control`}
                                    id="dailyRate"
                                />
                                }
                                {values.error.dailyRate.length > 1 && (
                                    <span className="text-red">{values.error.dailyRate}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="forn-group-input">
                                <label for="last name" className="form-label m-b-10">Fixed Rate <span className="text-red">(USD)</span></label>
                                {(values.rateType && values.rateType.code === 300) && <input name="fixedRate"
                                    type="text" className={`form-control   ${(values.error.fixedRate.length > 0) ? ' error' : ''}`}

                                    id="fixedRate" defaultValue={values.fixedRate} Value={values.fixedRate} onKeyUp={handleFieldsChange}
                                    placeholder=" " onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                />}
                                {(!values.rateType || values.rateType.code !== 300) && <input name="fixedRate1" disabled="true"
                                    type="text" className={`form-control`}
                                    id="fixedRate1"
                                />}
                                {values.error.fixedRate.length > 1 && (
                                    <span className="text-red">{values.error.fixedRate}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-6">
                            <div className="forn-group-input">
                                <label for="last name" className="form-label m-b-10">Hourly Rate <span className="text-red">(USD)</span></label>
                                {(values.rateType && values.rateType.code === 100) && <input name="hourlyRate"
                                    type="text" className={`form-control   ${(values.error.hourlyRate.length > 0) ? ' error' : ''}`}

                                    id="hourlyRate" defaultValue={values.hourlyRate} Value={values.hourlyRate}
                                    onKeyUp={handleFieldsChange} placeholder=" " onChange={handleFieldsChange}
                                    onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                                />}
                                {(!values.rateType || values.rateType.code !== 100) && <input name="dailyRate1" disabled="true"
                                    type="text" className={`form-control`}
                                    id="dailyRate"
                                />
                                }
                                {values.error.hourlyRate.length > 1 && (
                                    <span className="text-red">{values.error.hourlyRate}</span>
                                )}
                            </div>
                        </div>

                </div>

                {!props.mode || props.mode !== "edit" && <div className="row m-b-10 p-b-10">
                    <div className="col-lg-12 col-md-12 col-sm-12 m-b-10">
                        <h6>Milestone</h6>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="forn-group-input">
                            <label for="job title" className="form-label m-b-10">    Task Title <span className="text-red"> * </span></label>
                            <input
                                type="text" defaultValue={values.task} Value={values.task}
                                className={`form-control   ${(values.error.task.length > 0) ? ' error' : ''}`}
                                id="task"
                                name="task"
                                onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                            />{values.error.task.length > 1 && (
                                <span className="text-red">{values.error.task}</span>
                            )}
                        </div>
                    </div>



                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="forn-group-input">
                            <label for="amount" className="form-label m-b-10">Amount<span className="text-red"> * </span></label>
                            <input name="amount"
                                type="text" Value={values.amount} className={`form-control   ${(values.error.amount.length > 0) ? ' error' : ''}`}

                                id="amount" onKeyUp={handleFieldsChange}
                                placeholder="" onChange={handleFieldsChange} onMouseLeave={handleLeave} onPointerLeave={handleLeave}
                            />
                            {values.error.amount.length > 1 && (
                                <span className="text-red ">{values.error.amount}</span>
                            )}


                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="forn-group-input">
                            <label for="job title" className="form-label m-b-10">Due Date<span className="text-red"> * </span></label>
                            <CustomDatePicker className="form-control "
                                placeholderText="Due Date"
                                selected={values.dueDate}

                                onChange={(date) => (handleDateChange('dueDate', date))}
                                minDate={new Date()}
                            />
                            {values.error.dueDate.length > 1 && (
                                <span className="text-red ">{values.error.dueDate}</span>
                            )}


                        </div>
                    </div>
                    <div className="company-desc m-b-10">
                        <div className="desc-info flex-column justify-content-start">
                            <label for="first name" className="form-label m-b-10"><b>Task Details</b><span className="text-red">  (Max 500 character )</span></label>
                            <span className="text-gray">Remaining Character length:  <span className="text-green">{500 - ((milestoneDetail.editLength) ? milestoneDetail.editLength : 0)}</span></span>
                        </div>

                        <ReactQuill modules={modules} style={{ height: '100px' }}
                            formats={formats} theme="snow" id="editor"
                            onKeyDown={taskeditorKeyDown}
                            ref={taskQuillRef}
                            value={milestoneDetail.editvalue} onChange={taskeditorChange}
                        ></ReactQuill>
                        {values.error.taskDetail.length > 1 && (
                            <span className="text-red p-t-40">{values.error.taskDetail}</span>
                        )}

                    </div>
                </div>}

                 

                    <div className="panel-action justify-content-end m-b-20 m-t-20 p-t-20">
                        <button className="btn btn-sm btn-orange bg-green m-r-20 text-white" onClick={() => {
                        updateContract();

                        }}>Submit</button>
                        <button type="button" className="btn btn-sm btn-dark-outline text-black d-flex align-item-center" onClick={() => {
                        props.togglepopup();
                        }}>Cancel</button>
                    </div>

              

                </div>}

           

        </>
    );
};

export default Contract;
