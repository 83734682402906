import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import { FileUpload, getFileName } from "../../utils/fileUpload";
import {isInvalid } from "../../utils/helperFn";
import validator from 'validator';
import { UpdateExpertProfessional, GetExpertProfessional } from "../../utils/apiCalls";
import { GetJobUtils } from "../../utils/GetAPIUtils";
import ReactQuill from 'react-quill';
import DOMPurify from 'isomorphic-dompurify';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message"; 
const Professional = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    
    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [selectedIndustryData, setSelectedIndustryData] = useState([]);
    const [rowsSocial, setRowsSocial] = useState([]);
    const [compliances, setComplianceList] = useState([]);
    const [selectedCompliances, setSelectedCompliance] = useState([]);
    const [skills, setSkillsList] = useState([]);
    const [selectedSkillData, setSelectedSkillData] = useState([]);
    const [experience, setExperienceList] = useState([{}]);
    const [values, setValues] = React.useState({ industryType: '', profileDetailsText: '', compCategory: '', title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, profileDetails: "", skillSet: "", compliance: "", resetResumeName: '', resumeName: '', resume: null, error: { industryType: '', compCategory: '', title: '', expLevel: "", dailyRate: "", weeklyHrs: "", profileDetails: "", skillSet: "", compliance: "" } });
    const [initialValues, setInitialValues] = React.useState({ industryType: '', profileDetailsText: '', compCategory: '', title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, profileDetails: "", skillSet: "", resetResumeName: '', resumeName: '', compliance: "", resume: null, error: { industryType: '', compCategory: '', title: '', expLevel: "", dailyRate: "", weeklyHrs: "", profileDetails: "", skillSet: "", compliance: "" } });
    const [onLoadUserInfo, setOnLoadUserInfo] = useState({ industryType: '', profileDetailsText: '', compCategory: '', title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, profileDetails: "", skillSet: "", resetResumeName: '', resumeName: '', compliance: "", resume: null, error: { industryType: '', compCategory: '', title: '', expLevel: "", dailyRate: "", weeklyHrs: "", profileDetails: "", skillSet: "", compliance: "" } });
    const [fullSkillSet, setFullSkillSet] = useState([]);
    const [fullIndustryType, setFullIndustryType] = useState([]);
    const [fullCompliance, setFullCompliance] = useState([]);
    const [fullComplianceCategory, setFullComplianceCategory] = useState([]);
    const [weeklyHours, setWeeklyHours] = useState([{}]);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [initialData, setInitialData] = useState(null);
    const [compCategory, setCompCategory] = useState([]);
    const [selectedCompCategory, setSelectedCompCategory] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [uploadtooltip, setUploadTooltip] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const reactQuillRef = React.useRef();
    const [editvalue, setEditvalue] = useState('');
    const [editLength, setEditLength] = useState(0);
    const [message, setMessage] = React.useState(gContext.visibleProfileMessage);
   


    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };


    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];




    const editorKeyDown = (event) => {

        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().length > 4000 && event.key !== 'Backspace') {
            event.preventDefault();
        }
        var key = event.which || event.keyCode; // Detecting keyCode 

        // Detecting Ctrl 
        var ctrl = event.ctrlKey ? event.ctrlKey : ((key == 17)
            ? true : false);

         if (key == 67 && ctrl) {
             if (unprivilegedEditor.getText().length > 4000  ) {
                 event.preventDefault();
             }
        } 
    };

    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }

    const editorChange = (content, delta, source, editor) => {
        let { error, ...rest } = values;
        
        const prData = editvalue;
       
        if (editor.getText().length <= 4000) {
            setEditvalue(editor.getHTML());
            setEditLength(editor.getText().length);

            if (isHtml(editor.getText())) {
                error.profileDetails = "Invalid format.";
                setValues({ ...values, error, ['profileDetailsText']: editor.getText() , ['profileDetails']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
                
            }
            else {
                error.profileDetails = "";
                setValues({ ...values, error, ['profileDetailsText']: editor.getText() , ['profileDetails']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
        } else  {
           
            if (editor.getText().length > 4000) {
                setEditLength(4000);

            }
        }
    };

    function prevPage()
    {
        gContext.setCurrentPage('5'); 
    }

    function nextPage() {
        gContext.setCurrentPage('12'); 
    }
    function checkValidity() {
        setMessage(false);
        let isValid = false;
        
       
        let { error, ...rest } = values; 
        error.title = (rest.title == null || rest.title == '') ? "Title is required" : (isInvalid(rest.title) ? "Invalid format" : error.title) ;
        error.profileDetails = ((reactQuillRef.current.unprivilegedEditor.getText()).length == 1) ? "Profile Summary is required" : '';
        error.profileDetails = (isInvalid(reactQuillRef.current.unprivilegedEditor.getText())) ? "Invalid format" : error.profileDetails;
        
        error.expLevel = (rest.expLevel) ? error.expLevel : "Experience Level is required";
        error.dailyRate = (rest.dailyRate) ? error.dailyRate : "Daily Rate is required";
        error.weeklyHrs = (rest.weeklyHrs) ? error.weeklyHrs : "Weekly hours is required";
        
        error.industryType = (rest.industryType) ? "" : "Industry Type  is required";
        error.skillSet = (!rest.skillSet) ? "Skill Set is required" : error.skillSet;
        error.compliance = (!rest.compliance) ? "Compliance is required" : error.compliance;
        error.compCategory = (!rest.compCategory) ? "Compliance Category is required" : error.compCategory;

        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
                
            }
        });     
       

        if (isValid)
           return false; 
        else
            return true; 
    }


    async function updateProfile() {

        setErrorMessage('');
        setMessage(false);
        if (checkValidity()) {
            

            gContext.setLoaderModalVisible(true);

            var data = { ...values };

            try {

                if (values.resume) {

                    data.resumeName = getFileName(values.resume, "resume");
                    FileUpload(values.resume, data.resumeName.split('/').pop());
                }
                const result = await UpdateExpertProfessional(data);


                if (result.data.expertProUpdate.responseData.code == "200") {
                    setMessage(true); 
                    gContext.getProfileInfo(gContext.userInfo.userType, gContext.userInfo.email, gContext.userInfo.sub);
                    gContext.setVisibleProfileMessage(true);
                    props.gotoNext();
                }
                else {
                    setErrorMessage(JSON.parse(result.data.expertProUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                setErrorMessage("Server error");
            }
        }

        else {  setMessage(false);
            setErrorMessage("Required fields are missing.");

        }

        gContext.setLoaderModalVisible(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });
    }
    async function loadProfile() {
        setLoaded(false);

        try {
            let profile = null;


            const result = await GetExpertProfessional();
            if (result.data.expertProLoad.responseData.code == "200") {
                profile = JSON.parse(result.data.expertProLoad.responseData.message);
                setInitialData(profile);

                if (profile && profile.length > 0) {
                    let profileData = { ...values };
                    const exp = (profile[0].explevelid) ? experience.filter(item => item["value"] == profile[0].explevelid) : null;
                    const hours = (profile[0].weeklyhrsid) ? weeklyHours.filter(item => item["value"] == profile[0].weeklyhrsid) : null;
                    profileData.title = profile[0].title;
                    profileData.dailyRate = profile[0].dailyrate;
                    profileData.profileDetails = profile[0].profile;
                    profileData.skillSet = profile[0].skillids;
                    profileData.compliance = profile[0].compids;
                    profileData.resumeName = (profile[0].resume && profile[0].resume != "null") ? profile[0].resume : '';
                    profileData.resetResumeName = (profile[0].resume && profile[0].resume != "null") ? profile[0].resume : '';
                    profileData.expLevelId = (profile[0].explevelid) ? profile[0].explevelid : 0;
                    profileData.weeklyHrsId = (profile[0].weeklyhrsid) ? profile[0].weeklyhrsid : 0;
                    profileData.expLevel = (exp?.length > 0) ? exp[0] : null;
                    profileData.weeklyHrs = (hours?.length > 0) ? hours[0] : null;
                    profileData.compCategory = profile[0].compcatids;
                    profileData.industryType = (profile[0].indtypeidslist) ? profile[0].indtypeidslist : "";

                    setInitialValues(profileData);
                    setValues(profileData);
                    
                    setTimeout(() => {
                        getUtils();
                        setTimeout(() => {
                            gContext.setLoaderModalVisible(false);
                            setLoaded(true);
                        }, 500);
                    }, 500);

                }
                else {

                    getUtils();
                    setTimeout(() => {
                        gContext.setLoaderModalVisible(false);
                        setLoaded(true);
                    }, 500);

                }

            }
            else {
                getUtils();
                
                    gContext.setLoaderModalVisible(false);
                    setLoaded(true);
                
            }
          

        }
        catch (e) {
            console.log(e);
            getUtils();
            gContext.setLoaderModalVisible(false);
            setLoaded(true);
        }
    }
    const handleSelectChange = (e, name) => {
        setMessage(false);
        const value = e;

        let error = { ...values.error };
        switch (name) {
            case "weeklyHrs":
                error.weeklyHrs = "";
                break;
            case "expLevel":
                error.expLevel = "";
                break;
            default:

                break;
        }

        setValues({ ...values, error, [name]: value, [name + 'Id']: value.value });

    }
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;
        setMessage(false);
        let error = { ...values.error };
        switch (name) {
            case "title":
                error.title = validator.isEmpty(value) ? "Title is required" : "";
                break;
            case "dailyRate":
              
              error.dailyRate = (value != '') ? validator.isDecimal(value) ? ((value < 10) || (value > 5000)) ? "Daily rate range is 10-5000" : "" : "Daily rate is invalid" : '';
               
                break;
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }
        setValues({ ...values, error, [name]: value });

    }
    function addToCompCategory(id, code) { 

        if (compCategory) {
            const deleted = selectedCompCategory.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                let result = [...compCategory, deleted[0]];
                result.sort((a, b) => {
                    return a.code - b.code;
                });
                setCompCategory(result);
            }
            const added = selectedCompCategory.filter(item => item["id"] != id);
            setSelectedCompCategory(added);
            setChanges('compCategory', added);

        }
    }
    function removeCompCategory(id, code) {

        const selected = compCategory.filter(item => item["id"] == id);

        if (selected.length > 0) {

            setChanges('compCategory', [...selectedCompCategory, selected[0]]);
            setSelectedCompCategory([...selectedCompCategory, selected[0]]);
        }
        const removed = compCategory.filter(item => item["id"] != id);
        setCompCategory(removed);

    }

   
    function removeSelectedSkill(id) {
       
       
        
        const selected = skills.filter(item => item["id"] == id);

       
       

        if (selected.length > 0) {

            setChanges('skillSet', [...selectedSkillData, selected[0]]);
            setSelectedSkillData([...selectedSkillData, selected[0]]);
        }

        const removed = skills.filter(item => item["id"] != id);
       
        setSkillsList(removed);

      


    }

    function removeSelectedIndustryType(id) {

        const selected = industryTypeList.filter(item => item["id"] == id);
        

        if (selected.length > 0) {

            setChanges('industryType', [...selectedIndustryData, selected[0]]);
            setSelectedIndustryData([...selectedIndustryData, selected[0]]);
        }
        const removed = industryTypeList.filter(item => item["id"] != id);
        setIndustryTypeList(removed)
        //setSkillsList(removed);
    }

    function addToIndustryType(id) {
        if (industryTypeList) {
            const deleted = selectedIndustryData.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                setIndustryTypeList([...industryTypeList, deleted[0]]);
            }
            const added = selectedIndustryData.filter(item => item["id"] != id);
            setSelectedIndustryData(added);
            setChanges('industryType', added);

        }
    }

    function addToSkills(id) {

        if (skills) {
            const deleted = selectedSkillData.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                setSkillsList([...skills, deleted[0]]);
            }
            const added = selectedSkillData.filter(item => item["id"] != id);
            setSelectedSkillData(added);
            setChanges('skillSet', added);

        }
       
       
    }


    function removeCompliance(id) {
        const selected = compliances.filter(item => item["id"] == id.toString());
        if (selected.length > 0) {
            setChanges('compliance', [...selectedCompliances, selected[0]]);
            setSelectedCompliance([...selectedCompliances, selected[0]]);
        }
        const removed = compliances.filter(item => item["id"] != id.toString());
        setComplianceList(removed);
    }
    function addToCompliance(id) {

        if (compliances) {
            const deleted = selectedCompliances.filter(item => item["id"] == id.toString());

            if (deleted.length > 0) {
                setComplianceList([...compliances, deleted[0]]);
            }
            const added = selectedCompliances.filter(item => item["id"] != id.toString());
            setSelectedCompliance(added);
            setChanges('compliance', added);

        }
      
    }
    function setChanges(fields, lists) {

        let value = '';
        lists.map((data) => (value += data.id + ','));
        let error = { ...values.error };
        if (value !== "")
            error[fields] = '';
        setValues({ ...values, error, [fields]: (value !== '') ? value.substring(0, value.length - 1) : '' });

    }

    async function getUtils() {


        try {
            const result = await GetJobUtils();
            if (result.data && result.data.jobUtils && result.data.jobUtils.responseData.code == "200") {

                let data = JSON.parse(result.data.jobUtils.responseData.message);


                setExperienceList(data.expLevels);
                setWeeklyHours(data.weeklyHours);
                setSkillsList(data.skills);
                setFullSkillSet(data.skills);
                setComplianceList(data.complianceTypes);
                setFullCompliance(data.complianceTypes);
                setIndustryTypeList(data.industryTypes);
                setFullIndustryType(data.industryTypes);
                setCompCategory(data.complianceCategory);
                setFullComplianceCategory(data.complianceCategory);
            }


        } catch (e) {
            console.log(e);
        }
    }

   

    const onFileChange = (event) => {
         
        setMessage(false);
        if (event.target.files.length > 0) {

            TestFileType(event.target.files[0],['gif', 'jpg', 'png', 'jpeg','pdf','docx','doc','msword','vnd.openxmlformats-officedocument.wordprocessingml.document']);

          
        }
    }

    function TestFileType(fileName, fileTypes )
    {       
      
       
           var type = fileName.type.substring(fileName.type.indexOf("/")+1, fileName.type.length);

           if((fileTypes.indexOf(type) != -1) == true)
           {
             
            setValues({ ...values, ["resume"]: fileName});
                         
           }
           

           }
 
    function cancelEdit()
     {
        setRowsSocial([]);
        setMessage("");
        if (gContext.userInfo.profileInfo == null) 
        {
            setValues(initialValues);
            loadProfile();
            setSelectedIndustryData(initialValues.industryType);
            setEditvalue(initialValues.profileDetails);

            setSelectedCompliance(initialValues.compliance);
            setSelectedCompCategory(initialValues.compCategory);
            setSelectedSkillData(initialValues.skillSet);
        }
        if (gContext.userInfo.profileInfo != null)
         {
            if (gContext.userInfo.userID != null) 
            {

                if (JSON.stringify(gContext.userInfo.profileComplete) == 'null') {
              
                    try {
                        setInitialValues(onLoadUserInfo)
                        setValues(onLoadUserInfo);
                        setSelectedIndustryData(initialValues.industryType);
                        setEditvalue(initialValues.profileDetails);
                        setSelectedSkillData(onLoadUserInfo.skillSet);
                        setSelectedCompliance(onLoadUserInfo.compliance);
                        setSelectedCompCategory(onLoadUserInfo.compCategory);
                        loadProfile();


                  


                    }
                    
                    catch (e) {
                        console.log(e);
                    }


                }

                else if (JSON.stringify(gContext.userInfo.profileComplete) != 'null') 
                {
                    

                    let inititalvalue = [];

                    initialValues.industryType.toString().split(',').forEach((value) => {
                        let sdata = fullIndustryType.filter(item => item["id"] == value);
                        inititalvalue.push(sdata[0]);});

                    /** completele diff-false
                     * same value -true
                     */
                    if (JSON.stringify(inititalvalue) === JSON.stringify(selectedIndustryData)) {
                        let selected = [];
                        let removelist = [];
                        initialValues.industryType.toString().split(',').forEach((value) => {

                            let data = selectedIndustryData.filter(item => item["id"] == value);
                            let rdata = fullIndustryType.filter(item => item["id"] != value);
                            selected.push(data[0]);
                            removelist = rdata;
                        });
                        setSelectedIndustryData(selected);
                        setIndustryTypeList(removelist);

                    }
                    else if (JSON.stringify(inititalvalue) !== JSON.stringify(selectedIndustryData)) {
                        let selected = [];
                        let removelist = [];
                        initialValues.industryType.toString().split(',').forEach((value) => {
                            let data = fullIndustryType.filter(item => item["id"] == value);
                            let rdata = fullIndustryType.filter(item => item["id"] != value);
                            selected.push(data[0]);
                            removelist = rdata;
                        });
                        setSelectedIndustryData(selected);
                        setIndustryTypeList(removelist);
                    }

                    /**Skill set */
                    let sinititalvalue = [];

                    initialValues.skillSet.toString().split(',').forEach((value) => {
                        let sdata = fullSkillSet.filter(item => item["id"] == value);
                        sinititalvalue.push(sdata[0]);});

                    /** completele diff-false
                     * same value -true
                     */
                    if (JSON.stringify(sinititalvalue) === JSON.stringify(selectedSkillData)) {
                        let skselected = [];
                        let skremovelist = [];
                        initialValues.skillSet.toString().split(',').forEach((value) => {

                            let data = selectedSkillData.filter(item => item["id"] == value);
                            let rdata = fullSkillSet.filter(item => item["id"] != value);
                            skselected.push(data[0]);
                            skremovelist = rdata;
                        });
                        setSelectedSkillData(skselected);
                        setSkillsList(skremovelist);

                    }
                    else if (JSON.stringify(sinititalvalue) !== JSON.stringify(selectedSkillData))
                     {
                        let selected = [];
                        let removelist = [];
                        initialValues.skillSet.toString().split(',').forEach((value) => {
                            let data = fullSkillSet.filter(item => item["id"] == value);
                            let rdata = fullSkillSet.filter(item => item["id"] != value);
                            selected.push(data[0]);
                            removelist = rdata;
                        });
                        setSelectedSkillData(selected);
                        setSkillsList(removelist);
                    }

                /**Complaince */
                let cinititalvalue = [];

                initialValues.compliance.toString().split(',').forEach((value) => {
                    let sdata = fullCompliance.filter(item => item["id"] == value);
                    cinititalvalue.push(sdata[0]);});

                /** completele diff-false
                 * same value -true
                 */
                if (JSON.stringify(cinititalvalue) === JSON.stringify(selectedCompliances)) {
                    let cselected = [];
                    let cremovelist = [];
                    initialValues.compliance.toString().split(',').forEach((value) => {

                        let data = selectedCompliances.filter(item => item["id"] == value);
                        let rdata = fullCompliance.filter(item => item["id"] != value);
                        cselected.push(data[0]);
                        cremovelist = rdata;
                    });
                    setSelectedCompliance(cselected);
                    setComplianceList(cremovelist);

                }
                else if (JSON.stringify(cinititalvalue) !== JSON.stringify(selectedCompliances))
                 {
                    let selected = [];
                    let removelist = [];
                    initialValues.compliance.toString().split(',').forEach((value) => {
                        let data = fullCompliance.filter(item => item["id"] == value);
                        let rdata = fullCompliance.filter(item => item["id"] != value);
                        selected.push(data[0]);
                        removelist = rdata;
                    });
                    setSelectedCompliance(selected);
                    setComplianceList(removelist);
                } 


                /**Complaince Category */
                let ccinititalvalue = [];

                initialValues.compCategory.toString().split(',').forEach((value) => {
                    let sdata = fullComplianceCategory.filter(item => item["id"] == value);
                    ccinititalvalue.push(sdata[0]);});

                /** completele diff-false
                 * same value -true
                 */
                if (JSON.stringify(ccinititalvalue) === JSON.stringify(selectedCompCategory)) {
                    let ccselected = [];
                    let ccremovelist = [];
                    initialValues.compCategory.toString().split(',').forEach((value) => {

                        let data = selectedCompCategory.filter(item => item["id"] == value);
                        let rdata = fullComplianceCategory.filter(item => item["id"] != value);
                        ccselected.push(data[0]);
                        ccremovelist = rdata;
                    });
                    setSelectedCompCategory(ccselected);
                    setCompCategory(ccremovelist);

                }
                else if (JSON.stringify(ccinititalvalue) !== JSON.stringify(selectedCompCategory))
                 {
                    let selected = [];
                    let removelist = [];
                    initialValues.compCategory.toString().split(',').forEach((value) => {
                        let data = fullComplianceCategory.filter(item => item["id"] == value);
                        let rdata = fullComplianceCategory.filter(item => item["id"] != value);
                        selected.push(data[0]);
                        removelist = rdata;
                    });
                    setSelectedCompCategory(selected);
                    setCompCategory(removelist);
                } 

               setValues(initialValues); 

                }
                if(values.resume == null && initialValues.resume == null)
                {
                    
                    values.resume = initialValues.resume;
                }

                if(values.resume != null && initialValues.resume == null)
                {
                    
                    values.resume = initialValues.resume;
                }

                if(values.resume != null && initialValues.resume != null)
                {
                   
                    values.resume = initialValues.resume;
                }


            }
            
        }




    }

    function loadHoursExp() {
        const exp = (values.expLevelId )? experience.filter(item => item["value"] == values.expLevelId):null;
        const hours = (values.weeklyHrsId)? weeklyHours.filter(item => item["value"] == values.weeklyHrsId):null;
        setValues({ ...values, ['weeklyHrs']: (hours?.length > 0) ? hours[0] : null, ['expLevel']: (exp?.length > 0) ? exp[0] : null });
        setInitialValues({ ...initialValues, ['weeklyHrs']: (hours?.length > 0) ? hours[0] : null, ['expLevel']: (exp?.length > 0) ? exp[0] : null });
    }
    useEffect(() => {
        setEditLength(editvalue.replace(/(<([^>]+)>)/ig, '').length);
    }, [editvalue]);

    useEffect(() => {
        loadProfile();
        document.title = "Professional | Syncuppro";
    }, []);
    useEffect(() => {
        if (values.expLevelId != null && values.expLevel == null) {
            loadHoursExp();
        }
    }, [experience]);
    useEffect(() => {
        if (initialData != null && values.weeklyHrsId != null && values.weeklyHrs == null) {
            loadHoursExp();
        }
    }, [weeklyHours]);

    useEffect(() => {
        if (values.industryType && values.industryType != "") {
            let selected = [];
            let removedlist = [];
            values.industryType.toString().split(',').forEach((value) => {
                let removed = [];
                let data = industryTypeList.filter(item => item["id"] == value);

                if (data.length > 0) { selected.push(data[0]) }
                removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : industryTypeList.filter(item => item["id"] != value);
                removedlist = removed;
            });
            setSelectedIndustryData(selected)
            setIndustryTypeList(removedlist);

        }
    }, [fullIndustryType]);



    useEffect(() => {
        if (initialData != null && initialData.length > 0 && initialData[0].skillids != null) { 
            let selected = [];
            let removedlist = [];
            initialData[0].skillids.split(',').forEach((value) => {
                let removed = [];
                let data = skills.filter(item => item["id"] == value);
                if (data.length > 0) { selected.push(data[0]) }
                removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : skills.filter(item => item["id"] != value);
                removedlist = removed;
            });
            setSelectedSkillData(selected);
           
            setSkillsList(removedlist);
          
        }
    }, [fullSkillSet]);
    useEffect(() => {
        if (initialData != null && initialData.length > 0 && initialData[0].compids) { 
            let selected = [];
            let removedlist = [];
            initialData[0].compids.split(',').forEach((value) => {
                let removed = [];
                let data = compliances.filter(item => item["id"] == value);
                if (data.length > 0) { selected.push(data[0]) }
                removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : compliances.filter(item => item["id"] != value);
                removedlist = removed;
            });
            setSelectedCompliance(selected)
            setComplianceList(removedlist);
            setEditvalue(values.profileDetails);
        }
    }, [fullCompliance]);

    useEffect(() => {
        if (values.compCategory && values.compCategory != "") {
            let selected = [];
            let removedlist = [];
            values.compCategory.split(',').forEach((value) => {
                let removed = [];
                let data = compCategory.filter(item => item["id"] == value);
                if (data.length > 0) { selected.push(data[0]) }
                removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : compCategory.filter(item => item["id"] != value);
                removedlist = removed;
            });
            setSelectedCompCategory(selected)
            setCompCategory(removedlist);

        }

    }, [fullComplianceCategory]);
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);

    const popoverResume = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>Allowed format pdf,jpeg,png,jpg</p>

            </div> 
        </Popover>
    );
    const popoverWeeklyHrs = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>Provide your availability to perform the work</p>

            </div> 
        </Popover>
    );
    const popoverSkillSet = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>

                    Select the functional role required based on the desired skills. For instance, for skills in documentation support, choose "Documentation," and for expertise in internal audit, select "Internal Auditor."
                </p>

            </div>




        </Popover>

    );
    const popoverCompliance = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p   >

                    <strong>Choose the category based on the job details:</strong> 



                    <br />  For Internal Audit (1st and 2nd Party), select "Auditor: 1st and 2nd Party."

                    <br />  For Training purposes, select "Training."

                    <br />  For External / 3rd Party Audit, choose "Auditor 3rd Party."

                    <br />  For Documentation/QA/Guidance/Consulting, select "Consultant." Please note, you cannot select both "3rd Party" and "Consultant" simultaneously.

                    <br />  A <strong>Consultant</strong> is a person who is an expert in ISO/Compliance standards and has an extensive experience in implementing the standards, following the guidelines and has worked in a variety of industries.

                                                                              <br />   A<strong> first-party audit</strong> is performed inside an organization to measure its strength and weakness against its systems or techniques or potentially against external standard principles embraced by the organization. A first-party audit is an internal audit led by auditors who are working in the organization but who have no personal stake in the audit results of the area being audited.

                                                                              <br />  A <strong> second-party audit</strong> is an external audit performed on a supplier by a client or by a contracted organization for a client. An agreement is set up, and the services and products are being or will be, conveyed. Second-party audits are dependent upon the guidelines of agreement law, as they are legally binding to the client and the supplier. Second-party audits will quite often be more formal than first-party audits since audit results could impact the client's buying choices.

                                                                             <br />   A <strong>third-party audit</strong> is performed by an audit organization independent of the client-supplier relationship and is liberated from an irreconcilable circumstance. The independence of the audit organization is a vital part of an external audit. Third-party reviews might bring about certification, enlistment, acknowledgment, an honor, license endorsement, a reference, a fine, or punishment given by the 3rd party organization.

                                                                             <br />  <strong> 1st party auditor</strong> can be an internal resource of organization  who conducts review of organizational systems.

                                                                              <br />  <strong> 2nd party auditor</strong> can be an internal or external resource conducting review of supplier /vendor systems.

                                                                              <br /> <strong>  3rd party auditor</strong> are affiliated to 3rd independent bodies to perform audit of the organizational system.

                                                                   </p>

            </div>

           



        </Popover>
        
    );
const popoverDescription = (
    <Popover className="   " >

       

        <div className="tooltip-header bg-gray">
            <h5>Information</h5>
        </div>
        <div className="tooltip-meta">
            <p> Provide a high level summary of your experience and relevant background. </p>

        </div>

    </Popover>
       
    );
    return (
        <>

          

            {loaded &&   <div className="card panel  p-0">

                    <div className="card-header">

                        <div className="panel-title">
                            <h6>PROFESSIONAL</h6>
                        </div>
                        <div className="row d-none ">
                            {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                            {errorMessage !== "" && <><span className="text-red">{errorMessage} </span><br /></>}

                        </div>

                        <div className="panel-action">
                            <button className="btn btn-orange  btn-sm bg-green m-r-20 text-white" onClick={() => {
                                updateProfile();

                            }}>Save</button>
                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                cancelEdit();

                            }}>Cancel</button>
                        </div>

                    </div>

                    <div className="card-body p-b-0">

                        <div className="row">

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="forn-group-input">
                                    <label for="title" className="form-label m-b-10">Title<span className="text-red"> * </span></label>
                                <input type="text" defaultValue={values.title} value={values.title}
                                    className={`form-control   ${(values.error.title.length > 0) ? 'error' : ''}`}
                                    id="Title"
                                    name="title"
                                    placeholder="eg.Software Engineer" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                />{values.error.title.length > 1 && (
                                    <span className="text-red">{values.error.title}</span>
                                )}
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="forn-group-input">
                                <label for="lastname" className="form-label m-b-10">Experience Level<span className="text-red"> * </span></label>
                                <Select name="expLevel" value={values.expLevel}
                                    options={experience} placeholder="Select..."
                                    className={`form-select padding-select  ${(values.error.expLevel.length > 0) ? 'error' : ''}`}
                                    border={false} onChange={(e) => handleSelectChange(e, 'expLevel')}
                                />
                                {values.error.expLevel.length > 1 && (
                                    <span className="text-red">{values.error.expLevel}</span>
                                )}
                                </div>
                            </div>

                        </div>

                    <div className="company-desc">
                        <label for="first name" className="form-label m-b-0 d-flex">Profile Summary <OverlayTrigger trigger="hover" placement="auto" overlay={popoverDescription} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger>  </label>
                            <div className="desc-info">
                             
                            <span className="text-red"> * (Max 4000 characters) </span>
                            <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green"> {((editLength != null) ? 4000 - editLength : 0)}/4000</span></p>
                            </div>

                        <ReactQuill modules={modules}
                            formats={formats} theme="snow" id="editor" style={{ height: "20vh" }} className="w-full m-b-40"
                            onKeyDown={editorKeyDown}
                            ref={reactQuillRef}
                            value={editvalue} onChange={editorChange}
                        ></ReactQuill>
                        {values.error.profileDetails.length > 1 && (
                            <span className="text-red m-t-5">{values.error.profileDetails}</span>
                        )}

                        </div>

                        <div className="row">

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="forn-group-input">
                                    <label className="form-label m-b-10">Daily Rate<span className="text-red"> * </span>(USD)</label>
                                <input name="dailyRate"
                                    type="text" className={`form-control   ${(values.error.dailyRate.length > 0) ? 'error' : ''}`}

                                    id="dailyRate" defaultValue={values.dailyRate} value={values.dailyRate} onKeyUp={handleFieldsChange}
                                    placeholder="100" onChange={handleFieldsChange}
                                />
                                {values.error.dailyRate.length > 1 && (
                                    <span className="text-red">{values.error.dailyRate}</span>
                                )}
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="forn-group-input">
                                <label className="form-label m-b-10 d-flex">Available weekly hours<span className="text-red"> * </span> <OverlayTrigger trigger="hover" placement="auto" overlay={popoverWeeklyHrs} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger></label>
                                <Select options={weeklyHours}
                                    placeholder="Select..." value={values.weeklyHrs}
                                    className={`form-select padding-select ${(values.error.weeklyHrs.length > 0) ? 'error' : ''}`}
                                    border={false} onChange={(e) => handleSelectChange(e, 'weeklyHrs')}
                                />
                                {values.error.weeklyHrs.length > 1 && (
                                    <span className="text-red">{values.error.weeklyHrs}</span>
                                )}
                                </div>
                            </div>

                    </div>

                    <div className="company-meta">
                        <h6 className="d-flex">Compliance Standard<span className="text-red"> * </span></h6>
                        {values.error.compliance.length > 1 && (
                            <span className="text-red">{values.error.compliance}</span>
                        )}
                        {selectedCompliances.length > 0 && <>  <div className="badge-btn-group">

                            {selectedCompliances.map((data, index) => {
                                const { id, code } = data;
                                return ((selectedCompliances.length > 0) ?
                                    <span className="badge compliance-standard btn btn-sm pointer" key={index} onClick={() => { addToCompliance(id) }}>{code}
                                        <button className="btn-icon text-light-gray m-l-5 m-icon material-icons">close</button>
                                    </span>


                                    : '')
                            })}



                        </div></>}

                    </div>

                    {selectedCompliances.length > 0 && <>   <div className="divider-line-20"></div></>}

                    <div className="badge-group">
                        {compliances.map((data, index) => {

                            const { code, id } = data;
                            return (

                                <span key={index} onClick={() => { removeCompliance(id) }} title="Click to select compliance" data-toggle="tooltip" className="badge compliance-standard pointer "> {code}</span>
                            )
                        })}


                    </div>

                    <div className="company-meta">
                        <h6 className="d-flex">Compliance Category<span className="text-red"> *  </span>


                            <OverlayTrigger trigger="hover" placement="top" overlay={popoverCompliance} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger> </h6>
                        {values.error.compCategory.length > 1 && (
                            <span className="text-red">{values.error.compCategory}</span>
                        )}



                        {selectedCompCategory.length > 0 && <>
                            <div className="badge-btn-group">

                                {selectedCompCategory.length > 0 && <>

                                    {selectedCompCategory.map((data, index) => {
                                        const { id, code, category } = data;
                                        return ((selectedCompCategory.length > 0) ?
                                            <span className="badge  compliance-category btn btn-sm pointer" key={index} onClick={() => { addToCompCategory(id, code) }}>{category}
                                                <button className="btn-icon text-light-gray m-l-5 m-icon material-icons">close</button>
                                            </span>
                                            : '')
                                    })}



                                </>}
                            </div></>}</div>


                    {selectedCompCategory.length > 0 && <>    <div className="divider-line-20"></div></>}



                    <div className="badge-group">
                        {compCategory.map((data, index) => {
                            const { id, code, category } = data;
                            return (<li className="badge compliance-category" key={index}>
                                {(selectedCompCategory.length === 0 || (selectedCompCategory.length > 0 && selectedCompCategory[0].code == "400")) &&

                                    <a title="Click to select" data-toggle="tooltip" className="text-gray pointer" onClick={() => { removeCompCategory(id, code) }} >
                                        {category}
                                    </a>
                                }
                                {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "100") &&

                                    <a title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="text-gray pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                        {category}
                                    </a>
                                }
                                {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "200") &&

                                    <a title={(code == "400") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code == "400")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="text-gray pointer" onClick={() => { if (code == "400") { removeCompCategory(id, code) } }} >
                                        {category}
                                    </a>
                                }
                                {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "300") &&

                                    <a title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="text-gray pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                        {category}
                                    </a>
                                }

                            </li>)
                        })}


                    </div>
                    <div className="company-meta">
                        <h6>Skill set <span className="text-red"> *</span>  <OverlayTrigger trigger="hover" placement="auto" overlay={popoverSkillSet} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger></h6>
                        {values.error.skillSet.length > 1 && (
                            <span className="text-red">{values.error.skillSet}</span>
                        )}
                        {selectedSkillData.length > 0 && <>    <div className="badge-btn-group">



                            {selectedSkillData.map((data, index) => {
                                const { id, skill } = data;
                                return ((selectedSkillData.length > 0) ?

                                    <span className="badge skills btn btn-sm pointer" key={index} onClick={() => { addToSkills(id) }}> {skill}
                                        <button className="btn-icon text-light-gray m-l-5 m-icon material-icons">close</button>
                                    </span>
                                    : '')
                            })}


                        </div></>}
                    </div>


                    {selectedSkillData.length > 0 && <>    <div className="divider-line-20"></div></>}

                    <div className="badge-group">
                        {skills.map((data, index) => {
                            const { id, skill } = data;
                            return (
                                <span className="badge skills pointer" key={index} onClick={() => { removeSelectedSkill(id) }}>{skill}</span>
                            )
                        })}

                    </div>

                        <div className="company-meta">
                            <h6>Industry types <span className="text-red"> * </span></h6>
                        {values.error.industryType.length > 1 && (
                            <span className="text-red">{values.error.industryType}</span>
                        )}
                        {selectedIndustryData.length > 0 && <>   <div className="badge-btn-group">
                           

                            {selectedIndustryData.map((data, index) => {
                                const { id, name } = data;
                                return ((selectedIndustryData.length > 0) ?

                                    <span key={index} title="Click to remove" data-toggle="tooltip" className="badge industry-types btn btn-sm pointer" onClick={() => { addToIndustryType(id) }}> {name}
<button className="btn-icon text-light-gray m-l-5 m-icon material-icons">close</button>
                                    </span>
                                    : '')
                            })}
                        </div></>}
                    </div>


                    {selectedIndustryData.length > 0 && <>    <div className="divider-line-20"></div></>}

                        

                    <div className="badge-group">
                        {industryTypeList.map((data, index) => {
                            const { id, name } = data;
                            return ( 


                                <span key={index} title="Click to select" data-toggle="tooltip" className="badge industry-types pointer" onClick={() => { removeSelectedIndustryType(id) }} >{name}</span>
                               

                             )
                        })}
                            
                        </div>

                   

                     
                      
                        <div className="company-meta m-b-20">
                        <h6 className="d-flex">Upload Resume  <OverlayTrigger trigger="hover" placement="auto" overlay={popoverResume} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger></h6>
                        {(values.resumeName === "") &&
                          <>
                            <label
                                htmlFor="fileUpload"
                                className="mb-0  "
                                style={{ cursor: 'pointer' }}>
                                {(!values.resume) &&

                                <>    <div class="inline-icon-info">
                                        <div class="upload-file p-relative">
                                        <span class="text-light-gray m-icon material-icons m-r-10" data-toggle="tooltip" title="Click here to add file" >upload_file</span>Upload a resume
                     
                    </div>
                                        </div>


                                   </>}

                            </label>
                          

                            {(values.resume) && <>

                                <div className="upload-doc-img m-b-10">
                                    <div className="workers-img">
                                        <img src="../images/file.png" htmlFor="idUpload" />
                                        <div className="btn-icon-close" data-toggle="tooltip" title="Remove Resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: values.resetResumeName })}>
                                            <span className="m-icon material-icons">close</span>
                                        </div>
                                    </div>


                                  

                                </div>

                                
                                </>
                            }

                            {(values.resume) && <span className="text-gray" > {(values.resume) ? values.resume.name : ''} </span>}




                            <input
                                type="file" key={values.resume }
                                id="fileUpload" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                className="sr-only"
                                onChange={onFileChange} onSelect={onFileChange}
                            />

                        </ >}
                        {(values.resumeName !== "") &&
                            <>  

                            <div className="upload-doc-img m-b-10">
                                <div className="workers-img pointer"  >
                                    <img src="../images/file.png" htmlFor="idUpload" data-toggle="tooltip" title="Click here to view." onClick={() => { window.open(s3URL + values.resumeName, "_blank"); }} />
                               


                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: "" })}>
                                    <span className="m-icon material-icons">close</span>
                                </div>
                                </div>
                            </div>
                                 </>}
                           
                        </div>

                    </div>

                    <div className="card-footer">
                        <div className="panel-action">
                        <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                            updateProfile();

                        }}>Save</button>
                            <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                cancelEdit();

                            }}>Cancel</button>
                        </div>
                    </div>

                </div> }
            {!loaded &&
                <div className="tab-pane active show" role="tabpanel">




                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title movingLoader w-150">
                                <h6>&nbsp;</h6>
                            </div>

                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>

                            </div>

                        </div>

                        <div className="card-body p-b-0">


                            <div className="row">

                                 

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName" 

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName" style={{ height: '150px' }}

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>
                            </div>
                        </div>

                    </div>
                </div>}

            <div
                className="dashboard-main-container mt-20 mt-lg-25 d-none"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-23">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">


                                <div className="row ">

                                    <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 ">


                                        <div className="row d-none">
                                            {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                                            {errorMessage !== "" && <><span className="error    w-100  font-weight-semibold line-height-reset px-8 py-5 font-size-5">{errorMessage} </span><br /></>}
                                         
                                        </div>
                                        



                                    </div>
                                </div>

                            </div>
                            {loaded && <div className="col-xxxl-12  ">
                                <div className="pb-6">
                                    <div className="width-100  float-left  height-100px background-gray margin-10">
                                        <div className="row justify-content-center">
                                            <div className="col-12 dark-mode-texts">
                                                <div className="py-5">


                                                    <button type="button"
                                                        className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                            prevPage();
                                                        }}>&laquo; Prev
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                        <div className="row justify-content-end">
                                            <div className="col-12 dark-mode-texts">
                                                <div className="py-5">


                                                    <button type="button"
                                                        className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                            nextPage();
                                                        }}>NEXT &raquo;
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {gContext.currentPage === "6" &&
                                    <>  <h5 className="font-size-8 font-weight-semibold mb-11">

                                    </h5>
                                        <div className="contact-form bg-white shadow-8 ">
                                            <div className="font-size-6 font-weight-semibold mb-11    bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                                Professional Information
                                            </div>
                                            <form action="/" className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                                <fieldset>

                                                    <div className="row mb-xl-1 mb-9">

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Title <span className="text-red">*</span>
                                                                </label>
                                                                <input
                                                                    type="text" defaultValue={values.title} value={values.title}
                                                                    className={`form-control   ${(values.error.title.length > 0) ? 'error' : ''}`}
                                                                    id="Title"
                                                                    name="title"
                                                                    placeholder="eg.Software Engineer" onChange={handleFieldsChange} onKeyUp={handleFieldsChange}
                                                                />{values.error.title.length > 1 && (
                                                                    <span className="text-red">{values.error.title}</span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Experience Level <span className="text-red">* </span>
                                                                </label>
                                                                <Select name="expLevel" value={values.expLevel}
                                                                    options={experience} placeholder="Select..."
                                                                    className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(values.error.expLevel.length > 0) ? 'error' : ''}`}
                                                                    border={false} onChange={(e) => handleSelectChange(e, 'expLevel')}
                                                                />
                                                                {values.error.expLevel.length > 1 && (
                                                                    <span className="text-red">{values.error.expLevel}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">


                                                                <label
                                                                    htmlFor="aboutTextarea"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4 media"
                                                                >
                                                                    <div className="media mb-0 pb-0">  Profile Summary<span className="text-red">&nbsp;* ( Max 4000 Character)</span>
                                                                        <div className="position-relative w-50" >
                                                                            <div className="circle-20 bg-green-2 ml-3 font-family-Cascadia Code  pointer text-white font-size-4  "
                                                                                onMouseOver={() => setTooltip(!tooltip)} onMouseLeave={() => setTooltip(!tooltip)}>
                                                                                <i className="fa fa-info  font-size-2 " onMouseOver={() => setTooltip(!tooltip)} onMouseLeave={() => setTooltip(!tooltip)}></i>  </div>
                                                                            {tooltip === true && <div className="focus-reset mt-10   w-80 pos-abs-tl   z-index-supper bg-white rounded">
                                                                                <div className=" border w-80  px-5 font-size-3  z-index-supper bg-gray-opacity-1 rounded shadow-12">
                                                                                    <p className="font-size-3 pt-1 font-weight-normal  text-black line-height-1p4"  >
                                                                                        <strong className="line-height-1p6">Information</strong> <br />Please add your professional capabilities with core competencies here in details.</p> </div>

                                                                            </div>}</div></div>
                                                                    <span className="font-size-3 text-green-2 font-weight-semibold">
                                                                       Remaining Character length: {((editLength != null) ? 4000 - editLength : 0)}</span>
                                                                </label>



                                                                
                                                                {values.error.profileDetails.length > 1 && (
                                                                    <span className="text-red">{values.error.profileDetails}</span>
                                                                )}
                                                            </div>

                                                        </div>
                                                        <div className="col-md-12 d-none">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="aboutTextarea"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Profile Summary  <span className="text-red">* ( Max 4000 Character)</span>
                                                                    <br /><span className="font-size-3 text-green-2 font-weight-semibold"> Remaining Character length: {4000 - ((values.profileDetails) ? values.profileDetails.length : 0)}</span>
                                                                </label>
                                                                <textarea
                                                                    name="profileDetails"
                                                                    id="profileDetails" maxLength="4000"
                                                                    cols="30"
                                                                    rows="7" defaultValue={values.profileDetails} Value={values.profileDetails}
                                                                    className={`border   ${(values.error.profileDetails.length > 0) ? 'border-red' : 'border-mercury'} text-gray w-100 pt-4 pl-6`}
                                                                    placeholder="Describe about your profile what make you unique" onKeyUp={handleFieldsChange} onChange={handleFieldsChange}
                                                                ></textarea>{values.error.profileDetails.length > 1 && (
                                                                    <span className="text-red">{values.error.profileDetails}</span>
                                                                )}
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-6 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Daily Rate <span className="text-red"> * </span> <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                                </label>
                                                                <input name="dailyRate"
                                                                    type="text" className={`form-control   ${(values.error.dailyRate.length > 0) ? 'error' : ''}`}

                                                                    id="dailyRate" defaultValue={values.dailyRate} value={values.dailyRate} onKeyUp={handleFieldsChange}
                                                                    placeholder=" " onChange={handleFieldsChange}
                                                                />
                                                                {values.error.dailyRate.length > 1 && (
                                                                    <span className="text-red">{values.error.dailyRate}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                Available weekly hours <span className="text-red"> *</span> <OverlayTrigger trigger="hover" placement="auto" overlay={popoverWeeklyHrs} ><span className="text-theme m-icon material-icons m-l-5"  >info</span></OverlayTrigger>
                                                                </label>
                                                                <Select options={weeklyHours}
                                                                    placeholder="Select..." value={values.weeklyHrs}
                                                                    className={`form-control pl-0 arrow-3 w-100 font-size-4 d-flex align-items-center w-100   ${(values.error.weeklyHrs.length > 0) ? 'error' : ''}`}
                                                                    border={false} onChange={(e) => handleSelectChange(e, 'weeklyHrs')}
                                                                />
                                                                {values.error.weeklyHrs.length > 1 && (
                                                                    <span className="text-red">{values.error.weeklyHrs}</span>
                                                                )}
                                                            </div>
                                                        </div>



                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Industry Types <span className="text-red">* </span>
                                                                </label>
                                                                {values.error.industryType.length > 1 && (
                                                                    <span className="text-red">{values.error.industryType}</span>
                                                                )}
                                                                {selectedIndustryData.length > 0 && <>       <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedIndustryData.map((data, index) => {
                                                                        const { id, name } = data;
                                                                        return ((selectedIndustryData.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip"
                                                                                className="bg-red-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer"
                                                                                onClick={() => { addToIndustryType(id) }} >
                                                                                {name}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 ">
                                                                    </div></>}
                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {industryTypeList.map((data, index) => {
                                                                        const { id, name } = data;
                                                                        return (<li key={index}>



                                                                            <a title="Click to select" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer"
                                                                                onClick={() => { removeSelectedIndustryType(id) }} >
                                                                                {name}
                                                                            </a>

                                                                        </li>)
                                                                    })}





                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Skill set <span className="text-red">* </span>
                                                                </label>
                                                                {values.error.skillSet.length > 1 && (
                                                                    <span className="text-red">{values.error.skillSet}</span>
                                                                )}
                                                                {selectedSkillData.length > 0 && <>         <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedSkillData.map((data, index) => {
                                                                        const { id, skill } = data;
                                                                        return ((selectedSkillData.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip" 
                                                                            className="bg-orange-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer"
                                                                             onClick={() => { addToSkills(id) }} >
                                                                                {skill}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 ">
                                                                    </div></>}
                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {skills.map((data, index) => {
                                                                        const { id, skill } = data;
                                                                        return (<li key={index}>

                                                                            <a title="Click to select" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => {removeSelectedSkill(id) }} >
                                                                                {skill}
                                                                            </a>

                                                                        </li>)
                                                                    })}





                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                            <label
                                                                htmlFor="select2"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4 media "
                                                            >
                                                                <div className="media mb-0 pb-0 w-100">   Compliance Category <span className="text-red">* </span>
                                                                    <div className="position-relative w-75">
                                                                        <span className="circle-20 bg-green-2 ml-3 w-100 font-family-Cascadia Code  pointer text-white font-size-4  "
                                                                            onMouseOver={() => setTooltip(!tooltip)}
                                                                            onMouseLeave={() => setTooltip(!tooltip)}>
                                                                            <i className="fa fa-info  font-size-2 "></i>  </span>
                                                                        {tooltip === true && <div className="focus-reset mt-10   w-100 pos-abs-tl   z-index-supper bg-white rounded">
                                                                            <div className=" border w-100  px-5 font-size-3  z-index-supper bg-gray-opacity-1 rounded shadow-12">
                                                                                <p className="font-size-3 pt-1 font-weight-normal  text-black line-height-1p4"  >
                                                                                    A <strong>Consultant</strong> is a person who is an expert in ISO/Compliance standards and has an extensive experience in implementing the standards, following the guidelines and has worked in a variety of industries.

                                                                              <br />   A<strong> first-party audit</strong> is performed inside an organization to measure its strength and weakness against its systems or techniques or potentially against external standard principles embraced by the organization. A first-party audit is an internal audit led by auditors who are working in the organization but who have no personal stake in the audit results of the area being audited.

                                                                              <br />  A <strong> second-party audit</strong> is an external audit performed on a supplier by a client or by a contracted organization for a client. An agreement is set up, and the services and products are being or will be, conveyed. Second-party audits are dependent upon the guidelines of agreement law, as they are legally binding to the client and the supplier. Second-party audits will quite often be more formal than first-party audits since audit results could impact the client's buying choices.

                                                                             <br />   A <strong>third-party audit</strong> is performed by an audit organization independent of the client-supplier relationship and is liberated from an irreconcilable circumstance. The independence of the audit organization is a vital part of an external audit. Third-party reviews might bring about certification, enlistment, acknowledgment, an honor, license endorsement, a reference, a fine, or punishment given by the 3rd party organization.

                                                                             <br />  <strong> 1st party auditor</strong> can be an internal resource of organization  who conducts review of organizational systems.

                                                                              <br />  <strong> 2nd party auditor</strong> can be an internal or external resource conducting review of supplier /vendor systems.

                                                                              <br /> <strong>  3rd party auditor</strong> are affiliated to 3rd independent bodies to perform audit of the organizational system.

                                                                   </p></div> </div>}</div></div>
                                                            </label>
                                                                {values.error.compCategory.length > 1 && (
                                                                    <span className="text-red">{values.error.compCategory}</span>
                                                                )}
                                                                {selectedCompCategory.length > 0 && <>       <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedCompCategory.map((data, index) => {
                                                                        const { id, code, category } = data;
                                                                        return ((selectedCompCategory.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip" className="bg-yellow-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { addToCompCategory(id, code) }} >
                                                                                {category}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}
                                                                </ul>
                                                                    <div className="or-devider py-5 ">
                                                                    </div></>}
                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {compCategory.map((data, index) => {
                                                                        const { id, code, category } = data;
                                                                        return (<li key={index}>
                                                                            {(selectedCompCategory.length === 0 || (selectedCompCategory.length > 0 && selectedCompCategory[0].code == "400")) &&

                                                                                <a title="Click to select" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { removeCompCategory(id, code) }} >
                                                                                    {category}
                                                                                </a>
                                                                            }
                                                                            {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "100") &&

                                                                                <a title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                                                                    {category}
                                                                                </a>
                                                                            }
                                                                            {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "200") &&

                                                                                <a title={(code == "400") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code == "400")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center pointer" onClick={() => { if (code == "400") { removeCompCategory(id, code) } }} >
                                                                                    {category}
                                                                                </a>
                                                                            }
                                                                            {(selectedCompCategory.length > 0 && selectedCompCategory[0].code == "300") &&

                                                                                <a title={(code != "200") ? 'Click to select' : 'Not allowed'} style={{ cursor: ((code != "200")) ? '' : 'not-allowed' }} data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-3 min-height-32 d-flex align-items-center pointer" onClick={() => { if (code != "200") { removeCompCategory(id, code) } }} >
                                                                                    {category}
                                                                                </a>
                                                                            }

                                                                        </li>)
                                                                    })}





                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 ">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="select2"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Compliance Standards <span className="text-red">* </span>
                                                                </label>
                                                                {values.error.compliance.length > 1 && (
                                                                    <span className="text-red">{values.error.compliance}</span>
                                                                )}
                                                                {selectedCompliances.length > 0 && <>     <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {selectedCompliances.map((data, index) => {
                                                                        const { id, code } = data;
                                                                        return ((selectedCompliances.length > 0) ? <li key={index}>

                                                                            <a title="Click to remove" data-toggle="tooltip" className="bg-green-opacity-2 position-relative text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { addToCompliance(id) }} >
                                                                                {code}
                                                                                <i className="fas fa-times text-black-2 pos-abs-tr  p-1   "></i>

                                                                            </a>

                                                                        </li> : '')
                                                                    })}


                                                                </ul>

                                                                    <div className="or-devider py-5 ">
                                                                    </div></>}

                                                                <ul className="list-unstyled d-flex align-items-center flex-wrap">

                                                                    {compliances.map((data, index) => {

                                                                        const { code, id } = data;
                                                                        return (<li key={index}>

                                                                            <a title="Click to select compliance" data-toggle="tooltip" className="bg-polar text-black-2  mr-6 px-7 mt-2 mb-2 font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer" onClick={() => { removeCompliance(id) }} >
                                                                                {code}
                                                                            </a>

                                                                        </li>)
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 mb-8">
                                                            <div className="form-group">

                                                                <label
                                                                    htmlFor="labelupload"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    <div className="media mb-0 pb-0">  Upload Resume
                                                                        <div className="position-relative w-50"  >
                                                                            <div className="circle-20 bg-green-2 ml-3 font-family-Cascadia Code  pointer text-white font-size-4  "
                                                                                onMouseOver={() => setUploadTooltip(!uploadtooltip)}
                                                                                onMouseLeave={() => setUploadTooltip(!uploadtooltip)}>
                                                                                <i className="fa fa-info  font-size-2 "></i>  </div>
                                                                            {uploadtooltip === true && <div className="focus-reset mt-10   w-80 pos-abs-tl   z-index-supper bg-white rounded">
                                                                                <div className=" border w-50  px-5 font-size-3  z-index-supper bg-gray-opacity-1 rounded shadow-12">
                                                                                    <p className="font-size-3 pt-1 font-weight-normal  text-black line-height-1p4"  >
                                                                                        <strong className="line-height-1p6">Information</strong> <br />Upload only .pdf,.jpg,.png,.jpeg extensions file.</p> </div>

                                                                            </div>}</div></div>

                                                                </label>
                                                                {(values.resumeName === "") && <div className="upload-file mb-8 text-left " style={{ cursor: 'pointer' }}>



                                                                    <label
                                                                        htmlFor="fileUpload"
                                                                        className="mb-0 font-size-3"
                                                                        style={{ cursor: 'pointer' }}>
                                                                        {(!values.resume) && <div className=" m-0 p-0   text-center  "  >
                                                                            <i className="fa-solid   fa-file-arrow-up font-size-12 pointer text-green " data-toggle="tooltip" title="Click here to add file"></i>
                                                                        </div>}

                                                                    </label>

                                                                    {(values.resume) && <div className=" m-0 p-0 position-relative circle-100 bg-green-opacity-2 border border-width-1 text-center  image-container"  >
                                                                        <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i>
                                                                        <div className="text-center border border-width-1 border-red circle-30 remove pos-abs-br bg-white "  >
                                                                            <i className="fas fa-trash   pointer font-size-4 text-red" data-toggle="tooltip"
                                                                                title="Remove resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: values.resetResumeName })}></i></div></div>}

                                                                    {(values.resume) && <span className=" pt-5 font-size-4 text-green-2"> {(values.resume) ? values.resume.name : ''} </span>}




                                                                    

                                                                </div>}
                                                                {(values.resumeName !== "") &&
                                                                    <> <div className=" m-0 p-0 position-relative circle-100 bg-green-opacity-2 border border-width-1 text-center  image-container"  ><a href={s3URL + values.resumeName} target="blank" data-toggle="tooltip" title="Click here to view."> <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i></a>  <div className="text-center border border-width-1 border-red circle-30 remove pos-abs-br bg-white "  > <i className="fas fa-trash   pointer font-size-4 text-red" data-toggle="tooltip" title="Remove resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: '' })}></i></div></div></>}
                                                            </div>

                                                        </div>




                                                    </div>




                                                    <div className="col-lg-12  justify-content-md-end">
                                                        <div className="media py-10 w-100  justify-content-md-end ml-9"  >
                                                            <div className="p-0 m-0 position-relative"> <button
                                                                type="button"

                                                                className="btn btn-primary btn-medium    btn-green  text-white  rounded-5 text-uppercase"
                                                                style={{ minWidth: '60px', height: '34px' }}
                                                                onClick={() => {
                                                                    updateProfile();

                                                                }}>
                                                                Save
                                                            </button>
                                                            </div>
                                                            <div className="p-0 m-0 position-relative">
                                                                <button
                                                                    type="button"

                                                                    className="btn btn-primary  btn-medium  btn-green  text-white  ml-5 ml-sm-3 rounded-5 text-uppercase"
                                                                    style={{ minWidth: '80px', height: '34px' }} onClick={() => {
                                                                        cancelEdit();

                                                                    }}>
                                                                    Cancel
                                                                </button></div></div>

                                                    </div>








                                                </fieldset>
                                            </form>
                                        </div>
                                    </>
                                }
                                <div className="width-100  float-left  height-100px background-gray margin-10">
                                    <div className="row justify-content-center">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">


                                                <button type="button"
                                                    className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                        prevPage();
                                                    }}>&laquo; PREV
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                    <div className="row justify-content-end">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">


                                                <button type="button"
                                                    className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                        nextPage();
                                                    }}>NEXT &raquo;
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>}
                            {!loaded &&
                                <div className="col-xxxl-12 pt-15 " >
                                    <div className="contact-form  bg-white rounded-4 ">


                                        <div className="font-size-6 font-weight-semibold mb-11 py-8   movingLoader pl-sm-10 pl-4 pr-sm-11 pr-4  ">

                                        </div>


                                        <div className=" pl-sm-10 pl-4 error font-size-4 pt-2 pb-3"> <br /></div>


                                        <form className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-10">
                                            <fieldset>
                                                <div className="row mb-xl-1 mb-9">

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input


                                                                type="text"
                                                                className={`form-control movingLoader `}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input

                                                                type="text"
                                                                className={`form-control movingLoader `}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input


                                                                type="text"
                                                                className={`form-control movingLoader py-20 `}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input


                                                                type="text"
                                                                className={`form-control movingLoader `}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input

                                                                type="text"
                                                                className={`form-control movingLoader `}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input


                                                                type="text"
                                                                className={`form-control movingLoader `}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input

                                                                type="text"
                                                                className={`form-control movingLoader `}
                                                            />
                                                        </div>
                                                    </div> <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input


                                                                type="text"
                                                                className={`form-control movingLoader `}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input

                                                                type="text"
                                                                className={`form-control movingLoader `}
                                                            />
                                                        </div>
                                                    </div> <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input


                                                                type="text"
                                                                className={`form-control movingLoader `}

                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >

                                                            </label>
                                                            <input

                                                                type="text"
                                                                className={`form-control movingLoader `}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12  justify-content-md-end">
                                                    <div className="media py-10 w-100  justify-content-md-end ml-9"  >
                                                        <div className="p-0 m-0 position-relative">
                                                            <label


                                                                className="form-control movingLoader  movingLoader rounded-5 text-uppercase"
                                                                style={{ minWidth: '60px', height: '34px' }}
                                                            >

                                                            </label>


                                                        </div>
                                                        <div className="px-5 m-0 position-relative ">
                                                            <label


                                                                className="form-control movingLoader  movingLoader rounded-5 text-uppercase"
                                                                style={{ minWidth: '60px', height: '34px' }}
                                                            >

                                                            </label>

                                                        </div>
                                                    </div></div>

                                            </fieldset>
                                        </form>
                                    </div> </div>}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Professional;
