import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
const imgP = "../assets/image/avatar.png";
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const ModalGeneral = (props) => {

    const gContext = useContext(GlobalContext);

    const handleClose = () => {
        gContext.setGeneral('expert');
        gContext.toggleGeneralModal();
    };

    return (
        <ModalStyled
            {...props}
            size="lg"
            centered
            show={gContext.generalModalVisible}
            onHide={gContext.toggleGeneralModal}
        >
            <Modal.Body className="p-0">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                    onClick={handleClose}
                >
                    <i className="fas fa-times"></i>
                </button>
                <div className="login-modal-main bg-athens rounded-8 overflow-hidden">
                   
                    <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                        <div className="d-flex align-items-top pr-11 mb-9 flex-wrap flex-sm-nowrap">
                            <div className="square-72 d-block mr-8 mb-7 mb-sm-0">
                                <img src={imgP} alt="" width="80px" />
                            </div>
                            <div className="w-100 mt-n2">
                                <h3 className="mb-0">

                                    <a className="font-size-5 font-weight-semibold text-black-2">
                                        Bradley
                              </a>

                                </h3>

                                <a className="font-size-4 text-default-color line-height-2">
                                    AWS
                            </a>

                                <div className="d-flex align-items-center justify-content-md-between flex-wrap">

                                    <p className="font-size-4 text-gray pt-5">
                                        <i className="fa-solid fa-quote-left text-green  pb-3"></i>     He is a leader in building a strong team spirit and identity. All his actions are directed toward the success of the team over his own interests. He exhibits a high degree of openness and objectivity to the views of others. He has been able to balance the needs of the team with his individual responsibilities.    <i className="fa-solid fa-quote-right text-green  pl-3"></i>  
                              </p>


                                    

                                </div>
                                <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                    <p className="font-size-4 text-gray">
                                        
                              </p>
                                    
                                    <a className="font-size-3 ">

                                        <i className="fas fa-star mr-1  text-green"></i><i className="fas fa-star mr-1  text-green"></i><i className="fas fa-star mr-1  text-green"></i><i className="fas fa-star mr-1  text-green"></i><i className="fas fa-star mr-1  text-gray"></i>
                                    </a>

                                </div>
                            </div>
                        </div>

                         </div>
                </div>

                {/*    <div className="login-modal-main bg-white rounded-8 overflow-hidden">
                  <div className="media   px-10 py-5 bg-green-opacity-3  ">




                      <div className=" align-items-left  ">
                          <h3 className="mb-0">

                              <span className="font-size-6   text-primary">
                                  <i className="fa fa-lg fa-info-circle text-primary pr-5"></i>    Information
                                  </span>

                          </h3>


                      </div>
                  </div>
                  <div className="row no-gutters">

                      <div className="col-md-6">
                          <div className="media align-items-center px-10 py-10">


                              <div className=" align-items-left px-5 py-5">
                                  <a className="font-size-4 text-default-color line-height-2">
                                      {gContext.errorModal}
                                  </a>

                              </div>
                          </div>
                      </div>


                  </div>
              </div>*/}
            </Modal.Body>
        </ModalStyled>
    );
};

export default ModalGeneral;
