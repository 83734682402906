import React from "react";
import { withTheme } from "styled-components";
import Select from "react-select";
const defaultOptions = [
    //{ value: "chocolate", label: "Chocolate" },
    //{ value: "strawberry", label: "Strawberry" },
    //{ value: "vanilla", label: "Vanilla" },
];
const getCustomStyles = (theme, accentColor, bg, border, indicator) => {
    return {
       
        dropdownIndicator: () => {
            return {
                display: !indicator && "none",              
            };
        },
        indicatorSeparator: () => { },
        option: (provided, state) => {
            return {
                ...provided,
                color: state.isSelected ? bg : theme.colors.dark,
                textAlign: "left",
                backgroundColor: state.isSelected ? "#91AE28" : (state.isFocused ? "#EDF8F5" :bg),
                
              
            };
          
        },
        control: (provided, state) => {
            return {
                ...provided,
                border: !border
                    ? "none"
                    : state.menuIsOpen || state.isFocused
                        ? `1px solid ${theme.colors[accentColor]} !important`
                        : `1px solid ${theme.colors.border} !important`,
                borderRadius: 10,
                
                borderRadius: 5,
                padding: "0px 0px",
                width: "100%",
                 
               
                outline: "none",
                boxShadow: "none",
                textAlign: "left",
              
                 
            };
        },
    };
};
 

const SelectStyled = ({
    theme,
    bg = "#fff",
    border = true,
    accentColor = "success",
    name = "item",
    indicator = true,
    options = defaultOptions,
    ...rest
}) => {
    return (
        <Select 
            filterOption={(option, input) => {
                
                if (input !== "" && option.label  )
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) === 0
                else
                    return true;
            }
            }
            styles={getCustomStyles(theme, accentColor, bg, border, indicator)}
            defaultValue={options[1]}
            name={name}  
            options={options}
            instanceId="inId"
            isSearchable="true"
            {...rest}
        />
    );
};
export default withTheme(SelectStyled);