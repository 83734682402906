import React, { useState, useEffect } from "react";
import styled from "styled-components";  

const radio = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
  &::before {
    content: "\f111";
    font-weight: 400;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f192";
      font-weight: 900;
      color: #00b074;
    }
  }
`;
const radioDisabled = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
  &::before {
    content: "\f111";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;
const check = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
  &::before {
    content: "\f0c8";
    font-weight: 400;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;
const checkDisabled = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #2b3940 !important;
  font-size: 16px;
  color: inherit;
  &::before {
    content: "\f0c8";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    color: #7e8989;
    margin-right: 11px;
    margin-top: 2px;
  }
  &.active {
    color: #2b3940 !important;
    font-weight: 600;
    &::before {
      content: "\f14a";
      font-weight: 900;
      color: #00b074;
    }
  }
`;
export default function RadioButton({multiple=false, children, clear = false, value = "", field = "", handleChange, selectedValue = null, disable = false, showSelected = false }) {
    const [active, setActive] = useState(false);
    let [CheckStyled, setCheckStyled] = useState((multiple) ? check : radio);
    useEffect(() => {
        if (clear) {
            setActive(false); 
        }
    }, [clear]);
    useEffect(() => {

        
            if (disable)
                setCheckStyled((multiple)?checkDisabled:radioDisabled);
            else {
                setCheckStyled((multiple) ? check : radio );
            }
       

    }, [disable]);
    useEffect(() => {
        if (selectedValue !== value) {
            setActive(false);
        }
        else   {
            setActive(true);
        }
    }, [selectedValue]);

    useEffect(() => {
        if (selectedValue !== value) {
            setActive(false);
        }
        else   {
            setActive(true);
        }
    }, []);

    
    return (
        <>
            <CheckStyled  
                className={`toggle-item ${active ? "active" : ""}  `} style={{ pointerEvents: disable ? "none" : "auto" }}
                onClick={() => {
                    handleChange(!active, field, value, multiple);
                    setActive(!active);

                }}
            >
                {children}
            </CheckStyled>
        </>
    );
};
