import React, { useContext, useState, useEffect,useRef } from "react";
import CountUp from "react-countup"; 
import LazyLoad from "react-lazyload";
import { ClientDashboardCounts, ProposalLoadAll, GetClientJobs, LoadClientOffers, ClientContractsLoad} from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext"; 
import { Collapse } from "react-bootstrap";

export default function DashboardMain() {
    const gContext = useContext(GlobalContext);   
    const [jobsCount, setJobsCount] = useState(null);
    const [proposalCount, setProposalCount] = useState(null);
    const [contractCount, setContractCount] = useState(null);
    const [offersCount, setOffersCount] = useState(null);     
    const [loader, setLoader] = useState({ job: true, proposal: true, contract: true, offer: true });
    const [proposalDataList, setProposalDataList] = useState([]);
    const [jobList, setJobList] = useState([]);
    const [offerDataList, setOfferDataList] = useState([]);
    const [contractDataList, setContractDataList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    async function BindJobs() {
        try {
            
            const result = await GetClientJobs(gContext.userInfo.userID);

            if (result.data.clientJobsLoad.responseData.code == "200") {

                let data = JSON.parse(result.data.clientJobsLoad.responseData.message);
                
                if (data) {                

                    setJobList(data);
                }
            }
            setLoader({ ...loader, ["job"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["job"]: true });
        }
    }
    async function BindCounts() {
        try { 
            const result = await ClientDashboardCounts(gContext.userInfo.userID);

            if (result.data.clientDashboardCounts.responseData.code == "200") {

                let data = JSON.parse(result.data.clientDashboardCounts.responseData.message);
                 
                if (data) { 
                    setJobsCount(data.jobCounts);
                    setProposalCount(data.proposalCounts);
                    setContractCount(data.contractCounts);
                    setOffersCount(data.offerCounts);

                }
            }
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function BindProposals() {
        try { 
            const result = await ProposalLoadAll(gContext.userInfo.userID);
            
            if (result.data.proposalLoadAll.responseData.code == "200") {

                let data = JSON.parse(result.data.proposalLoadAll.responseData.message);

                if (data) {
                    setProposalDataList(data);
                }
            }
            setLoader({ ...loader, ["proposal"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["proposal"]: true });
        }
    }
    async function BindOffers() {
        try {
         
            const result = await LoadClientOffers(gContext.userInfo.userID);

            if (result.data.loadClientOffers.responseData.code == "200") {

                let data = JSON.parse(result.data.loadClientOffers.responseData.message);
                
                if (data) {
                   
                    setOfferDataList(data); 
                }
            }
            setLoader({ ...loader, ["offer"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["offer"]: true });
        }
    }
    async function BindContracts() {
        try {
           
            const result = await ClientContractsLoad(gContext.userInfo.userID);

            if (result.data.clientContractsLoad.responseData.code == "200") {

                let data = JSON.parse(result.data.clientContractsLoad.responseData.message);
                
                if (data) {
                   
                    setContractDataList(data);
                }
            }
            setTimeout(() => {
                setLoaded(true);

            }, 2000);
            setLoader({ ...loader, ["contract"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["contract"]: true });
        }
    } 
    useEffect(() => {
        BindCounts();
        BindProposals();
        BindJobs();
        BindOffers();
        BindContracts();
        
        document.title = "Dashboard | Syncuppro";
    }, []);
    useEffect(() => {
        if (gContext.refreshDashboard == "proposal") 
            BindProposals();
        if(gContext.refreshDashboard =="offer") 
            BindOffers();
        if (gContext.refreshDashboard == "contract") { BindOffers(); BindContracts(); }
        BindCounts();
        gContext.toggleRefreshDashboard("");
    }, [gContext.refreshDashboard]);
    useEffect(() => {
        
    }, [offerDataList]);

    useEffect(() => {
      
    }, [proposalDataList]);
    useEffect(() => {
      
    }, [contractDataList]);
    
    return (
        <>
            {loaded &&
            <div className="dashboard-main-container mt-25 mt-lg-31 min-h-100vh ">
                <div className="container mb-10">
                    <div className="row mb-7">
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-briefcase"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1 ">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={6} end={(jobsCount) ? jobsCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Jobs
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green-2 bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-server"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={3} end={(jobsCount) ? jobsCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Posted Jobs
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Applicants
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-check"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.shortlisted : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Interviewing 
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green-3 bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-pen"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(offersCount) ? offersCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Offers
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-egg-blue bg-egg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-address-card"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(contractCount) ? contractCount.hired : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Hired
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category -->  fa-archive,fa-address-book,fa-map-pin*/}
                        </div>

                       

                        



                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-contract"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                               <CountUp duration={3} end={(contractCount) ? contractCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Active Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green  bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-signature"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                            <CountUp duration={3} end={(contractCount) ? contractCount.completed : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Completed Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-red  bg-red-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-ban"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                            <CountUp duration={3} end={(contractCount) ? contractCount.terminated: 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Terminated Contract
                            </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        
                        
                       
                    </div>




                 

                </div>
                </div>}
            {!loaded &&
                <div className="dashboard-main-container mt-25 mt-lg-31  min-h-100vh ">
                    <div className="container">
                        <div className="row mb-7">
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a
                                    href="/#"
                                    className="media bg-white movingLoader rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="  circle-56 font-size-6 mr-7">

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                            <LazyLoad>
                                                <span className="counter">

                                                </span>
                                            </LazyLoad>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a
                                    href="/#"
                                    className="media bg-white movingLoader rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="  circle-56 font-size-6 mr-7">

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                            <LazyLoad>
                                                <span className="counter">

                                                </span>
                                            </LazyLoad>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a
                                    href="/#"
                                    className="media bg-white movingLoader rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="  circle-56 font-size-6 mr-7">

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                            <LazyLoad>
                                                <span className="counter">

                                                </span>
                                            </LazyLoad>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a
                                    href="/#"
                                    className="media bg-white movingLoader rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="  circle-56 font-size-6 mr-7">

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                            <LazyLoad>
                                                <span className="counter">

                                                </span>
                                            </LazyLoad>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>





                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a
                                    href="/#"
                                    className="media bg-white movingLoader rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="  circle-56 font-size-6 mr-7">

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                            <LazyLoad>
                                                <span className="counter">

                                                </span>
                                            </LazyLoad>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a
                                    href="/#"
                                    className="media bg-white movingLoader rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="  circle-56 font-size-6 mr-7">

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                            <LazyLoad>
                                                <span className="counter">

                                                </span>
                                            </LazyLoad>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>



                        </div>

                        <div
                            className="accordion rounded-10 border  pl-0  mt-10"

                        >
                            <div className="border-bottom overflow-hidden ">
                                <div className={`mb-0 m-0 border-bottom-0  bg-athens p-0 `} id="heading2-3">
                                   
                                        <span className="  border border-color-2 movingLoader  px-0 py-7 w-25 mt-5"></span>
                                   
                                </div>
                                <Collapse in={true}>
                                    <div className="row bg-white p-0 m-0 mt-10">
                                        <div className="col-12  bg-athens  p-0 m-0">
                                            <div className="header-btns   d-xs-flex ">
                                                <div className="pl-0  " >  <a
                                                    className={`btn movingLoader    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}


                                                >

                                                </a><a
                                                    className={`btn  movingLoader    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}


                                                >

                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                    <div className="col-12  p-0 m-0 mt-5">
                                            <div className="table-responsive p-0 m-0">
                                            <table className="table table-striped border border-color-2 p-0 m-0">
                                                <thead>
                                                    <tr className=" py-7 border border-color-2 movingLoader">
                                                        <th
                                                            scope="col"
                                                            className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                        >

                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                        >

                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                        >

                                                        </th>


                                                        < th
                                                            scope="col"
                                                            className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                        >

                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="border border-color-2" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                    </tr>
                                                    <tr className="border border-color-2 movingLoader" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                    </tr>
                                                    <tr className="border border-color-2" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>

                                                    </tr>   <tr className="border border-color-2 movingLoader" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="border-bottom overflow-hidden ">
                                <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                    <button
                                          className="btn-reset  hover-shadow-1  font-size-6 font-weight-semibold text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                        type="button"

                                        aria-expanded={true}
                                    >

                                    </button>
                                </div>
                                <Collapse in={true}>
                                    <div className="row bg-white p-0 m-0 mt-10">
                                        <div className="col-12  bg-athens  p-0 m-0">
                                            <div className="header-btns   d-xs-flex ">
                                                <div className="pl-0  " >    <a
                                                    className={`btn movingLoader    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}


                                                >

                                                </a><a
                                                    className={`btn  movingLoader    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}


                                                >

                                                    </a>
                                                    <a
                                                        className={`btn movingLoader    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}


                                                    >

                                                    </a><a
                                                        className={`btn  movingLoader    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}


                                                    >

                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-12 mt-5 m-0 p-0 ">
                                            <div className="table-responsive">
                                            <table className="table table-striped border border-color-2 p-0 m-0">
                                                <thead>
                                                    <tr className=" py-7 border border-color-2 movingLoader">
                                                        <th
                                                            scope="col"
                                                            className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                        >

                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                        >

                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                        >

                                                        </th>


                                                        < th
                                                            scope="col"
                                                            className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                        >

                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr className="border border-color-2" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                    </tr>
                                                    <tr className="border border-color-2 movingLoader" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                    </tr>
                                                    <tr className="border border-color-2" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>

                                                    </tr>   <tr className="border border-color-2 movingLoader" >
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>
                                                        <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                            </div>
                                        </div>

                                    </div>
                                </Collapse>
                            </div>


                        </div>




                    </div>
                </div>}
        </>
    );
};