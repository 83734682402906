import React, { useState, useContext, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext";
import ConfirmMessages from "../../utils/confirmMessages";
import { GetExpertTestimonial, UpdateExpertTestimonial, DeleteExpertRow } from "../../utils/apiCalls"; 
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { ExpertFileUpload, getExpertFile, createDirName } from "../../utils/fileUpload";
import { isInvalid } from "../../utils/helperFn";
import validator from 'validator';
 
const Testimonials = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowsTestimonial, setRowsTestimonial] = useState([]);
    const [storedTestimonial, setStoredTestimonial] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [message, setMessage] = React.useState(false); 
    const [loaded, setLoaded] = useState(false);
    const popoverFile = (
        <Popover className="   " >
            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>Allowed format pdf,jpeg,png,jpg,gif.</p>

            </div>





        </Popover>
    );
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);

    const onFileChange = (index, event) => {
        setMessage(false);

        if (event.target.files.length > 0) {            
            TestFileType(index, event.target.files[0], ['gif', 'jpg', 'png', 'jpeg', 'pdf']);
 
        }
    } 
    function TestFileType(index, fileName, fileTypes) {


        var type = fileName.type.substring(fileName.type.indexOf("/") + 1, fileName.type.length);

        if ((fileTypes.indexOf(type) != -1) == true) {

            const rowsInput = [...rowsTestimonial];

            rowsInput[index]["file"] = fileName;
            rowsInput[index]["fileName"] = fileName["name"];
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", ["digitalLink"]: "" }
            setRowsTestimonial(rowsInput);

        }

    } 
 
    function addTableRows() {
        setMessage(false);
        const rowsInput = {

            load: false, id: null, expertId: gContext.userInfo.userID,
            name: '', digitalLink: '', file: '', resetFileName: '', fileName: '', error: { name: '', digitalLink: '', fileName: '' }
        }

        setRowsTestimonial([...rowsTestimonial, rowsInput]);

    }

    function spliceRow(index) {
        const rows = [...rowsTestimonial];
        rows.splice(index, 1);
        setRowsTestimonial(rows);
    }
    /*For edu table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Testimonial delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
    }
    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);

            try {
                gContext.setLoaderModalVisible(true);
                const result = await DeleteExpertRow(id, 'tes', storedTestimonial.length === 1 ? 0 : null);

                if (result.data.expertRowDelete.responseData.code == "200") { 
                    getTestimonial();
                }
                else {

                }
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
            }
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
        gContext.setLoaderModalVisible(false);
    }

    const handleChange = (index, evnt) => {
        setMessage(false);
        const { name, value } = evnt.target;
        const rowsInput = [...rowsTestimonial];
        rowsInput[index][name] = value;
        if (name == "digitalLink")
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", ["digitalLink"]: (value.trim() == "") ? "" : (validator.isURL(value) ? "" : "Digital Link is invalid") }
        else
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], [name]: "" }
        setRowsTestimonial(rowsInput);
    }
    const ChangeFile = (index, value) => {
        setMessage(false);
        const rowsInput = [...rowsTestimonial];
        rowsInput[index]["file"] = null;
        rowsInput[index]["fileName"] = value;
        rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", ["digitalLink"]: "" }
        setRowsTestimonial(rowsInput);

    }
    async function getTestimonial() {
        try {
            setLoaded(false);
            const result = await GetExpertTestimonial(gContext.userInfo.userID);

            if (result.data.expertTesLoad.responseData.code == "200") {
                BindData(JSON.parse(result.data.expertTesLoad.responseData.message));
                setStoredTestimonial(JSON.parse(result.data.expertTesLoad.responseData.message));
                gContext.setLoaderModalVisible(false);
            }
            setLoaded(true);
        }
        catch (e) {
            console.log(e);
            setLoaded(true);
        }
    }

    function BindData(data) {
        
        const rowsInput = []

        try {

            if (data.length > 0) {
                data.map((row) => {
                    rowsInput.push({
                        load: false, id: row.id, expertId: gContext.userInfo.userID,
                        name: row.name, digitalLink: row.url, file: '', resetFileName: row.filename, fileName: row.filename, error: { name: '', digitalLink: '', fileName: '' }
                    });
                });
                setRowsTestimonial(rowsInput);

            }
            else {

                setRowsTestimonial([]);
            }
        } catch (e) {
            console.log(e);
        }
    }
    function checkValidity(index) {
        let isValid = false;


        let { error, ...rest } = rowsTestimonial[index];

        
        error.name = validator.isEmpty(rest.name) ? " Name is required" : (isInvalid(rest.name) ? "Invalid format" : error.name);

        error.digitalLink = (validator.isEmpty(rest.digitalLink.trim()) && (rest.fileName == "")) ? "Enter link or file" : error.digitalLink;
        error.fileName = (validator.isEmpty(rest.digitalLink.trim()) && (rest.fileName == "")) ? "Enter link or file" : "";


        const rows = [...rowsTestimonial];
        rows[index][error] = error;
        setRowsTestimonial(rows);

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });

        if (isValid)
            return false;
        else
            return true;

    }
    async function saveTestimonial(index) {
        setMessage(false);
        setErrorMessage('');

        if (checkValidity(index)) {
            const rowsInput = [...rowsTestimonial];
            rowsInput[index]['load'] = true;
            setRowsTestimonial(rowsInput);
            try {
                gContext.setLoaderModalVisible(true);
                var data = rowsTestimonial[index];
                if (data.file) {
                    var file = getExpertFile(data.file);

                    if (file) {
                        ExpertFileUpload(file, "testimonial");
                        data.fileName = createDirName() + "/testimonial/" + file.name;
                    }
                    else {
                        setErrorMessage("File upload error");
                        return;
                    }
                }
                const result = await UpdateExpertTestimonial(data);

                if (result.data.expertTesUpdate.responseData.code == "200") { 
                    setMessage(true);
                    getTestimonial();
                }
                else {
                    setErrorMessage(JSON.parse(result.data.expertTesUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                setErrorMessage("Server error"); gContext.setLoaderModalVisible(false);

            }
            gContext.setLoaderModalVisible(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'

            });
        }

        else {
            setMessage(false);
            setErrorMessage("Required fields are missing.");

        }
    }
    useEffect(() => {

        if (gContext.confirmModal.confirmed) {
            deleteData(gContext.confirmModal.index, gContext.confirmModal.id);
        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);
    useEffect(() => { 
        getTestimonial(); 
        document.title = "Testimonial | Syncuppro";
    }, [])
    return (
        <>


            {loaded && <div className="card panel  p-0">

                <div className="card-header">

                    <div className="client-title">
                        <h6>TESTIMONIALS INFORMATION</h6>
                    </div>
                </div>
                
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="min-lg-cell">Name<span className="text-red"> * </span></th>
                                    <th className="min-lg-cell">Digital Link</th>
                                    <th className="w-20 min-md-cell"></th>
                                    <th className="w-150 min-md-cell">


                                        <div className="d-flex align-items-center  ">File Upload
                                            <OverlayTrigger trigger="hover" placement="auto" overlay={popoverFile} >
                                                <span className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                            </OverlayTrigger></div></th>
                                    <th className="w-50"></th>
                                    <th className="w-50"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {rowsTestimonial.map((data, index) => {
                                    const { load, id, name, digitalLink, file, resetFileName, fileName, error } = data;

                                    return (
                                        <tr>
                                            <td>
                                                <input
                                                    name="name"
                                                    type="text"
                                                    className="form-control "
                                                    id="name"
                                                    placeholder="Name" defaultValue={name} value={name} onChange={(e) => (handleChange(index, e))}
                                                />
                                                {error.name.length > 1 && (
                                                    <span className="text-red font-weight-normal">{error.name}</span>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    name="digitalLink"
                                                    type="text"
                                                    className="form-control"
                                                    id="digitalLink"
                                                    placeholder="Digital Link" defaultValue={digitalLink} onChange={(e) => (handleChange(index, e))}
                                                />  {error.digitalLink.length > 1 && (
                                                    <span className="text-red ">{error.digitalLink}</span>
                                                )}
                                            </td>
                                            <td>
                                                <span>OR</span>
                                            </td>
                                            <td>



                                                {(fileName === "") &&
                                                    <>

                                                    {(!file) &&
                                                        <div className="view-btn">
                                                            <label htmlFor={`fileUpload${index}`} className="btn btn-dark-outline text-black file-btn d-flex align-item-center" data-toggle="tooltip" title="Click here to add file" >
                                                                <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                            </label>
                                                            <input

                                                                type="file"
                                                                id={`fileUpload${index}`} accept="image/*,application/pdf"
                                                                className="sr-only"
                                                                onChange={(e) => onFileChange(index, e)}
                                                            />

                                                        </div>


                                                    }

                                                    {(file) && <div className="mx-auto pt-0 mt-0 position-relative circle-48 bg-green-opacity-2 border border-width-1 text-center  image-container"  > <i className="fa-solid image fa-file-arrow-up font-size-7 pointer text-green-2 " ></i>  <div className="text-center border border-width-1 border-red circle-20 remove pos-abs-br bg-white "  > <i className="fas fa-trash   pointer font-size-3 text-red" data-toggle="tooltip" title="Remove file" onClick={() => ChangeFile(index, resetFileName)}></i></div></div>}

                                                    {(file) && <span className="    "> {file.name} </span>}

                                                   
                                               </>}
                                                {(fileName !== "") &&
                                                    <>
                                                        <div className="view-btn">
                                                        <a className="btn btn-dark-outline text-black file-btn  pointer" data-toggle="tooltip" title="Click here to view file" onClick={() => { window.location.href = (file) ? window.URL.createObjectURL(file) : s3URL + fileName; }} >
                                                                <span className="m-icon material-icons m-r-5">visibility</span>View File
                                                            </a>

                                                            <button type="button" className="btn-icon-close" data-toggle="tooltip" title="Remove file" onClick={() => ChangeFile(index, '')}><span className="m-icon material-icons">close</span></button>
                                                        </div> </>}
                                                {error.fileName.length > 1 && (
                                                    <>    <span className="text-red ">{error.fileName}</span></>
                                                )}





                                            </td>
                                            <td>
                                                <a className="btn btn-circle light" data-toggle="tooltip" title="Save Row" onClick={() => (saveTestimonial(index))}><span className="text-theme m-icon material-icons">    {(id) ? "edit " : "save"}</span></a>
                                            </td>
                                            <td>
                                                <a className="btn btn-circle light" data-toggle="tooltip" title="Delete Row" onClick={() => (deleteTableRows(index, id))}><span className="text-theme m-icon material-icons">delete</span></a>
                                            </td>
                                        </tr>)
                                })}

                            </tbody>
                        </table>
                        {(rowsTestimonial && rowsTestimonial.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                            <span className="text-light-gray m-icon material-icons m-b-10">menu</span>
                            <span>No Testimonial  found</span>
                        </div>}
                    </div>

                    <div className="add-more">
                        <button type="button" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                    </div>

                </div>

            </div>}
            {!loaded &&
                <>
                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title movingLoader w-150">
                                <h6> &nbsp;</h6>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="movingLoader">
                                        <tr><th></th>
                                            <th className="min-lg-cell"> </th>
                                            <th className="min-lg-cell"></th>
                                            <th className="min-lg-cell" > </th>
                                            <th className="w-100 min-md-cell" ></th>
                                            <th className="w-100 min-md-cell"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr><tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                            <div className="add-more">
                                <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center movingLoader w-100 p-5">&nbsp;</button>
                            </div>

                        </div>

                    </div>

                </>
            }
             
        </>
    );
};

export default Testimonials;
