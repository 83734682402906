import React, { useContext, useState, useEffect,useRef } from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import PageWrapper from "../../components/PageWrapper";

import { Collapse } from "react-bootstrap";
import { ExpertDashboardCounts, LoadExpertOffers, ExpertContractsLoad, LoadExpertJobs, LoadExpertProposals } from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import Offers from "../../sections/ClientDashboard/Offers";
import Contract from "../../sections/ExpertDashboard/Contracts";
import ExpertJobs from "../../sections/ExpertDashboard/ExpertJobs";
import Proposals from "../../sections/ExpertDashboard/Proposal";
export default function DashboardMain() {

    const gContext = useContext(GlobalContext);
    const [showJobs, setShowJobs] = useState("applied");
    const [showProposals, setShowProposals] = useState("open");
    const [showContracts, setShowContracts] = useState("new");
    const [showOffers, setShowOffers] = useState("new");
    const [jobsCount, setJobsCount] = useState(null);
    const [proposalCount, setProposalCount] = useState(null);
    const [contractCount, setContractCount] = useState(null);
    const [offersCount, setOffersCount] = useState(null); //offersCounts
    const [openItem, setOpenItem] = useState({ job: true, proposal: true, contract: true, offer: true });
    const [proposalDataList, setProposalDataList] = useState([]);
    const [offerDataList, setOfferDataList] = useState([]);
    const [contractDataList, setContractDataList] = useState([]);
    const [rowsJobs, setRowsJobs] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState({ job: true, proposal: true, contract: true, offer: true });
    const offerRef = useRef();
    const propsalRef = useRef();
    const contractRef = useRef();
    async function LoadJobs(mode) {

        try {
            
            
            const result = await LoadExpertJobs(gContext.userInfo.userID, mode);

            if (result.data.loadExpertJobs.responseData.code == "200") {

                let data = JSON.parse(result.data.loadExpertJobs.responseData.message);
                
                if (mode == "saved")  
                    setRowsJobs(data?.saved);
                else
                    setRowsJobs(data?.applied);
            }  
            setLoader({ ...loader, ["job"]: true });
        }
        catch (e) {
            console.log(e);
            setLoader({ ...loader, ["job"]: true });
        }
    }
    async function BindProposals() {
        try {
           
            const result = await LoadExpertProposals(gContext.userInfo.userID);
            
            if (result.data.expertProposals.responseData.code == "200") {

                let data = JSON.parse(result.data.expertProposals.responseData.message);
               
                if (data) {
                    setProposalDataList(data);
                }
            }
            setLoader({ ...loader, ["proposal"]: true });
        }
        catch (e) {
            console.log(e);
            setLoader({ ...loader, ["proposal"]: true });
        }
    }
    async function BindOffers() {
        try {
            
            const result = await LoadExpertOffers(gContext.userInfo.userID);
            
            if (result.data.loadExpertOffers.responseData.code == "200") {

                let data = JSON.parse(result.data.loadExpertOffers.responseData.message);
                 
                if (data) {
                    setOfferDataList(data);
                }
            }
            setLoader({ ...loader, ["offer"]: true });
        }
        catch (e) {
            console.log(e);
            setLoader({ ...loader, ["offer"]: true });
        }
    }
    useEffect(() => {

        BindOffers(); ContractsLoad(); LoadJobs('applied');  BindProposals(); BindCounts();
    }, []);

    async function ContractsLoad() {
        try {
           
            const result = await ExpertContractsLoad(gContext.userInfo.userID);
         
            if (result.data.expertContractsLoad.responseData.code == "200") {

                let data = JSON.parse(result.data.expertContractsLoad.responseData.message);
                
                if (data) {
                  
                    setContractDataList(data);
                }
            }
            setLoader({ ...loader, ["contract"]: true });
        }
        catch (e) {
           
            setLoader({ ...loader, ["contract"]: true });
            console.log(e); gContext.setLoaderModalVisible(false);
        }
    }


    async function BindCounts() {
        try {

            const result = await ExpertDashboardCounts(gContext.userInfo.userID);

            if (result.data.expertDashboardCounts.responseData.code == "200") {

                let data = JSON.parse(result.data.expertDashboardCounts.responseData.message);
                
                if (data) {
                    setJobsCount(data.jobCounts);
                    setProposalCount(data.proposalCounts);
                    setContractCount(data.contractCounts);
                    setOffersCount(data.offersCounts);

                }
            }

            setTimeout(() => {
                setLoaded(true);
               
            }, 2000);
        }
        catch (e) {
            console.log(e);

        }
    }
    useEffect(() => {
        if (loaded)
            gContext.setPageLoading(false);
    }, [loaded]);
    useEffect(() => {  }, [])

    useEffect(() => {
        if (gContext.refreshDashboard == "proposal")
            BindProposals();
        if (gContext.refreshDashboard == "offer")
            BindOffers();
        if (gContext.refreshDashboard == "contract") { ContractsLoad(); }
        gContext.toggleRefreshDashboard("");
    }, [gContext.refreshDashboard]);
    useEffect(() => {
        offerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [offerDataList]);

    useEffect(() => {
        propsalRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [proposalDataList]);
    useEffect(() => {
        contractRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [contractDataList]);
    return (
        <>

            {loaded &&

                <>
                    <span className="screen-darken"></span>

                    <div className="page-wrapper">

                        <div className="section statics-block-wrapper">

                        <div className="container-fluid">

                            <div className="statistic-div-wrapper align-item-center justify-content-center">

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Applied Jobs</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(jobsCount) ? jobsCount.applied : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/jobs.svg" alt="Shape" />
                                        <img src="../images/dashboard-icons/statistic-shape-1.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Saved Jobs</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(jobsCount) ? jobsCount.saved : 0} </h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/jobs.svg" alt="jobs" />
                                        <img src="../images/dashboard-icons/statistic-shape-2.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>  Active Proposals</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(proposalCount) ? proposalCount.active : 0} </h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/application.svg" alt="application" />
                                        <img src="../images/dashboard-icons/statistic-shape-3.svg" alt="Shape" />
                                    </div>
                                </div>

                               

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>New Offers</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(offersCount) ? offersCount.current : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/offers.svg" alt="offers" />
                                        <img src="../images/dashboard-icons/statistic-shape-5.svg" alt="Shape" />
                                    </div>
                                </div>

                             
                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Active Contracts</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(contractCount) ? contractCount.active : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/active-contract.svg" alt="active-contract" />
                                        <img src="../images/dashboard-icons/statistic-shape-7.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Completed Contract</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(contractCount) ? contractCount.completed : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/completed-contract.svg" alt="completed-contract" />
                                        <img src="../images/dashboard-icons/statistic-shape-8.svg" alt="Shape" />
                                    </div>
                                </div>

                                 
                            </div>
                                <div className="row d-none">

                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no">
                                            <h3>{(jobsCount) ? jobsCount.applied : 0}</h3>
                                            <span className="statics-label">Applied Jobs</span>
                                            </div>

                                            <div className="statics-img">
                                                <img src="../images/statics-icon/msg-icon.svg" alt="msg-icon.svg" />
                                            </div>

                                            <img className="img-first" src="../images/statics-icon/msg-icon.svg" alt="msg-icon.svg" />

                                        </div>

                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no">
                                            <h3>{(jobsCount) ? jobsCount.saved : 0} </h3>
                                                <span className="statics-label">Saved Jobs</span>
                                            </div>

                                            <div className="statics-img">
                                                <img src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg" />
                                            </div>

                                            <img className="img-first" src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg" />

                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no">
                                            <h3>{(proposalCount) ? proposalCount.active : 0} </h3>
                                            <span className="statics-label">  Active  Proposals</span>
                                            </div>

                                            <div className="statics-img">
                                                <img src="../images/statics-icon/user-icon-2.svg" alt="user-icon-2.svg" />
                                            </div>

                                            <img className="img-first" src="../images/statics-icon/user-icon-2.svg" alt="user-icon-2.svg" />

                                        </div>
                                    </div>

                                    
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no">
                                            <h3>{(offersCount) ? offersCount.current : 0}</h3>
                                            <span className="statics-label"> New Offers</span>
                                            </div>

                                            <div className="statics-img">
                                                <img src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg" />
                                            </div>

                                            <img className="img-first" src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg" />

                                        </div>
                                    </div>

                                 

                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no">
                                                <h3>{(contractCount) ? contractCount.active : 0}</h3>
                                                <span className="statics-label">Active Contracts</span>
                                            </div>

                                            <div className="statics-img">
                                                <img src="../images/statics-icon/list-icon.svg" alt="list-icon.svg" />
                                            </div>

                                            <img className="img-first" src="../images/statics-icon/list-icon.svg" alt="list-icon.svg" />

                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no">
                                            <h3>{(contractCount) ? contractCount.completed : 0}</h3>
                                                <span className="statics-label">Completed Contracts</span>
                                            </div>

                                            <div className="statics-img">
                                                <img src="../images/statics-icon/list-icon.svg" alt="list-icon.svg" />
                                            </div>

                                            <img className="img-first" src="../images/statics-icon/list-icon.svg" alt="list-icon.svg" />

                                        </div>
                                    </div>

                                     


                                </div>
                            </div>

                        </div>

                        <div className="section p-t-0">
                            <div className="container-fluid">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn  " data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true" onClick={() => { setOpenItem({ ...openItem, ["job"]: !openItem.job }); setShowJobs('applied'); }}><h5>JOBS</h5>
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <ul className="nav nav-tabs tab-light">
                                                        <li className="nav-item p-relative">
                                                        <a className={`nav-link pointer ${(showJobs == 'applied') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setLoader({ ...loader, ["job"]: false });
                                                            setShowJobs('applied');
                                                            LoadJobs('applied');
                                                        }}>Applied</a>
                                                        </li>
                                                        <li className="nav-item p-relative">
                                                        <a className={`nav-link pointer ${(showJobs == 'saved') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setLoader({ ...loader, ["job"]: false });
                                                            setShowJobs('saved');
                                                            LoadJobs('saved');


                                                        }}>Saved</a>
                                                        </li>
                                                        
                                                    </ul>

                                                <button type="button" className="btn btn-circle" onClick={() => { setLoader({ ...loader, ["job"]: false }); LoadJobs(showJobs); }} data-toggle="tooltip" title="Click here to refresh"><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

                                                </div>

                                                <div className="card-body">
                                                    <div className="tab-content">

                                                        <div className="tab-pane active" >
                                                             

                                                        {loader.job &&  < ExpertJobs mode={showJobs} rowsJobs={rowsJobs} updateData={(mode) => LoadJobs(mode)} /> }

                                                            {!loader.job &&
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr >
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>







                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            }
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-proposals" aria-expanded="false" onClick={() => { setOpenItem({ ...openItem, ["proposal"]: !openItem.proposal }); }}><h5>PROPOSALS</h5>
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-proposals" className="collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <div className="tab-light">
                                                        <ul className="nav nav-tabs">
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showProposals == 'open') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowProposals('open');

                                                            }}  >Submitted</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'active') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                setShowProposals('active');
                                                                }}  >Active</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'withdrawn') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                setShowProposals('withdrawn');

                                                            }}  >Withdrawn</a>
                                                            </li>                                                           
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showProposals == 'archived') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowProposals('archived');
                                                                }}  >Archived</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <button type="button" data-toggle="tooltip" title="Click here to refresh" className="btn btn-circle" onClick={() => { setLoader({ ...loader, ["proposal"]: false }); BindProposals() }}><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

                                                </div>

                                                <div className="tab-content">

                                                    <div className="tab-pane active" >
                                                        <div className="card-body"> 
                                                             
                                                        {loader.proposal &&  <Proposals mode={showProposals} proposalData={proposalDataList} callbackUpdateProposal={() => BindProposals()} /> }

                                                            {!loader.proposal &&
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr >
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>







                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            }

                                                        </div>
                                                    </div>


                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-wrapper accordion-dashboard">

                                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-offers" aria-expanded="false" onClick={() => setOpenItem({ ...openItem, ["offer"]: !openItem.offer })}><h5>OFFERS</h5>
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-offers" className="collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <div className="tab-light">
                                                        <ul className="nav nav-tabs">
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showOffers == 'new') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowOffers('new');

                                                                }}>New</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showOffers == 'Accepted') ? 'active' : ' '}`} href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowOffers('Accepted');

                                                                    }}>Accepted</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showOffers == 'Rejected') ? 'active' : ' '}`} href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowOffers('Rejected');

                                                                    }}>Rejected</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showOffers == 'Expired') ? 'active' : ' '}`} href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowOffers('Expired');

                                                                    }}>Expired</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showOffers == 'Withdrawn') ? 'active' : ' '}`} href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowOffers('Withdrawn');

                                                                    }}>Withdrawn</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showOffers == 'Archived') ? 'active' : ' '}`} href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowOffers('Archived');

                                                                    }}>Archived</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <button type="button" className="btn btn-circle" data-toggle="tooltip" title="Click here to refresh" onClick={() => { setLoader({ ...loader, ["offer"]: false }); BindOffers() }}><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

                                                </div>

                                                <div className="tab-content">

                                                    <div className="tab-pane active" >
                                                        <div className="card-body">
                                                        {loader.offer &&  < Offers proposalData={proposalDataList} mode={showOffers} offerData={offerDataList} callbackUpdateOffer={() => BindOffers()} /> }
                                                            {!loader.offer &&
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr >
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>







                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            }

                                                        </div>
                                                    </div>



                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-wrapper accordion-dashboard">
                                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-contracts" aria-expanded="false" onClick={() => setOpenItem({ ...openItem, ["contract"]: !openItem.contract })}><h5>CONTRACTS</h5>
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-contracts" className="collapse" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <div className="tab-light">
                                                        <ul className="nav nav-tabs">
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showContracts == 'new') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowContracts('new');

                                                                }}>
                                                                    New</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showContracts == 'Active') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowContracts('Active');

                                                                }}>
                                                                    Active</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showContracts == 'Completed') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowContracts('Completed');

                                                                }}>
                                                                    Completed</a>
                                                            </li>
                                                            <li className="nav-item p-relative">
                                                                <a className={`nav-link pointer ${(showContracts == 'Terminated') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowContracts('Terminated');

                                                                }}>
                                                                    Terminated</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                <button type="button" className="btn btn-circle" data-toggle="tooltip" title="Click here to refresh" onClick={() => { setLoader({ ...loader, ["contract"]: false }); ContractsLoad() }} ><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

                                                </div>

                                                <div className="tab-content">

                                                    <div className="tab-pane active" >
                                                        <div className="card-body">
                                                        {loader.contract &&  <Contract mode={showContracts} contractData={contractDataList} /> }
                                                            {!loader.contract &&
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr >
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>







                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            }

                                                        </div>
                                                    </div>



                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                <div className="dashboard-main-container mt-25 mt-lg-31  min-h-100vh d-none ">
                <div className="container">
                    <div className="row mb-7">
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a
                                href="/#"
                                className="media bg-white rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-briefcase"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={6} end={(jobsCount) ? jobsCount.applied : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Applied Job
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a
                                href="/#"
                                className="media bg-white rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-bookmark"></i>

                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={6} end={(jobsCount) ? jobsCount.saved : 0} />
                                            </span>

                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Saved Job
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a
                                href="/#"
                                className="media bg-white rounded-4 pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa"> #</i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                      Active  Proposals
                                        
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-yellow  bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-pen"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={3} end={(offersCount) ? offersCount.current : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        New Offer
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>





                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-contract"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={3} end={(contractCount) ? contractCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Active Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green  bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-signature"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={3} end={(contractCount) ? contractCount.completed : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Completed Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>



                    </div>

                    <div
                        className="accordion rounded-10 border  pl-0  mt-10"

                    >
                        <div className="border-bottom overflow-hidden ">
                            <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset  hover-shadow-1 font-size-6 font-weight-semibold  text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => { setOpenItem({ ...openItem, ["job"]: !openItem.job }); setShowJobs('applied'); }}
                                    aria-expanded={openItem.job}
                                >
                                    Jobs
                        </button>
                            </div>
                            <Collapse in={openItem.job}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media  d-xs-flex pl-0 ml-0">
                                            <div className="pl-0 mr-0 ml-0 w-100 " >    <a
                                                className={`btn ${(showJobs == 'applied') ? 'btn-primary ml-0 mx-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5 mr-1`}
                                                href="/#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                   
                                                    setShowJobs('applied');
                                                    LoadJobs('applied');
                                                }}
                                            >
                                                Applied
                </a>
                                                <a
                                                    className={`btn  ${(showJobs === 'saved') ? 'btn-primary' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        setShowJobs('saved');
                                                        LoadJobs('saved');
                                                    }}
                                                >
                                                    Saved
                </a>
                                                
                                            </div>
                                            <a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["job"]: false }); LoadJobs('applied'); }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {loader.job && <div className="col-12   "> < ExpertJobs mode={showJobs} rowsJobs={rowsJobs} updateData={(mode) => LoadJobs(mode)} /></div>}
                                    {!loader.job && <div className="col-12 px-10  pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}
                                </div>
                            </Collapse>
                        </div>
                        <div className="border-bottom overflow-hidden ">
                            <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset  hover-shadow-1  font-size-6 font-weight-semibold text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => { setOpenItem({ ...openItem, ["proposal"]: !openItem.proposal }); }}
                                    aria-expanded={openItem.proposal}
                                >
                                    Proposals
                        </button>
                            </div>
                            <Collapse in={openItem.proposal}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media  d-xs-flex ">
                                            <div className="pl-0 w-100 mr-0 " >    <a
                                                className={`btn ${(showProposals == 'open') ? 'btn-primary  mx-1 ' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}

                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowProposals('open');

                                                }}
                                            >
                                                Submitted
                </a><a
                                                    className={`btn ${(showProposals == 'active') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}

                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('active');

                                                    }}
                                                >
                                                    Active
                </a>
                                                <a
                                                    className={`btn  ${(showProposals == 'withdrawn') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0`}

                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('withdrawn');
                                                    }}
                                                >
                                                    Withdrawn
                </a>
                                                <a
                                                    className={`btn  ${(showProposals == 'archived') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0`}

                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('archived');
                                                    }}
                                                >
                                                    Archived
                </a>
                                               
                                            </div>
                                            <a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["proposal"]: false }); BindProposals() }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {loader.proposal && <div className=" col-12   "> <Proposals mode={showProposals} proposalData={proposalDataList} callbackUpdateProposal={() => BindProposals()} /></div>}
                                    {!loader.proposal && <div className="col-12 px-10 pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}
                                </div>
                            </Collapse>
                        </div>
                        <div className="border-bottom overflow-hidden ">
                            <div className={`mb-0 border-bottom-0 bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6  hover-shadow-1  text-green-2  font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => setOpenItem({ ...openItem, ["offer"]: !openItem.offer })}
                                    aria-expanded={openItem.offer}
                                >
                                    Offers
                        </button>
                            </div>
                            <Collapse in={openItem.offer}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media   d-xs-flex ">
                                            <div className="pl-0 w-100 mr-0 " >

                                                <a
                                                    className={`btn ${(showOffers == 'new') ? 'btn-primary  mx-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('new');

                                                    }}
                                                >
                                                    New
                </a><a
                                                    className={`btn ${(showOffers == 'Accepted') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Accepted');

                                                    }}
                                                >
                                                    Accepted
                </a>
                                                <a
                                                    className={`btn  ${(showOffers == 'Rejected') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Rejected');
                                                    }}
                                                >
                                                    Rejected
                </a>
                                                <a
                                                    className={`btn  ${(showOffers == 'Expired') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Expired');
                                                    }}
                                                >
                                                    Expired
                </a>
                                                <a
                                                    className={`btn ${(showOffers == 'Withdrawn') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Withdrawn');

                                                    }}
                                                >
                                                    Withdrawn
                </a>
                                                <a
                                                    className={`btn ${(showOffers == 'Archived') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Archived');

                                                    }}
                                                >
                                                    Archived
                </a>
                                               
                                            </div>
                                            <a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["offer"]: false }); BindOffers() }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {loader.offer && <div className=" col-12   "> < Offers proposalData={proposalDataList} mode={showOffers} offerData={offerDataList} callbackUpdateOffer={() => BindOffers()} /></div>}
                                    {!loader.offer && <div className="col-12 px-10 pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}

                                </div>
                            </Collapse>
                        </div>
                        <div className="border-bottom overflow-hidden ">
                            <div className={`mb-0 border-bottom-0 bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6   hover-shadow-1  text-green-2  font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => setOpenItem({ ...openItem, ["contract"]: !openItem.contract })}
                                    aria-expanded={openItem.contract}
                                >
                                    Contracts
                        </button>
                            </div>
                            <Collapse in={openItem.contract}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media  d-xs-flex ">
                                            <div className="pl-0  w-100 mr-0" >

                                                <a
                                                    className={`btn ${(showContracts == 'new') ? 'btn-primary  mx-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('new');

                                                    }}
                                                >
                                                    new
                </a><a
                                                    className={`btn ${(showContracts == 'Active') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('Active');

                                                    }}
                                                >
                                                    Active
                </a>
                                                <a
                                                    className={`btn  ${(showContracts == 'Completed') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('Completed');
                                                    }}
                                                >
                                                    Completed
                </a>
                                                <a
                                                    className={`btn  ${(showContracts == 'Terminated') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('Terminated');
                                                    }}
                                                >
                                                    Terminated
                </a>
                                               
                                            </div>
                                            <a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["contract"]: false }); ContractsLoad() }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {loader.contract && <div className=" col-12   "> <Contract mode={showContracts} contractData={contractDataList} /></div>}

                                    {!loader.contract && <div className="col-12 px-10  pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}
                                </div>
                            </Collapse>
                        </div>
                    </div>




                </div>
            </div>
</>

            }
            {!loaded &&
                <>
                    <span className="screen-darken"></span>

                    <div className="page-wrapper">

                        <div className="section statics-block-wrapper">

                            <div className="container-fluid">
                                <div className="row">

                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                        <div className="card statics-block">

                                            <div className="statics-no  ">
                                                <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                                <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>




                                        </div>

                                    </div>



                                </div>
                            </div>

                        </div>

                        <div className="section p-t-0">
                            <div className="container-fluid">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-wrapper accordion-dashboard">

                                        <a className="accordion-btn movingLoader" data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true"  >&nbsp;
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <ul className="nav nav-tabs tab-light">
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li> <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li> <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                    </ul>



                                                </div>

                                                <div className="card-body">
                                                    <div className="tab-content">

                                                        <div className="tab-pane active" >


                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>







                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-wrapper accordion-dashboard">

                                        <a className="accordion-btn movingLoader" data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true"  >&nbsp;
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <ul className="nav nav-tabs tab-light">
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li> <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li> <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                    </ul>



                                                </div>

                                                <div className="card-body">
                                                    <div className="tab-content">

                                                        <div className="tab-pane active" >


                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="w-130   "> </th>
                                                                            <th className="w-130    "> </th>
                                                                            <th className="w-130   "> </th>
                                                                            <th className="w-130    "> </th>
                                                                            <th className="w-130   "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130   ">
                                                                            </td>
                                                                            <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130   ">
                                                                            </td>
                                                                            <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>
                                                                        </tr>







                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div><div className="accordion-wrapper accordion-dashboard">

                                        <a className="accordion-btn movingLoader" data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true"  >&nbsp;
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <ul className="nav nav-tabs tab-light">
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li> <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li> <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                    </ul>


                                                </div>

                                                <div className="card-body">
                                                    <div className="tab-content">

                                                        <div className="tab-pane active" >


                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="w-130   "> </th>
                                                                            <th className="w-130    "> </th>
                                                                            <th className="w-130   "> </th>
                                                                            <th className="w-130    "> </th>
                                                                            <th className="w-130   "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130   ">
                                                                            </td>
                                                                            <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130   ">
                                                                            </td>
                                                                            <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>  <td className="w-130   ">
                                                                            </td>
                                                                        </tr>







                                                                    </tbody>
                                                                </table>
                                                            </div>


                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>







                                </div>
                            </div>
                        </div>

                    </div>

                </>   }

        </>
    );
};