import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { FlagUser } from "../../utils/apiCalls";
import { GetFlagReasons  } from "../../utils/GetAPIUtils";
import { Select } from "../../components/Core";
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalFlagReason = (props) => {

    const gContext = useContext(GlobalContext);
    const [userTypeId, setUserTypeId] = useState(null);
    const [error, setError] = useState("");
    const [showModal, setShowModal] = useState(false);
    

   
    function toggleModal() {   
        setUserTypeId(null);
        setError("");
      //  props.submitflag(null);
        props.togglemodal(); 
    }
 
    function handleOption(e) { 
        if (e) {
           // props.submitflag(userTypeId);
            setUserTypeId(e);
            //props.togglemodal();
        }
        else {
            setError("error");
        } 
    }
    const handleChange = (e) => {
        setUserTypeId(e);
        setError("");
    }
    useEffect(() => {
        setUserTypeId(null);
        setError("");
    }, []); 

 
    return (
        <ModalStyled
            backdrop="static"
            {...props}
            size="sm" className="modal fade"
            centered
            show={props.modalvisible}
            onHide={toggleModal}
        >





            <div className="modal-body">
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { toggleModal(); }}></button>
                <div className="row  m-t-20">
                    <div className="col-lg-12 ">
                        <div className="login-info">


                            <h6>   Welcome!  Create an  account</h6>
                           
                        </div>
                    </div>
                    {props.fbResponse    && <> <div className="col-lg-3 ">
                        <div className="login-info">

                            {props.fbResponse.picture && props.fbResponse.picture.data && <image src={props.fbResponse.picture.data.url ? props.fbResponse.picture.data.url : ""} roundedCircle />

                            }
                        </div>
                    </div> <div className="col-lg-9 ">
                        <div className="login-info">

                                <h6>{props.fbResponse.name }</h6><br/>
                                <h6>{props.fbResponse.email}</h6>
                        </div>
                    </div></>}
                    <div className="col-lg-12  ">
                    <div className="login-info">
                        <div className="form-group d-flex align-item-center justify-content-between">
                            <label for="email" className="form-label">Select user type<span>*</span>
                                <br />  
                            </label>

                            <div className="d-flex">
                                <button type="button" className={`btn   ${userTypeId == 'client' ? "btn-orange" : "btn-dark-outline"}  shadow-none m-r-10`} onClick={() => handleOption('client')}>Clients</button>
                                <button type="button" className={`btn ${userTypeId == 'expert' ? " btn-orange" : "btn-dark-outline"} shadow-none`} onClick={() => handleOption('expert')}>Experts</button>
                                </div>


                            </div>

                            {error != "" && (
                                <>  <span className="errortext">User Type is required</span></>
                            )}
                            <div className=" d-flex m-t-30">
                                <button type="button" className="btn btn-green btn-block  m-l-30 m-r-10">Sign up</button>  <button type="button" className="btn btn-green btn-block m-l-10 m-r-30" onClick={() => { toggleModal(); }}>Cancel</button></div>

                        </div>
                       
                </div>
                </div>
                
            </div>
            <Modal.Body className="p-0 d-none">
                
               
                <div className="row no-gutters w-100">

                    <div className="col-md-12 col-12 w-100 px-5 py-5">

                        <div className=" align-items-center px-5 py-5">
                            <div className="font-size-4   font-weight-semibold  line-height-2 pb-5">
                                Select User Type
                            </div>
                            <div>

                                <div className="media mb-2   row  ">
                                    {/* <!-- media icon start --> */}

                                    <div className="media-icon text-center  col-6  col-lg-6" onClick={() => handleOption('expert')} style={{ cursor: 'pointer', }}>

                                        <div className="row font-size-4  font-weight-semibold">
                                            <div className="col-2 py-1 "> <input
                                                name="expert"
                                                className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px' }}
                                                type="checkbox"
                                                id="expert-check" onChange={() => handleOption('expert')} checked={userTypeId == 'expert'}
                                            /><div className="border-1-green text-green font-size-3  d-none" style={{ width: '18px', height: '18px' }}>{userTypeId === 'expert' && <> <i className="fa fa-check text-right pos-abs-cr mr-0"  ></i> </>} </div></div>
                                            <div className="col-9 text-left ">  <i className="fa fa-user text-yellow font-size-4"></i> {' '} Expert</div>

                                        </div>
                                    </div>
                                    <div className="media-icon col-6 text-center  col-lg-6" onClick={() => handleOption('client')} style={{ cursor: 'pointer', }}>
                                        <div className="row font-size-4 font-weight-semibold">
                                            <div className="col-2 py-1  ">
                                                <input
                                                    name="client"
                                                    className="form-control  border-1-green text-green font-size-3  mr-5 bg-green" style={{ width: '18px', height: '18px' }}
                                                    type="checkbox"
                                                    id="client-check" onChange={() => handleOption('client')} checked={userTypeId == 'client'}
                                                />
                                                <div className="d-none border-1-green text-green font-size-3   " style={{ width: '18px', height: '18px' }}>{userTypeId === 'client' && <i className="fa fa-check  pos-abs-cr"  ></i>} </div></div>
                                            <div className="col-9  text-left  ">  <i className="fa fa-user-tie text-yellow font-size-4"></i> {' '} Client</div>
                                        </div> </div>

                                </div>

                                {error !== "" && (
                                    <span className="error font-size-3"> User Type is required </span>
                                )}</div>
                            <div className=" mt-5 media pt-2 w-100 mr-0  justify-content-md-end">
                                <button type="button"
                                    className=" btn-green   font-size-3 font-weight-bold rounded-5 border-0 text-uppercase" onClick={(e) => {


                                    }}>
                                    Submit
                                </button><button onClick={() => toggleModal()} type="button" className="btn-green  border-0  font-size-3 font-weight-bold ml-2   rounded-5 text-uppercase"   >
                                    Cancel
                                </button></div>
                        </div>

                    </div>


                </div>
               
            </Modal.Body>
            </ModalStyled>
        
    );
};

export default ModalFlagReason;
