import React, { useContext, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import { useParams } from "react-router-dom"; 
import ProfileView from "../sections/ExpertDashboard/ProfileView";
import ClientProfileView from "../sections/ClientDashboard/ProfileView";
import GlobalContext from "../context/GlobalContext";
export default function ProfileViewPage(props) {
    const { SUB } = useParams();
    const gContext = useContext(GlobalContext);
    gContext.setUserMode(props.userMode);
    window.addEventListener('message', function (message) {
        if (props.userMode) {
            if (message.origin === process.env.REACT_APP_EXURL.replace("resource", "admin")) {

                if (message.data) {
                    var data = JSON.parse(message.data);
                    if (data.payload["custom:usertype"] != "3")
                        window.location.href = "/notavailable";
                }
                else
                    window.location.href = "/notavailable";

            }
        }
    });

    useEffect(() => {
        document.title = "Profile | Syncuppro";
       
    }, []); 
    return (
        <>
          
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>
                {props.userType == "Expert" && <ProfileView sub={SUB} mode={props.mode} userMode={props.userMode} />}
                {props.userType == "Client" && <ClientProfileView sub={SUB} userMode={props.userMode} />}
              
            </PageWrapper>
            <iframe id="iframe" src={process.env.REACT_APP_EXURL.replace("resource", "admin")} style={{ width: "0px", height: "0", border: "none", position: "absolute" }}  ></iframe>
        </>
    );
};
