import React, { useContext } from "react";

import GlobalContext from "../../context/GlobalContext";

const imgC = "assets/image/l2/png/content-2-img1.png";
const Content = () => {
    const gContext = useContext(GlobalContext);
  return (
      <>
          <section id="whoweare" className="section ideal-group p-relative gradient-img">
              <div className="left-sec">
                  <div className="img-wrapper">
                      <img className="img-block" src="../images/find-compliance.jpg" alt="workers-img" />
                  </div>
              </div>

              <div className="frame-content-block">
                  <div className="section-title">

                      <h2 className="p-b-5">Why Select <span className="text-theme">SyncupPro</span>?</h2>
                  </div>

                  <div className="frame-block">
                      <img src="images/compliance-experts-profiles.svg" alt="compliance-experts-profiles" />
                      <div className="frame-data">
                          <h6>Robust Qualification and Assessment process</h6>
                          <p className="m-0">SyncupPro has designed a thorough qualification process and utilizes screening assessments to handpick the experts. All experts have been verified, validated with active industry specific credentials.</p>
                      </div>
                  </div>

                  <div className="frame-block">
                      <img src="images/multiple-proposal.svg" alt="multiple-proposal" />
                      <div className="frame-data">
                          <h6>Turnkey Compliance solution</h6>
                          <p className="m-0">We understand compliance requires multiple stages to achieve approval. Syncuppro has experts to support all stages starting from documentation, consulting, training and 3rd party auditing.</p>
                      </div>
                  </div>

                  <div className="frame-block">
                      <img src="images/project-tracking.svg" alt="project-tracking" />
                      <div className="frame-data">
                          <h6>Multiple Proposals</h6>
                          <p className="m-0">Get no obligation proposals from multiple experts, select one that fits your needs and budgets.</p>
                      </div>
                  </div>
                  {
                      gContext.userInfo.email == '' && <><button type="button" id="singup1" className="btn  btn-orange" onClick={(e) => {
                          e.preventDefault(); gContext.setDefaultUserType('client');
                          gContext.toggleSignUpModal();
                      }}>Start now for FREE </button></>}
                  

              </div>

          </section>
      {/* <!-- Content Area -->  */}
          <section id="whoweare1" className="bg-white pt-13 pt-lg-30 pb-13 pb-lg-30 d-none">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-lg-6 col-md-8 col-sm-10 col-xs-11 mb-9 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              {/* <!-- content-1 left-content start --> */}
                          <div className="position-relative pr-lg-20 pr-xs-15 pr-9 ">
                              
                {/* <!-- content img start --> */}
                <img src={imgC} alt="" className="w-100" />
                {/* <!-- content img end --> */}
                {/* <!-- abs-content start --> */}
                <div className="abs-content pos-abs-br bg-white shadow-2 pl-7 pt-8 pb-1 pr-11 max-width-px-311 rounded mb-15 mb-xs-18 mb-lg-15 mb-xl-18">
                  {/* <!-- media start --> */}
                  <div className="media mb-5">
                    {/* <!-- check-mark start --> */}
                    <span className="check-mark bg-yellow-2 circle-41">
                      <i className="fas fa-check text-white  font-size-6"></i>
                    </span>
                    {/* <!-- check-mark end --> */}
                    {/* <!-- media body start --> */}
                    <div className="media-body pl-5">
                      <h6 className="mb-0 font-size-3 text-green text-uppercase">
                        Job alert!
                      </h6>
                      <p className="mb-0 font-size-4 text-black-2">
                        New jobs are available !
                      </p>
                    </div>
                    {/* <!-- media body start --> */}
                  </div>
                  {/* <!-- media end --> */}
                </div>
                {/* <!-- abs-content end --> */}
              </div>
              {/* <!-- content-1 left-content end --> */}
            </div>
            <div
              className="col-xl-5 col-lg-6 col-md-8 col-xs-10"
              data-aos="fade-left"
              data-aos-duration="800"
            >
              {/* <!-- content-1 start --> */}
              <div className="content-1 pl-xl-5 pl-xxl-11 pr-xxl-10">
                {/* <!-- content-1 section-title start --> */}
                <h2 className="font-size-9 mb-md-15 mb-13">
                                  Find the ideal compliance Experts<br className="d-none d-sm-block" />
                  
                </h2>
                {/* <!-- content-1 section-title end --> */}
                {/* <!-- media start --> */}
                <div className="media mb-11">
                  {/* <!-- media icon start --> */}
                  <div className="media-icon px-6 py-5 bg-green-opacity-2 rounded-5">
                                      <i className="fa fa-search text-green"></i>
                  </div>
                  {/* <!-- media icon start --> */}
                  {/* <!-- media body start --> */}
                  <div className="media-body pl-7">
                                      <h5 className="mb-5 h5"> Thousands of Compliance Experts profiles </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                                          SyncupPro has thousands of prescreened experts addressing all the global compliance standards in different industry verticals.
                    </p>
                  </div>
                  {/* <!-- media body start --> */}
                </div>
                {/* <!-- media end --> */}
                {/* <!-- media start --> */}
                <div className="media mb-11">
                  {/* <!-- media icon start --> */}
                  <div className="media-icon px-6 py-5 bg-yellow-2-opacity-2 rounded-5">
                                      <i className="icon icon-layout-11 text-yellow-2"></i>
                  </div>
                  {/* <!-- media icon start --> */}
                  {/* <!-- media body start --> */}
                  <div className="media-body pl-7">
                                      <h5 className="mb-5 h5">Multiple Proposal</h5>
                    <p className="mb-0 font-size-4 text-default-color">
                                          Get no obligation proposals from multiple experts, select one that fits your needs and budget.
                    </p>
                  </div>
                  {/* <!-- media body start --> */}
                </div>
                {/* <!-- media end --> */}
                {/* <!-- media start --> */}
                <div className="media mb-11">
                  {/* <!-- media icon start --> */}
                  <div className="media-icon px-6 py-5 bg-red-opacity-2 rounded-5">
                                      <i className="fa fa-chart-line text-red"></i>
                  </div>
                  {/* <!-- media icon start --> */}
                  {/* <!-- media body start --> */}
                  <div className="media-body pl-7">
                                      <h5 className="mb-5 h5">Project tracking</h5>
                    <p className="mb-0 font-size-4 text-default-color">
                                          Create milestones and track your project progress with our user friendly time tracker and mobile app.
                    </p>
                  </div>
                  {/* <!-- media body start --> */}
                              </div>
                              <div className="media mb-11">
                                 
                                  {/* <!-- media icon start --> */}
                                  {/* <!-- media body start --> */}
                                  <div className="media-body pl-7">
                                      <div className="text-center">
                                      <a className="btn btn-green text-uppercase rounded-10 "
                                           style={{ minWidth: '180px'}} 
                                          onClick={(e) => {
                                              e.preventDefault();
                                              gContext.toggleSignUpModal();
                                          }}   >
                                              Start now for FREE
                    </a>
                                      </div>    
                                  </div>
                                  {/* <!-- media body start --> */}
                              </div>
                             
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
