import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";

const img = "assets/image/l1/jpg/faq.jpg";

export default function Terms(props) {
    const [openItem, setOpenItem] = useState(1);
    return (
        <>
            
            <PageWrapper   headerConfig={{
                    bgClass: "dynamic-sticky-bg",
                button: props.navType
          }}>

                <span className="screen-darken"></span>

                <div className="page-wrapper">

                    <div className="breadcrumb">
                        <div className="container-fluid">

                            <div className="breadcrumb-wrapper justify-content-center">
                                <h4>Terms of Use</h4>
                            </div>

                        </div>
                    </div>

                    <section className="section terms-block">
                        <div className="container-fluid">
                            <p>Date of Last Revision:<b>  September 05, 2022</b></p>

                            <p>Please read these terms of use carefully. By accessing or using our website, website application or ordering any service on or through the website or associated mobile applications (herein after collectively known as ‘Platform’) or by posting any content on the Platform, you agree to be bound by these terms of use and all terms incorporated by reference and you warrant that you have the full autonomy to accept these terms and conditions. Do not use our Platform if you do not agree to all of these terms.</p>

                            <p>The Platform is owned and operated by SyncupPro (hereinafter referred to as ‘Company’), a company registered under the laws of United States of America and having its registered office at [insert]. These Terms applies exclusively to your access to, and use of the Platform. If you are using the Platform on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity's behalf and that such entity agrees to indemnify you and the Company for its violations of these Terms.</p>

                            <p>The Company reserves the right to change or modify these Terms at any time and in its sole discretion. If there are any changes to these Terms, we will provide you with notice of such changes, such as by sending an email, push notification, posting a notice on the Platform or updating the date at the top of these Terms. Your continued use of the Platform will confirm your acceptance of the revised Terms. We encourage you to frequently review the Terms to ensure you understand the terms and conditions that apply to your use of the Platform. If you do not agree to the amended Terms, you must stop using the Platform.</p>

                            <p>Please keep in mind that this document is a legally binding agreement between the user of the Platform (hereinafter referred to as ‘you’, ‘your’ or ‘User’) and the Company. If you have not attained the age of giving legal consent, according to applicable laws, to this agreement, your parent or guardian must agree to this agreement on your behalf.</p>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>1. About Us</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>1.1. The Platform is a marketplace solution that is designed to allow Users to order services from experts registered on the Platform (‘Expert’).</p>

                                    <p>1.2. The Company offers a platform that enables Users and Experts to find one another, enter into service relationships and agreements, receive services from Expert, and make and receive payments. The Company neither performs nor employs Experts to perform services. You acknowledge and agree that The Company does not supervise, direct, control, or monitor Expert in the performance of any contractual obligations they may have under agreement between the User and the Expert. and agree that: (a) the Company is not responsible for the offering, performance, or procurement of services, (b) the Company does not make any representations about or guarantee any particular Expert’s offered services, and (c) nothing will create an employment, agency, or joint venture relationship between the Company, User and or the Expert.</p>

                                    <p>1.3. You further acknowledge and agree that you, and not the Company, are solely responsible for (a) evaluating and determining the suitability of any Expert; (b) assessing whether to enter into an agreement with the Expert; and (c) negotiating, agreeing to, and executing any terms or conditions of the contracts and for performing and monitoring performance under them. The Company is not a party to those contracts. Nothing in this Terms of Use is intended to or does prohibit or discourage any User from engaging in any other business activities or providing any services through any other channels they choose, provided, if applicable. Users are free at all times to engage in such other business activities and services and are encouraged to do so.</p>

                                    <p>1.4. If a User and an Expert decide to enter into a contract, the contract is a contractual relationship directly between the User and the Expert; The Company is not responsible for and is not a party to any such contract and under no circumstances will any such contract create an employment or any service relationship between the Company, User and or any Expert. With respect to any contract, User and Experts may enter into any agreements that they deem appropriate (e.g., confidentiality agreements, invention assignment agreements, assignment of rights, etc.), provided that those agreements do not conflict with, narrow, or expand the Company’s rights and obligations under these Terms of Use.</p>
                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>2. Eligibility for Platform</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>2.1. This Platform is intended solely for Users who are eighteen (18) years of age or older, and any registration by, use of or access to the Platform by any natural person under the age of eighteen (18) is unauthorized, unlicensed and in violation of these Terms of Use. Use of the Platform is available only to persons who can form a legally binding contract under applicable law.</p>

                                    <p>2.2. By using the Platform, you represent and warrant that you agree to and will abide by all of the terms and conditions of these Terms of Use and will not use the Platform for any illegal activity. If you violate any of these Terms of Use, or otherwise violate an agreement between us, the Company may terminate your access, delete your profile and any content or information that you have posted on the Platform and/or prohibit you from using or accessing the Platform (or any portion, aspect or feature of the Platform), at any time in its sole discretion, with or without notice, including without limitation if it believes that you are under the age of eighteen (18).</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>3. Account and Registration Obligations</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>3.1. For use of the Platform, you shall be responsible for creating an account with us, maintaining the confidentiality of your user-name and password and you shall be responsible for all activities that occur under your user name and Password.</p>

                                    <p>3.2. You agree that if you provide any information that is untrue, inaccurate, not current or incomplete or the Company have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, the Company shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide you with access to the Platform.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>4. Confidentiality of Account</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>4.1. If you choose, or are provided with, a user name, password, or any other piece of information as part of our security procedures, Users shall bear all responsibility for maintaining the confidentiality of such information; undertaking/implementing reasonable security measures to protect the same from malicious attacks or inadvertent losses and for any activity including transactions that are undertaken from the User Account and you agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. </p>

                                    <p>4.2. We have the right to disable any user name, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms. Company may also, in its sole discretion, limit the number of accounts that you may hold, maintain or acquire.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>5. Commercial Terms</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>5.1. The prices agreed between you and the Expert is inclusive all applicable taxes and the total price the User will pay for the services.</p>

                                    <p>5.2. The Company shall be further entitled to charge a separate admin fees (‘Admin Fees’). The Admin Fees shall be paid prior to availing any of the Services.</p>

                                    <p>5.3. The Company uses third-party payment service provider including, but not limited to stripe as its preferred payment gateway. When the User places an order on the Platform, the User acknowledges and authorizes the Company to manage payments and transactions between User and Expert. The User further consent that we may act as an intermediary to accept payments on the Expert’s behalf. The Company shall not be liable for any loss or damage arising from errors in entering payment information, disruptions or delays in payment processing, by the User. The Company or the Expert, as the case may be, reserves the right to suspend or cancel any payment if User’s payment information is unconfirmed or invalid.</p>

                                    <p>5.4. Escrow Services.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>6. Reviews, Feedback and Testimonials</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>6.1. Company may ask the User to provide reviews, feedback, or testimonials (hereinafter referred to as ‘Feedback’), for the Platform, Expert and or the services provided by the Expert. The Feedback should not be intentionally damaging, offensive or malicious. The Feedback may be used for internal purposes but could also be used publicly on the Platform, Expert and or the services provided by the Expert. The Company will not entertain any false Feedback. The Feedback should not include any contact information within the review.</p>
                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>7. Submitted Content</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>7.1. Company does not claim ownership of any materials you make available through the Platform. At Company's sole discretion, such materials may be included in the Platform in whole or in part or in a modified form. With respect to such materials, you submit or make available for inclusion on the Platform, you grant Company a perpetual, irrevocable, non-terminable, worldwide, royalty-free and non-exclusive license to use, copy, distribute, publicly display, modify, create derivative works, and sublicense such materials or any part of such materials (as well as use the name that you submit in connection with such submitted content).</p>

                                    <p>7.2. You hereby represent, warrant and covenant that any materials you provide do not include anything (including, but not limited to, text, images, music or video) to which you do not have the full right to grant the license. We take no responsibility and assume no liability for any submitted content posted or submitted by you. We have no obligation to post any material; we reserve the right in our absolute discretion to determine what is published on the Platform. If you do not agree to these terms and conditions, please do not provide us with any submitted content.</p>

                                    <p>7.3. You agree that you are fully responsible for the content you submit. You are prohibited from posting or transmitting to or from this Platform: (i) any unlawful, threatening, libelous, defamatory, obscene, pornographic, or other material or content that would violate rights of publicity and/or privacy or that would violate any law; and (ii) any material or content that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary right of any third party. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from your posting of content to this Platform.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>8. User Representations</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>8.1. You represent, warrant and agree that materials of any kind submitted through your account or your conduct while using the Platform or ordering a service will not violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights; or contain libelous, defamatory, fraudulent or otherwise unlawful material.</p>

                                    <p>8.2. You further agree that you may not use the Platform or place an order in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Platform. In addition, you agree not to use the Platform to intimidate, harass or in any way interfere with, disrupt, negatively affect or inhibit other users from using the Platform; impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age or your affiliation with or authority to act on behalf of any person or entity.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>9. Third Party Content</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>9.1. Third Party Content. Experts and third parties may provide certain information and content to Company (hereinafter referred to as ‘Third Party Content’). The Third-Party Content is, in each case, the copyrighted work of the creator/licensor. Please contact the appropriate third party for further information regarding such terms. This Platform may provide links to other websites/ application or resources over which Company does not have control (hereinafter referred to as ‘External Web Platforms’). Such links do not constitute an endorsement by Company of those External Web Platforms.</p>

                                    <p>9.2. You acknowledge that Company is providing these links to you only as a convenience, and further agree that Company is not responsible for the content of such External Web Platforms. If you access the External Web Platforms or to use or install any Platforms, software or content, you do so at your own risk and you should be aware that Company's terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any Platform to which you navigate from Company or relating to any Platforms you use or install from the Platform. Your use of External Web Platforms is subject to the terms of use and privacy policies located on the linked to External Web Platforms.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>10. Warranties and Limitation of Liabilities</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>10.1. Third-Party Services. You understand that the Company does not supervise, instruct, assist or otherwise provide services ordered through the Platform. We only provide the platform; the services are provided solely and directly by independent Expert, so The Company can't and does not make any warranties about their quality, merchantability, fitness for a specific purpose or even their legality. You release the Company from any claims related to such third-party services ordered through the Platform.</p>

                                    <p>10.2. Content You Access. You may come across materials that you find offensive or inappropriate while using the Platform. We make no representations concerning any content posted by users through the Platform. The Company is not responsible for the accuracy, copyright compliance, legality, or decency of content posted by users that you accessed through the Platform. You release us from all liability relating to that content.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>11. Disclaimer of Warranties/ Limitation of Liability</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>11.1. THE PLATFORM, SERVICES AND OTHER MATERIALS ARE PROVIDED BY THE COMPANY ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>

                                    <p>11.2. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE WILL HAVE NO LIABILITY RELATED TO USER CONTENT ARISING UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY OR OTHER LAWS. THE PLATFORM ALSO DISCLAIMS ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION OR UNAVAILABILITY OF ANY USER CONTENT.</p>

                                    <p>11.3. IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY OTHER DAMAGES RESULTING FROM: (A) THE USE OR THE INABILITY TO USE THE PLATFORM INCLUDING THE INABILITY TO FIND A QUALIFIED EXPERT; (B) THE USE OR THE INABILITY TO USE OR MERCHANTIBILITY OF ANY SERVICE ORDERED THROUGH THE PLATFORM; (C) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES; (D) UNAUTHORIZED ACCESS TO OR ALTERATION OF THE USER'S TRANSMISSIONS OR DATA; (E) ANY OTHER MATTER RELATING TO THE PLATFORM INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE PLATFORM.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>12. Indemnity</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>12.1. You shall indemnify and hold harmless to Company and (as applicable) Company's partners, subsidiaries, affiliates, third-parties and their respective officers, directors, agents, and employees, from any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising out of claims based upon the use of User's actions or inactions, including but not limited to any warranties, representations or undertakings or in relation to the services ordered through the Platform or arising out of the User's infringement of any applicable laws, regulations including but not limited to Intellectual Property Rights, Confidentiality obligations, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>13. Company’s Rights</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>13.1. Company reserves the right at all times to edit, refuse to post, or to remove, from the Platform, any information or materials for any reason whatsoever, and to disclose any information we deem appropriate to satisfy any applicable law, regulation, legal process, police request or governmental request.</p>

                                    <p>13.2. Company reserves the right to restrict your access to the Platform at any time without notice for any reason whatsoever. Without prejudice to the generality of the above, we reserve the right to restrict your access to the Platform at any time without notice in the event that we suspect you to be in material breach of any term of these Terms of Use.</p>

                                    <p>13.3. The Company also reserves the right to modify or discontinue temporarily or permanently all or part of the Platform with or without notice without liability for any modification or discontinuance. If you have any questions about your pending transactions, please feel free to contact the Company at contactus@syncuppro.com.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>14. Copyright</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>14.1. The Platform is our property or the property of our content suppliers and is protected by international copyright laws. All Platform that is not our property is used with permission. The arrangement and compilation of all Platform is our exclusive property and is protected by international copyright laws. All software used on the Platform is our property or the property of our software suppliers and is protected by international copyright laws.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>15. Trademarks</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>15.1. Certain graphics, logos, page headers, button icons, scripts, and service names are trademarks, service marks, or trade dress of the Company or our affiliated companies. Our trademarks and trade dress may not be used for any commercial or other purposes without our prior written consent. All other trademarks and service marks not owned by us or our affiliated companies that appear on the Platform are the property of their respective owners and may or may not be used without their prior written consent.</p>

                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>16. Copyright & Trademark Compliance</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>16.1. We honor the intellectual property rights of others. If you believe that your work has been copied or used on the Platform in a way that constitutes copyright or trademark infringement, please notify us by following the procedure set forth in the immediately following paragraph. Upon receipt of any bona fide claim of infringement, or upon becoming aware of any actual or alleged infringement by any other means, we will remove such actual or alleged infringing material pending our investigation.</p>

                                    <p>16.2. If you believe that your work has been copied, distributed or used on the Platform in a way that constitutes copyright or trademark infringement, please forward the following information to the Company in way of a notice.</p>


                                    <ul className="terms-list">
                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">Your name, address, telephone number, and email address;</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">A description of the copyrighted work that you claim has been infringed;</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">The exact URL or a description of where the alleged infringing material is located;</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; and</span>
                                        </li>

                                        <li>
                                            <span className="m-icon material-icons">arrow_forward</span>
                                            <span className="f-16">A statement by you, under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</span>
                                        </li>

                                    </ul>

                                    <p>The notice, as mentioned above, shall be sent at the following address: </p>
                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>17. Termination</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>17.1. You agree that Company, in its sole discretion, for any or no reason, and without penalty, may suspend or terminate your use of their Platform and remove and discard all or any part of your account, user profile, and any content, at any time. You agree that any suspension or termination of your access to the Platform or any account you may have may be affected without prior notice, and you agree that Company will not be liable to you or any third party for any such termination.</p>

                                    <p>17.2. In the event of any Force Majeure Event (as defined in "General Clauses" Section), breach of this agreement, or any other event that would make the availability of the Platform commercially unreasonable for Company, we may, in our discretion and without liability to you, with or without prior notice, suspend your access to all or a portion of the Platform.</p>


                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>18. Settlement of disputes</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>18.1. All questions, disputes and differences arising under or in relation to the interpretation of these terms of use and/or to the contents stated in this Platform and/or as to the determination of the rights and obligations of the users and the provider of the service herein shall be amicably settled through good faith consultation. The Parties shall engage in such good faith consultation for a minimum period of 30 (thirty) days starting from the date such question, dispute or difference is first communicated in writing by a Party (hereinafter referred to ‘Consultation Period’). Such consultation shall be deemed to have failed in the event either Party notifies such failure to the other in writing upon completion of the Consultation Period.</p>

                                    <p>18.2. The Company and User agree to submit in that event to the exclusive jurisdiction and venue of courts located in Georgia, United States of America.</p>


                                </div>

        </div>

                            <div className="card panel p-0 m-b-60">

                                <div className="card-header">
                                    <h5>19. General Provision</h5>
                                </div>

                                <div className="card-body p-b-0">
                                    <p>19.1. Assignment. This Agreement and your rights and obligations shall not be assignable, in whole or in part, without the prior written consent of Company. Any assignment without Company’ consent is void.</p>

                                    <p>19.2. Relationship of Parties. Nothing contained in this agreement shall be construed or interpreted as constituting a partnership, joint venture, agency, employer, employee relationship between the parties. No third person is intended to be a third party beneficiary thereof. The parties agree to constructively work together in furtherance of the mutually beneficial object set forth in the agreement.</p>

                                    <p>19.3. Non-Waiver-The failure of any party to enforce at any time any of the provisions of this agreement shall not be construed to be a waiver of the right of such party thereafter to enforce any such provisions.</p>

                                    <p>19.4. Severability-If any clause, sentence, paragraph or part of this agreement, or the Platform thereof to any person, shall for any reason be adjudged by a court of competent jurisdiction to be invalid, such judgment shall be limited and confined in its operation to the clause, sentence, paragraph or part thereof directly involved in the controversy and remainder of this agreement will continue in full force or effect</p>

                                    <p>19.5. Force Majeure- Company shall not be liable for (1) any inaccuracy, error, delay in, or omission of (i) any information, or (ii) the transmission or delivery of information; (2) any loss or damage arising from any event beyond Company's reasonable control, including but not limited to flood, extraordinary weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, communications, power failure, or equipment or software malfunction or any other cause beyond Company's reasonable control (each, a "Force Majeure Event").</p>

                                    <p>19.6. Notices-Any notices required or permitted by this agreement shall be deemed given if sent by e-mail, certified mail, postage prepaid, return receipt requested or by recognized overnight delivery service. All notices hereunder required to be given to the Company shall be sent to its address first mentioned herein and all notices to the user shall be sent to its address/ e-mail address as may be provided during the registration process.</p>

                                    <p>19.7. Entire Agreement-This agreement constitutes the entire understanding between the Partners hereto and it merges all prior discussions between them relating thereto.</p>

                                    <p>19.8. Headings-Headings and titles in this agreement are for reference purposes only and do not constitute part of this agreement and are of no legal force and effect.</p>

                                    <p>19.9. Applicable Laws-This agreement will be governed by United States of America and interpreted and construed in accordance with the laws of United States of America without regard to conflict of laws principles thereof.</p>

                                </div>

        </div>

                        </div> 
    </section>



                </div>
          </PageWrapper>
    </>
  );
};
