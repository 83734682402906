import React, { useState, useContext, useEffect, useRef } from "react";

import GlobalContext from "../../context/GlobalContext";
import { getMonthDateYear } from "../../utils/addDays";
import { LoadAlerts, CreateAlert } from "../../utils/apiCalls";
import validator from 'validator';
import ConfirmMessages from "../../utils/confirmMessages";

import Message from "../../components/Message";

const imgSystem = "../favicon.png";
const Notifications = (props) => {
    const gContext = useContext(GlobalContext); 
    const [currentValue, setCurrentValue] = useState({ id: null });
    const [message, setMessage] = React.useState(gContext.visibleProfileMessage);
    const [messageList, setMessageList] = React.useState(null);  
    const [errorMessage, setErrorMessage] = React.useState('');    
    const [loaded, setLoaded] = useState(false);
    let previousDate = "";
    let currentDate = "";

    

    useEffect(() => {
        if (gContext.confirmModal.confirmed) {
            deleteNotification(gContext.confirmModal.id, gContext.confirmModal.mode);
            gContext.setconfirmModal({
                ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
            });
        }
        else { setCurrentValue({ id: null }); }
    }, [gContext.confirmModal]);

    const deleteNotification = async (rowData) => {
        
        try {

            const result = await CreateAlert({ id: rowData.id, alert: rowData.alert, alertType: rowData.alerttype, display: false, status: rowData.status, toTypeId: gContext.userInfo.userType, toUserId: gContext.userInfo.userID });

            if (result.data.createAlert.responseData.code == "200") {

                BindAlerts();
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        
    }


    async function BindAlerts() {
        try {
           
            setLoaded(false);
            const result = await LoadAlerts(gContext.userInfo.userID, gContext.userInfo.userType);
            
            if (result.data.loadAlerts.responseData.code == "200") {

                let data = JSON.parse(result.data.loadAlerts.responseData.message);
                
                if (data.length > 0) {
                    setMessageList(data);
                }
                else
                    setMessageList([]);
            } else
                setMessageList([]);
             
            setLoaded(true);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log("alert",e);
            setLoaded(true);
        }
    }

    useEffect(() => {
        BindAlerts();
    }, [])

    useEffect(() => {
        document.title = "Notifications | Syncuppro";
    }, []);
    return (
        <>
            <span className="screen-darken"></span>

            <div className="page-wrapper">
                <div className="breadcrumb">
                    <div className="container-fluid">

                        <div className="breadcrumb-wrapper">
                            <h4>Notifications</h4>

                            <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / Notifications</span>
                            </div>

                        </div>

                    </div>
                </div>
                {loaded && <> 
                <div className="row ">
                    {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                    {errorMessage !== "" && <><span className="error ">{errorMessage} </span><br /></>}
                </div>

                </>}
                {!loaded && <>
                    <section className="section-sm">

                        
                                <div className="container-fluid"  >
                                     
                                        <div className="date-divider">
                                            <span className="w-50 movingLoader"> &nbsp;</span>
                                        </div>
                                     

                                    <a href="#!" className="notification-block  ">
                                        <span className="notification-icon movingLoader w-50">
                                            
                                                &nbsp;
                                           
                                        </span>
                                <div className="notification-meta movingLoader w-full">
                                            <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                            <span className="time movingLoader w-full" >  &nbsp;</span>
                                        </div>
                                        <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>
                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>
                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>
                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>

                        </div> 
                        <div className="container-fluid"  >

                            <div className="date-divider">
                                <span className="w-50 movingLoader"> &nbsp;</span>
                            </div>


                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>
                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>
                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>
                            <a href="#!" className="notification-block  ">
                                <span className="notification-icon movingLoader w-50">

                                    &nbsp;

                                </span>
                                <div className="notification-meta movingLoader w-full">
                                    <div className="text" style={{ wordWrap: "break-word" }}  ></div>
                                    <span className="time movingLoader w-full" >  &nbsp;</span>
                                </div>
                                <button type="button" className="btn btn-icon close movingLoader"  ></button>
                            </a>

                        </div>
                    </section>

                </>}
               
                {loaded && <> {(messageList == null || (messageList && messageList.length == 0)) &&
                    <div className="d-flex flex-column align-item-center justify-content-center" style={{ height:"50vh" }}>
                        <span className="text-light-gray m-icon material-icons m-b-10">notifications</span>
                        <span>No Notification found</span>
                    </div>}
                    
                    <section className="section-sm">
                        
                        {messageList && messageList.map((data, index) => {
                            previousDate = currentDate;
                            currentDate = (data.datecreated) ? (currentDate != getMonthDateYear(data.datecreated)) ? getMonthDateYear(data.datecreated) : currentDate : "";
                            let displaydate = new Date(data.datecreated);
                            return (<>
                                <div className="container-fluid" key={index}>
                                    {(previousDate != currentDate) &&
                                        <div className="date-divider">
                                        <span>{(data.datecreated) ? getMonthDateYear(data.datecreated) : ""}</span>
                                        </div>
                                    }

                                    <a href="#!" className="notification-block  ">
                                        <span className="notification-icon">
                                            {data.alerttype && data.alerttype.trim().toLowerCase() == "job" &&
                                                <span className="text-gray m-icon material-icons">work</span>}
                                            {data.alerttype && data.alerttype.trim().toLowerCase() == "account" &&
                                                <span className="text-gray m-icon material-icons">person</span>}
                                            {data.alerttype && data.alerttype.trim().toLowerCase() == "system" &&
                                                <span className="text-gray m-icon material-icons">Computer</span>}
                                            {data.alerttype && data.alerttype.trim().toLowerCase() == "payment" &&
                                                <span className="text-gray m-icon material-icons">attach_money</span>}
                                        </span>
                                        <div className="notification-meta">
                                            <div className="text" style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: data.alert.replaceAll("''", "\"") }}></div>
                                            <span className="time" > {(displaydate) ? ((displaydate.getHours().toString().length == 1) ? "0" : "") + ((displaydate.getHours() > 12) ? displaydate.getHours() - 12 : displaydate.getHours()) + ":" + ((displaydate.getMinutes().toString().length == 1) ? "0" : "") + displaydate.getMinutes() + ((displaydate.getHours() >= 12) ? " PM" : " AM") : ""}</span>
                                        </div>
                                        <button type="button" className="btn btn-icon close" data-toggle="tooltip" title="Click to remove" onClick={() => { deleteNotification(data); }}><span className="text-theme m-icon material-icons">close</span></button>
                                    </a>
                                     
                                </div></>)
                        })}
                        {/* <!-- End Single Category --> */}
                    </section>  </>}

                 

            </div>
            <div
                className="dashboard-main-container mt-20 mt-lg-25  min-h-100vh d-none "
                id="dashboard-body"
            >
                <div className="container box-width-80 mx-auto">
                    <div className="mb-15 mb-lg-23">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">


                                <div className="row ">
                                    {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                                    {errorMessage !== "" && <><span className="error py-10   w-100  font-weight-semibold line-height-reset px-8 bg-red-opacity-1 rounded-5 font-size-4">{errorMessage} </span><br /></>}
                                </div>

                            </div>
                            <div className="col-xxxl-12  ">
                                <h5 className="font-size-7 font-weight-semibold mb-5 text-green-2">
                                    Notifications
                                </h5>
                            </div>

                            {loaded && <> {(messageList == null || (messageList && messageList.length == 0)) && <div className="col-xxl-12"> <span className="error py-5   w-auto  font-weight-semibold line-height-reset   rounded-5 font-size-5">No notification found </span>

                            </div>}
                                <div className="col-xxl-12">

                                    {messageList && messageList.map((data, index) => {
                                        previousDate = currentDate;
                                        currentDate = (data.datecreated) ? (currentDate != getMonthDateYear(data.datecreated)) ? getMonthDateYear(data.datecreated) : currentDate : "";
                                        let displaydate = new Date(data.datecreated);
                                        return (<>
                                            <div key={index}>
                                                {(previousDate != currentDate) && <div className="or-devider">
                                                    <span className="font-size-3  bg-athens font-weight-semibold">{(data.datecreated) ? getMonthDateYear(data.datecreated) : ""}</span>
                                                </div>}
                                                <div className="media " >
                                                    {data.alerttype && data.alerttype.trim().toLowerCase() == "system" && <a className="  pointer" rel="noopener noreferrer"   >
                                                        <div className="text-blue   circle-30 font-size-6 mr-7">
                                                            <img src={imgSystem} alt="" className=" circle-30 w-100" />
                                                        </div></a>}
                                                    {data.alerttype && data.alerttype.trim().toLowerCase() == "account" && <a className="  pointer" rel="noopener noreferrer"   >
                                                        <div className="text-blue bg-red-opacity-1 circle-30 font-size-6 mr-7">
                                                            <i className="fa text-red fa-user font-size-4 "></i>

                                                        </div></a>}
                                                    {data.alerttype && data.alerttype.trim().toLowerCase() == "job" && <a className="  pointer" rel="noopener noreferrer"   >
                                                        <div className="text-blue bg-red-opacity-1 circle-30 font-size-6 mr-7">
                                                            <i className="fa text-red fa-briefcase  font-size-4"></i>

                                                        </div></a>}
                                                    {data.alerttype && data.alerttype.trim().toLowerCase() == "payment" && <a className="  pointer" rel="noopener noreferrer"   >
                                                        <div className="text-blue bg-red-opacity-1 circle-30 font-size-6 mr-7">
                                                            <i className="fa text-red fa-dollar font-size-4 "></i>

                                                        </div></a>}
                                                    <div

                                                        className={`   rounded-4 pl-7 pt-1 pb-1 pr-7 position-relative    mb-3  w-auto  bg-white  `}
                                                    >
                                                        <div data-toggle="tooltip" title="Click to remove" className="  font-weight-semibold pos-abs-tr    text-white font-size-4  mr-2 mb-2 pointer  " onClick={() => { deleteNotification(data); }}>
                                                            <i className="fas fa-times text-red"></i>
                                                        </div>

                                                        <span className="font-size-2 text-green-2 justify-content-start ml-auto  mt-0 p-0">
                                                            {(displaydate) ? ((displaydate.getHours().toString().length == 1) ? "0" : "") + ((displaydate.getHours() > 12) ? displaydate.getHours() - 12 : displaydate.getHours()) + ":" + ((displaydate.getMinutes().toString().length == 1) ? "0" : "") + displaydate.getMinutes() + ((displaydate.getHours() >= 12) ? " PM" : " AM") : ""}</span>

                                                        <div className="w-100 font-size-3 font-black-2" style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: data.alert.replaceAll("''", "\"") }}></div>

                                                    </div>

                                                </div>
                                            </div></>)
                                    })}
                                    {/* <!-- End Single Category --> */}
                                </div></>}
                            {!loaded &&

                                <div className="col-lg-12 mt-15">
                                    <div className="container">




                                        <div className="  row">

                                            <div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div>
                                            <div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div><div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div><div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div><div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div> <div className="or-devider col-lg-12 col-lg-12">
                                                <span className="font-size-3  bg-athens font-weight-semibold">{"   "} </span>
                                            </div><div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div><div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div>


                                            <div className="or-devider col-lg-12">
                                                <span className="font-size-3  bg-athens font-weight-semibold"> </span>
                                            </div>
                                            <div className="  col-lg-12  ">
                                                {/* <!-- Single Category --> */}
                                                <a
                                                    href="/#"
                                                    className="media bg-white movingLoader rounded-4 pl-7 pt-2 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                                                >
                                                    <div className="  circle-56 font-size-6 mr-7">

                                                    </div>
                                                    {/* <!-- Category Content --> */}
                                                    <div className="">
                                                        <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">

                                                        </h5>
                                                        <p className="font-size-4 font-weight-normal text-gray mb-0">

                                                        </p>
                                                    </div>
                                                </a>
                                                {/* <!-- End Single Category --> */}
                                            </div>

                                        </div>







                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="dashboard-main-container mt-20 mt-lg-25  min-h-100vh d-none"
                id="dashboard-body"
            ></div>
        </>
    );
};

export default Notifications;
