import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
 
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const ModalPassword = (props) => {

    const gContext = useContext(GlobalContext);   
    const pwd = process.env.REACT_APP_PROMPT_PASSWORD;
    const showPassword = process.env.REACT_APP_SHOW_PWD_PROMPT;
    const [showPrompt, setShowPrompt] = useState(showPassword == "true" && !localStorage.getItem("pwdconfirm"));
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    function confirmPassword() {
        if (pwd == password) {
            setShowPrompt(false);
            localStorage.setItem("pwdconfirm",true);
        }
        else {
            setError("Invalid Password");
        }
    }
    useEffect(() => { 
        setPassword("");
    }, []);
    const handleEsc = (e) => {        
        if (e.keyCode === 27 && showPassword == "true" )        {
            setError(""); 
            setShowPrompt(true); 
            return false;
        }           
    };
    useEffect(() => {        
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);
    return (
        <ModalStyled
            size="sm"
            className="modal"
            backdrop="static"
            centered
            show={showPrompt}
            onHide={() => { setError(""); setShowPrompt(false); }}
        > 

            <div className="modal-body text-center" >
                <button type="button" className="btn-close d-none" ></button>              
                
                <h5 className="m-b-30 m-t-5"> Enter Password </h5>
                
                    <div className="d-flex m-b-5 w-full">
                    <input
                        name="txtPassword"  
                        type="password" 
                        className={`form-control w-full  ${(error.length > 0) ? ' error' : ''}`}
                        id="txtPassword"
                        placeholder={"Password"} onChange={(e) => { setPassword(e.target.value); }}
                    />
                    
                </div> 
                <div className="d-flex m-b-20 w-full">
                    <span className="text-red ">{error}</span>
                </div>
              
                <div className="d-flex ">
                    <button type="button" className="btn btn-green btn-block m-r-50 m-l-50" onClick={() => { confirmPassword(); }}>Submit</button>

                </div>

        </div>

                    
             
        </ModalStyled>
    );
};

export default ModalPassword;
