import React, { useContext } from 'react'
import GlobalContext from "../../context/GlobalContext";
import Zendesk, { ZendeskAPI } from "./../../../src/ZendeskConfig";
export default function UserOption(props) {
   
    const gContext = useContext(GlobalContext);
    const showContent = (mode) => {
        
        props.state.userData.category = props.state.data[mode];
        console.log(props.state.data[mode]);
        props.actions.showContent(mode,props.state.data[mode].name, props.state.data[mode].link);
       
    }

    return (
        <div>
            
            <button className='start-btn m-1' onClick={() => showContent("faq")}>FAQ</button>
            <button className='start-btn m-1' onClick={() => showContent("privacy")}>Privacy Policy</button>
            <button className='start-btn m-1' onClick={() => showContent("contract")}>Contract Lifecycle</button>
            <button className='start-btn m-1' onClick={() => showContent("terms")}>Terms of Service</button>
            <button className='start-btn m-1' onClick={() => showContent("cookie")}>Cookie Policy</button>
            <button className='start-btn m-1' onClick={() => showContent("conduct")}>Code of Conduct</button>
            <button className='start-btn m-1' onClick={() => showContent("payment")}>Payment Terms</button>
            <button className='start-btn m-1' onClick={() => { gContext.toggleBubble(false); gContext.toggleBot(false); ZendeskAPI("messenger", "open"); }}>Chat with Syncuppro Agent</button>
        </div >
    )
}
