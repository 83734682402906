import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import HashLoader from "react-spinners/HashLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import SyncLoader from "react-spinners/SyncLoader";
import { useLocation } from 'react-router-dom';
const imgLoader = "../../assets/image/svg/spinning-circles.svg";


const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 20050; 
  }
`;

const ModalLoader = (props) => {

    const gContext = useContext(GlobalContext); 
    const pathName = useLocation().pathname;
    return (
      
       <> {
            gContext.loaderModalVisible && <>


                {/*     
                {pathName.trim().toLowerCase() === "/profile" && <> <div className=" loaderbase "  >
                </div><div style={{ zIndex: '99999999999' }} className=" pos-abs-center-load   ">

                        <SyncLoader color="#569d11" size="20px" />

                    </div></>}

                {pathName.trim().toLowerCase() === "/searchjob" && <> <div className=" loaderbase "  >
                </div><div style={{ zIndex: '99999999999' }} className=" pos-abs-center-load   ">

                        <HashLoader color="#569d11" size="60px" />

                    </div></>}
                {pathName.trim().toLowerCase() !== "/searchjob" && pathName.trim().toLowerCase() !== "/searchexpert" && pathName.trim().toLowerCase() !== "/profile" && <> <div className=" loaderbase "  >
                </div><div style={{ zIndex: '99999999999' }} className=" pos-abs-center-load   ">

                        <PropagateLoader color="#569d11" size="30px" />

                    </div></>}
                <div style={{ zIndex: '99999999999' }} className=" pos-abs-center-load  d-none ">

                   

                </div>
                {pathName.trim().toLowerCase() === "/searchexpert" && <>  <div className=" loaderbase "  >
                </div>
                    <div style={{ zIndex: '99999999999' }} className=" pos-abs-center-load   ">

                        <ScaleLoader color="#569d11" height="100px" width="10px" radius="5px" margin="5px" />

                    </div></>}*/}
                <div className=" loader-base "  > 
                    <div className=" loader-position   ">

                        <ScaleLoader color="#F7921E" className="loader" height="100px" width="8px" radius="10px" margin="5px" />

                    </div>
                </div>
                
            </>
        }</>
    );
};

export default ModalLoader;
