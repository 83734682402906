import React, { useContext, useState,useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";

const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const ModalError = (props) => {
   
  const gContext = useContext(GlobalContext);

    const handleClose = () => {
        gContext.setSuccessMessage('');
        gContext.toggleSuccessModal();
    };
 
    useEffect(() => {
        if (gContext.successMessage=="")
            gContext.setSuccessModalVisible(false);
    }, [gContext.successMessage]);
    useEffect(() => {
        //if (gContext.successModalVisible==false)
        //gContext.setSuccessMessage('');
    }, [gContext.successModalVisible]);
  return (
      <ModalStyled size="sm"
          centered backdrop="static"
          show={gContext.successModalVisible}
          onHide={() => {  gContext.toggleSuccessModal(); }}
    >
          <div className="modal-body text-center">
              <img className="m-b-20" src="../images/sucess-check.svg" alt="Success" />
              <h5 className="m-b-10">Success!</h5>
              <p className="text-light-gray">{gContext.successMessage}</p>

              <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-green w-120" onClick={() => { handleClose() }}>Ok</button>
              </div>

          </div>
    </ModalStyled>
  );
};

export default ModalError;
