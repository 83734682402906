import React, { useContext, useEffect, useState } from "react";
import StarRating from "../../components/Core/Rating";
import styled from "styled-components";
import { ClientJobsRead } from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import ConfirmMessages from "../../utils/confirmMessages";
import CreateOffer from "../../sections/ClientDashboard/CreateOffer";
import Message from "../../components/Message";
import { sinceDate } from "../../utils/addDays";
import ReactCountryFlag from "react-country-flag";
import { Modal } from "react-bootstrap";
import DOMPurify from 'isomorphic-dompurify';
const imgLoader = "../assets/image/svg/spinning-circles.svg";

const ModalStyled = styled(Modal)`
   &.modal {
     
  }
`;

export default function JobProposal(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    const [job, setJobs] = useState({});
    const [proposal, setProposal] = useState({});
    const [values, setValues] = React.useState({ proposalSummary: '', proposalSummaryText: '', bidRate: null, resetResumeName: '', resumeName: '', resume: null, error: { bidRate: "", proposalSummary: "" } });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showUpdate, setShowUpdate] = React.useState(false);
    const [showOffer, setShowOffer] = useState(false);
    const [offerStatus, setOfferStatus] = useState(false);
    
    const handleClose = () => {

    };

    async function BindJobs() {
        try {
            gContext.setLoaderModalVisible(true);
            const id = props.id;
            const result = await ClientJobsRead(id);

            if (result.data.jobRead.responseData.code == "200") {

                let data = JSON.parse(result.data.jobRead.responseData.message);
                if (data.length > 0) {  
                        setJobs(data[0]);
                }
            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            //  window.location.href = "/error";
        }
    }

     



    useEffect(() => {
        if (props.proposalmodalvisible) {
            setValues({ proposalSummary: '', proposalSummaryText: '', bidRate: null, resetResumeName: '', resumeName: '', resume: null, error: { bidRate: "", proposalSummary: "" } });
            setShowUpdate(false);
            setOfferStatus(false);
            BindJobs();
            
            setProposal(props.proposaldata);
            gContext.setLoaderModalVisible(false);
        }
        else {
            
            setJobs({});
            setProposal({});
            setShowOffer(false);
        }
    }, [props.proposalmodalvisible]);


    return (
        <>





            <ModalStyled  
                centered size={offerStatus === true ? "sm" : "lg"}
                
                backdrop="static"
                show={props.proposalmodalvisible}
                onHide={props.toggleproposalmodal}
            >

                {showOffer === false && offerStatus==false && <>  {showUpdate === false && <div className="modal-content">


                    <div className="modal-header">
                        <h4 className="modal-title"> Proposal</h4>
                        <button type="button" className="btn-close" onClick={() => {
                            props.toggleproposalmodal();
                        }}></button>
                    </div>


                    <div className="modal-body">
                        <div className="d-flex justify-content-between m-b-20">

                            <h6><a href={"job/" + job.guid} rel="noopener noreferrer" target="blank">
                                {(job.title) ? job.title : ''}
                            </a></h6>

                            {job.dateposted && <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">timer</span><span>Posted on : <b>{sinceDate(job.dateposted, Date.now())}</b></span>
                            </div>}

                        </div>

                        <p className="m-b-20 three-line" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p>

                        <div className="divider-line"></div>

                        <div className="row">

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-wrapper">
                                    <span className="text-theme m-icon material-icons m-r-10">monitor</span>
                                    <div className="d-flex flex-column">
                                        <span className="m-b-5 text-capi ">Job type</span>
                                        <b><span className="text-capi">{job.type?job.type.toLowerCase():''}</span></b>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-wrapper">
                                    <span className="text-theme m-icon material-icons m-r-10">location_on</span>
                                    <div className="d-flex flex-column">
                                        <span className="m-b-5">Location </span>
                                        <div className="info-meta flag">
                                            <ReactCountryFlag
                                                countryCode={(job.countrycode) ? job.countrycode : ''}
                                                svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '.7em',
                                                }}
                                                title={(job.countryname) ? job.countryname : ''}
                                            />       <span className="one-line text-black"><strong> 
                                            
                                             {' '} <span className={`text-black ${(job.city != null && job.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{job.city != null?job.city.toLowerCase():''}</span> 
                                           {' '}  {(job.state) ? job.state : ''} {' '} {(job.countryname) ? job.countryname : ''}</strong></span>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-wrapper">
                                    <span className="text-theme m-icon material-icons m-r-10">straighten</span>
                                    <div className="d-flex flex-column">
                                        <span className="m-b-5">Project Length</span>
                                        <b>  {(job.projlength) ? job.projlength : ''}</b>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-wrapper m-0">
                                    <span className="text-theme m-icon material-icons m-r-10">work</span>
                                    <div className="d-flex flex-column">
                                        <span className="m-b-5">Weekly Hours</span>
                                        <b> {(job.weeklyhrs) ? job.weeklyhrs : ''}</b>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="icon-wrapper m-0">
                                    <span className="text-theme m-icon material-icons m-r-10">work</span>
                                    <div className="d-flex flex-column">
                                        <span className="m-b-5">Experience Level</span>
                                        <b>{job.explevel}</b>
                                    </div>
                                </div>
                            </div>


                            {(job.ratefrom && job.rateto) ?

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5"> Rate Range (USD)</span>
                                            <b> {(job.ratefrom && job.rateto) ? "$" + new Intl.NumberFormat('en-US').format(job.ratefrom) + " - " + "$" +new Intl.NumberFormat('en-US').format(job.rateto) : ''}</b>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">  {(job.dailyrate) ? "Daily " : ((job.fixedrate) ? "Fixed " : "Hourly ")}Rate (USD)</span>
                                            <b>  {(job.dailyrate) ? "$" + (new Intl.NumberFormat('en-US').format(job.dailyrate)) : ((job.hourlyrate) ? "$" + (new Intl.NumberFormat('en-US').format(job.hourlyrate)) : "$" + (new Intl.NumberFormat('en-US').format(job.fixedrate)))}</b>
                                        </div>
                                    </div>
                                </div>
                            }


                        </div>
                        {gContext.userInfo.userType === "2" && <>  
                            <div className="modal-footer m-t-5" >
                                <div className="panel-action">



                                    {gContext.userInfo.userType === "2" && <button className="btn btn-orange btn-sm   m-r-10 text-white" onClick={(e) => { props.viewmessage() }}>Message</button>}
                                    {(gContext.userInfo.userType === "2" && proposal.statuscode === 200) && <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={(e) => { setShowOffer(true) }}>Make an Offer</button>}
                                </div>
                            </div></>}
                        <div className="divider-line m-t-5"></div>



                        <div className="row">
                            {errorMessage === "" && <>  <div className="col-lg-12 col-md-12 col-sm-12 p-b-10">
                                <h6>Proposal Details </h6>
                                <span className="text-red">{errorMessage} </span></div></>}

                            {(gContext.userInfo.userType === "1") &&

                                <div className="col-lg-6 col-md-6 col-sm-12 ">
                                    <a className="d-flex align-item-center" target="_blank" rel="noopener noreferrer" href={"/client/" + proposal.sub} >
                                        <div className="medie-info-img">
                                            <img src={s3URL + proposal.logo} alt="" />
                                        </div>
                                        <span className="expert-desg"><strong>{proposal.company}</strong></span>
                                    </a>
                                </div>


                            }

                            {(gContext.userInfo.userType === "2") &&

                                <div className="col-lg-6 col-md-6 col-sm-12 ">
                                    <a className="d-flex align-item-center" target="_blank" rel="noopener noreferrer" href={"/expert/" + proposal.sub} >
                                        <div className="medie-info-img">
                                            <img src={s3URL + proposal.avatar} alt="" />
                                        </div>
                                        <span className="expert-desg"><strong> {proposal.firstname + ' ' + proposal.lastname}</strong></span>
                                    </a>
                                </div>

                            }


                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="forn-group-input ">
                                    <label for="first name" className="form-label m-b-10">Bid Rate (USD) <span className="text-red"> </span></label>
                                   
                                       ${(proposal.bid) ? new Intl.NumberFormat('en-US').format(proposal.bid) : ""}
                                     
                                </div>
                            </div>
                            <div className="company-desc ">
                                <div className="desc-info">
                                    <label className="form-label m-b-0 f-16"> Cover Letter    </label>
                                </div>

                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(proposal.cover_letter) }}></div>


                            </div>


                            {proposal.attachments && proposal.attachments !== "" && <div className="company-meta m-b-20">
                                <label className="d-flex form-label m-b-10 f-16">  Attachment  </label>
                               
                                <a href={s3URL + proposal.attachments} rel="noopener noreferrer" target="_blank" data-toggle="tooltip" title="Click here to view.">  <img src="../images/file.png" htmlFor="idUpload" /> </a>
                                



                            </div>}





                        </div>


                    </div>

                </div>}</>}
                {showOffer === true && offerStatus == false && <>  <CreateOffer setOfferStatus={(status) => { setOfferStatus(status) }} job={job} proposal={proposal} toggleOffer={() => props.toggleproposalmodal()} /> </>}
                {offerStatus === true &&

                    <div className="modal-body text-center ">
                        <img className="m-b-20" src="../images/sucess-check.svg" />
                        <h5 className="m-b-10">Successful!</h5>
                        <p className="text-light-gray">{ConfirmMessages.OfferCreatedMessage}</p>

                        <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120 pointer" onClick={props.toggleproposalmodal}>Ok</button>
                        </div>

                    </div>

                }
                <Modal.Body className="p-0 d-none">
                    <button
                        type="button"
                        className="d-none circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    {showOffer === false && <>  {showUpdate === false &&
                        <div className="container px-0 py-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12  ">
                                    <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                        Proposal
                  </div>
                                </div>
                                <div className="col-xl-12 col-lg-12  mx-auto ">
                                    <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                        {/* <!-- Single Featured Job --> */}
                                        <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                            <div className="row mb-6">
                                                <div className="col-lg-6">

                                                    <a className="font-size-5 text-black-2 font-weight-semibold" href={"job/" + job.guid} rel="noopener noreferrer" target="blank">
                                                        {(job.title) ? job.title : ''}
                                                    </a>
                                                    <div className="media align-items-center d-none">
                                                        {/* <!-- media logo start --> */}
                                                        <div className="square-72   d-block mr-8">
                                                            {(job.logo) && <img src={s3URL + job.logo} className="w-100 rounded" alt="" />}
                                                        </div>
                                                        {/* <!-- media logo end --> */}
                                                        {/* <!-- media texts start --> */}
                                                        <div>
                                                            <h3 className="font-size-6 mb-0">
                                                                {(job.company) ? job.company : ''}
                                                            </h3>
                                                            <span className="font-size-3 text-gray line-height-2">
                                                                {(job.comp_tagline) ? job.comp_tagline : ''}
                                                            </span><br />
                                                            <span className="font-size-3 text-gray line-height-2">
                                                                <StarRating rating={4.5} />
                                                            </span>
                                                        </div>
                                                        {/* <!-- media texts end --> */}
                                                    </div>

                                                </div>
                                                <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                                                    {/* <!-- media date start --> */}
                                                    <div className="media justify-content-md-end">
                                                        <p className="font-size-4 text-gray mb-0">
                                                            {(job.dateposted) && <>   <h5 className="font-size-4 text-right   text-black-2 pt-5 mr-0 font-weight-semibold  media"><div className="image mr-5">
                                                                <i className="fa-regular fa-clock text-green-2"></i>
                                                            </div> <span className="font-size-4 text-gray font-weight-semibold">
                                                                    Posted on : &nbsp;
                                                </span> {sinceDate(job.dateposted, Date.now())}</h5></>}
                                                        </p>
                                                    </div>
                                                    {/* <!-- media date end --> */}
                                                </div>
                                                <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                                                    <div className=" font-size-4 text-gray mb-0 " style={{ textOverflow: 'ellipsis', height: '73px', overflow: 'hidden', whitespace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                                    </div>  {(DOMPurify.sanitize(job.jobdesc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 4000) && <div className="mt-n7  mb-7 text-right w-100"><i className="fa-solid fa-ellipsis mr-5"></i> </div>}
                                                </div>

                                                <div className="col-lg-12 text-right pt-10">
                                                {gContext.userInfo.userType === "2" && <a className="btn btn-green text-uppercase btn-small rounded-3 w-150 mr-4 mb-5 pointer" onClick={(e) => { props.viewmessage() }}
                                                >
                                                    Message
                                                </a>}
                                                {(gContext.userInfo.userType === "2" && proposal.statuscode === 200) && <a className="btn btn-green text-uppercase btn-small rounded-3 w-150 mr-4 mb-5 pointer" onClick={(e) => { setShowOffer(true) }}
                                                >
                                                    Make an Offer
                                                </a>}

                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- End Single Featured Job --> */}
                                        <div className="row bg-white px-5 m-0 mt-1 border-bottom border-width-1 border-default-color"><div className=" col-12   ">

                                            <div className="job-details-content pt-3 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-5    light-mode-texts">
                                                <div className="row">

                                                    <div className="col-md-4  pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6  ">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-desktop text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Job Type
                                                </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9"> <span className="text-capi">{job.type?job.type.toLowerCase():''}</span> 

                                                        </h6>
                                                    </div>
                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-location-dot text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Location 
                                                </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-0">   <ReactCountryFlag
                                                            countryCode={(job.countrycode) ? job.countrycode : ''}
                                                            svg
                                                            style={{
                                                                width: '2em',
                                                                height: '1em',
                                                            }}
                                                            title={(job.countryname) ? job.countryname : ''}
                                                        />       {' '} <span className="text-capi">{(job.city) ? job.city.toLowerCase() : ''}</span>{' '}{(job.state) ? job.state : ''} {' '} {(job.countryname) ? job.countryname : ''}


                                                        </h6>

                                                    </div>
                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-ruler-horizontal text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Project Length
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {(job.projlength) ? job.projlength : ''}

                                                        </h6>
                                                    </div>

                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-business-time text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0 ">
                                                                Weekly Hours
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {(job.weeklyhrs) ? job.weeklyhrs : ''}

                                                        </h6>
                                                    </div>

                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-briefcase text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Experience Level
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {job.explevel}

                                                        </h6>
                                                    </div>

                                                    {(job.ratefrom && job.rateto) ?
                                                        <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                            <div className="media justify-content-md-start">
                                                                <div className="image mr-5">
                                                                    <i className="fa-solid fa-dollar-sign  text-green-2 font-size-5"></i>
                                                                </div>
                                                                <p className="font-weight-normal font-size-4 text-black-2 mb-0 pl-2 ">
                                                                    Rate Range (USD)
                                                        </p>
                                                            </div>
                                                            <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-8 pl-2">  {(job.ratefrom && job.rateto) ? new Intl.NumberFormat('en-US').format(job.ratefrom) + " - " + new Intl.NumberFormat('en-US').format(job.rateto) : ''}

                                                            </h6>
                                                        </div> : <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                            <div className="media justify-content-md-start">
                                                                <div className="image mr-5">
                                                                    <i className="fa-solid fa-dollar-sign text-green-2 font-size-5"></i>
                                                                </div>
                                                                <p className="font-weight-normal font-size-4 text-black-2 mb-0 pl-2">
                                                                {(job.dailyrate) ? "Daily " : ((job.fixedrate) ? "Fixed " : "Hourly ")}Rate (USD)
                                                    </p>
                                                            </div>
                                                            <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-8 pl-2">
                                                            {(job.dailyrate) ? (new Intl.NumberFormat('en-US').format(job.dailyrate)) : ((job.hourlyrate) ? (new Intl.NumberFormat('en-US').format(job.hourlyrate)) : (new Intl.NumberFormat('en-US').format(job.fixedrate)))}
                                                            </h6>
                                                        </div>}



                                                </div>


                                            </div>
                                        </div></div>

                                        <form action="/" className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                            <fieldset>


                                                <div className="row ">
                                                    <h6>Proposal Details </h6>
                                                    {errorMessage === "" && <><span className="error py-5   w-100  font-weight-semibold line-height-reset px-8   rounded-5 font-size-4">{errorMessage} </span><br /></>}
                                                </div>


                                                <div className="row mb-xl-1 mb-9">
                                                <div className="col-lg-6 ">
                                                 
                                                    {(gContext.userInfo.userType === "1") &&
                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={"/client/" + proposal.sub}  >
                                                            <div className="circle-36 mr-6">
                                                                <img src={s3URL + proposal.logo} alt="" className=" circle-36 w-100" />
                                                            </div>
                                                            <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                            {proposal.company}
                                                            </h4>
                                                        </a>
                                                    }

                                                    {(gContext.userInfo.userType === "2") &&
                                                        <a className="media min-width-px-235 align-items-center pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + proposal.sub}  >
                                                            <div className="circle-36 mr-6">
                                                                <img src={s3URL + proposal.avatar} alt="" className=" circle-36 w-100" />
                                                            </div>
                                                            <h4 className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer"   >
                                                                {proposal.firstname + ' ' + proposal.lastname}
                                                            </h4>
                                                        </a>
                                                    }

                                                    </div>

                                                    <div className="col-lg-6 ">

                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-dollar-sign text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0 pl-2">
                                                                Bid Rate (USD)
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-8 pl-2">
                                                            {(proposal.bid) ? new Intl.NumberFormat('en-US').format(proposal.bid) : ""}
                                                        </h6>


                                                    </div>
                                                    <div className="col-xl-12 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                                                        <div className="">
                                                            <p className="mb-4 font-size-4 text-gray font-weight-semibold">
                                                                Cover Letter
                                                </p>
                                                            <div className="font-size-4 text-black-2 mb-7" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(proposal.cover_letter) }}>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    {proposal.attachments && proposal.attachments !== "" && <div className="col-xl-12 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                                                        <div className="">
                                                            <p className="mb-4 font-size-4 text-gray font-weight-semibold">
                                                                Attachment
                                                </p>

                                                        </div>
                                                        <a href={s3URL + proposal.attachments} rel="noopener noreferrer" target="_blank" data-toggle="tooltip" title="Click here to view."> <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i></a>
                                                    </div>}

                                                </div>








                                            </fieldset>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>}
                        {showUpdate === true && <div className="row  px-auto  text-center pt-5">
                            <Message message={'Your proposal has been submitted successfully'} />
                        </div>} </>}
                

                </Modal.Body>
            </ModalStyled>
        </>
    );
};
