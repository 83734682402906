import { useCallback, useEffect, useState, useContext } from "react";
import { useLocation } from 'react-router-dom';
import GlobalContext from "../context/GlobalContext";
const useTabActive = () => {
    const [visibilityState, setVisibilityState] = useState(true);
    const gContext = useContext(GlobalContext);
    const path=useLocation().pathname;

    const handleVisibilityChange = useCallback(() => {
        
       // if (document.visibilityState === 'visible' &&  !localStorage.getItem("connect")) { window.location.href = path ;}
  }, []);
    
    useEffect(() => { 
      
  document.addEventListener("visibilitychange", handleVisibilityChange)
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    
    }
  }, []);

  return visibilityState;
}

export default useTabActive;