import React, { useState, useContext, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext";  
import validator from 'validator';
import { changePwd, login } from './../../graphql/mutations'; 
import { AddUserPayment, createOnboardAccount } from "../../utils/payment";
import { UpdateSettingNotification  } from "../../utils/apiCalls";
import { API, graphqlOperation } from 'aws-amplify'; 
import {  useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import PaymentHistory from "../../sections/payment/PaymentHistory"; 

const ChangePassword = (props) => {
    const gContext = useContext(GlobalContext);
    const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;
    const [values, setValues] = React.useState({ oldPassword: '', password1: '', password2: '', error: {  oldPassword: '', password1: '', password2: '' } });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [visibleLoader, setVisibleLoader] = useState(false);
    const [errorPassword, setErrorPassword] = React.useState({ min8: false, upperCase: false, lowerCase: false, numeric: false, special: false });
    const [showPassFirst, setShowPassFirst] = useState(true);
    const [showOldPass, setShowOldPass] = useState(true);
    const [optionValue, setOptionValue] = React.useState({ emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [showPassSecond, setShowPassSecond] = useState(true);
    const [successMessage, setSuccessMessage] = React.useState(false);
   
    const user = (localStorage.getItem("connect")) ? JSON.parse(localStorage.getItem("connect")) : null;
    const toggleOldPassword = () => {
        setShowOldPass(!showOldPass);
    };
    
    const togglePasswordFirst = () => {
        setShowPassFirst(!showPassFirst);
    };

    const togglePasswordSecond = () => {
        setShowPassSecond(!showPassSecond);
    };
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);

    useEffect(() => {

        setOptionValue({ emailsenabled: gContext.userInfo.emailsenabled, marketingemailsenabled: gContext.userInfo.marketingemailsenabled, messagesenabled: gContext.userInfo.messagesenabled });
    }, [gContext.userInfo]);
    
    async function addPayment() {
        try {
            setErrorMessage("");
            if (gContext.userInfo.userID) {
                gContext.setLoaderModalVisible(true);
                setVisibleLoader(true);               
                let info = (gContext.userInfo && gContext.userInfo.userType == "1") ? gContext.userInfo.profileInfo : JSON.parse(gContext.userInfo.profileInfo);
                let status = null;
              
                if (info && info.length > 0 && info[0].stripeid == null) {
                    if (gContext.userInfo && gContext.userInfo.userType == "1" && gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment)
                        status = await AddUserPayment(info, gContext.userInfo.userType);
                    else if (gContext.userInfo && gContext.userInfo.userType == "2" && gContext.userInfo.idVerified && gContext.userInfo.profileComplete)
                        status = await AddUserPayment(info, gContext.userInfo.userType);
                    else
                        setErrorMessage("Profile is not verified. Please complete verification process. ");
                    if (status)
                        setErrorMessage("Server error");
                }
                else if (info && info.length > 0 && info[0].stripeid != null && (gContext.userInfo.paymentConnected  == false|| gContext.userInfo.paymentConnected?.details_submitted == false || gContext.userInfo.paymentConnected?.charges_enabled == false)) {
                    const onboardStatus = await createOnboardAccount(info[0].stripeid);
                    
                    if (onboardStatus.data.paymentOnboardAccount.responseData.code == 200) {

                        const onboardurl = JSON.parse(onboardStatus.data.paymentOnboardAccount.responseData.message);
                        if (onboardurl.url)
                            window.location.href = onboardurl.url;

                    } else {

                        setErrorMessage("Server error");
                    }
                }
                gContext.setLoaderModalVisible(false);
                setVisibleLoader(false);
            }
        } catch (e) {
            console.log(e);
            setVisibleLoader(false);
            setErrorMessage("Server Error");
        }
    }
    function validatePassword(value) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const lowerChars = /[a-z]/;
        const upperChars = /[A-Z]/;
        const numericChars = /[0-9]/;

        setErrorPassword({ min8: (value.length >= 8), upperCase: upperChars.test(value), lowerCase: lowerChars.test(value), numeric: numericChars.test(value), special: specialChars.test(value) });

    }
    function checkValidity() {
        let inValid = false;
        let { error, ...rest } = values;

        error.oldPassword = validator.isEmpty(rest.oldPassword) ? "Current Password is required" : error.oldPassword;

        error.password1 = validator.isEmpty(rest.password1) ? "Password is required" : error.password1;
       
    
        error.password2 = validator.isEmpty(rest.password2) ? "Confirm Password is required" : error.password2;
        error.password1 = (rest.password1 != "" && rest.oldPassword == rest.password1) ? "New password  and current password should not be same." : validator.isEmpty(rest.password1) ? "Password is required" : error.password1;
       
       if(rest.password1 != "" && rest.password2 != "" && rest.password1 != rest.password2)
       {
           error.password2 = "The passwords do not match.";
       }
        
        setValues({ ...values, error }); 

        Object.values(rest).forEach(val => {
            if (val == '') {
                inValid = true;
            }
        });

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                
                if (val != "valid")
                    inValid = true;
            }
        });
        if (inValid) { return false; }
        else { return true; }
    }
    const handleChange = (e) => {
        setErrorMessage("");
        const { name, value } = e.target;
        let error = { ...values.error };
       

        switch (name) {
            case "password1":
                if (value != "") {
                    error.password1 = validator.isStrongPassword(value, {
                        minLength: 8, minLowercase: 1,
                        minUppercase: 1, minNumbers: 1, minSymbols: 1
                    }) ? "valid" : "*"; 
                    validatePassword(value);
                }
                else
                    error.password1 = "";
                    error.password2 = "";

                break;
            case "oldPassword":
                error.oldPassword = validator.isEmpty(value) ? "Current Password is required" : '';
                error.password1 ="";
                break;
            case "password2":
                
                if(value == "" && name == "password2")
                { 
                    error.password2 =  "Confirm Password is required";
                }

                 else if(value != null && values.password1 != null && name == "password2" )
                 {
                    
                    error.password2 = (values.password1 != value) ? "The passwords do not match." :'';
              

                 }
                 if(value == null && name == "password1" )

                  {  
                    error.password2 = "";
                  }
                break;
            
            
            default:
                break;
        }
         
            setValues({ ...values, error, [name]: value });

    }
    async function resetPassword() {
        
        setErrorMessage("");
        let email;
        const user = JSON.parse(localStorage.getItem("connect")); 
       
        if (checkValidity())
        {
            if (user) {
                email = user.payload["email"];
            } else { return; }
           
           gContext.setLoaderModalVisible(true);
            let data = changePwd.replace("$email", email).replace("$newPwd", values.password1).replace("$oldPwd", values.oldPassword);
            try {
                const result = await API.graphql(graphqlOperation(data));

                if (result.data && result.data.changePwd.responseData) {
                   
                    if (result.data.changePwd.responseData.code == "200")
                     { 
                        setSuccessModalVisible(true);
                        localStorage.removeItem('userInfo');
                        localStorage.removeItem('userEmail');
                        localStorage.setItem('passwordChanged', true); 

                    }
                    else {
                        setErrorMessage("The current password  is incorrect.");
                       
                    }
                }
            }
            catch (e) {
                console.log(e);
                setErrorMessage("Server error");
              
                gContext.setLoaderModalVisible(false);
            }
           
            gContext.setLoaderModalVisible(false);
        }
        
    } 
    
    useEffect(() => {
    
        document.title = "Settings | Syncuppro";
    }, []);

    function cancelEdit() {
        setValues({ oldPassword: '', password1: '', password2: '', error: { oldPassword: '', password1: '', password2: '' } });
      

    }
    function deleteProfile() {

        let deletetext = ["Your information will be deleted from this platform permanently.",
            <br />, <br />,
            "Are you sure you want to delete?"];

        gContext.setconfirmModal({ header: "Profile delete confirmation", ["text"]: deletetext, ["confirmed"]: false, showButton: true, ["navigate"]: true, ["deleteaccount"]: true });

        gContext.toggleConfirmModal();
    }

    async function updateOptionSetting() {
        try {
            if (gContext.userInfo.userID) {
                gContext.setLoaderModalVisible(true);
                const result = await UpdateSettingNotification(optionValue, gContext.userInfo.userType, gContext.userInfo.userID);


                if (result.data.updateSettingNotification.responseData.code == "200") {
                    gContext.getProfileInfo();
                    gContext.setSuccessMessage("Updated successfully");
                    gContext.toggleSuccessModal();
                }

                gContext.setLoaderModalVisible(false);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }
    }

    function handleOption(checked, field) {
        setOptionValue({ ...optionValue, [field]: checked });
    }
    function cancelOptionSetting() {
        setOptionValue({ emailsenabled: gContext.userInfo.emailsenabled, marketingemailsenabled: gContext.userInfo.marketingemailsenabled, messagesenabled: gContext.userInfo.messagesenabled });
    }
    return (
        <>
            <span className="screen-darken"></span>

            <div className="page-wrapper">

                <div className="breadcrumb">
                    <div className="container-fluid">

                        <div className="breadcrumb-wrapper">
                            <h4>Settings</h4>

                            <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray"> / Settings</span>
                            </div>

                        </div>

                    </div>
                </div>

                <section className="section-sm settings-block">
                    <div className="container-fluid">

                        <div className="accordion" id="accordion-settings">
                            {gContext.userInfo.userType != "" && <div className="accordion-wrapper">

                                <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-my-payment" aria-expanded="false"><h5>MY PAYMENT DETAIL</h5>
                                    <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-my-payment" className="collapse show" data-bs-parent="#accordion-settings">
                                    <div className="accordion-body card panel p-0">

                                        <div className="card-body">
                                            <div className="row align-items-end">

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input m-b-0">
                                                        {!(gContext.userInfo.paymentConnected?.details_submitted && gContext.userInfo.paymentConnected?.charges_enabled) && <label for="current password" className="form-label m-b-10">Click <strong>Connect My Payment Account</strong> to add payment account detail. Eg., credit card,debit card etc </label>}
                                                        {(gContext.userInfo.paymentConnected?.details_submitted && gContext.userInfo.paymentConnected?.charges_enabled) && <label for="current password" className="form-label m-b-10">  <strong>  Your Payment Account</strong> connected successfully. </label>}


                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    
                                                    <div className="delete-account">
                                                        <button className="btn btn-orange" disabled={gContext.userInfo.paymentConnected == false || (gContext.userInfo.paymentConnected?.details_submitted && gContext.userInfo.paymentConnected?.charges_enabled)} onClick={(e) => {
                                                           addPayment();

                                                        }}>+ Connect My Payment Account </button>
                                                    </div>
                                                    
                                                </div>
                                                {((!gContext.userInfo.paymentConnected?.details_submitted && gContext.userInfo.paymentConnected?.charges_enabled) || (gContext.userInfo.paymentConnected?.details_submitted && !gContext.userInfo.paymentConnected?.charges_enabled)) && <div className="col-lg-12 col-md-12 col-sm-12 ">
                                                    <div className=" delete-account mt-2">
                                                        <span className="text-red">* Submitted payment information are incomplete. Please try again!</span>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>}

                            <div className="accordion-wrapper">

                                <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-my-paymentHistory" aria-expanded="false"><h5>PAYMENT HISTORY</h5>
                                    <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-my-paymentHistory" className="collapse " data-bs-parent="#accordion-settings">
                                    <div className="accordion-body card panel p-0">

                                        <div className="card-body">
                                            <div className="row align-items-end">

                                                <PaymentHistory/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="accordion-wrapper">

                                <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-change-passwords" aria-expanded="false"><h5>CHANGE PASSWORDS</h5>
                                    <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-change-passwords" className="collapse " data-bs-parent="#accordion-settings">
                                    <div className="accordion-body card panel p-0">

                                        <div className="card-body p-b-0">
                                            <div className="row">

                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                    <div className="forn-group-input p-relative">
                                                        <label for="current password" className="form-label m-b-10">Current Password<span className="text-red"> * </span></label>

                                                         
                                                            
                                                        <span onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleOldPassword();
                                                        }} className="btn-icon text-light-gray m-icon material-icons align-item-right pointer" style={{ width: "24px", height:"24px" }}>{showOldPass ? "visibility_off" : "visibility_on"}</span>

                                                        <input type={showOldPass ? "password" : "text"} name="oldPassword" value={values.oldPassword}
                                                            className={`form-control      ${(values.error.oldPassword.length > 0) ? ' error' : ''}`}
                                                            placeholder="Current Password"
                                                            id="oldPassword" required onChange={handleChange} />
                                                        {values.error.oldPassword.length > 1 && (
                                                            <span className="text-red ">{values.error.oldPassword}</span>
                                                        )}

                      </div>
                                                    </div>

                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                        <div className="forn-group-input p-relative">
                                                            <label for="new password" className="form-label m-b-10">New Password <span className="text-red"> * </span></label>

                                                        <button type="button" className="btn-icon" onClick={(e) => {
                                                            e.preventDefault();
                                                            togglePasswordFirst();
                                                        }}>
                                                            <span className="text-light-gray m-icon material-icons" style={{ width: "24px", height: "24px" }}>{showPassFirst ? "visibility_off" : "visibility_on"}</span>
                                                        </button>
                                                        <input type={showPassFirst ? "password" : "text"} value={values.password1}
                                                            className={`form-control  ${(values.error.password1.length > 0 && values.error.password1 !== "valid" ) ? ' error' : ''}`}
                                                            id="password1" name="password1"
                                                            placeholder="Enter password" required onChange={handleChange}

                                                        />
                                              
                                                        {values.error.password1.length > 1 && (
                                                            <span className="text-red">{values.error.password1 !== "valid" && values.error.password1}</span>
                                                        )}
                                                        {(values.error.password1.length === 1) && (<>
                                                            <span className={`${(errorPassword.min8) ? 'text-green' : 'text-red'}  `}> * Password must have minimum 8 characters</span> 
                                                            <span className={`${(errorPassword.upperCase) ? 'text-green' : 'text-red'} `}>  * 1 uppercase letter</span> 
                                                            <span className={`${(errorPassword.lowerCase) ? 'text-green' : 'text-red'} `}>  * 1 lowercase letter</span>  
                                                            <span className={`${(errorPassword.numeric) ? 'text-green' : 'text-red'}  `}> * 1 numeric value</span> 
                                                            <span className={`${(errorPassword.special) ? 'text-green' : 'text-red'}  `}> * 1 special character</span>
                                                        </>)}
                      </div>
                                                        </div>

                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                            <div className="forn-group-input p-relative">
                                                                <label for="confirm password" className="form-label m-b-10">Confirm Password<span className="text-red"> * </span></label>

                                                        <button type="button" className="btn-icon" onClick={(e) => {
                                                            e.preventDefault();
                                                            togglePasswordSecond();
                                                        }}>
                                                            <span className="text-light-gray m-icon material-icons" style={{ width: "24px", height: "24px" }}>{showPassSecond ? "visibility_off" : "visibility_on"}</span>
                                                        </button>
                                                        <input type={showPassSecond ? "password" : "text"} value={values.password2}
                                                                    className={`form-control ${(values.error.password2.length > 0) ? ' error' : ''}`}
                                                                    id="password2" name="password2"
                                                                    placeholder="Confirm password" required onChange={handleChange}
                                                                />
                                                                 
                                                    {values.error.password2.length > 1 && (
                                                        <span className="text-red">{values.error.password2}</span>
                                                    )}


                      </div>
                                                            </div>

                                                        </div>
                                                    </div> 

                <div className="card-footer">
                                                        <div className="panel-action">
                                                <button onClick={resetPassword} className="btn btn-orange btn-sm bg-green m-r-20 text-white"  >Update</button>
                                                <button onClick={() => { cancelEdit();}} type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center">Cancel</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div> 

                            <div className="accordion-wrapper">

                                <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-my-option" aria-expanded="false"><h5>NOTIFICATION SETTINGS</h5>
                                    <span className="m-icon material-icons indicator show">expand_more</span>
                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                </a>

                                <div id="accordion-my-option" className="collapse" data-bs-parent="#accordion-settings">
                                    <div className="accordion-body card panel p-0">

                                        <div className="card-body">
                                            <div className="row align-items-end">

                                                 
                                                
                                                <div className="col-lg-12 col-md-12 col-sm-12 p-10">
                                                    <div className="d-flex align-item-center m-r-30 pointer" onClick={()=>handleOption(!optionValue.emailsenabled, "emailsenabled")}>
                                                        <div className="form-check m-r-10">
                                                            <input className="form-check-input" type="checkbox" name="emailsenabled" id="expert-check"   checked={optionValue.emailsenabled} />
                                                        </div>
                                                        <span className="f-16">E-mail</span>
                                                    </div>
                                                     

                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 p-10">
                                                    <div className="d-flex align-item-center m-r-30 pointer" onClick={() => handleOption(!optionValue.messagesenabled, "messagesenabled")}>
                                                        <div className="form-check m-r-10">
                                                            <input className="form-check-input" type="checkbox" name="messagesenabled" id="expert-check" checked={optionValue.messagesenabled} />
                                                        </div>
                                                        <span className="f-16">Messages</span>
                                                    </div>


                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 p-10">
                                                    <div className="d-flex align-item-center m-r-30 pointer" onClick={() => handleOption(!optionValue.marketingemailsenabled, "marketingemailsenabled")}>
                                                        <div className="form-check m-r-10">
                                                            <input className="form-check-input" type="checkbox" name="marketingemailsenabled" id="expert-check"   checked={optionValue.marketingemailsenabled} />
                                                        </div>
                                                        <span className="f-16">Marketplace updates</span>
                                                    </div>


                                                </div>
                                               


                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <div className="panel-action">
                                                <button onClick={()=>updateOptionSetting()} className="btn btn-orange btn-sm bg-green m-r-20 text-white"  >Update</button>
                                                <button onClick={() => { cancelOptionSetting(); }} type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
          <div className="accordion-wrapper">

                                            <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-my-account" aria-expanded="false"><h5>MY ACCOUNTS</h5>
                                                <span className="m-icon material-icons indicator show">expand_more</span>
                                                <span className="m-icon material-icons indicator hide">expand_less</span>
                                            </a>

                                            <div id="accordion-my-account" className="collapse" data-bs-parent="#accordion-settings">
                                                <div className="accordion-body card panel p-0">

                                                    <div className="card-body">
                                                        <div className="row align-items-end">

                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="forn-group-input m-b-0">
                                                                    <label for="current password" className="form-label m-b-10">Registered Email</label>
                                                        <input className="form-control" value={user?user.payload["email"]:""} disabled/>
                      </div>
                                                                </div>

                                                                <div className="col-lg-6 col-md-6 col-sm-12">

                                                                    <div className="delete-account m-t-20">
                                                        <button className="btn btn-red-outline" onClick={(e) => {
                                                            deleteProfile();
                                                        }}><span className="m-icon material-icons m-r-10">delete</span> <span>Delete My Account</span></button>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div> 

              </div>
                                                </div>
                                            </div> 

        </div>
                                    </div>    
    </section>
                <ModalStyled size="sm"
                    centered
                    backdrop="static"
                    show={visibleLoader}
                    onHide={() => setVisibleLoader(!visibleLoader)}
            >

                        <div className="modal-body text-center bg-gray">                    
                        <p className="text-green f-18">This may take sometime, please do not refresh. </p>
                         
                        </div>
                </ModalStyled >
                

            </div>

            <ModalStyled size="sm"
                centered backdrop="static"
                show={successModalVisible}                
            >
                <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/sucess-check.svg" alt="Success" />
                    <h5 className="m-b-10">Success!</h5>
                    <p className="text-light-gray">New Password updated successfully</p>

                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120" onClick={() => { setSuccessModalVisible(false); window.location.href = "/"; }}>Ok</button>
                    </div>

                </div>
            </ModalStyled>

          <div
                className="dashboard-main-container mt-24 mt-lg-31 min-h-198vh d-none"
              id="dashboard-body"
          >
              <div className="container">
                  <div className="mb-15 mb-lg-23">
                      <div className="row">
                          <div className="col-xxxl-12 px-lg-13 px-6">
                              
                              
                                  <div className="contact-form bg-white shadow-8 ">
                                      <div className="font-size-6 font-weight-semibold mb-11   text-gray bg-gray-opacity-2 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                      Change Password
                  </div>

                                      <form action="/" className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-10">
                                        <fieldset>
                                            {errorMessage != "" && <><span className="error py-3 mb-2   font-weight-semibold line-height-reset px-2 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span><br /></>}
                                            <div className="row mb-xl-1 mb-9">
                                               
                                                {(successMessage === false) &&
                                                    <div className="col-lg-6">
                                                        <div className="form-group">

                                                            <label
                                                                htmlFor="oldPassword"
                                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                                            >
                                                                Current Password <span className="error font-size-3">*</span>
                                                            </label>
                                                            <div className="position-relative">
                                                            <input
                                                                type={showOldPass ? "password" : "text"} name="oldPassword"
                                                                className={`form-control font-weight-normal    ${(values.error.oldPassword.length > 0) ? 'form-control-error' : ''}`}
                                                                placeholder="Current Password"
                                                                id="oldPassword" required onChange={handleChange}
                                                            />
                                                             
                                                             <a
                                                                    href="/#"
                                                                    className="pos-abs-cr fas mr-6 text-black-2"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        toggleOldPassword();
                                                                    }}
                                                                >
                                                                    {showOldPass ? <i className="fa fa-eye-slash" ></i> : <i className="fa fa-eye" ></i>}

                                                                </a>
                                                                </div>
                                                            {values.error.oldPassword.length > 1 && (
                                                                <span className="text-red font-size-3">{values.error.oldPassword}</span>
                                                            )}
                                                        </div>
                                                        
                                                        
                                                        <div className="form-group">
                                                        <label
                                                                htmlFor="password1"
                                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                                            >
                                                                New Password <span className="error font-size-3">*</span>
                                                            </label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={showPassFirst ? "password" : "text"}
                                                                    className={`form-control font-weight-normal  ${(values.error.password1.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="password1" name="password1"
                                                                    placeholder="Enter password" required onChange={handleChange}
                                                                />
                                                                <a
                                                                    href="/#"
                                                                    className=" pos-abs-cr fas mr-6 text-black-2"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        togglePasswordFirst();
                                                                    }}
                                                                >  {showPassFirst ? <i className="fa fa-eye-slash" ></i> : <i className="fa fa-eye " ></i>}
                                                                    <span className="d-none">none</span>
                                                                </a>
                                                            </div>
                                                            {values.error.password1.length > 1 && (
                                                                <span className="error font-size-3">{values.error.password1 !== "valid" && values.error.password1}</span>
                                                            )}
                                                            {(values.error.password1.length === 1) && (<>
                                                                <span className={`${(errorPassword.min8) ? 'text-green' : 'error'} font-size-3`}> * Password must have minimum 8 characters</span> <br />
                                                                <span className={`${(errorPassword.upperCase) ? 'text-green' : 'error'} font-size-3`}>  * 1 uppercase letter</span> <br />
                                                                <span className={`${(errorPassword.lowerCase) ? 'text-green' : 'error'} font-size-3`}>  * 1 lowercase letter</span> <br />
                                                                <span className={`${(errorPassword.numeric) ? 'text-green' : 'error'} font-size-3`}> * 1 numeric value</span><br />
                                                                <span className={`${(errorPassword.special) ? 'text-green' : 'error'} font-size-3`}> * 1 special character</span>
                                                            </>)}
                                                        </div>


                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="password2"
                                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                                            >
                                                                Confirm Password <span className="error font-size-3">*</span>
                                                            </label>
                                                            <div className="position-relative">
                                                                <input
                                                                    type={showPassSecond ? "password" : "text"}
                                                                    className={`form-control font-weight-normal  ${(values.error.password2.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="password2" name="password2"
                                                                    placeholder="Confirm password" required onChange={handleChange}
                                                                />
                                                                <a
                                                                    href="/#"
                                                                    className=" pos-abs-cr fas mr-6 text-black-2"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        togglePasswordSecond();
                                                                    }}
                                                                >  {showPassSecond ? <i className="fa fa-eye-slash" ></i> : <i className="fa fa-eye " ></i>}
                                                                    <span className="d-none">none</span>
                                                                </a>
                                                            </div>
                                                            {values.error.password2.length > 1 && (
                                                                <span className="error font-size-3">{values.error.password2}</span>
                                                            )}
                                                        </div>


                                                        
                                                    </div>

                                                }
                                              </div>
                                                
                                              <div className="col-lg-12  justify-content-md-end">
                                                        <div className="media py-10 w-100  justify-content-md-end ml-9"  >
                                                            <div className="p-0 m-0 position-relative">
                                                                <button
                                                                    type="button"

                                                                    className="btn btn-primary btn-medium    btn-green  text-white  rounded-5 text-uppercase" 
                                                                    style={{ minWidth: '60px', height: '34px' }}
                                                                    onClick={resetPassword}>
                                                                    Save
                                                                </button>


                                                            </div>
                                                            <div className="p-0 m-0 position-relative ">
                                                                <button
                                                                    type="button"

                                                                    className="btn btn-primary  btn-medium  btn-green  text-white  ml-5 ml-sm-3 rounded-5 text-uppercase" 
                                                                    style={{ minWidth: '80px', height: '34px'}} onClick={() => {
                                                                        cancelEdit(); 

                                                                    }}>
                                                                    Cancel
                                                                </button>

                                                            </div>
                                                        </div>
                                                        </div>
                                         
                                          </fieldset>
                                      </form>
                                  </div>
                             
                               
                             
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
};

export default ChangePassword;
