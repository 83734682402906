

export const getCountry = /* GraphQL */ `
  query getCountry {
   countries {
        id
        code
        name
    }
}
`;

export const getComplianceTypes = /* GraphQL */ `
  query CTQuery {
  complianceTypes {
    code
    id
  }
}
`;

export const getIndustryTypes = /* GraphQL */ `
 query ITQuery {
  industryTypes {
    id
    name
  }
}
`;

export const getSkills = /* GraphQL */ `
  query SSQuery {
  skills {
    id
    skill
  }
}
`;

export const getExpertDegrees = /* GraphQL */ `
  query EDQuery {
  expertDegrees {
    degree
    id
  }
}`;

export const getExpLevels = /* GraphQL */ `
query ELQuery {
  expLevels {
    id
    level
  }
}

`;
export const getSocialProviders = /* GraphQL */ `
query SLQuery {
  socialProviders {
    id
    link
  }
}

`;

export const getLanguages = /* GraphQL */ `
query LangQuery {
    languages {
        id
        language
    }
}

`;
export const getLanguagesProficiencyLevel = /* GraphQL */
    `query LPQuery {
  languageProficiencyLevel {
    id
    level
  }
} 
`;
export const getWeeklyHrs = /* GraphQL */
    `query WHQuery {
    weeklyHours {
        hours
        id
    }
}
`;

export const getProjectLength = ` 
  query MyQuery {
  projectLengths {
    id
    length
   }
}`;
export const getComplianceCategory = ` 
   query MyQuery {
  complianceCategory {
    category
    code
    id
  }
}`;
export const getRateTypes = ` 
   query MyQuery {
  rateTypes {
    code
    id
    rate
  }
}`;
export const getFlagReasons = `
query MyQuery {
    flagReasons {
        id
        reason
        usertypeid
    }
}`;

export const getIssueCategory = `
query MyQuery {
  issueCategory {
    category
    id
  }
}`;

export const homeFeaturedList = `
query MyQuery {
    homeFeaturedList {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const siteObjectCounts = `
query MyQuery {
  siteObjectCounts {
    totalExperts
    totalJobs
  }
}`;
export const getJobUtils = `
query MyQuery {
    jobUtils {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const loadAllMilestoneStatus = `
query MyQuery {
    loadAllMilestoneStatus {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;