import React, { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Collapse } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
 

export default function Contact(props) { 
    const [openItem, setOpenItem] = useState(2);
    const [showClients, setShowClients] = useState(true);
  return (
      <>
          
          <PageWrapper headerConfig={{
              bgClass: "dynamic-sticky-bg",
              button: props.navType
          }}>

              <span className="screen-darken"></span>

              <div className="page-wrapper">

                  <div className="section faq-block">

                      <div className="container">

                          <div className="section-title">
                              <h1>Frequently <span className="text-theme">Asked Questions </span></h1>
                          </div>

                          <div className="tab-wrapper">
                              <ul className="nav nav-tabs" role="tablist">
                                  <li className="nav-item" role="presentation">
                                      <a className="btn tab-btn active m-r-10" data-bs-toggle="tab" href="#for-clients" aria-selected="true" role="tab">For Clients</a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                      <a className="btn tab-btn" data-bs-toggle="tab" href="#for-experts" aria-selected="false" role="tab" tabindex="-1">For Experts</a>
                                  </li>
                              </ul>
                          </div>

                          <div className="tab-content">
                              <div id="for-clients" className="tab-pane active show" role="tabpanel">
                                  <div className="accordion-body card panel p-0">
                                      <div className="card-header">

                                          <ul className="nav nav-tabs tab-light">
                                              <li className="nav-item p-relative">
                                                  <a className="nav-link active" data-bs-toggle="tab" href="#for-clients-all">All</a>
                                              </li>
                                              <li className="nav-item p-relative">
                                                  <a className="nav-link" data-bs-toggle="tab" href="#for-clients-questions">General Questions</a>
                                              </li>
                                          </ul> 
              </div> 

              <div className="card-body p-0">
                                          <div className="tab-content">

                                              <div className="tab-pane active" id="for-clients-all">
                                                  <div className="accordion" id="faq-clients-1">

                                                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq1" aria-expanded="false">
                                                              <h5>What happens if I'm not satisfied with an expert?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq1" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">We allow our clients to begin a trial period with up to three experts from our network per position. While we rigorously screen all applicants to ensure talent and intelligence, we understand that not every person will be a perfect fit for every company. For this reason, we allow our clients to work with multiple experts for each position before they decide on a candidate with whom they are confident and comfortable.</p>
                                                              </div>
                                                          </div>
                                                      </div> 

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq2" aria-expanded="false">
                                                              <h5>How is Syncuppro different?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq2" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Many organizations are faced with the issue of finding reliable compliance specific talent. At Syncuppro we follow a rigorous screening process to identify and select the best talent the world has to offer. You'll work with experts in Healthcare, Aerospace, Manufacturing, Government contracting, Information Technology (never generalized reps) who are hand- selected to be the right match for you from our network.</p>
                                                              </div>
                                                          </div>
                                                      </div> 

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq3" aria-expanded="false">
                                                              <h5>What Does Syncuppro Offer?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq3" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Let Syncuppro help you connect with right compliance expert!</p>
                                                                  <p className="text-gray">You can screen from in-depth database and shortlist and select the right fit for your organizational needs. Whether you need documentation support, internal audit, supplier audit or external audit Syncuppro can connect you with an expert for your needs.</p>
                                                              </div>
                                                          </div>
                                                      </div> 

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq4" aria-expanded="false">
                                                              <h5>Are Experts certified?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq4" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Experts come from a variety of backgrounds, including Training organizations, Certification bodies, Consultants and Contractors. All Certification bodies are certified. Training organizations provide certified courses including Lead Auditor Training, Lead Implementor, Provisional Auditor and so on. Consultants are going to be certified to the standards. Per our Terms of Service, Syncuppro is a communications platform that connects Clients and Experts.</p>
                                                              </div>
                                                          </div>
                                                      </div> 

                    </div> 
                  </div>

                                              <div className="tab-pane" id="for-clients-questions">
                                                  <div className="accordion" id="faq-clients-2">

                                                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq1" aria-expanded="false">
                                                              <h5>What happens if I'm not satisfied with an expert?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq1" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">We allow our clients to begin a trial period with up to three experts from our network per position. While we rigorously screen all applicants to ensure talent and intelligence, we understand that not every person will be a perfect fit for every company. For this reason, we allow our clients to work with multiple experts for each position before they decide on a candidate with whom they are confident and comfortable.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq2" aria-expanded="false">
                                                              <h5>How is Syncuppro different?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq2" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Many organizations are faced with the issue of finding reliable compliance specific talent. At Syncuppro we follow a rigorous screening process to identify and select the best talent the world has to offer. You'll work with experts in Healthcare, Aerospace, Manufacturing, Government contracting, Information Technology (never generalized reps) who are hand- selected to be the right match for you from our network.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq3" aria-expanded="false">
                                                              <h5>What Does Syncuppro Offer?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq3" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Let Syncuppro help you connect with right compliance expert!</p>
                                                                  <p className="text-gray">You can screen from in-depth database and shortlist and select the right fit for your organizational needs. Whether you need documentation support, internal audit, supplier audit or external audit Syncuppro can connect you with an expert for your needs.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq4" aria-expanded="false">
                                                              <h5>Are Experts certified?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq4" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Experts come from a variety of backgrounds, including Training organizations, Certification bodies, Consultants and Contractors. All Certification bodies are certified. Training organizations provide certified courses including Lead Auditor Training, Lead Implementor, Provisional Auditor and so on. Consultants are going to be certified to the standards. Per our Terms of Service, Syncuppro is a communications platform that connects Clients and Experts.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                    </div>
                  </div>
                                          </div>
              </div> 

            </div>
                              </div> 

          <div id="for-experts" className="tab-pane" role="tabpanel">
                                  <div className="accordion-body card panel p-0">
                                      <div className="card-header">

                                          <ul className="nav nav-tabs tab-light">
                                              <li className="nav-item p-relative">
                                                  <a className="nav-link active" data-bs-toggle="tab" href="#for-experts-all">All</a>
                                              </li>
                                              <li className="nav-item p-relative">
                                                  <a className="nav-link" data-bs-toggle="tab" href="#for-experts-general">General Questions</a>
                                              </li>
                                          </ul> 
              </div>

              <div className="card-body p-0">
                                          <div className="tab-content">

                                              <div className="tab-pane active" id="for-experts-all">
                                                  <div className="accordion" id="faq-clients-1">

                                                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq1" aria-expanded="false">
                                                              <h5>How many proposals can I send in a month?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq1" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">At the beginning of every month all users are given 15 Proposal credits completely free.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq2" aria-expanded="false">
                                                              <h5>How to track client deliverables?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq2" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">The project status monitoring program allows for easy adoption of milestones of the contract and subsequent monitoring.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq3" aria-expanded="false">
                                                              <h5>What to do if not receiving client feedback?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq3" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Reach out to the client and request feedback. Most of the times clients will provide it.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq4" aria-expanded="false">
                                                              <h5>Why am I not getting shortlisted?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq4" className="collapse" data-bs-parent="#faq-clients-1">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Most Experts get selected who have updated profile and are good match to client requirement. If accurate screening is done both from client and expert.</p>
                                                                  <p className="text-gray">Here are some tips to keep your profile updated:</p>
                                                                  <ul>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Update a good profile picture.</li>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Update all the certificates.</li>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Ensure skill matrix is updated.</li>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Update your resume and create a list of recently worked projects.</li>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                      </div>

                    </div>
                  </div>

                                              <div className="tab-pane" id="for-experts-general">
                                                  <div className="accordion" id="faq-clients-2">

                                                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq1" aria-expanded="false">
                                                              <h5>How many proposals can I send in a month?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq1" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">At the beginning of every month all users are given 15 Proposal credits completely free.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq2" aria-expanded="false">
                                                              <h5>How to track client deliverables?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq2" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">The project status monitoring program allows for easy adoption of milestones of the contract and subsequent monitoring.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq3" aria-expanded="false">
                                                              <h5>What to do if not receiving client feedback?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq3" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Reach out to the client and request feedback. Most of the times clients will provide it.</p>
                                                              </div>
                                                          </div>
                                                      </div>

                      <div className="accordion-wrapper">
                                                          <a className="accordion-btn" data-bs-toggle="collapse" href="#faq4" aria-expanded="false">
                                                              <h5>Why am I not getting shortlisted?</h5>
                                                              <span className="m-icon material-icons indicator show">expand_more</span>
                                                              <span className="m-icon material-icons indicator hide">expand_less</span>
                                                          </a>

                                                          <div id="faq4" className="collapse" data-bs-parent="#faq-clients-2">
                                                              <div className="accordion-body panel">
                                                                  <p className="text-gray">Most Experts get selected who have updated profile and are good match to client requirement. If accurate screening is done both from client and expert.</p>
                                                                  <p className="text-gray">Here are some tips to keep your profile updated:</p>
                                                                  <ul>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Update a good profile picture.</li>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Update all the certificates.</li>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Ensure skill matrix is updated.</li>
                                                                      <li className="d-flex align-item-center"><span className="text-theme m-icon material-icons m-r-10">check</span> Update your resume and create a list of recently worked projects.</li>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                      </div> 

                    </div> 
                  </div>
                                          </div>
              </div> 

            </div>
                              </div> 


        </div> 
      </div> 

    </div> 

  </div>
             
              <div className="jobDetails-section bg-default-2 pt-md-30 pt-sm-25 pt-15 pb-md-27 pb-sm-20 pb-17 d-none">
                  <div className="w-100 text-center" >
                      <h3 className="font-size-9 font-weight-bold mb-7 mb-lg-13">
                          Frequently Asked Questions
                              </h3>
                  </div>
                  <div className="container">
                      <div className="row">
                          <div className="col-12 col-xxl-12 col-lg-12 col-md-12 ">
                              <div className="header-btns   d-xs-flex ">
                                  <div className="pr-1 ml-auto" >    <a
                                      className={`btn ${(showClients) ? 'btn-primary' : 'btn-athens'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                      href="/#"
                                      onClick={(e) => {
                                          e.preventDefault();
                                          setShowClients(true);
                                           
                                      }}
                                  >
                                              For Clients
                </a>
                                      <a
                                          className={`btn  ${(!showClients) ? 'btn-primary' : 'btn-athens' }    btn-medium  text-uppercase font-size-3 rounded-0`}
                                          href="/#"
                                          onClick={(e) => {
                                              e.preventDefault();
                                              setShowClients(false);
                                          }}
                                      >
                                           For Experts
                </a>
                                    
                                  </div>
                                
                              </div>
                          </div>
            
                         
                          {(showClients === true) && < div
                className="col-xl-12 col-md-12"
                data-aos="fade-left"
                data-aos-duration="1000" 
                          >

                              <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                  <div className="bg-white rounded-4 shadow-9">
                                      {/* <!-- Tab Section Start --> */}
                                      <Nav
                                          className="nav border-bottom border-mercury pl-10 "
                                          role="tablist"
                                      >
                                          <li className="tab-menu-items nav-item pr-12  pointer">
                                              <Nav.Link
                                                  eventKey="one"
                                                  className="text-uppercase font-size-4 font-weight-bold text-default-color py-3 px-0 pointer" style={{ cursor: 'pointer'}} onClick={() => setOpenItem(2)}  
                                              >
                                                  All
                        </Nav.Link>
                                          </li>
                                          <li className="tab-menu-items nav-item pr-12  ">
                                              <Nav.Link
                                                  eventKey="two"
                                                  className="text-uppercase font-size-4 font-weight-bold text-default-color py-3 px-0 pointer" style={{ cursor: 'pointer' }} onClick={() => setOpenItem(2)}
                                              >
                                                  General Questions
                        </Nav.Link>
                                          </li>
                                          
                                          
                                      </Nav>
                                      {/* <!-- Tab Content --> */}
                                      <Tab.Content>
                                          <Tab.Pane eventKey="one">
                                              <div className="faq-content pt-lg-4 pt-6 pl-10 pr-10 pb-10">
                                                  <div
                                                      className="accordion rounded-10 border-green border-top-5 pl-1  mt-10"

                                                  >
                                                      <div className="border-bottom overflow-hidden ">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 2) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-3">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(2)}
                                                                  aria-expanded={openItem === 2}
                                                              >
                                                                  What happens if I'm not satisfied with an expert?
                        </button>
                                                          </div>
                                                          <Collapse in={openItem === 2}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">We allow our clients to begin a trial period with up to three experts from our network per
                                                                      position. While we rigorously screen all applicants to ensure talent and intelligence, we
                                                                      understand that not every person will be a perfect fit for every company. For this reason, we
                                                                      allow our clients to work with multiple experts for each position before they decide on a
                                                                      candidate with whom they are confident and comfortable.

 </p> 
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 3) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(3)}
                                                                  aria-expanded={openItem === 3}>
                                                                  How is Syncuppro different?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 3}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Many organizations are faced with the issue of finding reliable compliance specific talent. At
                                                                          Syncuppro we follow a rigorous screening process to identify and select the best talent the
                                                                          world has to offer. You'll work with experts in Healthcare, Aerospace, Manufacturing,
                                                                          Government contracting, Information Technology (never generalized reps) who are hand-
                                                                          selected to be the right match for you from our network.
                                                                      </p>

                                                                     
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 4) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(4)}
                                                                  aria-expanded={openItem === 4}>
                                                                  What Does Syncuppro Offer?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 4}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Let Syncuppro help you connect with right compliance expert!
                                                                      </p>
                                                                      <p className="font-size-4">
                                                                          You can screen from in-depth database and shortlist and select the right fit for your
                                                                          organizational needs. Whether you need documentation support, internal audit, supplier audit
or external audit Syncuppro can connect you with an expert for your needs.</p>
                                                                      
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 5) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(5)}
                                                                  aria-expanded={openItem === 5}>
                                                                  Are Experts certified?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 5}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Experts come from a variety of backgrounds, including Training organizations, Certification
                                                                          bodies, Consultants and Contractors. All Certification bodies are certified. Training organizations
                                                                          provide certified courses including Lead Auditor Training, Lead Implementor, Provisional Auditor
                                                                          and so on. Consultants are going to be certified to the standards. Per our Terms of Service,
                                                                          Syncuppro is a communications platform that connects Clients and Experts.
                                                                      </p>
                                                                      

                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                        

                                       

                                                    


                                                  </div>
                                              </div>    
                                          </Tab.Pane>
                                           
                                          <Tab.Pane eventKey="two">
                                              <div className="faq-content pt-lg-4 pt-6 pl-10 pr-10 pb-10">
                                                  <div
                                                      className="accordion rounded-10 border-green border-top-5 pl-1  mt-10"

                                                  >
                                                      <div className="border-bottom overflow-hidden ">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 2) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-3">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(2)}
                                                                  aria-expanded={openItem === 2}
                                                              >
                                                                  What happens if I'm not satisfied with an expert?
                        </button>
                                                          </div>
                                                          <Collapse in={openItem === 2}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">We allow our clients to begin a trial period with up to three experts from our network per
                                                                      position. While we rigorously screen all applicants to ensure talent and intelligence, we
                                                                      understand that not every person will be a perfect fit for every company. For this reason, we
                                                                      allow our clients to work with multiple experts for each position before they decide on a
                                                                      candidate with whom they are confident and comfortable.

 </p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 3) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(3)}
                                                                  aria-expanded={openItem === 3}>
                                                                  How is Syncuppro different?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 3}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Many organizations are faced with the issue of finding reliable compliance specific talent. At
                                                                          Syncuppro we follow a rigorous screening process to identify and select the best talent the
                                                                          world has to offer. You'll work with experts in Healthcare, Aerospace, Manufacturing,
                                                                          Government contracting, Information Technology (never generalized reps) who are hand-
                                                                          selected to be the right match for you from our network.
                                                                      </p>


                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 4) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(4)}
                                                                  aria-expanded={openItem === 4}>
                                                                  What Does Syncuppro Offer?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 4}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Let Syncuppro help you connect with right compliance expert!
                                                                      </p>
                                                                      <p className="font-size-4">
                                                                          You can screen from in-depth database and shortlist and select the right fit for your
                                                                          organizational needs. Whether you need documentation support, internal audit, supplier audit
or external audit Syncuppro can connect you with an expert for your needs.</p>

                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 5) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(5)}
                                                                  aria-expanded={openItem === 5}>
                                                                  Are Experts certified?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 5}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Experts come from a variety of backgrounds, including Training organizations, Certification
                                                                          bodies, Consultants and Contractors. All Certification bodies are certified. Training organizations
                                                                          provide certified courses including Lead Auditor Training, Lead Implementor, Provisional Auditor
                                                                          and so on. Consultants are going to be certified to the standards. Per our Terms of Service,
                                                                          Syncuppro is a communications platform that connects Clients and Experts.
                                                                      </p>


                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>








                                                  </div>
                                              </div>
                                          </Tab.Pane>
                                         

                                      </Tab.Content>
                                      {/* <!-- Tab Content End --> */}
                                      {/* <!-- Tab Section End --> */}
                                  </div>
                              </Tab.Container>


                 
                          </div>}
                          {(showClients === false) && < div
                              className="col-xl-12 col-md-12"
                              data-aos="fade-left"
                              data-aos-duration="1000"
                          >

                              <Tab.Container id="left-tabs-example" defaultActiveKey="one">
                                  <div className="bg-white rounded-0 shadow-9">
                                      {/* <!-- Tab Section Start --> */}
                                      <Nav
                                          className="nav border-bottom border-mercury pl-10 "
                                          role="tablist"
                                      >
                                          <li className="tab-menu-items nav-item pr-12  ">
                                              <Nav.Link
                                                  eventKey="one"
                                                  className="text-uppercase font-size-4 font-weight-bold text-default-color py-3 px-0 " onClick={() => setOpenItem(2)}
                                              >
                                                  All User Questions
                        </Nav.Link>
                                          </li>
                                          <li className="tab-menu-items nav-item pr-12  ">
                                              <Nav.Link
                                                  eventKey="two"
                                                  className="text-uppercase font-size-4 font-weight-bold text-default-color py-3 px-0 " onClick={() => setOpenItem(2)}
                                              >
                                                  General Questions
                        </Nav.Link>
                                          </li>
                                         
                                      </Nav>
                                      {/* <!-- Tab Content --> */}
                                      <Tab.Content>
                                          <Tab.Pane eventKey="one">
                                              <div className="faq-content pt-lg-4 pt-6 pl-10 pr-10 pb-10">
                                                  <div
                                                      className="accordion rounded-10 border-green border-top-5 pl-1  mt-10"

                                                  >
                                                      <div className="border-bottom overflow-hidden ">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 2) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-3">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(2)}
                                                                  aria-expanded={openItem === 2}
                                                              >
                                                                  How many proposals can I send in a month?
                        </button>
                                                          </div>
                                                          <Collapse in={openItem === 2}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">At the beginning of every month all users are given 15 Proposal credits completely free.

 </p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 3) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(3)}
                                                                  aria-expanded={openItem === 3}>
                                                                  How to track client deliverables?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 3}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          The project status monitoring program allows for easy adoption of milestones of the contract
                                                                          and subsequent monitoring.
                                                                      </p>


                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 4) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(4)}
                                                                  aria-expanded={openItem === 4}>
                                                                  What to do if not receiving client feedback?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 4}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Reach out to the client and request feedback. Most of the times clients will provide it.
                                                                      </p>
                                                                      

                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 5) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(5)}
                                                                  aria-expanded={openItem === 5}>
                                                                  Why am I not getting shortlisted?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 5}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Most Experts get selected who have updated profile and are good match to client requirement.
                                                                          If accurate screening is done both from client and expert.
                                                                      </p>
                                                                      <p className="font-size-4">
                                                                          Here are some tips to keep your profile updated:</p>
                                                                      <p className="font-size-4"><i className="fa fa-arrow-right mr-2 pt-1" > </i> Update a good profile picture.<br />
                                                                          <i className="fa fa-arrow-right mr-2 pt-1" > </i>  Update all the certificates.<br />
                                                                          <i className="fa fa-arrow-right mr-2 pt-1" > </i> Ensure skill matrix is updated.<br />
                                                                          <i className="fa fa-arrow-right mr-2 pt-1" > </i> Update your resume and create a list of recently worked projects.<br /></p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>








                                                  </div>
                                              </div>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="two">
                                              <div className="faq-content pt-lg-4 pt-6 pl-10 pr-10 pb-10">
                                                  <div
                                                      className="accordion rounded-10 border-green border-top-5 pl-1  mt-10"

                                                  >
                                                      <div className="border-bottom overflow-hidden ">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 2) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-3">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(2)}
                                                                  aria-expanded={openItem === 2}
                                                              >
                                                                  How many proposals can I send in a month?
                        </button>
                                                          </div>
                                                          <Collapse in={openItem === 2}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">At the beginning of every month all users are given 15 Proposal credits completely free.

 </p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>
                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 3) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(3)}
                                                                  aria-expanded={openItem === 3}>
                                                                  How to track client deliverables?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 3}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          The project status monitoring program allows for easy adoption of milestones of the contract
                                                                          and subsequent monitoring.
                                                                      </p>


                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 4) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(4)}
                                                                  aria-expanded={openItem === 4}>
                                                                  What to do if not receiving client feedback?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 4}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Reach out to the client and request feedback. Most of the times clients will provide it.
                                                                      </p>


                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>

                                                      <div className="border-bottom overflow-hidden">
                                                          <div className={`mb - 0 border-bottom-0 ${(openItem == 5) ? 'bg-green-opacity-3' : 'bg-athens'} `} id="heading2-4">
                                                              <button
                                                                  className="btn-reset font-size-5 font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                                  type="button"
                                                                  onClick={() => setOpenItem(5)}
                                                                  aria-expanded={openItem === 5}>
                                                                  Why am I not getting shortlisted?
                                                              </button>
                                                          </div>
                                                          <Collapse in={openItem === 5}>
                                                              <div className="pr-7">
                                                                  <div className="mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6 px-5">
                                                                      <p className="font-size-4">
                                                                          Most Experts get selected who have updated profile and are good match to client requirement.
                                                                          If accurate screening is done both from client and expert.
                                                                      </p>
                                                                      <p className="font-size-4">
                                                                          Here are some tips to keep your profile updated:</p>
                                                                      <p className="font-size-4"><i className="fa fa-arrow-right mr-2 pt-1" > </i> Update a good profile picture.<br />
                                                                          <i className="fa fa-arrow-right mr-2 pt-1" > </i>  Update all the certificates.<br />
                                                                          <i className="fa fa-arrow-right mr-2 pt-1" > </i> Ensure skill matrix is updated.<br />
                                                                          <i className="fa fa-arrow-right mr-2 pt-1" > </i> Update your resume and create a list of recently worked projects.<br /></p>
                                                                  </div>
                                                              </div>
                                                          </Collapse>
                                                      </div>








                                                  </div>
                                              </div>
                                          </Tab.Pane>
                                      </Tab.Content>
                                      {/* <!-- Tab Content End --> */}
                                      {/* <!-- Tab Section End --> */}
                                  </div>
                              </Tab.Container>



                          </div>}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
